// CollapsibleTile.js
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ReactMarkdown from 'react-markdown';
import { Paperclip, ChevronDown, ChevronUp } from 'lucide-react'; // Added icons

/**
 * CollapsibleTile component - Renders as a small inline element when collapsed,
 * expanding to full width within its container when opened.
 */
const CollapsibleTile = ({ name, content, onToggle, isCodeBlock, language, isPreformatted }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const handleToggle = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  useEffect(() => {
    if (onToggle) {
      onToggle(isExpanded);
    }
  }, [isExpanded, onToggle]);

  const handleShowMore = useCallback((e) => {
    e.stopPropagation();
    setShowFullContent(true);
  }, []);

  const handleShowLess = useCallback((e) => {
    e.stopPropagation();
    setShowFullContent(false);
  }, []);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(content).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    });
  }, [content]);

  const getLineCount = (text) => {
    return String(text || '').split('\n').length;
  };

  const lineCount = getLineCount(content);
  const truncatedContent = content?.slice(0, 1000) ?? '';
  const isLongContent = content?.length > 1000;

  const CustomInlineCode = ({ children }) => (
    <code style={{
      fontFamily: 'monospace',
      fontSize: '0.9em',
      backgroundColor: '#3a3a3a',
      color: '#e5e7eb',
      padding: '1px 3px',
      borderRadius: '3px',
    }}>
      {children}
    </code>
  );

  const renderContent = () => {
    const displayContent = String(showFullContent || !isLongContent ? content : truncatedContent);

    if (isPreformatted) {
      return (
        <pre className="whitespace-pre-wrap break-words text-sm">
          {displayContent}
        </pre>
      );
    } else if (isCodeBlock) {
      return (
        <SyntaxHighlighter
          language={language || 'text'}
          style={vscDarkPlus}
          customStyle={{ maxWidth: '100%', paddingTop: '2rem', fontSize: '0.875rem' }}
          wrapLines={true}
          wrapLongLines={true}
        >
          {displayContent}
        </SyntaxHighlighter>
      );
    } else {
      return (
        <ReactMarkdown
          components={{
            code({ node, inline, className, children, ...props }) {
              return inline ? (
                <CustomInlineCode>{children}</CustomInlineCode>
              ) : (
                <SyntaxHighlighter
                  language={language || 'text'}
                  style={vscDarkPlus}
                  PreTag="div"
                  customStyle={{ maxWidth: '100%', paddingTop: '2rem', fontSize: '0.875rem' }}
                  wrapLines={true}
                  wrapLongLines={true}
                  {...props}
                >
                  {String(children).replace(/\n$/, '')}
                </SyntaxHighlighter>
              );
            },
            p: ({children}) => <p className="mb-2 last:mb-0">{children}</p>
          }}
        >
          {displayContent}
        </ReactMarkdown>
      );
    }
  };

  const tileName = isCodeBlock ? 'Code Block' : name || 'Attachment';

  return (
    // Conditional outer container: inline when collapsed, block+full-width when expanded
    <div className={`mb-2 align-top ${isExpanded ? 'block w-full' : 'inline-block'}`}>
      <button
        onClick={handleToggle}
        // Inline-flex, small padding, no w-full. Conditional bottom rounding.
        className={`inline-flex items-center gap-1.5 bg-[#2c2c2c] text-white px-2 py-1 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-400 transition-colors duration-200 text-left hover:bg-[#3a3a3a] ${
          isExpanded ? 'rounded-b-none w-full' : '' // Make button full width ONLY when expanded to match container
        }`}
        title={isExpanded ? 'Collapse' : `Open ${tileName}`}
      >
        <Paperclip size={14} className="flex-shrink-0 text-gray-400" />
        <span className="font-medium text-sm flex-grow truncate">{tileName}</span>
        {/* Show different icon based on state */}
        {isExpanded ? (
           <ChevronUp size={16} className="flex-shrink-0 text-gray-400 ml-1" />
        ) : (
           <ChevronDown size={16} className="flex-shrink-0 text-gray-400 ml-1" />
        )}
      </button>

      {isExpanded && (
        // Expanded content: full width, appears below button
        <div className="p-3 bg-[#2c2c2c] rounded-b-lg text-left max-h-[70vh] w-full overflow-auto relative text-white">
          {/* Buttons remain absolute */}
          <button
            onClick={handleCopy}
            className="absolute top-2 right-2 z-10 bg-blue-600 text-white px-2 py-0.5 rounded text-xs hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-400 transition-colors duration-200"
          >
            {isCopied ? 'Copied!' : 'Copy'}
          </button>
          <div className="absolute top-2 left-2 z-10 bg-[#3a3a3a] text-gray-300 px-2 py-0.5 rounded text-xs">
            {lineCount} lines
          </div>

          {/* Content container */}
          <div className="max-w-full pt-4">
            {renderContent()}
          </div>

          {/* Show More/Less Button */}
          {isLongContent && (
            <div className="mt-2 text-center">
              {!showFullContent ? (
                <button
                  onClick={handleShowMore}
                  className="text-blue-400 hover:text-blue-300 text-sm px-2 py-1 rounded hover:bg-[#3a3a3a]"
                >
                  Show More ({content.length - truncatedContent.length} more chars)
                </button>
              ) : (
                <button
                  onClick={handleShowLess}
                  className="text-blue-400 hover:text-blue-300 text-sm px-2 py-1 rounded hover:bg-[#3a3a3a]"
                >
                  Show Less
                </button>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

CollapsibleTile.propTypes = {
  name: PropTypes.string,
  content: PropTypes.string.isRequired,
  onToggle: PropTypes.func,
  isCodeBlock: PropTypes.bool,
  language: PropTypes.string,
  isPreformatted: PropTypes.bool,
};

CollapsibleTile.defaultProps = {
  isCodeBlock: false,
  isPreformatted: false,
  name: 'Attachment',
};

export default CollapsibleTile;