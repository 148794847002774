import React, { useState, useEffect } from 'react';
import {
  ShoppingBag,
  Search,
  User,
  Heart,
  ShoppingCart,
  Menu,
  X,
  ChevronDown,
  Star,
  Filter,
  Grid,
  List,
  ArrowRight,
  Plus,
  Minus,
  Truck,
  RefreshCw,
  Shield,
  CreditCard,
  Instagram,
  Facebook,
  Twitter,
  Mail
} from 'lucide-react';
import EcommerceCategory from './EcommerceCategory';
import EcommerceCheckout from './EcommerceCheckout';

const EcommerceDemo = ({ activeDemoTab = 'home', setActiveDemoTab }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [activeCategory, setActiveCategory] = useState('all');
  const [viewStyle, setViewStyle] = useState('grid');
  const [activeProduct, setActiveProduct] = useState(null);
  const [activeTab, setActiveTab] = useState('description');
  const [quantity, setQuantity] = useState(1);
  const [scrolled, setScrolled] = useState(false);
  const [selectedColor, setSelectedColor] = useState('Midnight Blue');
  const [selectedSize, setSelectedSize] = useState('M');

  // Sample categories
  const categories = [
    { id: 'all', name: 'All Products' },
    { id: 'new', name: 'New Arrivals' },
    { id: 'best', name: 'Best Sellers' },
    { id: 'tops', name: 'Tops' },
    { id: 'bottoms', name: 'Bottoms' },
    { id: 'outerwear', name: 'Outerwear' },
    { id: 'accessories', name: 'Accessories' },
    { id: 'sale', name: 'Sale' }
  ];

  // Sample products
  const products = [
    {
      id: 1,
      name: 'Premium Cotton T-Shirt',
      price: 34.99,
      rating: 4.8,
      reviewCount: 124,
      category: 'tops',
      tags: ['best', 'new'],
      colors: ['Midnight Blue', 'Charcoal Gray', 'Forest Green', 'White'],
      sizes: ['XS', 'S', 'M', 'L', 'XL', 'XXL'],
      images: [
        'https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80',
        'https://images.unsplash.com/photo-1586363104862-3a5e2ab60d99?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1471&q=80',
        'https://images.unsplash.com/photo-1618677831708-0e7fda3148b0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80'
      ],
      isFeatured: true,
      isNew: true,
      isSale: false,
      salePrice: null,
      description: 'Our premium cotton t-shirt is crafted from 100% organic combed cotton for exceptional softness and durability. The breathable fabric ensures all-day comfort, while the classic fit flatters every body type. Featuring reinforced seams and a tagless collar to prevent irritation, this versatile staple is designed to maintain its shape and color through countless washes.',
      features: [
        '100% organic combed cotton',
        'Reinforced seams for durability',
        'Tagless collar for comfort',
        'Pre-shrunk fabric',
        'Ethically manufactured'
      ],
      care: 'Machine wash cold with similar colors. Tumble dry low. Do not bleach. Warm iron if needed.'
    },
    {
      id: 2,
      name: 'Slim Fit Chino Pants',
      price: 69.95,
      rating: 4.5,
      reviewCount: 98,
      category: 'bottoms',
      tags: ['best'],
      colors: ['Khaki', 'Navy', 'Olive', 'Black'],
      sizes: ['28', '30', '32', '34', '36', '38'],
      images: [
        'https://images.unsplash.com/photo-1473966968600-fa801b869a1a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80',
        'https://images.unsplash.com/photo-1624378439575-d8705ad7ae80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1497&q=80'
      ],
      isFeatured: false,
      isNew: false,
      isSale: false,
      salePrice: null,
      description: 'Our Slim Fit Chino Pants offer a modern, tailored look without sacrificing comfort. Made from premium cotton with a touch of elastane for stretch, these versatile pants transition seamlessly from work to weekend. The slim fit through the hip and thigh creates a sleek silhouette, while the slightly tapered leg provides a contemporary profile.',
      features: [
        '98% cotton, 2% elastane for comfort stretch',
        'Zip fly with button closure',
        'Side slash pockets and back welt pockets',
        'Wrinkle-resistant fabric',
        'Available in multiple inseam lengths'
      ],
      care: 'Machine wash cold. Tumble dry low. Warm iron if needed. Do not bleach.'
    },
    {
      id: 3,
      name: 'Waterproof Mountain Jacket',
      price: 199.99,
      rating: 4.9,
      reviewCount: 67,
      category: 'outerwear',
      tags: ['new'],
      colors: ['Red', 'Blue', 'Black', 'Yellow'],
      sizes: ['S', 'M', 'L', 'XL'],
      images: [
        'https://images.unsplash.com/photo-1547949003-9792a18a2601?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
        'https://images.unsplash.com/photo-1542327897-d73f4005b533?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80'
      ],
      isFeatured: true,
      isNew: true,
      isSale: false,
      salePrice: null,
      description: 'Conquer the elements with our Waterproof Mountain Jacket. Engineered with our proprietary 3-layer weatherproof membrane, this jacket provides exceptional protection against rain, snow, and wind, while maintaining breathability during high-energy activities. Multiple storage options, adjustable features, and a helmet-compatible hood make this the ultimate outdoor companion.',
      features: [
        'Waterproof rating: 20,000mm',
        'Breathability rating: 15,000g/m²/24h',
        'Fully taped seams',
        'Helmet-compatible adjustable hood',
        'Underarm ventilation zips',
        'Multiple internal and external pockets',
        'Adjustable hem and cuffs'
      ],
      care: 'Machine wash cold on gentle cycle. Hang to dry. Do not use fabric softener or bleach.'
    },
    {
      id: 4,
      name: 'Leather Weekend Duffle',
      price: 249.95,
      salePrice: 199.95,
      rating: 4.7,
      reviewCount: 45,
      category: 'accessories',
      tags: ['sale'],
      colors: ['Brown', 'Black', 'Tan'],
      sizes: ['One Size'],
      images: [
        'https://images.unsplash.com/photo-1590874103328-eac38a683ce7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1476&q=80',
        'https://images.unsplash.com/photo-1553062407-98eeb64c6a62?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80'
      ],
      isFeatured: false,
      isNew: false,
      isSale: true,
      description: 'Our Leather Weekend Duffle combines timeless style with practical design for the modern traveler. Crafted from full-grain leather that develops a rich patina over time, this bag features a spacious main compartment, multiple organizational pockets, and durable hardware. Perfect for weekend getaways or as a stylish gym bag.',
      features: [
        'Full-grain leather construction',
        'Durable canvas lining',
        'Solid brass hardware',
        'Detachable, adjustable shoulder strap',
        'Interior and exterior pockets',
        'Dimensions: 22"L x 12"H x 10"W',
        'Capacity: 40L'
      ],
      care: 'Spot clean with damp cloth. Treat leather with conditioner every 3-6 months. Store stuffed to maintain shape.'
    }
  ];

  // Filter products based on active category
  const filteredProducts = activeCategory === 'all'
     ? products
     : activeCategory === 'new'
    ? products.filter(product => product.isNew)
    : activeCategory === 'best'
    ? products.filter(product => product.tags.includes('best'))
    : activeCategory === 'sale'
    ? products.filter(product => product.isSale)
    : products.filter(product => product.category === activeCategory);

  // Track scroll position for sticky header
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Handle product details view
  const openProductDetails = (product) => {
    setActiveProduct(product);
    setQuantity(1); // Reset quantity when opening new product
    setSelectedColor(product.colors[0]);
    setSelectedSize(product.sizes[0]);
    window.scrollTo(0, 0); // Scroll to top when viewing details
  };

  const closeProductDetails = () => {
    setActiveProduct(null);
  };

  // Handle quantity change
  const incrementQuantity = () => {
    setQuantity(prev => prev + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(prev => prev - 1);
    }
  };

  // Sample Cart Items
  const cartItems = [
    {
      id: 2,
      name: 'Slim Fit Chino Pants',
      price: 69.95,
      color: 'Khaki',
      size: '32',
      quantity: 1,
      image: 'https://images.unsplash.com/photo-1473966968600-fa801b869a1a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80'
    }
  ];

  // If activeDemoTab is "category", render the EcommerceCategory component
  if (activeDemoTab === 'category') {
    return <EcommerceCategory />;
  }

  // If activeDemoTab is "checkout", render the EcommerceCheckout component
  if (activeDemoTab === 'checkout') {
    return <EcommerceCheckout />;
  }

  // Otherwise, render the main EcommerceDemo home page
  return (
    <div className="bg-white font-sans">
      {/* Announcement Bar */}
      <div className="bg-gray-900 text-white py-2 text-center text-sm">
        <p>Free shipping on orders over $100 | Use code WELCOME15 for 15% off your first order</p>
      </div>
      {/* Header Navigation - Mobile-first design with UX principles */}
      <header className={`sticky top-0 z-50 transition-all duration-300 ${scrolled ? 'bg-white shadow-md py-3' : 'bg-white py-4'}`}>
        <div className="w-full max-w-7xl mx-auto px-4 sm:px-6">
          {/* Mobile Header - Simple, focused 3-item layout following design patterns */}
          <div className="md:hidden grid grid-cols-3 items-center">
            {/* Left: Menu button - standard position */}
            <div className="flex justify-start">
              <button 
                className="text-gray-600 p-2"
                onClick={() => setIsNavOpen(true)}
                aria-label="Menu"
              >
                <Menu className="h-6 w-6" />
              </button>
            </div>
            
            {/* Center: Logo - most important element */}
            <div className="flex justify-center">
              <a href="#" className="text-xl font-bold text-gray-900 flex items-center">
                <ShoppingBag className="h-6 w-6 mr-2" />
                <span>NORDIC</span>
              </a>
            </div>
            
            {/* Right: Cart button - easy access */}
            <div className="flex justify-end">
              <button 
                className="text-gray-600 p-2 relative"
                onClick={() => setIsCartOpen(true)}
                aria-label="Cart"
              >
                <ShoppingCart className="h-6 w-6" />
                <span className="absolute -top-1 -right-1 bg-blue-600 text-white rounded-full text-xs h-4 w-4 flex items-center justify-center">
                  1
                </span>
              </button>
            </div>
          </div>
          
          {/* Desktop Header - Complete navigation and features */}
          <div className="hidden md:flex justify-between items-center">
            {/* Logo */}
            <a href="#" className="text-2xl font-bold text-gray-900 flex items-center">
              <ShoppingBag className="h-6 w-6 mr-3" />
              <span>NORDIC</span>
            </a>
            
        {/* Desktop Navigation */}
        <nav className="hidden md:flex items-center space-x-8">
          <a
            href="#"
            className={`text-gray-600 hover:text-gray-900 font-medium transition-colors ${activeDemoTab === 'home' ? 'text-blue-600' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              setActiveDemoTab('home');
            }}
          >
            Home
          </a>
          <a
            href="#"
            className={`text-gray-600 hover:text-gray-900 font-medium transition-colors ${activeDemoTab === 'category' ? 'text-blue-600' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              setActiveDemoTab('category');
            }}
          >
            Collections
          </a>
          <a
            href="#"
            className={`text-gray-600 hover:text-gray-900 font-medium transition-colors ${activeDemoTab === 'checkout' ? 'text-blue-600' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              setActiveDemoTab('checkout');
            }}
          >
            Checkout
          </a>
        </nav>
            
            {/* Desktop Action Icons */}
            <div className="flex items-center space-x-6">
              <button className="text-gray-600 hover:text-gray-900 p-1.5" aria-label="Search">
                <Search className="h-5 w-5" />
              </button>
              <button className="text-gray-600 hover:text-gray-900 p-1.5" aria-label="Account">
                <User className="h-5 w-5" />
              </button>
              <button className="text-gray-600 hover:text-gray-900 p-1.5" aria-label="Wishlist">
                <Heart className="h-5 w-5" />
              </button>
              <button 
                className="text-gray-600 hover:text-gray-900 relative p-1.5"
                onClick={() => setIsCartOpen(true)}
                aria-label="Cart"
              >
                <ShoppingCart className="h-5 w-5" />
                <span className="absolute -top-1 -right-1 bg-blue-600 text-white rounded-full text-xs h-4 w-4 flex items-center justify-center">
                  1
                </span>
              </button>
            </div>
          </div>
        </div>
      </header>
      {/* Mobile Navigation Overlay */}
      {isNavOpen && (
        <div className="fixed inset-0 z-50 bg-white p-4 overflow-y-auto">
          <div className="flex justify-between items-center mb-6">
            <a href="#" className="text-2xl font-bold text-gray-900 flex items-center">
              <ShoppingBag className="h-6 w-6 mr-2" />
              <span>NORDIC</span>
            </a>
            <button 
              className="text-gray-600"
              onClick={() => setIsNavOpen(false)}
            >
              <X className="h-6 w-6" />
            </button>
          </div>
          <nav className="flex flex-col space-y-6">
            {categories.map((category) => (
              <a 
                key={category.id}
                href="#"
                className="text-gray-900 font-medium py-2 border-b border-gray-100"
                onClick={(e) => {
                  e.preventDefault();
                  setActiveCategory(category.id);
                  setIsNavOpen(false);
                  setActiveProduct(null);
                }}
              >
                {category.name}
              </a>
            ))}
            <div className="pt-6 flex flex-col space-y-4">
              <a href="#" className="flex items-center text-gray-700">
                <User className="h-5 w-5 mr-2" />
                My Account
              </a>
              <a href="#" className="flex items-center text-gray-700">
                <Heart className="h-5 w-5 mr-2" />
                Wishlist
              </a>
              <button 
                className="flex items-center text-gray-700"
                onClick={() => {
                  setIsNavOpen(false);
                  setIsCartOpen(true);
                }}
              >
                <ShoppingCart className="h-5 w-5 mr-2" />
                Cart (1 item)
              </button>
            </div>
          </nav>
        </div>
      )}
      {/* Shopping Cart Sidebar */}
      {isCartOpen && (
        <div className="fixed inset-0 z-50 overflow-hidden">
          <div className="absolute inset-0 bg-black bg-opacity-50" onClick={() => setIsCartOpen(false)}></div>
          <div className="absolute inset-y-0 right-0 w-full max-w-md bg-white shadow-xl overflow-y-auto transform transition-transform duration-300">
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-bold text-gray-900">Your Cart (1)</h2>
                <button
                  className="text-gray-500 hover:text-gray-700"
                  onClick={() => setIsCartOpen(false)}
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              {cartItems.length === 0 ? (
                <div className="text-center py-12">
                  <ShoppingCart className="h-12 w-12 mx-auto text-gray-400 mb-4" />
                  <h3 className="text-lg font-medium text-gray-900 mb-2">Your cart is empty</h3>
                  <p className="text-gray-500 mb-6">Looks like you haven't added anything to your cart yet.</p>
                  <button 
                    className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
                    onClick={() => setIsCartOpen(false)}
                  >
                    Continue Shopping
                  </button>
                </div>
              ) : (
                <>
                  <div className="divide-y divide-gray-200">
                    {cartItems.map((item) => (
                      <div key={item.id} className="py-4 flex">
                        <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                          <img 
                            src={item.image}
                            alt={item.name}
                            className="h-full w-full object-cover object-center"
                          />
                        </div>
                        <div className="ml-4 flex flex-1 flex-col">
                          <div className="flex justify-between text-base font-medium text-gray-900">
                            <h3>{item.name}</h3>
                            <p className="ml-4">${item.price.toFixed(2)}</p>
                          </div>
                          <p className="mt-1 text-sm text-gray-500">
                            {item.color} / Size {item.size}
                          </p>
                          <div className="flex items-center justify-between text-sm mt-2">
                            <div className="flex border rounded-md">
                              <button className="px-2 py-1 text-gray-600 hover:bg-gray-100">-</button>
                              <span className="px-3 py-1 border-x">{item.quantity}</span>
                              <button className="px-2 py-1 text-gray-600 hover:bg-gray-100">+</button>
                            </div>
                            <button className="text-blue-600 hover:text-blue-500">Remove</button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mt-6 space-y-4">
                    <div className="flex justify-between text-base font-medium text-gray-900">
                      <p>Subtotal</p>
                      <p>$69.95</p>
                    </div>
                    <div className="flex justify-between text-sm text-gray-500">
                      <p>Shipping</p>
                      <p>Calculated at checkout</p>
                    </div>
                    <div className="border-t border-gray-200 pt-4 flex justify-between text-base font-medium text-gray-900">
                      <p>Total</p>
                      <p>$69.95</p>
                    </div>
                    <div className="mt-6">
                      <button 
                        className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
                        onClick={(e) => {
                          e.preventDefault();
                          setIsCartOpen(false);
                          setActiveDemoTab('checkout');
                        }}
                      >
                        Checkout
                      </button>
                    </div>
                    <div className="text-center mt-2">
                      <button 
                        className="text-blue-600 hover:text-blue-500 text-sm"
                        onClick={() => setIsCartOpen(false)}
                      >
                        Continue Shopping
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {/* Main Content */}
      <main className="container mx-auto px-4 md:px-6 py-8">
        {/* Product Details Page */}
        {activeProduct ? (
          <div className="bg-white">
            <div className="max-w-7xl mx-auto">
              {/* Breadcrumb */}
              <nav className="flex mb-6 text-sm text-gray-500">
                <a href="#" onClick={(e) => {
                  e.preventDefault();
                  closeProductDetails();
                }} className="hover:text-gray-900">Home</a>
                <span className="mx-2">/</span>
                <a href="#" onClick={(e) => {
                  e.preventDefault();
                  closeProductDetails();
                }} className="hover:text-gray-900">
                  {activeProduct.category.charAt(0).toUpperCase() + activeProduct.category.slice(1)}
                </a>
                <span className="mx-2">/</span>
                <span className="text-gray-900">{activeProduct.name}</span>
              </nav>
              <div className="grid md:grid-cols-2 gap-12">
                {/* Product Images */}
                <div className="space-y-4">
                  <div className="bg-gray-100 rounded-lg overflow-hidden">
                    <img 
                      src={activeProduct.images[0]}
                      alt={activeProduct.name}
                      className="w-full h-96 object-cover object-center"
                    />
                  </div>
                  <div className="grid grid-cols-3 gap-4">
                    {activeProduct.images.map((image, index) => (
                      <button key={index} className="bg-gray-100 rounded-lg overflow-hidden">
                        <img 
                          src={image}
                          alt={`${activeProduct.name} view ${index + 1}`}
                          className="w-full h-24 object-cover object-center"
                        />
                      </button>
                    ))}
                  </div>
                </div>
                {/* Product Info */}
                <div>
                  <h1 className="text-2xl font-bold text-gray-900 mb-2">{activeProduct.name}</h1>
                  
                  <div className="flex items-center mb-4">
                    <div className="flex text-yellow-400 mr-2">
                      {[...Array(5)].map((_, i) => (
                        <Star key={i} className={`h-4 w-4 ${i < Math.floor(activeProduct.rating) ? 'fill-current' : 'fill-none'}`} />
                      ))}
                    </div>
                    <span className="text-gray-600 text-sm">{activeProduct.rating} ({activeProduct.reviewCount} reviews)</span>
                  </div>
                  <div className="mb-6">
                    {activeProduct.isSale ? (
                      <div className="flex items-center">
                        <span className="text-2xl font-bold text-red-600 mr-2">${activeProduct.salePrice.toFixed(2)}</span>
                        <span className="text-lg text-gray-500 line-through">${activeProduct.price.toFixed(2)}</span>
                        <span className="ml-2 bg-red-100 text-red-700 text-xs px-2 py-1 rounded">
                          {Math.round((1 - activeProduct.salePrice / activeProduct.price) * 100)}% OFF
                        </span>
                      </div>
                    ) : (
                      <span className="text-2xl font-bold text-gray-900">${activeProduct.price.toFixed(2)}</span>
                    )}
                  </div>
                  {/* Color Selection */}
                  <div className="mb-6">
                    <h3 className="text-sm font-medium text-gray-900 mb-3">Color: {selectedColor}</h3>
                    <div className="flex space-x-2">
                      {activeProduct.colors.map((color) => (
                        <button
                          key={color}
                          onClick={() => setSelectedColor(color)}
                          className={`w-9 h-9 rounded-full flex items-center justify-center border ${
                            selectedColor === color 
                              ? 'border-blue-600 ring-2 ring-blue-200'
                              : 'border-gray-300'
                          }`}
                        >
                          <span 
                            className="w-7 h-7 rounded-full border border-white"
                            style={{ 
                              backgroundColor: 
                                color === 'Midnight Blue' ? '#1e3a8a' :
                                color === 'Charcoal Gray' ? '#4b5563' :
                                color === 'Forest Green' ? '#064e3b' :
                                color === 'White' ? '#ffffff' :
                                color === 'Khaki' ? '#d4c19c' :
                                color === 'Navy' ? '#172554' :
                                color === 'Olive' ? '#65a30d' :
                                color === 'Black' ? '#000000' :
                                color === 'Red' ? '#dc2626' :
                                color === 'Blue' ? '#2563eb' :
                                color === 'Yellow' ? '#fcd34d' :
                                color === 'Brown' ? '#92400e' :
                                color === 'Tan' ? '#d6d3d1' :
                                '#000000'
                            }}
                          ></span>
                        </button>
                      ))}
                    </div>
                  </div>
                  {/* Size Selection */}
                  <div className="mb-6">
                    <div className="flex justify-between items-center mb-3">
                      <h3 className="text-sm font-medium text-gray-900">Size: {selectedSize}</h3>
                      <button className="text-sm text-blue-600 hover:text-blue-500">Size Guide</button>
                    </div>
                    <div className="grid grid-cols-4 sm:grid-cols-6 gap-2">
                      {activeProduct.sizes.map((size) => (
                        <button
                          key={size}
                          onClick={() => setSelectedSize(size)}
                          className={`border rounded-md py-2 text-sm font-medium transition-colors ${
                            selectedSize === size 
                              ? 'border-blue-600 bg-blue-50 text-blue-600'
                              : 'border-gray-300 text-gray-700 hover:bg-gray-50'
                          }`}
                        >
                          {size}
                        </button>
                      ))}
                    </div>
                  </div>
                  {/* Quantity Selector */}
                  <div className="mb-6">
                    <h3 className="text-sm font-medium text-gray-900 mb-3">Quantity</h3>
                    <div className="flex border border-gray-300 rounded-md w-36">
                      <button 
                        onClick={decrementQuantity}
                        className="px-3 py-2 text-gray-600 hover:bg-gray-100 transition-colors"
                        disabled={quantity <= 1}
                      >
                        <Minus className="h-4 w-4" />
                      </button>
                      <span className="flex-1 text-center py-2 border-x border-gray-300">{quantity}</span>
                      <button 
                        onClick={incrementQuantity}
                        className="px-3 py-2 text-gray-600 hover:bg-gray-100 transition-colors"
                      >
                        <Plus className="h-4 w-4" />
                      </button>
                    </div>
                  </div>
                  {/* Add to Cart Button */}
                  <div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0 sm:space-x-3 mb-8">
                    <button 
                      className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center"
                    >
                      Add to Cart
                      <ShoppingCart className="h-5 w-5 ml-2" />
                    </button>
                    <button 
                      className="w-full border border-gray-300 text-gray-700 px-6 py-3 rounded-lg hover:bg-gray-50 transition-colors flex items-center justify-center"
                    >
                      <Heart className="h-5 w-5 mr-2" />
                      Add to Wishlist
                    </button>
                  </div>
                  {/* Product Benefits */}
                  <div className="grid grid-cols-2 gap-4 mb-8">
                    <div className="flex items-center text-gray-600">
                      <Truck className="h-5 w-5 mr-2 text-blue-600" />
                      <span className="text-sm">Free shipping over $100</span>
                    </div>
                    <div className="flex items-center text-gray-600">
                      <RefreshCw className="h-5 w-5 mr-2 text-blue-600" />
                      <span className="text-sm">Free 30-day returns</span>
                    </div>
                    <div className="flex items-center text-gray-600">
                      <Shield className="h-5 w-5 mr-2 text-blue-600" />
                      <span className="text-sm">2-year warranty</span>
                    </div>
                    <div className="flex items-center text-gray-600">
                      <CreditCard className="h-5 w-5 mr-2 text-blue-600" />
                      <span className="text-sm">Secure checkout</span>
                    </div>
                  </div>
                  {/* Description Tabs */}
                  <div className="border-t border-gray-200 pt-6">
                    <div className="flex border-b border-gray-200">
                      <button
                        onClick={() => setActiveTab('description')}
                        className={`py-3 px-4 text-sm font-medium -mb-px ${
                          activeTab === 'description'
                            ? 'border-b-2 border-blue-600 text-blue-600'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                      >
                        Description
                      </button>
                      <button
                        onClick={() => setActiveTab('features')}
                        className={`py-3 px-4 text-sm font-medium -mb-px ${
                          activeTab === 'features'
                            ? 'border-b-2 border-blue-600 text-blue-600'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                      >
                        Features
                      </button>
                      <button
                        onClick={() => setActiveTab('care')}
                        className={`py-3 px-4 text-sm font-medium -mb-px ${
                          activeTab === 'care'
                            ? 'border-b-2 border-blue-600 text-blue-600'
                            : 'text-gray-500 hover:text-gray-700'
                        }`}
                      >
                        Care
                      </button>
                    </div>
                    <div className="py-4">
                      {activeTab === 'description' && (
                        <p className="text-gray-600">{activeProduct.description}</p>
                      )}
                      {activeTab === 'features' && (
                        <ul className="list-disc list-inside space-y-2 text-gray-600">
                          {activeProduct.features.map((feature, index) => (
                            <li key={index}>{feature}</li>
                          ))}
                        </ul>
                      )}
                      {activeTab === 'care' && (
                        <p className="text-gray-600">{activeProduct.care}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Related Products */}
              <div className="mt-16">
                <h2 className="text-xl font-bold text-gray-900 mb-6">You May Also Like</h2>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                  {products.filter(p => p.id !== activeProduct.id).slice(0, 4).map((product) => (
                    <div key={product.id} className="group">
                      <div className="bg-gray-100 rounded-lg overflow-hidden mb-3 relative aspect-square">
                        <img 
                          src={product.images[0]}
                          alt={product.name}
                          className="w-full h-full object-cover object-center group-hover:scale-105 transition-transform duration-300"
                        />
                        {product.isSale && (
                          <span className="absolute top-2 right-2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded">
                            SALE
                          </span>
                        )}
                        {product.isNew && (
                          <span className="absolute top-2 left-2 bg-blue-500 text-white text-xs font-bold px-2 py-1 rounded">
                            NEW
                          </span>
                        )}
                        <div className="absolute inset-0 bg-black bg-opacity-20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                          <button 
                            onClick={() => openProductDetails(product)}
                            className="bg-white text-gray-900 px-4 py-2 rounded-lg text-sm font-medium transform -translate-y-2 group-hover:translate-y-0 transition-transform duration-300"
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                      <h3 className="font-medium text-gray-900 mb-1">{product.name}</h3>
                      <div className="flex items-center justify-between">
                        {product.isSale ? (
                          <div className="flex items-center">
                            <span className="font-bold text-red-600 mr-2">${product.salePrice.toFixed(2)}</span>
                            <span className="text-sm text-gray-500 line-through">${product.price.toFixed(2)}</span>
                          </div>
                        ) : (
                          <span className="font-bold text-gray-900">${product.price.toFixed(2)}</span>
                        )}
                        <div className="flex text-yellow-400 text-xs">
                          {[...Array(5)].map((_, i) => (
                            <Star key={i} className={`h-3 w-3 ${i < Math.floor(product.rating) ? 'fill-current' : 'fill-none'}`} />
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {/* Page Title and Filters */}
            <div className="mb-8">
              <h1 className="text-2xl font-bold text-gray-900 mb-4">
                {categories.find(c => c.id === activeCategory)?.name || 'All Products'}
              </h1>
              <div className="flex flex-wrap items-center justify-between gap-4">
                <div className="flex items-center space-x-2">
                  <button
                    className={`p-2 text-gray-600 hover:text-gray-900 ${viewStyle === 'grid' ? 'bg-gray-100 text-gray-900 rounded' : ''}`}
                    onClick={() => setViewStyle('grid')}
                    aria-label="Grid view"
                  >
                    <Grid className="h-5 w-5" />
                  </button>
                  <button
                    className={`p-2 text-gray-600 hover:text-gray-900 ${viewStyle === 'list' ? 'bg-gray-100 text-gray-900 rounded' : ''}`}
                    onClick={() => setViewStyle('list')}
                    aria-label="List view"
                  >
                    <List className="h-5 w-5" />
                  </button>
                  <span className="text-gray-500 text-sm">Showing {filteredProducts.length} products</span>
                </div>
                <div className="flex items-center space-x-4">
                  <div className="relative">
                    <button className="flex items-center space-x-1 text-gray-700 hover:text-gray-900 font-medium">
                      <Filter className="h-4 w-4" />
                      <span>Filter</span>
                    </button>
                  </div>
                  <div className="hidden md:block relative">
                    <select className="p-2 pr-8 border border-gray-300 rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500">
                      <option>Sort by: Featured</option>
                      <option>Price: Low to High</option>
                      <option>Price: High to Low</option>
                      <option>Newest First</option>
                      <option>Best Selling</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {/* Product Grid View */}
            {viewStyle === 'grid' ? (
              <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {filteredProducts.map((product) => (
                  <div key={product.id} className="group">
                    <div className="bg-gray-100 rounded-lg overflow-hidden mb-3 relative aspect-square">
                      <img 
                        src={product.images[0]}
                        alt={product.name}
                        className="w-full h-full object-cover object-center group-hover:scale-105 transition-transform duration-300"
                      />
                      {product.isSale && (
                        <span className="absolute top-2 right-2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded">
                          SALE
                        </span>
                      )}
                      {product.isNew && (
                        <span className="absolute top-2 left-2 bg-blue-500 text-white text-xs font-bold px-2 py-1 rounded">
                          NEW
                        </span>
                      )}
                      <div className="absolute inset-0 bg-black bg-opacity-20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                        <button 
                          onClick={() => openProductDetails(product)}
                          className="bg-white text-gray-900 px-4 py-2 rounded-lg text-sm font-medium transform -translate-y-2 group-hover:translate-y-0 transition-transform duration-300"
                        >
                          View Details
                        </button>
                      </div>
                    </div>
                    <h3 className="font-medium text-gray-900 mb-1">{product.name}</h3>
                    <div className="flex items-center justify-between">
                      {product.isSale ? (
                        <div className="flex items-center">
                          <span className="font-bold text-red-600 mr-2">${product.salePrice.toFixed(2)}</span>
                          <span className="text-sm text-gray-500 line-through">${product.price.toFixed(2)}</span>
                        </div>
                      ) : (
                        <span className="font-bold text-gray-900">${product.price.toFixed(2)}</span>
                      )}
                      <div className="flex text-yellow-400 text-xs">
                        {[...Array(5)].map((_, i) => (
                          <Star key={i} className={`h-3 w-3 ${i < Math.floor(product.rating) ? 'fill-current' : 'fill-none'}`} />
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              // Product List View
              <div className="space-y-6">
                {filteredProducts.map((product) => (
                  <div key={product.id} className="flex flex-col sm:flex-row bg-white border border-gray-200 rounded-lg overflow-hidden hover:shadow-md transition-shadow duration-300">
                    <div className="sm:w-52 h-48 bg-gray-100 relative flex-shrink-0">
                      <img 
                        src={product.images[0]}
                        alt={product.name}
                        className="w-full h-full object-cover object-center"
                      />
                      {product.isSale && (
                        <span className="absolute top-2 right-2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded">
                          SALE
                        </span>
                      )}
                      {product.isNew && (
                        <span className="absolute top-2 left-2 bg-blue-500 text-white text-xs font-bold px-2 py-1 rounded">
                          NEW
                        </span>
                      )}
                    </div>
                    <div className="p-4 flex-1 flex flex-col justify-between">
                      <div>
                        <h3 className="font-medium text-gray-900 mb-1">{product.name}</h3>
                        <div className="flex items-center mb-2">
                          <div className="flex text-yellow-400 mr-2">
                            {[...Array(5)].map((_, i) => (
                              <Star key={i} className={`h-4 w-4 ${i < Math.floor(product.rating) ? 'fill-current' : 'fill-none'}`} />
                            ))}
                          </div>
                          <span className="text-gray-600 text-sm">{product.rating} ({product.reviewCount} reviews)</span>
                        </div>
                        <p className="text-gray-600 text-sm mb-4 line-clamp-2">{product.description}</p>
                        <div className="flex flex-wrap gap-1 mb-4">
                          {product.colors.map((color) => (
                            <span key={color} className="inline-block px-2 py-1 bg-gray-100 text-gray-700 text-xs rounded">
                              {color}
                            </span>
                          ))}
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <div>
                          {product.isSale ? (
                            <div className="flex items-center">
                              <span className="font-bold text-red-600 mr-2">${product.salePrice.toFixed(2)}</span>
                              <span className="text-sm text-gray-500 line-through">${product.price.toFixed(2)}</span>
                            </div>
                          ) : (
                            <span className="font-bold text-gray-900">${product.price.toFixed(2)}</span>
                          )}
                        </div>
                        <div className="flex space-x-2">
                          <button 
                            onClick={() => openProductDetails(product)}
                            className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
                          >
                            View Details
                          </button>
                          <button className="bg-gray-100 text-gray-700 p-2 rounded hover:bg-gray-200 transition-colors">
                            <Heart className="h-5 w-5" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {/* Pagination */}
            <div className="mt-12 flex justify-center">
              <nav className="inline-flex rounded-md shadow">
                <a href="#" className="px-4 py-2 rounded-l-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-50">
                  Previous
                </a>
                <a href="#" className="px-4 py-2 border-t border-b border-gray-300 bg-blue-50 text-blue-600 font-medium">
                  1
                </a>
                <a href="#" className="px-4 py-2 border-t border-b border-gray-300 bg-white text-gray-700 hover:bg-gray-50">
                  2
                </a>
                <a href="#" className="px-4 py-2 border-t border-b border-gray-300 bg-white text-gray-700 hover:bg-gray-50">
                  3
                </a>
                <a href="#" className="px-4 py-2 rounded-r-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-50">
                  Next
                </a>
              </nav>
            </div>
          </>
        )}
      </main>
      {/* Features Section */}
      <section className="bg-gray-50 py-16">
        <div className="container mx-auto px-4 md:px-6">
          <div className="grid md:grid-cols-4 gap-8 text-center">
            <div className="flex flex-col items-center">
              <Truck className="h-10 w-10 text-blue-600 mb-4" />
              <h3 className="font-bold text-gray-900 mb-2">Free Shipping</h3>
              <p className="text-gray-600 text-sm">On all orders over $100</p>
            </div>
            <div className="flex flex-col items-center">
              <RefreshCw className="h-10 w-10 text-blue-600 mb-4" />
              <h3 className="font-bold text-gray-900 mb-2">Easy Returns</h3>
              <p className="text-gray-600 text-sm">30-day return policy</p>
            </div>
            <div className="flex flex-col items-center">
              <Shield className="h-10 w-10 text-blue-600 mb-4" />
              <h3 className="font-bold text-gray-900 mb-2">Secure Shopping</h3>
              <p className="text-gray-600 text-sm">Your data is protected</p>
            </div>
            <div className="flex flex-col items-center">
              <CreditCard className="h-10 w-10 text-blue-600 mb-4" />
              <h3 className="font-bold text-gray-900 mb-2">Flexible Payment</h3>
              <p className="text-gray-600 text-sm">Pay with multiple cards</p>
            </div>
          </div>
        </div>
      </section>
      {/* Newsletter */}
      <section className="bg-blue-600 py-16">
        <div className="container mx-auto px-4 md:px-6 text-center">
          <h2 className="text-2xl font-bold text-white mb-2">Join our newsletter</h2>
          <p className="text-blue-100 mb-6 max-w-xl mx-auto">Subscribe and be the first to know about new products, exclusive offers, and more.</p>
          <form className="flex flex-col sm:flex-row gap-2 max-w-md mx-auto">
            <input 
              type="email"
              placeholder="Enter your email"
              className="w-full px-4 py-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
              required
            />
            <button type="submit" className="w-full sm:w-auto bg-gray-900 text-white px-6 py-3 rounded-lg hover:bg-gray-800 transition-colors">
              Subscribe
            </button>
          </form>
        </div>
      </section>
      {/* Footer */}
      <footer className="bg-gray-900 text-gray-300 pt-16 pb-8">
        <div className="container mx-auto px-4 md:px-6">
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-12 mb-16">
            <div>
              <a href="#" className="text-2xl font-bold text-white flex items-center mb-4">
                <ShoppingBag className="h-6 w-6 mr-2" />
                <span>NORDIC</span>
              </a>
              <p className="mb-4">Quality clothing inspired by Nordic design principles - minimalist, functional, and sustainable.</p>
              <div className="flex space-x-4">
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <Facebook className="h-5 w-5" />
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <Instagram className="h-5 w-5" />
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <Twitter className="h-5 w-5" />
                </a>
              </div>
            </div>
            <div>
              <h3 className="font-bold text-white text-lg mb-4">Shop</h3>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-white transition-colors">New Arrivals</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Best Sellers</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Sale</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Tops</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Bottoms</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Outerwear</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Accessories</a></li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold text-white text-lg mb-4">Help</h3>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-white transition-colors">Customer Service</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Track Your Order</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Returns & Exchanges</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Shipping Information</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Size Guide</a></li>
                <li><a href="#" className="hover:text-white transition-colors">FAQ</a></li>
              </ul>
            </div>
            <div>
              <h3 className="font-bold text-white text-lg mb-4">About Us</h3>
              <ul className="space-y-2">
                <li><a href="#" className="hover:text-white transition-colors">Our Story</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Sustainability</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Careers</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Press</a></li>
                <li><a href="#" className="hover:text-white transition-colors">Contact Us</a></li>
              </ul>
            </div>
          </div>
          <div className="border-t border-gray-800 pt-8 flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm mb-4 md:mb-0">© 2023 NORDIC. All rights reserved.</p>
            <div className="flex space-x-8">
              <a href="#" className="text-sm hover:text-white transition-colors">Privacy Policy</a>
              <a href="#" className="text-sm hover:text-white transition-colors">Terms & Conditions</a>
              <a href="#" className="text-sm hover:text-white transition-colors">Accessibility</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default EcommerceDemo;