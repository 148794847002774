// ChatWindow.js
import React, { useState, useEffect, useRef, useCallback } from 'react'; // Added useCallback
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import { Loader2 } from 'lucide-react';

const ChatWindow = ({
  messages,
  inputMessage,
  setInputMessage,
  isGenerating,
  onSendMessage,
  onStopGeneration,
  pastes,
  onDeletePaste,
  onFileUpload,
  onAddPaste,
  listRef, // Existing ref for MessageList internal use
  currentlySelectedModel,
  experimentalFeatures,
  onToggleExperimental,
  isThreadLoading,
}) => {
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const lastMessageCountRef = useRef(messages.length);

  // Ref for the container wrapping MessageInput
  const inputContainerRef = useRef(null);
  // State to hold the dynamic padding value in pixels
  const [inputHeightPadding, setInputHeightPadding] = useState(80); // Default padding
  // Calculate the gap for bottom-1 (0.25rem * 16px/rem = 4px, adjust if base font size differs)
  const bottomGap = 4;

  // Effect to observe input container height and update padding
  useEffect(() => {
    const inputElement = inputContainerRef.current;
    if (!inputElement) return;

    const resizeObserver = new ResizeObserver(entries => {
      // Request animation frame to avoid layout thrashing and ensure latest dimensions
      window.requestAnimationFrame(() => {
        if (!entries || entries.length === 0) {
          return;
        }
        const { height } = entries[0].contentRect;
        // Update state with input height + desired gap
        setInputHeightPadding(height + bottomGap);
      });
    });

    resizeObserver.observe(inputElement);

    // Initial measurement in case observer is slow or doesn't fire initially
    const initialHeight = inputElement.getBoundingClientRect().height;
    if (initialHeight > 0) { // Ensure we have a valid height
       setInputHeightPadding(initialHeight + bottomGap);
    }


    // Cleanup function to disconnect observer
    return () => {
      resizeObserver.disconnect();
    };
    // Empty dependency array ensures this runs only on mount and unmount
  }, []); // Add bottomGap if it could ever change, but it's constant here

  // --- Auto-scroll logic (no changes needed here) ---
  useEffect(() => {
    const hasNewMessage = messages.length > lastMessageCountRef.current;
    if (hasNewMessage) {
      setShouldAutoScroll(true);
    }
    lastMessageCountRef.current = messages.length;
  }, [messages.length]);

  const handleSendMessageWrapper = useCallback(async (message) => { // Wrapped in useCallback
    setShouldAutoScroll(true);
    await onSendMessage(message);
    setTimeout(() => {
      if (listRef.current) {
        const container = listRef.current._outerRef;
        if (container) {
          container.scrollTo({
            top: container.scrollHeight,
            behavior: 'auto'
          });
        }
      }
    }, 50);
  }, [onSendMessage, listRef]); // Dependencies for useCallback

  const displayMessages = isGenerating
    ? [...messages, { type: 'loading', role: 'assistant' }]
    : messages;

  return (
    <div className="flex flex-col h-full relative">
      {/* Loading overlay */}
      {isThreadLoading && (
        <div className="absolute inset-0 bg-[#1a1a1a] bg-opacity-80 flex items-center justify-center z-50">
          <div className="flex flex-col items-center space-y-4 p-4 rounded-lg bg-[#2a2a2a]">
            <Loader2 className="w-8 h-8 text-orange-500 animate-spin" />
            <p className="text-white text-sm">Loading conversation...</p>
          </div>
        </div>
      )}

      {/* MessageList container - REMOVED pb-xx, ADDED dynamic style */}
      <div
        className="flex-grow overflow-hidden" // Removed pb-xx class
        style={{ paddingBottom: `${inputHeightPadding}px` }} // Apply dynamic padding
      >
        <MessageList
          messages={displayMessages}
          shouldAutoScroll={shouldAutoScroll}
          listRef={listRef} // Pass the existing listRef for internal MessageList use
        />
      </div>

      {/* MessageInput container - ADDED ref */}
      <div
        ref={inputContainerRef} // Attach ref here
        className="flex-shrink-0 absolute bottom-1 left-0 right-0 max-w-[1000px] w-full mx-auto px-2 sm:px-4 z-10"
      >
        <MessageInput
          inputMessage={inputMessage}
          setInputMessage={setInputMessage}
          isGenerating={isGenerating}
          onSendMessage={handleSendMessageWrapper}
          onStopGeneration={onStopGeneration}
          pastes={pastes}
          onDeletePaste={onDeletePaste}
          onFileUpload={onFileUpload}
          onAddPaste={onAddPaste}
          currentlySelectedModel={currentlySelectedModel}
          experimentalFeatures={experimentalFeatures}
          onToggleExperimental={onToggleExperimental}
          isDisabled={isThreadLoading}
          disabledMessage="Please wait while the conversation loads or start a new chat."
        />
      </div>
    </div>
  );
};

export default ChatWindow;