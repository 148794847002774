import React, { useState, useEffect } from 'react';
import { ArrowRight, Star, MapPin, Clock, Phone, Instagram, Facebook, Twitter, Menu, ChevronRight, Calendar, ShoppingBag } from 'lucide-react';

const RestaurantDemo = () => {
  const [activeMenuCategory, setActiveMenuCategory] = useState('appetizers');
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  // Track scroll position to add background to navbar when scrolled
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Sample menu data
  const menuItems = {
    appetizers: [
      {
        name: 'Bruschetta al Pomodoro',
        description: 'Toasted bread topped with fresh tomatoes, garlic, basil, and extra virgin olive oil',
        price: '12.95',
        tag: 'vegetarian',
        image: 'https://images.unsplash.com/photo-1572695157366-5e585ab2b69f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=200&q=80'
      },
      {
        name: 'Arancini',
        description: 'Crispy fried risotto balls filled with mozzarella, peas, and a touch of saffron',
        price: '14.50',
        tag: 'signature',
        image: 'https://images.unsplash.com/photo-1595295333158-4742f28fbd85?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=200&q=80'
      },
      {
        name: 'Calamari Fritti',
        description: 'Tender squid, lightly floured and fried, served with lemon and marinara sauce',
        price: '16.95',
        image: 'https://images.unsplash.com/photo-1599487488170-d11ec9c172f0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=200&q=80'
      }
    ],
    pasta: [
      {
        name: 'Spaghetti al Pomodoro',
        description: 'Spaghetti with San Marzano tomato sauce, fresh basil, and Parmigiano-Reggiano',
        price: '18.95',
        tag: 'vegetarian',
        image: 'https://images.unsplash.com/photo-1608219992759-8d74ed8d76eb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=200&q=80'
      },
      {
        name: 'Fettuccine Alfredo',
        description: 'Housemade fettuccine in a rich butter and Parmigiano-Reggiano sauce',
        price: '21.50',
        tag: 'vegetarian',
        image: 'https://images.unsplash.com/photo-1645112411341-6c4fd023714a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=200&q=80'
      },
      {
        name: 'Linguine alle Vongole',
        description: 'Linguine with fresh clams, white wine, garlic, red pepper flakes, and parsley',
        price: '24.95',
        tag: 'signature',
        image: 'https://images.unsplash.com/photo-1563379926898-05f4575a45d8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=200&q=80'
      }
    ],
    mains: [
      {
        name: 'Osso Buco',
        description: 'Braised veal shank with gremolata, served with saffron risotto',
        price: '34.95',
        tag: 'signature',
        image: 'https://images.unsplash.com/photo-1574484284002-952d92456975?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=200&q=80'
      },
      {
        name: 'Bistecca alla Fiorentina',
        description: 'Grilled T-bone steak with rosemary and garlic, served with roasted potatoes',
        price: '42.50',
        image: 'https://images.unsplash.com/photo-1504973960431-1c467e159aa4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=200&q=80'
      },
      {
        name: 'Branzino al Forno',
        description: 'Whole roasted Mediterranean sea bass with herbs, lemon, and olive oil',
        price: '36.95',
        image: 'https://images.unsplash.com/photo-1519708227418-c8fd9a32b7a2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=200&q=80'
      }
    ],
    desserts: [
      {
        name: 'Tiramisu',
        description: 'Classic Italian dessert with layers of coffee-soaked ladyfingers and mascarpone cream',
        price: '11.95',
        tag: 'signature',
        image: 'https://images.unsplash.com/photo-1571877227200-a0d98ea607e9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=200&q=80'
      },
      {
        name: 'Panna Cotta',
        description: 'Silky vanilla cream with seasonal berry compote',
        price: '10.50',
        image: 'https://images.unsplash.com/photo-1488477181946-6428a0291777?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=200&q=80'
      },
      {
        name: 'Cannoli Siciliani',
        description: 'Crisp pastry tubes filled with sweet ricotta cream and chocolate chips',
        price: '12.95',
        tag: 'vegetarian',
        image: 'https://images.unsplash.com/photo-1551024506-0bccd828d307?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=200&q=80'
      }
    ]
  };

  // Sample gallery images
  const galleryImages = [
    'https://images.unsplash.com/photo-1564758866811-4d92f84015e9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
    'https://images.unsplash.com/photo-1559339352-11d035aa65de?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80',
    'https://images.unsplash.com/photo-1592861956120-e524fc739696?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80',
    'https://images.unsplash.com/photo-1567610850848-889b8eaa6cc2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80'
  ];

  // Reviews
  const reviews = [
    {
      name: "Michael R.",
      rating: 5,
      comment: "The food was incredible! Authentic Italian flavors that transported me straight to Tuscany. Will definitely be back soon.",
      date: "October 15, 2023"
    },
    {
      name: "Sophia L.",
      rating: 5,
      comment: "Amazing ambiance and the service was impeccable. The Osso Buco was the best I've ever had. Highly recommended!",
      date: "September 28, 2023"
    },
    {
      name: "David T.",
      rating: 4,
      comment: "Great pasta dishes and excellent wine selection. The restaurant has a wonderful romantic atmosphere for date night.",
      date: "October 3, 2023"
    }
  ];

  return (
    <div className="bg-white font-serif">
    {/* Navigation */}
        <nav className={`fixed w-full z-10 transition-all duration-300 ${scrolled ? 'bg-white shadow-md py-2' : 'bg-transparent py-4'}`}>
          <div className="container mx-auto px-6 flex justify-between items-center">
            <a href="#" className="text-2xl font-bold italic tracking-wide text-amber-800">
            Sapore
            <span className="text-green-700 text-sm ml-1 non-italic">Ristorante</span>
            </a>
            
            {/* Desktop navigation */}
            <div className="hidden md:flex items-center space-x-8">
            <a href="#" className={`${scrolled ? 'text-amber-900' : 'text-white'} hover:text-amber-700 font-medium`}>Home</a>
            <a href="#menu" className={`${scrolled ? 'text-amber-900' : 'text-white'} hover:text-amber-700 font-medium`}>Menu</a>
            <a href="#about" className={`${scrolled ? 'text-amber-900' : 'text-white'} hover:text-amber-700 font-medium`}>About</a>
            <a href="#gallery" className={`${scrolled ? 'text-amber-900' : 'text-white'} hover:text-amber-700 font-medium`}>Gallery</a>
            <a href="#contact" className={`${scrolled ? 'text-amber-900' : 'text-white'} hover:text-amber-700 font-medium`}>Contact</a>
            <button className="bg-amber-600 text-white px-4 py-2 rounded-lg hover:bg-amber-700 transition duration-300 flex items-center">
              Reserve a Table
              <Calendar className="w-4 h-4 ml-2" />
            </button>
            <button className="bg-green-700 text-white px-4 py-2 rounded-lg hover:bg-green-800 transition duration-300 flex items-center">
              Order Online
              <ShoppingBag className="w-4 h-4 ml-2" />
            </button>
            </div>
            
            {/* Mobile menu button */}
          <button 
            className="md:hidden text-amber-900 focus:outline-none" 
            onClick={() => setIsNavOpen(!isNavOpen)}
          >
            <Menu className="h-6 w-6" />
          </button>
        </div>
        
        {/* Mobile navigation */}
        {isNavOpen && (
          <div className="md:hidden bg-white shadow-lg absolute w-full py-4">
            <div className="container mx-auto px-6 flex flex-col space-y-4">
              <a href="#" className="text-amber-900 hover:text-amber-700 font-medium py-2">Home</a>
              <a href="#menu" className="text-amber-900 hover:text-amber-700 font-medium py-2">Menu</a>
              <a href="#about" className="text-amber-900 hover:text-amber-700 font-medium py-2">About</a>
              <a href="#gallery" className="text-amber-900 hover:text-amber-700 font-medium py-2">Gallery</a>
              <a href="#contact" className="text-amber-900 hover:text-amber-700 font-medium py-2">Contact</a>
              <button className="bg-amber-600 text-white px-4 py-2 rounded-lg hover:bg-amber-700 transition duration-300 flex items-center justify-center">
                Reserve a Table
                <Calendar className="w-4 h-4 ml-2" />
              </button>
              <button className="bg-green-700 text-white px-4 py-2 rounded-lg hover:bg-green-800 transition duration-300 flex items-center justify-center">
                Order Online
                <ShoppingBag className="w-4 h-4 ml-2" />
              </button>
            </div>
          </div>
        )}
      </nav>

      {/* Hero Section */}
      <section className="relative h-screen flex items-center justify-center overflow-hidden">
        <div className="absolute inset-0 z-0">
          <img 
            src="https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80"
            alt="Italian cuisine"
            className="object-cover h-full w-full"
          />
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        <div className="container mx-auto px-4 sm:px-6 relative z-10 text-center">
          <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold text-white mb-4 italic">
            Authentic Italian Cuisine
          </h1>
          <p className="text-lg sm:text-xl text-white mb-6 sm:mb-8 max-w-2xl mx-auto">
            Experience the flavors of Italy in every bite. From hand-made pasta to wood-fired pizzas, our traditional recipes will transport you to the heart of Tuscany.
          </p>
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 justify-center">
            <button className="bg-amber-600 text-white px-6 py-3 rounded-lg hover:bg-amber-700 transition duration-300 flex items-center justify-center w-full sm:w-auto">
              View Menu
              <ChevronRight className="w-5 h-5 ml-2" />
            </button>
            <button className="bg-white text-amber-800 px-6 py-3 rounded-lg hover:bg-gray-100 transition duration-300 flex items-center justify-center w-full sm:w-auto">
              Reserve a Table
              <Calendar className="w-5 h-5 ml-2" />
            </button>
          </div>
        </div>
        
        {/* Down arrow with hover explainer */}
        <div className="absolute bottom-4 left-0 right-0 flex flex-col items-center z-10">
          <div className="group relative">
            <a href="#menu" className="text-white bg-amber-800 bg-opacity-50 rounded-full p-2 flex items-center justify-center animate-bounce hover:bg-amber-700 transition-all duration-300">
              <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
              </svg>
            </a>
            {/* Tooltip that appears on hover */}
            <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300 absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-white text-amber-800 text-xs font-medium py-1 px-3 rounded-lg shadow-lg whitespace-nowrap">
              View Our Menu
              <div className="absolute top-full left-1/2 transform -translate-x-1/2 w-2 h-2 rotate-45 bg-white"></div>
            </div>
          </div>
        </div>
      </section>

      {/* Special Announcement */}
      <section className="bg-amber-50 py-4 border-y border-amber-200">
        <div className="container mx-auto px-6 text-center">
          <p className="text-amber-800 font-medium">
            <span className="font-bold">New Seasonal Menu:</span> Try our fresh autumn dishes featuring locally sourced truffles and porcini mushrooms!
          </p>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-3xl font-bold text-amber-800 mb-6 italic">Our Story</h2>
              <p className="text-gray-700 mb-4">
                Founded in 1986 by the Rossi family, Sapore brings the authentic flavors of Tuscany to your table. Our head chef, Marco Rossi, learned the art of Italian cooking from his grandmother in the hills of Florence.
              </p>
              <p className="text-gray-700 mb-4">
                We take pride in using only the finest ingredients, imported directly from Italy or sourced locally from organic farms. Our pasta is made fresh daily, and our bread is baked in our wood-fired oven throughout the day.
              </p>
              <p className="text-gray-700 mb-6">
                At Sapore, we believe that food brings people together. Our warm, inviting atmosphere is designed to make you feel like you're dining in an Italian family home, where conversations flow as freely as our carefully selected wines.
              </p>
              <div className="flex items-center space-x-4">
                <img 
                  src="https://images.unsplash.com/photo-1566554273541-37a9ca77b91f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=150&q=80" 
                  alt="Chef Marco Rossi" 
                  className="rounded-full w-16 h-16 object-cover border-2 border-amber-600"
                />
                <div>
                  <h4 className="font-bold text-gray-900">Chef Marco Rossi</h4>
                  <p className="text-gray-600 text-sm">Head Chef & Owner</p>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <img 
                src="https://images.unsplash.com/photo-1559339352-11d035aa65de?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80" 
                alt="Restaurant interior" 
                className="rounded-lg shadow-lg h-64 object-cover"
              />
              <img 
                src="https://images.unsplash.com/photo-1600335895229-6e75511892c8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80" 
                alt="Fresh pasta preparation" 
                className="rounded-lg shadow-lg h-64 object-cover"
              />
              <img 
                src="https://images.unsplash.com/photo-1579684947550-22e945225d9a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80" 
                alt="Wood-fired oven" 
                className="rounded-lg shadow-lg h-64 object-cover"
              />
              <img 
                src="https://images.unsplash.com/photo-1602631985686-1bb0e6a8696e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80" 
                alt="Olive oil and ingredients" 
                className="rounded-lg shadow-lg h-64 object-cover"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Menu Section */}
      <section id="menu" className="py-20 bg-amber-50">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-amber-800 mb-2 text-center italic">Our Menu</h2>
          <p className="text-gray-700 mb-12 text-center max-w-3xl mx-auto">
            Savor the authentic flavors of Italy with our carefully crafted dishes made from traditional recipes and the finest ingredients.
          </p>

          {/* Menu Categories */}
          <div className="flex flex-wrap justify-center space-x-2 md:space-x-4 mb-12">
            {Object.keys(menuItems).map((category) => (
              <button
                key={category}
                onClick={() => setActiveMenuCategory(category)}
                className={`px-4 py-2 rounded-full mb-2 transition duration-300 ${
                  activeMenuCategory === category 
                    ? 'bg-amber-600 text-white' 
                    : 'bg-white text-amber-800 hover:bg-amber-200'
                }`}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            ))}
          </div>

          {/* Menu Items */}
          <div className="grid md:grid-cols-2 gap-8">
            {menuItems[activeMenuCategory].map((item, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden flex">
                <img 
                  src={item.image} 
                  alt={item.name} 
                  className="w-24 h-24 md:w-36 md:h-36 object-cover"
                />
                <div className="p-4 flex-1 flex flex-col justify-between">
                  <div>
                    <div className="flex justify-between items-start mb-1">
                      <h3 className="font-bold text-gray-900">{item.name}</h3>
                      <span className="text-amber-800 font-semibold">${item.price}</span>
                    </div>
                    <p className="text-gray-600 text-sm mb-2">{item.description}</p>
                  </div>
                  {item.tag && (
                    <span className={`text-xs py-1 px-2 rounded-full inline-block w-fit ${
                      item.tag === 'vegetarian' 
                        ? 'bg-green-100 text-green-800' 
                        : 'bg-amber-100 text-amber-800'
                    }`}>
                      {item.tag === 'vegetarian' ? 'Vegetarian' : 'Chef\'s Signature'}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="text-center mt-12">
            <button className="bg-amber-600 text-white px-6 py-3 rounded-lg hover:bg-amber-700 transition duration-300 flex items-center mx-auto">
              View Full Menu
              <ArrowRight className="w-5 h-5 ml-2" />
            </button>
          </div>
        </div>
      </section>

      {/* Gallery Section */}
      <section id="gallery" className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-amber-800 mb-2 text-center italic">Gallery</h2>
          <p className="text-gray-700 mb-12 text-center max-w-3xl mx-auto">
            A glimpse of our warm atmosphere, delicious dishes, and the authentic Italian dining experience we offer.
          </p>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {galleryImages.map((image, index) => (
              <div key={index} className="overflow-hidden rounded-lg shadow-md h-64 md:h-80">
                <img 
                  src={image} 
                  alt={`Restaurant gallery image ${index + 1}`} 
                  className="h-full w-full object-cover transition duration-300 transform hover:scale-110"
                />
              </div>
            ))}
          </div>

          <div className="text-center mt-12">
            <button className="bg-gray-100 text-amber-800 px-6 py-3 rounded-lg hover:bg-gray-200 transition duration-300 flex items-center mx-auto">
              View More Photos
              <ArrowRight className="w-5 h-5 ml-2" />
            </button>
          </div>
        </div>
      </section>

      {/* Reviews Section */}
      <section className="py-20 bg-amber-50">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-amber-800 mb-2 text-center italic">Guest Reviews</h2>
          <p className="text-gray-700 mb-12 text-center max-w-3xl mx-auto">
            Don't just take our word for it. Here's what our guests have to say about their dining experience at Sapore.
          </p>

          <div className="grid md:grid-cols-3 gap-8">
            {reviews.map((review, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6">
                <div className="flex items-center mb-4">
                  <div className="flex">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} className={`w-5 h-5 ${i < review.rating ? 'text-yellow-400 fill-yellow-400' : 'text-gray-300'}`} />
                    ))}
                  </div>
                  <span className="ml-2 text-sm text-gray-500">{review.date}</span>
                </div>
                <p className="text-gray-700 mb-4 italic">"{review.comment}"</p>
                <p className="font-semibold text-gray-900">- {review.name}</p>
              </div>
            ))}
          </div>

          <div className="text-center mt-12">
            <a href="#" className="text-amber-700 hover:text-amber-800 transition duration-300 flex items-center justify-center">
              Read More Reviews on Yelp
              <ArrowRight className="w-4 h-4 ml-1" />
            </a>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="relative py-20 bg-cover bg-center" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1513104890138-7c749659a591?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80)' }}>
        <div className="absolute inset-0 bg-black opacity-60"></div>
        <div className="container mx-auto px-6 relative">
          <div className="max-w-lg mx-auto text-center text-white">
            <h2 className="text-3xl font-bold mb-4 italic">Reserve Your Table</h2>
            <p className="mb-8">
              Experience the warm hospitality and exquisite flavors of Sapore. Book your table today for an unforgettable Italian dining experience.
            </p>
            <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 justify-center">
              <button className="bg-amber-600 text-white px-6 py-3 rounded-lg hover:bg-amber-700 transition duration-300 flex items-center justify-center">
                Make a Reservation
                <Calendar className="w-5 h-5 ml-2" />
              </button>
              <button className="bg-green-700 text-white px-6 py-3 rounded-lg hover:bg-green-800 transition duration-300 flex items-center justify-center">
                Order Online
                <ShoppingBag className="w-5 h-5 ml-2" />
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* Contact/Info Section */}
      <section id="contact" className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-2 gap-12">
            <div>
              <h2 className="text-3xl font-bold text-amber-800 mb-6 italic">Visit Us</h2>
              
              <div className="flex items-start mb-6">
                <MapPin className="w-6 h-6 text-amber-600 mr-4 flex-shrink-0 mt-1" />
                <div>
                  <h3 className="font-bold text-gray-900 mb-1">Address</h3>
                  <p className="text-gray-700">123 Pasta Lane, Little Italy</p>
                  <p className="text-gray-700">San Francisco, CA 94133</p>
                </div>
              </div>
              
              <div className="flex items-start mb-6">
                <Clock className="w-6 h-6 text-amber-600 mr-4 flex-shrink-0 mt-1" />
                <div>
                  <h3 className="font-bold text-gray-900 mb-1">Hours</h3>
                  <p className="text-gray-700">Monday - Thursday: 11:30 AM - 10:00 PM</p>
                  <p className="text-gray-700">Friday - Saturday: 11:30 AM - 11:00 PM</p>
                  <p className="text-gray-700">Sunday: 11:30 AM - 9:00 PM</p>
                </div>
              </div>
              
              <div className="flex items-start mb-8">
                <Phone className="w-6 h-6 text-amber-600 mr-4 flex-shrink-0 mt-1" />
                <div>
                  <h3 className="font-bold text-gray-900 mb-1">Contact</h3>
                  <p className="text-gray-700">Phone: (415) 555-1234</p>
                  <p className="text-gray-700">Email: info@saporeristorante.com</p>
                </div>
              </div>
              
              <div className="flex space-x-4">
                <a href="#" className="text-amber-600 hover:text-amber-800 transition duration-300">
                  <Facebook className="w-6 h-6" />
                </a>
                <a href="#" className="text-amber-600 hover:text-amber-800 transition duration-300">
                  <Instagram className="w-6 h-6" />
                </a>
                <a href="#" className="text-amber-600 hover:text-amber-800 transition duration-300">
                  <Twitter className="w-6 h-6" />
                </a>
              </div>
            </div>
            
            <div className="rounded-lg overflow-hidden shadow-lg h-80 md:h-auto">
              {/* Map would be embedded here - using a placeholder image for demo */}
              <div className="bg-gray-200 h-full w-full flex items-center justify-center">
                <div className="text-center p-6">
                  <MapPin className="w-8 h-8 text-amber-600 mx-auto mb-2" />
                  <p className="text-gray-700 font-medium">Interactive map would be embedded here</p>
                  <p className="text-gray-500 text-sm">123 Pasta Lane, Little Italy, San Francisco</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-amber-900 text-amber-100 py-12">
        <div className="container mx-auto px-6">
          <div className="grid md:grid-cols-4 gap-8 mb-8">
            <div>
              <h3 className="text-xl font-bold mb-4 italic">Sapore</h3>
              <p className="mb-4">
                Authentic Italian cuisine in the heart of San Francisco's Little Italy. Family-owned since 1986.
              </p>
              <div className="flex space-x-4">
                <a href="#" className="text-amber-200 hover:text-white transition duration-300">
                  <Facebook className="w-5 h-5" />
                </a>
                <a href="#" className="text-amber-200 hover:text-white transition duration-300">
                  <Instagram className="w-5 h-5" />
                </a>
                <a href="#" className="text-amber-200 hover:text-white transition duration-300">
                  <Twitter className="w-5 h-5" />
                </a>
              </div>
            </div>
            
            <div>
              <h4 className="font-bold mb-4">Hours</h4>
              <p className="text-sm mb-2">Monday - Thursday: 11:30 AM - 10:00 PM</p>
              <p className="text-sm mb-2">Friday - Saturday: 11:30 AM - 11:00 PM</p>
              <p className="text-sm mb-2">Sunday: 11:30 AM - 9:00 PM</p>
            </div>
            
            <div>
              <h4 className="font-bold mb-4">Contact</h4>
              <p className="text-sm mb-2">123 Pasta Lane, Little Italy</p>
              <p className="text-sm mb-2">San Francisco, CA 94133</p>
              <p className="text-sm mb-2">Phone: (415) 555-1234</p>
              <p className="text-sm">Email: info@saporeristorante.com</p>
            </div>
            <div>
            <h4 className="font-bold mb-4">Newsletter</h4>
            <p className="text-sm mb-4">Subscribe to our newsletter for special offers and events.</p>
            <div className="flex flex-col sm:flex-row">
                <input
                type="email"
                placeholder="Your email"
                className="px-3 py-2 text-gray-900 rounded-lg sm:rounded-r-none flex-1 focus:outline-none w-full"
                />
                <button className="bg-amber-600 text-white px-3 py-2 rounded-lg sm:rounded-l-none hover:bg-amber-700 transition duration-300 mt-2 sm:mt-0">
                Subscribe
                </button>
            </div>
            </div>
          </div>
          
          <div className="border-t border-amber-800 pt-8 flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm text-amber-300 mb-4 md:mb-0">
              &copy; {new Date().getFullYear()} Sapore Ristorante. All rights reserved.
            </p>
            <div className="flex space-x-8">
              <a href="#" className="text-sm text-amber-300 hover:text-white transition duration-300">Privacy Policy</a>
              <a href="#" className="text-sm text-amber-300 hover:text-white transition duration-300">Terms of Service</a>
              <a href="#" className="text-sm text-amber-300 hover:text-white transition duration-300">Accessibility</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default RestaurantDemo;