import React, { useState } from 'react';
import {
  FileText,
  Users,
  Award,
  Briefcase,
  CheckCircle,
  ChevronRight,
  ArrowRight,
  Calendar,
  Clock,
  MessageSquare
} from 'lucide-react';

const ProfessionalServicesPage = () => {
  const [activeTab, setActiveTab] = useState('all');
  const [selectedService, setSelectedService] = useState(null);
  
  // Primary service categories
  const serviceCategories = [
    { id: 'all', name: 'All Services' },
    { id: 'tax', name: 'Tax Services' },
    { id: 'accounting', name: 'Accounting & Bookkeeping' },
    { id: 'advisory', name: 'Business Advisory' },
    { id: 'wealth', name: 'Wealth Management' }
  ];
  
  // Service data
  const services = [
    {
      id: 1,
      name: "Personal Tax Preparation",
      category: "tax",
      icon: <FileText className="h-12 w-12 text-blue-600" />,
      description: "Comprehensive tax preparation services for individuals, ensuring accurate filing and maximum refunds.",
      benefits: [
        "Personalized tax strategies to minimize liability",
        "Electronic filing for faster refunds",
        "Year-round tax planning and support",
        "Representation in case of audit",
        "Previous year return review and amendment if needed"
      ],
      process: [
        "Initial consultation and document collection",
        "Thorough review of your financial situation",
        "Strategic tax planning to minimize liability",
        "Preparation and filing of all required forms",
        "Final review and explanation of your return"
      ],
      faqs: [
        {
          question: "When should I start preparing my tax return?",
          answer: "We recommend gathering documents as soon as you receive them, typically starting in January. The earlier you begin the process, the more time we have to identify all possible deductions and credits."
        },
        {
          question: "What documents do I need to provide?",
          answer: "You'll need W-2s, 1099s, mortgage interest statements, property tax records, charitable donation receipts, medical expenses, and information about any major life changes (marriage, children, home purchase, etc.)."
        }
      ]
    },
    {
      id: 2,
      name: "Business Tax Services",
      category: "tax",
      icon: <Briefcase className="h-12 w-12 text-blue-600" />,
      description: "Specialized tax planning and compliance services for businesses of all sizes and structures.",
      benefits: [
        "Tax-optimized business structure recommendations",
        "Strategic tax planning throughout the fiscal year",
        "Quarterly tax preparation and estimated payments",
        "Identification of all eligible business deductions",
        "State and local tax compliance"
      ],
      process: [
        "Comprehensive business assessment",
        "Development of customized tax strategy",
        "Regular financial reviews and adjustments",
        "Preparation and filing of all business returns",
        "Year-round planning for future tax periods"
      ],
      faqs: [
        {
          question: "What business expenses are tax deductible?",
          answer: "Common deductible expenses include operating expenses, employee salaries and benefits, rent, utilities, business travel, insurance, interest on business loans, depreciation of assets, and certain start-up costs. We'll help identify all deductions specific to your business."
        },
        {
          question: "Should my business be filing quarterly estimated taxes?",
          answer: "Most businesses should make quarterly estimated tax payments if they expect to owe $1,000 or more in taxes when they file their annual return. We'll help you calculate the correct amount and ensure timely filing to avoid penalties."
        }
      ]
    },
    {
      id: 3,
      name: "Monthly Bookkeeping",
      category: "accounting",
      icon: <Users className="h-12 w-12 text-blue-600" />,
      description: "Ongoing financial management to keep your business records accurate, organized, and up-to-date.",
      benefits: [
        "Timely and accurate financial statements",
        "Clear insight into cash flow and financial position",
        "Identification of potential tax savings",
        "Simplified tax preparation process",
        "Early detection of financial issues or opportunities"
      ],
      process: [
        "Setup or review of accounting systems",
        "Monthly transaction categorization and reconciliation",
        "Generation of monthly financial statements",
        "Regular financial review meetings",
        "Year-end preparation for tax filing"
      ],
      faqs: [
        {
          question: "What accounting software do you work with?",
          answer: "We work with all major accounting platforms including QuickBooks, Xero, FreshBooks, and Sage. We can help you select the most appropriate software for your business needs or work with your existing system."
        },
        {
          question: "How often will I receive financial reports?",
          answer: "With our monthly bookkeeping service, you'll receive updated financial statements every month including income statements, balance sheets, and cash flow statements. We also provide quarterly comprehensive reviews of your financial position."
        }
      ]
    },
    {
      id: 4,
      name: "Financial Statement Preparation",
      category: "accounting",
      icon: <FileText className="h-12 w-12 text-blue-600" />,
      description: "Professional preparation of accurate financial statements for internal management, lenders, or investors.",
      benefits: [
        "GAAP-compliant financial reporting",
        "Enhanced credibility with lenders and investors",
        "Clear picture of financial performance",
        "Identification of financial trends and opportunities",
        "Support for business loans and financing"
      ],
      process: [
        "Review and organization of financial records",
        "Reconciliation of accounts and transactions",
        "Preparation of income statements, balance sheets, and cash flow statements",
        "Detailed notes and explanations as needed",
        "Management letter with key insights and recommendations"
      ],
      faqs: [
        {
          question: "What types of financial statements do you prepare?",
          answer: "We prepare all standard financial statements including income statements (profit & loss), balance sheets, cash flow statements, and statements of shareholders' equity. We can also create custom financial reports tailored to your specific needs."
        },
        {
          question: "Do you provide compiled, reviewed, or audited financial statements?",
          answer: "We provide compiled financial statements and can arrange for reviewed statements through our partner firms. For audited statements required by lenders or investors, we work with licensed audit partners to ensure compliance with all requirements."
        }
      ]
    },
    {
      id: 5,
      name: "Strategic Business Advisory",
      category: "advisory",
      icon: <Briefcase className="h-12 w-12 text-blue-600" />,
      description: "Expert guidance to help your business overcome challenges, identify opportunities, and achieve sustainable growth.",
      benefits: [
        "Objective assessment of business performance",
        "Strategic planning and goal setting",
        "Financial modeling and forecasting",
        "Operational efficiency improvements",
        "Growth and expansion strategies"
      ],
      process: [
        "Comprehensive business assessment",
        "Identification of key challenges and opportunities",
        "Development of strategic recommendations",
        "Implementation planning and support",
        "Ongoing monitoring and adjustment"
      ],
      faqs: [
        {
          question: "How is business advisory different from accounting services?",
          answer: "While accounting services focus on historical financial recording and reporting, business advisory is forward-looking and strategic. We use financial insights as a foundation to help you make informed decisions about your business's future direction, growth opportunities, and potential challenges."
        },
        {
          question: "How often should we meet for business advisory sessions?",
          answer: "For most businesses, we recommend quarterly strategic reviews. However, the frequency can be adjusted based on your business needs, growth phase, or when facing significant challenges or opportunities. Monthly check-ins are common for businesses in rapid growth phases."
        }
      ]
    },
    {
      id: 6,
      name: "CFO Advisory Services",
      category: "advisory",
      icon: <Award className="h-12 w-12 text-blue-600" />,
      description: "Part-time CFO expertise to provide financial leadership without the cost of a full-time executive.",
      benefits: [
        "Executive-level financial guidance",
        "Cash flow management and forecasting",
        "Development of financial systems and controls",
        "Strategic planning and budgeting",
        "Capital raising and financing support"
      ],
      process: [
        "Initial financial assessment and goal setting",
        "Development of financial strategies and KPIs",
        "Regular review meetings and performance tracking",
        "Financial analysis and decision support",
        "Board and investor relations guidance"
      ],
      faqs: [
        {
          question: "Is my business large enough to benefit from CFO services?",
          answer: "Businesses of all sizes can benefit from CFO expertise. Our part-time CFO services are specifically designed to give growing businesses access to executive-level financial guidance without the full-time salary cost. This service is particularly valuable for businesses with revenues between $1M-$20M, those seeking funding, or experiencing rapid growth."
        },
        {
          question: "What's the difference between bookkeeping and CFO services?",
          answer: "Bookkeeping focuses on recording financial transactions and maintaining records, while CFO services provide strategic financial analysis, forecasting, and leadership. A CFO helps you use financial data to make strategic business decisions, secure financing, optimize operations, and plan for long-term growth."
        }
      ]
    },
    {
      id: 7,
      name: "Retirement Planning",
      category: "wealth",
      icon: <Users className="h-12 w-12 text-blue-600" />,
      description: "Comprehensive retirement strategy development to ensure financial security and peace of mind for your future.",
      benefits: [
        "Personalized retirement needs analysis",
        "Tax-efficient investment strategies",
        "Retirement account optimization (401(k), IRA, etc.)",
        "Social Security maximization strategies",
        "Regular progress reviews and adjustments"
      ],
      process: [
        "Assessment of current financial position",
        "Determination of retirement needs and goals",
        "Development of personalized retirement strategy",
        "Implementation of recommended retirement vehicles",
        "Regular reviews and strategy adjustments"
      ],
      faqs: [
        {
          question: "When should I start planning for retirement?",
          answer: "The best time to start retirement planning is as early as possible in your career, as this maximizes the power of compound growth. However, it's never too late to begin planning. No matter your age, we can develop strategies to improve your retirement outlook and make the most of your remaining working years."
        },
        {
          question: "How much do I need to save for retirement?",
          answer: "The amount needed for retirement varies significantly based on your lifestyle expectations, projected longevity, healthcare needs, and other factors. Rather than using a one-size-fits-all approach, we conduct a detailed analysis of your specific situation to determine appropriate savings targets and strategies."
        }
      ]
    },
    {
      id: 8,
      name: "Investment Management",
      category: "wealth",
      icon: <Award className="h-12 w-12 text-blue-600" />,
      description: "Professional management of investment portfolios to maximize returns while aligning with your risk tolerance and goals.",
      benefits: [
        "Personalized investment strategy development",
        "Diversified portfolio construction",
        "Regular portfolio monitoring and rebalancing",
        "Tax-efficient investment management",
        "Consolidated performance reporting"
      ],
      process: [
        "Assessment of financial goals and risk tolerance",
        "Development of personalized investment strategy",
        "Strategic asset allocation and portfolio construction",
        "Ongoing monitoring and performance analysis",
        "Regular rebalancing and strategy adjustments"
      ],
      faqs: [
        {
          question: "How do you determine the right investment strategy for me?",
          answer: "We develop investment strategies based on several factors including your financial goals, time horizon, risk tolerance, income needs, tax situation, and other unique circumstances. Our approach combines thorough financial planning with disciplined investment management to create a strategy tailored specifically to you."
        },
        {
          question: "What types of investments do you use in client portfolios?",
          answer: "We primarily utilize a mix of low-cost index funds, ETFs, mutual funds, and individual securities when appropriate. Our investment philosophy emphasizes proper asset allocation, diversification, cost management, and tax efficiency rather than market timing or speculative investments."
        }
      ]
    }
  ];
  
  // Filter services based on active tab
  const filteredServices = activeTab === 'all' 
    ? services 
    : services.filter(service => service.category === activeTab);
    
  // Handle service selection
  const handleServiceSelect = (service) => {
    setSelectedService(service);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  // Handle back button click
  const handleBack = () => {
    setSelectedService(null);
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Header Space - to account for fixed header */}
      <div className="h-20"></div>
      
      {/* Page Header */}
      <section className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h1 className="text-3xl sm:text-4xl font-bold mb-4">
              {selectedService ? selectedService.name : 'Our Services'}
            </h1>
            <p className="text-xl text-blue-100">
              {selectedService 
                ? selectedService.description 
                : 'Comprehensive financial solutions tailored to your unique needs, whether you are an individual, family, or business owner.'}
            </p>
          </div>
        </div>
      </section>
      
      {selectedService ? (
        /* Service Detail View */
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <button 
            onClick={handleBack}
            className="flex items-center text-blue-600 hover:text-blue-800 mb-8"
          >
            <svg className="h-4 w-4 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            Back to Services
          </button>
          
          <div className="grid md:grid-cols-3 gap-8">
            <div className="md:col-span-2">
              <div className="bg-white rounded-lg shadow-md p-8 mb-8">
                <div className="flex items-start mb-6">
                  <div className="h-16 w-16 bg-blue-100 rounded-lg flex items-center justify-center mr-6">
                    {selectedService.icon}
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold text-gray-900 mb-2">{selectedService.name}</h2>
                    <p className="text-gray-600">{selectedService.description}</p>
                  </div>
                </div>
                
                <h3 className="text-xl font-bold text-gray-900 mb-4">Key Benefits</h3>
                <div className="space-y-3 mb-8">
                  {selectedService.benefits.map((benefit, index) => (
                    <div key={index} className="flex items-start">
                      <CheckCircle className="h-5 w-5 text-green-500 mr-3 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-700">{benefit}</span>
                    </div>
                  ))}
                </div>
                
                <h3 className="text-xl font-bold text-gray-900 mb-4">Our Process</h3>
                <div className="space-y-6 mb-8">
                  {selectedService.process.map((step, index) => (
                    <div key={index} className="flex">
                      <div className="flex-shrink-0 mr-4">
                        <div className="h-10 w-10 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center font-bold">
                          {index + 1}
                        </div>
                      </div>
                      <div>
                        <p className="text-gray-700">{step}</p>
                        {index < selectedService.process.length - 1 && (
                          <div className="h-6 w-px bg-blue-100 ml-5 my-2"></div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                
                <h3 className="text-xl font-bold text-gray-900 mb-4">Frequently Asked Questions</h3>
                <div className="space-y-6">
                  {selectedService.faqs.map((faq, index) => (
                    <div key={index} className="bg-gray-50 rounded-lg p-6">
                      <h4 className="font-bold text-gray-900 mb-2">{faq.question}</h4>
                      <p className="text-gray-600">{faq.answer}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            
            <div className="md:col-span-1">
              <div className="bg-white rounded-lg shadow-md p-6 sticky top-24">
                <h3 className="text-lg font-bold text-gray-900 mb-4">Ready to Get Started?</h3>
                <p className="text-gray-600 mb-6">
                  Schedule a complimentary consultation to discuss your needs and how we can help you achieve your financial goals.
                </p>
                
                <a 
                  href="#contact" 
                  className="block w-full bg-blue-600 text-white text-center px-4 py-3 rounded-md hover:bg-blue-700 transition duration-200 mb-4"
                >
                  Schedule a Consultation
                </a>
                
                <div className="space-y-4 mt-8">
                  <div className="flex items-start">
                    <Clock className="h-5 w-5 text-blue-600 mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <h4 className="font-medium text-gray-900">Initial Consultation</h4>
                      <p className="text-sm text-gray-600">30-minute complimentary session</p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <Calendar className="h-5 w-5 text-blue-600 mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <h4 className="font-medium text-gray-900">Flexible Scheduling</h4>
                      <p className="text-sm text-gray-600">Virtual or in-person meetings</p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <MessageSquare className="h-5 w-5 text-blue-600 mr-3 flex-shrink-0 mt-0.5" />
                    <div>
                      <h4 className="font-medium text-gray-900">Have Questions?</h4>
                      <p className="text-sm text-gray-600">Call us at (303) 555-1234</p>
                    </div>
                  </div>
                </div>
                
                <div className="border-t border-gray-200 mt-8 pt-8">
                  <h4 className="font-medium text-gray-900 mb-3">Related Services</h4>
                  <div className="space-y-3">
                    {services
                      .filter(service => 
                        service.category === selectedService.category && 
                        service.id !== selectedService.id
                      )
                      .slice(0, 2)
                      .map(service => (
                        <button
                          key={service.id}
                          onClick={() => handleServiceSelect(service)}
                          className="flex items-center w-full text-left hover:bg-gray-50 p-2 rounded-md transition duration-200"
                        >
                          <div className="h-8 w-8 bg-blue-100 rounded flex items-center justify-center mr-3">
                            {service.icon}
                          </div>
                          <span className="text-gray-700">{service.name}</span>
                        </button>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        /* Services List View */
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Service Categories Tabs */}
          <div className="flex flex-wrap justify-center mb-12">
            <div className="bg-gray-100 rounded-lg p-1 flex flex-wrap">
              {serviceCategories.map((category) => (
                <button
                  key={category.id}
                  onClick={() => setActiveTab(category.id)}
                  className={`px-4 py-2 rounded-md text-sm font-medium transition ${
                    activeTab === category.id
                      ? 'bg-white shadow-sm text-blue-600'
                      : 'text-gray-600 hover:text-gray-900'
                  }`}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>
          
          {/* Services Grid */}
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filteredServices.map((service) => (
              <div
                key={service.id}
                className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300"
              >
                <div className="h-16 w-16 bg-blue-100 rounded-lg flex items-center justify-center mb-6">
                  {service.icon}
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-3">{service.name}</h3>
                <p className="text-gray-600 mb-4 line-clamp-3">{service.description}</p>
                
                <div className="space-y-2 mb-6">
                  {service.benefits.slice(0, 3).map((benefit, index) => (
                    <div key={index} className="flex items-start">
                      <CheckCircle className="h-4 w-4 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span className="text-sm text-gray-700">{benefit}</span>
                    </div>
                  ))}
                </div>
                
                <button
                  onClick={() => handleServiceSelect(service)}
                  className="flex items-center text-blue-600 hover:text-blue-800 font-medium"
                >
                  Learn More
                  <ChevronRight className="h-5 w-5 ml-1" />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
      
      {/* CTA Section */}
      <section className="bg-gray-50 py-16">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Not Sure Which Service You Need?
            </h2>
            <p className="text-xl text-gray-600 mb-8">
              Our team of experts will help determine the best solutions for your specific financial situation.
            </p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <a 
                href="#contact" 
                className="inline-flex items-center justify-center bg-blue-600 text-white px-6 py-3 rounded-md hover:bg-blue-700 transition duration-200"
              >
                Schedule a Free Consultation
                <ArrowRight className="ml-2 h-5 w-5" />
              </a>
              <a 
                href="tel:3035551234" 
                className="inline-flex items-center justify-center border border-gray-300 text-gray-700 px-6 py-3 rounded-md hover:bg-gray-50 transition duration-200"
              >
                Call (303) 555-1234
              </a>
            </div>
          </div>
        </div>
      </section>
      
      {/* Client Testimonials */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">What Our Clients Say</h2>
            <div className="w-20 h-1 bg-blue-600 mx-auto"></div>
          </div>
          
          <div className="grid md:grid-cols-3 gap-8">
            <div className="bg-gray-50 rounded-lg p-6 relative">
              <div className="absolute -top-4 -left-4 text-6xl text-blue-200">"</div>
              <div className="relative">
                <p className="text-gray-600 italic mb-6">
                  Alpine Financial's tax services have been invaluable for my small business. Their strategic approach saved us thousands in taxes last year alone, and their guidance throughout the year helps me make smart financial decisions.
                </p>
                <div className="flex items-center">
                  <img 
                    src="https://randomuser.me/api/portraits/men/42.jpg" 
                    alt="Client" 
                    className="h-12 w-12 rounded-full mr-4"
                  />
                  <div>
                    <h4 className="font-bold text-gray-900">Robert Martinez</h4>
                    <p className="text-gray-500 text-sm">Owner, Elevation Construction</p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="bg-gray-50 rounded-lg p-6 relative">
              <div className="absolute -top-4 -left-4 text-6xl text-blue-200">"</div>
              <div className="relative">
                <p className="text-gray-600 italic mb-6">
                  I've been working with Alpine for retirement planning for the past five years. Their personalized approach and regular portfolio reviews give me confidence that I'm on track to meet my retirement goals.
                </p>
                <div className="flex items-center">
                  <img 
                    src="https://randomuser.me/api/portraits/women/28.jpg" 
                    alt="Client" 
                    className="h-12 w-12 rounded-full mr-4"
                  />
                  <div>
                    <h4 className="font-bold text-gray-900">Lisa Thompson</h4>
                    <p className="text-gray-500 text-sm">Medical Professional</p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="bg-gray-50 rounded-lg p-6 relative">
              <div className="absolute -top-4 -left-4 text-6xl text-blue-200">"</div>
              <div className="relative">
                <p className="text-gray-600 italic mb-6">
                  The CFO advisory services from Alpine Financial transformed how we manage our company finances. They've helped us implement systems that provide clear visibility into our performance and growth opportunities.
                </p>
                <div className="flex items-center">
                  <img 
                    src="https://randomuser.me/api/portraits/women/33.jpg" 
                    alt="Client" 
                    className="h-12 w-12 rounded-full mr-4"
                  />
                  <div>
                    <h4 className="font-bold text-gray-900">Jennifer Smith</h4>
                    <p className="text-gray-500 text-sm">CEO, Mountain View Technologies</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      {/* Contact Section */}
      <section id="contact" className="py-16 bg-blue-50">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Get Started Today</h2>
            <p className="text-xl text-gray-600">
              Schedule a complimentary consultation to discuss your needs and how we can help you achieve your financial goals.
            </p>
          </div>
          
          <div className="max-w-lg mx-auto bg-white rounded-lg shadow-md p-8">
            <form className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">Your Name</label>
                <input
                  type="text"
                  id="name"
                  className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>
              
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email Address</label>
                <input
                  type="email"
                  id="email"
                  className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  required
                />
              </div>
              
              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">Phone Number</label>
                <input
                  type="tel"
                  id="phone"
                  className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              
              <div>
                <label htmlFor="service" className="block text-sm font-medium text-gray-700 mb-1">Service of Interest</label>
                <select
                  id="service"
                  className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                >
                  <option value="">Select a Service</option>
                  <option value="tax">Tax Services</option>
                  <option value="accounting">Accounting & Bookkeeping</option>
                  <option value="advisory">Business Advisory</option>
                  <option value="wealth">Wealth Management</option>
                  <option value="other">Not Sure / Other</option>
                </select>
              </div>
              
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">Your Message</label>
                <textarea
                  id="message"
                  rows="4"
                  className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                ></textarea>
              </div>
              
              <button
                type="submit"
                className="w-full bg-blue-600 text-white px-6 py-3 rounded-md hover:bg-blue-700 transition duration-200 flex items-center justify-center"
              >
                <Calendar className="mr-2 h-5 w-5" />
                Schedule Consultation
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProfessionalServicesPage;