// ChatContainer.js
import React, { useEffect, useCallback, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../Header';
import ThreadSidebar from '../Sidebar/ThreadSidebar';
import ChatWindow from './ChatWindow';
import SettingsModal from '../Modals/SettingsModal';
import EditThreadModal from '../Modals/EditThreadModal';
import ErrorPopup from '../Common/ErrorPopup';
import ErrorBoundary from '../ErrorBoundary';
import useAuth from '../../hooks/useAuth';
import useChat from '../../hooks/useChat';
import useThreads from '../../hooks/useThreads';
import usePastes from '../../hooks/usePastes';
import useSettings from '../../hooks/useSettings';
import config from '../../config';
import ReactDOM from 'react-dom';
import { API_BASE_URL, generateChatTitle, updateThreadName } from '../../services/api';
import ChatLanding from './ChatLanding';

const ChatContainer = ({ onLogout, isLanding }) => {
  const navigate = useNavigate();
  const { verifyAuth } = useAuth(onLogout);
  const listRef = useRef(null);
  const userMenuContainerRef = useRef(null);
  const { threadId } = useParams();

  // Settings Management
  const {
    customInstructions,
    setCustomInstructions,
    currentlySelectedModel,
    setCurrentlySelectedModel,
    isSettingsOpen,
    handleSettingsToggle,
    handleSaveSettings,
    handleExperimentalFeaturesToggle,
    fetchSettings,
    error: settingsError,
    setError: setSettingsError,
    experimentalFeatures,
    setExperimentalFeatures,
    sidebarPosition,        // Use this from the hook
    setSidebarPosition,     // Use this from the hook
  } = useSettings({ onLogout, defaultModel: 'gpt-4o-mini' });

  // Paste Management
  const {
    pastes,
    setPastes,
    handleDeletePaste,
    handleFileUpload,
    handleAddPaste,
  } = usePastes();

  // Thread Management
  const {
    threads,
    setThreads,
    threadOrder,
    setThreadOrder,
    currentThreadId,
    setCurrentThreadId,
    currentThreadIndex,
    isLoading: threadsLoading,
    error: threadError,
    setError: setThreadError, // Add the setError function for thread errors
    hasMore,
    handleThreadChange,
    handleDeleteThread,
    handleUpdateThreadName,
    loadMoreThreads,
      handleNewThread,
    loadingStates // Get loadingStates from the hook
  } = useThreads({ onLogout, defaultModel: 'gemini-1.5-flash-latest' });

  // Chat State Management
  const {
    messages,
    setMessages,
    inputMessage,
    setInputMessage,
    isGenerating,
    isLoading,
    handleSendMessage,
    handleStopGeneration,
    error: chatError,
    setError: setChatError,
    createNewChat, // Get the new createNewChat function
    handleRetryLastMessage,
  } = useChat({
    threads,
    setThreads,
    threadOrder,
    setThreadOrder,
    currentThreadId,
    setCurrentThreadId,
    onLogout,
    customInstructions,
    pastes,
    setPastes,
    currentlySelectedModel,
  });

  // UI State
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingThreadId, setEditingThreadId] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isHoverControlled, setIsHoverControlled] = useState(false); // set to false by default

    // Track if the current load is a result of new chat
    const [isNewThread, setIsNewThread] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);


  // Shared Thread State
  const [isSharedThread, setIsSharedThread] = useState(false);
  const [sharedThreadData, setSharedThreadData] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const sharedId = params.get('share');

    // Moved this to before the useEffect that uses it
    const handleThreadChangeWrapper = useCallback(async (threadId, needsLoad = false) => {

       // Prevent unnecessary thread changes
      // Added conditional to check if isLanding
    if (!isLanding && threadId === currentThreadId && !needsLoad) {
        return;
    }

      // Check if threads are still loading
      if (threadsLoading) {
        return;
      }

      if (!threads?.has(threadId)) {
        return;
      }

      // Reset states before loading new thread
      setInputMessage('');
      setPastes([]);

        // Update URL without triggering navigation if we're already on the correct route
        const currentPath = `/chat/${threadId}`;
        if (window.location.pathname !== currentPath) {
            navigate(currentPath);
        }

      // Then handle thread change
      handleThreadChange(threadId, needsLoad)
        .catch(error => {
          console.error('Error changing thread:', error);
          setChatError('Failed to load thread content');
        });
  }, [
    handleThreadChange,
    threads,
    threadsLoading,
    setInputMessage,
    setPastes,
    navigate,
    setChatError,
    currentThreadId,
      isLanding
  ]);

  // Effect to handle shared thread loading
  useEffect(() => {
    const loadSharedThread = async () => {
      if (!sharedId) return;

      try {
        const response = await fetch(`${config.apiUrl}/api/shared/${sharedId}`, {
          credentials: 'include',
        });

        if (!response.ok) {
          setChatError('Shared thread not found or no longer available');
          return;
        }

        const data = await response.json();
        setSharedThreadData(data);
        setIsSharedThread(true);

        // If user is logged in and thread was copied to their account
        if (data.thread_id) {
          handleThreadChangeWrapper(data.thread_id);
        } else {
          // For non-logged in users, display the shared content
          setMessages(data.messages || []);
        }
      } catch (error) {
        console.error('Error loading shared thread:', error);
        setChatError('Error loading shared thread');
      }
    };

    loadSharedThread();
  }, [sharedId, setChatError, setMessages, handleThreadChangeWrapper]);

  //Effect to handle initial thread loading
  useEffect(() => {
    if (!threadId || isLanding || !threads?.size) return;

    // Skip if this is a new thread creation
    if (isNewThread) {
        setIsNewThread(false);
        return;
    }

    // Important: Check if thread exists and isn't current
    if (threads.has(threadId) && threadId !== currentThreadId) {
        // Add a small delay to ensure state is synchronized
        setTimeout(() => {
            handleThreadChangeWrapper(threadId, true);
        }, 100);
    }
}, [threadId, isLanding, threads, currentThreadId, isNewThread, handleThreadChangeWrapper]);

  // Modified message handler for shared threads
  const handleSharedThreadMessage = useCallback(async (message) => {
    if (!verifyAuth()) {
      // Show login prompt for non-authenticated users
      navigate('/login', {
        state: {
          returnUrl: `/chat?share=${sharedId}`,
          message: "Create an account to continue this conversation"
        }
      });
      return;
    }

    // Normal message handling for authenticated users
    handleSendMessage(message);
  }, [verifyAuth, navigate, sharedId, handleSendMessage]);


  // Handle mouse enter event to open the sidebars
  // removing the hover effect
  const handleMouseEnterSidebar = useCallback(() => {}, []);
  // Effect to verify authentication and fetch settings
  useEffect(() => {
    console.log('ChatContainer: useEffect - Verifying auth and fetching settings');
    verifyAuth();
    fetchSettings();
  }, [verifyAuth, fetchSettings]);
  // Handle mouse leave events
  // removing the hover effect
  const handleMouseLeaveSidebar = useCallback(() => {}, []);

  // Handle sidebar toggles
    const handleToggleSidebar = useCallback(() => {

        setIsHoverControlled(false);
      setIsSidebarOpen((prevState) => {
          return !prevState;
      });

    }, [sidebarPosition]);

  /**
   * Creates a new chat thread and resets the chat state.
   */
  const handleNewChat = useCallback(async () => {
    try {
      setIsNewThread(true);
      setInputMessage('');
      setPastes([]);

      const response = await fetch(`${API_BASE_URL}/create_thread`, {
        method: 'POST',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ name: 'New Chat' }),
      });

      const data = await response.json();
      if (data.thread_uid) {
        if (isGenerating) handleStopGeneration();

        // Handle new thread creation
        await handleNewThread({thread_uid: data.thread_uid, name: "New Chat"});

        // Important: Navigate after thread creation is complete
        navigate(`/chat/${data.thread_uid}`, { replace: true }); // Use replace to prevent back button issues
            return data.thread_uid; // Return the thread ID
      }
        return null
    } catch (error) {
      console.error('Failed to create chat:', error);
      setChatError('Failed to create chat');
        setIsNewThread(false);
        return null
    }
  }, [
    setInputMessage,
    setPastes,
    setChatError,
      isGenerating,
      handleStopGeneration,
      handleNewThread,
      navigate
  ]);

  /**
   * Opens the edit thread modal for renaming a thread.
   * @param {string} threadId - The ID of the thread to edit
   */
  const handleEditThread = useCallback((threadId) => {

    setEditingThreadId(threadId);
    setIsEditModalOpen(true);
  }, []);

  /**
   * Saves the new thread name and closes the edit modal.
   * @param {string} threadId - The ID of the thread
   * @param {string} newName - The new name for the thread
   */
  const handleSaveThreadName = useCallback(
    (threadId, newName) => {
      handleUpdateThreadName(threadId, newName);
      setIsEditModalOpen(false);
      setEditingThreadId(null);
    },
    [handleUpdateThreadName]
  );

  /**
   * Deletes the current thread and closes settings modal.
   */
  const handleDeleteCurrentThread = useCallback(() => {
    if (currentThreadId) {
      handleDeleteThread(currentThreadId);
      handleSettingsToggle(); // Close the settings modal after deletion
    }
  }, [
    currentThreadId,
    handleDeleteThread,
    handleSettingsToggle,
  ]);

  /**
   * Navigates to the dashboard page.
   */
  const handleDashboardClick = useCallback(() => {
    navigate('/dashboard');
  }, [navigate]);

    const renderUserMenuPortal = useCallback((userMenuJSX) => {
        if (!userMenuContainerRef.current) return null;
          return ReactDOM.createPortal(userMenuJSX, userMenuContainerRef.current)
    }, [])


    const handleNewChatAndMessage = useCallback(async (msg) => {
        try {
            // Create new thread first
            const newThreadId = await handleNewChat();
            if (!newThreadId) {
                throw new Error('Failed to create new thread');
            }
            await handleSendMessage(msg, newThreadId);
            
            return newThreadId;
        } catch (error) {
            console.error('handleNewChatAndMessage: Error:', error);
            setChatError('Failed to create and send message');
            return null;
        }
    }, [
        handleNewChat,
        handleSendMessage,
        handleUpdateThreadName,
        setChatError,
        generateChatTitle,
    ]);

  useEffect(() => {
      if (isSidebarOpen && window.innerWidth <= 450) {
          document.body.classList.add('sidebar-open');
      } else {
          document.body.classList.remove('sidebar-open');
      }

      return () => {
          document.body.classList.remove('sidebar-open');
      };
  }, [isSidebarOpen]);

    const chatLandingProps = {
        onSendMessage: async (msg) => {
            const newThreadId = await handleNewChatAndMessage(msg);
            if (newThreadId) {
                navigate(`/chat/${newThreadId}`);
            }
        }
    };

  // Render landing page if isLanding is true
    if (isLanding) {
        return (
            <div className="flex flex-col h-screen bg-[#1a1a1a] text-white relative overflow-hidden">
                {/* User Menu Container */}
                <div
                    ref={userMenuContainerRef}
                    className='fixed w-0 h-0 pointer-events-none z-[10000]'
                    style={{
                        bottom: 0, // Position at the bottom
                        ...(sidebarPosition === 'left'
                            ? { left: '288px' }  // 72px * 4 (sidebar width)
                            : { right: '288px' })
                    }}
                />
                <Header
                  onSettingsToggle={handleSettingsToggle}
                  onLogout={onLogout}
                  onNewChat={handleNewChat}
                  onToggleSidebar={handleToggleSidebar}
                  onDashboardClick={handleDashboardClick}
                  currentThreadId={currentThreadId}
                  currentlySelectedModel={currentlySelectedModel}
                  setCurrentlySelectedModel={setCurrentlySelectedModel}
                  sidebarPosition={sidebarPosition}
                  setSidebarPosition={setSidebarPosition}
                  isSidebarOpen={isSidebarOpen}
                isLanding={isLanding} // Add this line
                />
                <div className="flex-grow flex justify-center overflow-hidden relative">

                    <div className={`
                        flex flex-col flex-grow min-h-0 transition-all duration-300
                        max-w-[1000px] w-full
                        px-2 sm:px-4
                    `}>
                        <ChatLanding
                            {...chatLandingProps}
                            onNewChat={handleNewChat}
                            threads={threads}
                            threadOrder={threadOrder}
                            onThreadSelect={handleThreadChangeWrapper}
                            inputMessage={inputMessage}
                            setInputMessage={setInputMessage}
                            currentlySelectedModel={currentlySelectedModel}
                            experimentalFeatures={experimentalFeatures}
                            onToggleExperimental={handleExperimentalFeaturesToggle}
                          pastes={pastes}
                          onDeletePaste={handleDeletePaste}
                          onFileUpload={handleFileUpload}
                          onAddPaste={handleAddPaste}
                           sidebarPosition={sidebarPosition}
                          isSidebarOpen={isSidebarOpen}
                           onToggleSidebar={handleToggleSidebar}
                           onLogout={onLogout}
                           userMenuContainerRef={userMenuContainerRef}
                           handleMouseLeaveSidebar={handleMouseLeaveSidebar}
                            hasMore={hasMore}
                            loadMoreThreads={loadMoreThreads}
                            onEditThread={handleEditThread}
                            onDeleteThread={handleDeleteThread}
                            handleThreadChangeWrapper={handleThreadChangeWrapper}
                            threadsLoading={threadsLoading}
                            createNewChat={createNewChat}
                            setCurrentlySelectedModel={setCurrentlySelectedModel}
                            setSidebarPosition={setSidebarPosition}
                           isLoadingMore={isLoadingMore} // Pass isLoadingMore here
                        />
                    </div>
                </div>
            </div>
        );
    }

  return (
    <ErrorBoundary>
      <div className="flex flex-col h-screen bg-[#1a1a1a] text-white relative overflow-hidden">
        {/* User Menu Container */}
        <div
          ref={userMenuContainerRef}
          className='fixed w-0 h-0 pointer-events-none z-[10000]'
          style={{
            bottom: 0, // Position at the bottom
            ...(sidebarPosition === 'left'
              ? { left: '288px' }  // 72px * 4 (sidebar width)
              : { right: '288px' })
          }}
        />

        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10 sm:hidden"
            onClick={handleToggleSidebar}
          />
        )}

        <div className={`
          flex-1 relative flex flex-col
          transition-all duration-300
          ${isSidebarOpen ? 'sm:ml-72' : 'ml-0'}
          ${sidebarPosition === 'right' && isSidebarOpen ? 'sm:mr-72' : 'mr-0'}
        `}>

          <Header
            onSettingsToggle={handleSettingsToggle}
            onLogout={onLogout}
            onNewChat={handleNewChat}
            onToggleSidebar={handleToggleSidebar} // Pass onToggleSidebar
            onDashboardClick={handleDashboardClick}
            currentThreadId={currentThreadId}
            currentlySelectedModel={currentlySelectedModel}
            setCurrentlySelectedModel={setCurrentlySelectedModel}
            sidebarPosition={sidebarPosition}         // Add this
            setSidebarPosition={setSidebarPosition}   // Add this
              isSidebarOpen={isSidebarOpen} //Pass the prop here
          />

          <div className="flex-grow flex justify-center overflow-hidden relative">
           {/* Left Sidebar - only render if position is left */}
            {sidebarPosition === 'left' && (
              <ThreadSidebar
                position="left"
                threads={threads}
                threadOrder={threadOrder}
                currentThreadId={currentThreadId}
                onThreadChange={handleThreadChangeWrapper}
                onEditThread={handleEditThread}
                onDeleteThread={handleDeleteThread}
                onNewChat={handleNewChat}
                isOpen={isSidebarOpen}
                onMouseLeave={handleMouseLeaveSidebar}
                hasMore={hasMore}
                onLoadMore={() => {
                    setIsLoadingMore(true);
                    loadMoreThreads(() => setIsLoadingMore(false));
                }}
                isLoading={threadsLoading}
                  onToggleSidebar={handleToggleSidebar} // Pass the onToggleSidebar function
                  onLogout={onLogout}
                userMenuContainerRef={userMenuContainerRef}
                  isLoadingMore={isLoadingMore} // Pass isLoadingMore here
              />
            )}

          {/* Right Sidebar - only render if position is right */}
            {sidebarPosition === 'right' && (
              <ThreadSidebar
                position="right"
                threads={threads}
                threadOrder={threadOrder}
                currentThreadId={currentThreadId}
                onThreadChange={handleThreadChangeWrapper}
                onEditThread={handleEditThread}
                onDeleteThread={handleDeleteThread}
                onNewChat={handleNewChat}
                isOpen={isSidebarOpen}
                onMouseLeave={handleMouseLeaveSidebar}
                hasMore={hasMore}
                onLoadMore={() => {
                    setIsLoadingMore(true);
                    loadMoreThreads(() => setIsLoadingMore(false));
                }}
                isLoading={threadsLoading}
                  onToggleSidebar={handleToggleSidebar} // Pass the onToggleSidebar function
                   onLogout={onLogout}
                   userMenuContainerRef={userMenuContainerRef}
                   isLoadingMore={isLoadingMore} // Pass isLoadingMore here
              />
            )}

          <div className={`
              flex flex-col flex-grow min-h-0 transition-all duration-300
              max-w-[1000px] w-full
              px-2 sm:px-4
              ${isSidebarOpen && sidebarPosition === 'left' ? 'sm:ml-72' : 'ml-0'}
              ${isSidebarOpen && sidebarPosition === 'right' ? 'sm:mr-72' : 'mr-0'}
            `}>
              <ChatWindow
                messages={messages}
                inputMessage={inputMessage}
                setInputMessage={setInputMessage}
                isLoading={isLoading}
                isGenerating={isGenerating}
                onSendMessage={isSharedThread ? handleSharedThreadMessage : handleSendMessage}
                onStopGeneration={handleStopGeneration}
                pastes={pastes}
                onDeletePaste={handleDeletePaste}
                onFileUpload={handleFileUpload}
                onAddPaste={handleAddPaste}
                listRef={listRef}
                currentlySelectedModel={currentlySelectedModel}
                experimentalFeatures={experimentalFeatures}
                onToggleExperimental={handleExperimentalFeaturesToggle}
                 isThreadLoading={loadingStates.get(currentThreadId) || !threads.get(currentThreadId)?.isLoaded}
              />
            </div>
          </div>

          {isSettingsOpen && (
            <SettingsModal
              customInstructions={customInstructions}
              setCustomInstructions={setCustomInstructions}
              currentlySelectedModel={currentlySelectedModel}
              setCurrentlySelectedModel={setCurrentlySelectedModel}
              onSave={handleSaveSettings}
              onClose={handleSettingsToggle}
              onDeleteThread={handleDeleteCurrentThread}
                sidebarPosition={sidebarPosition}
                setSidebarPosition={setSidebarPosition}
            />
          )}

          {isEditModalOpen && (
            <EditThreadModal
              threadId={editingThreadId}
              currentName={threads?.get(editingThreadId)?.name || ''}
              onSave={handleSaveThreadName}
              onClose={() => setIsEditModalOpen(false)}
              onDeleteThread={handleDeleteThread}
              handleThreadChange={handleThreadChange}
              threadOrder={threadOrder}
              currentThreadId={currentThreadId}
            />
          )}

          {(chatError || threadError || settingsError) && (
            <ErrorPopup
              error={chatError || threadError || settingsError}
              onClose={() => {
                setChatError(null);
                setSettingsError(null);
                setThreadError(null); // Clear thread error here
              }}
            />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ChatContainer;
