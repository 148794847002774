// AssistantMessage.js
import React, { useMemo, useCallback } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import CollapsibleTile from './Common/CollapsibleTile';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm'; // For GitHub Flavored Markdown support
import ActionBar from './ActionBar';

const AssistantMessage = React.memo(({ content = '', files = [], pastes = [], onTileToggle, onRetry, isLatest = false }) => {
  // Simply use content as is - no LaTeX processing
  const processedContent = useMemo(() => content || '', [content]);

  // Define custom renderers for ReactMarkdown
  const markdownComponents = useMemo(() => ({
    // Handle CODE blocks (```) and inline code (`)
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || '');
      const codeContent = String(children).trim();
      // Heuristic: Treat as inline if ReactMarkdown says so, OR if it claims block
      // but the actual code content has no newline characters.
      const isEffectivelyInline = inline || !codeContent.includes('\n');
      if (isEffectivelyInline) {
        // --- INLINE CODE (`code` or ``code``) ---
        // Render standard inline code tag - Apply styling via CSS or Tailwind
        return (
          <code className="font-mono text-sm bg-[#2c2c2c] px-1 py-0.5 rounded text-[#F28C28]" {...props}>
            {codeContent}
          </code>
        );
      } else {
        // --- BLOCK CODE (```code```) ---
        // Render using CollapsibleTile for block code
        const blockCodeContent = String(children).replace(/\n$/, '');
        return (
          <CollapsibleTile
            key={`code-block-${node?.position?.start?.line}-${node?.position?.start?.column}`}
            content={blockCodeContent}
            isCodeBlock={true}
            language={match?.[1]}
            onToggle={onTileToggle} // Pass down the function from props
          />
        );
      }
    },
    // Standard HTML elements renderers (customize if needed)
    img: ({ node, ...props }) => ( <img {...props} alt={props.alt || ''} style={{ maxWidth: '100%', height: 'auto' }} className="max-w-full h-auto rounded-lg my-2" loading="lazy" /> ),
    a: props => ( <a {...props} className="text-orange-500 underline hover:text-orange-600" target="_blank" rel="noopener noreferrer" /> ),
    ul: props => <ul className="list-disc pl-6 my-2" {...props} />,
    ol: props => <ol className="list-decimal pl-6 my-2" {...props} />,
    p: props => <p className="my-2" {...props} />,
    strong: props => <strong className="font-bold" {...props} />,
    em: props => <em className="italic" {...props} />,
    blockquote: props => ( <blockquote className="border-l-4 border-gray-500 pl-4 my-2 italic" {...props} /> ),
    // --- Custom Component for XML-like Tags ---
    // Ensure 'rehypeRaw' plugin is active
    'paste-1': ({ node, children, ...props }) => (
        <CollapsibleTile
            key={`paste-1-${node?.position?.start?.line}-${node?.position?.start?.column}`}
            name="paste-1"
            content={React.Children.toArray(children).map(child => typeof child === 'string' ? child : '').join('').trim()}
            onToggle={onTileToggle} // Pass down the function from props
            isPreformatted={true}
        />
    ),
    // Add other custom tags here, ensuring keys are stable...
  }), []);

  // Render files and pastes (Separate from Markdown content - for measurement?)
  const renderAuxiliaryContent = () => {
    if (!files?.length && !pastes?.length) return null;
    return (
      <div className="w-full absolute" style={{ position: 'absolute', opacity: 0, pointerEvents: 'none', zIndex: -1 }}>
        {files?.map((file, index) => (
          <CollapsibleTile key={`file-${index}`} name={file.name} content={file.content} onToggle={onTileToggle} />
        ))}
        {pastes?.map((paste, index) => (
          <CollapsibleTile key={`paste-${index}`} name={paste.fileName || `Paste ${index + 1}`} content={paste.content} onToggle={onTileToggle} />
        ))}
      </div>
    );
  };

  return (
    <div className="text-left flex flex-col items-start group">
      {/* Main Content Area */}
      <div className="bg-[#1a1a1a] p-2 rounded-lg text-left max-w-[90%] w-fit prose prose-sm prose-invert overflow-hidden">
        <div className="max-w-full overflow-x-auto">
          <ReactMarkdown
            children={processedContent}
            components={markdownComponents}
            rehypePlugins={[rehypeRaw]}
            remarkPlugins={[remarkGfm]}
          />
        </div>
        <style jsx global>{`
          .prose pre {
            max-width: 100%;
            overflow-x: auto;
          }
          .prose p, .prose li, .prose h1, .prose h2, .prose h3, .prose h4, .prose h5, .prose h6 {
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
            hyphens: auto;
            white-space: pre-wrap;
          }
        `}</style>
      </div>
      {/* Action Bar below the content */}
      <ActionBar
        messageContent={content} // Pass original content if needed for actions
        onRetry={onRetry}
        messageRole="assistant"
        isLatest={isLatest}
      />
      {/* Render auxiliary file/paste content (if needed for measurement etc.) */}
      {renderAuxiliaryContent()}
    </div>
  );
});

AssistantMessage.propTypes = {
  content: PropTypes.string.isRequired,
  files: PropTypes.array,
  pastes: PropTypes.array,
  onTileToggle: PropTypes.func,
  onRetry: PropTypes.func,
  isLatest: PropTypes.bool,
};

export default AssistantMessage;