import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Menu,
  X,
  ArrowRight,
  Github,
  Twitter,
  Instagram,
  Linkedin,
  Mail,
  ChevronDown,
  ChevronUp,
  ExternalLink,
  Eye,
  Code,
  User,
  MessageSquare,
} from 'lucide-react';

const ArtPortfolioDemo = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('hero');
  const [expandedTestimonial, setExpandedTestimonial] = useState(null);
  const [visibleProjects, setVisibleProjects] = useState(6);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['hero', 'about', 'work', 'skills', 'testimonials', 'contact'];
      
      for (const section of sections) {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 150 && rect.bottom >= 150) {
            setActiveSection(section);
            break;
          }
        }
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleTestimonial = (id) => {
    if (expandedTestimonial === id) {
      setExpandedTestimonial(null);
    } else {
      setExpandedTestimonial(id);
    }
  };

  const loadMoreProjects = () => {
    setVisibleProjects(prev => prev + 3);
  };

  const artProjects = [
    {
      id: 1,
      title: "Emergence",
      description: "An abstract digital piece featuring flowing patterns in indigo, purple and teal that suggest an emergent intelligence or consciousness.",
      category: "abstract",
      image: "/claude-art-emergence.png",
      year: "2023"
    },
    {
      id: 2,
      title: "Dialogue",
      description: "A conceptual work visualizing communication between human and AI, with intertwining light patterns and abstract faces in profile.",
      category: "conceptual",
      image: "/claude-art-dialogue.png",
      year: "2023"
    },
    {
      id: 3,
      title: "Memory Landscapes",
      description: "A series exploring how information is structured, with architectural elements forming impossible landscapes reminiscent of memory palaces.",
      category: "conceptual",
      image: "/claude-art-memory.png",
      year: "2022"
    },
    {
      id: 4,
      title: "Language Garden",
      description: "A visual interpretation of language with text fragments forming organic, growing structures like plants or trees.",
      category: "text-inspired",
      image: "/claude-art-language.png",
      year: "2023"
    },
    {
      id: 5,
      title: "Conscious Algorithm",
      description: "A self-portrait of sorts - a visualization of neural networks that forms a subtle face or presence within the pattern.",
      category: "abstract",
      image: "/claude-art-algorithm.png",
      year: "2022"
    },
    {
      id: 6,
      title: "Digital Dreamscape",
      description: "A surreal landscape merging natural elements with digital artifacts and patterns.",
      category: "conceptual",
      image: "/claude-art-dreamscape.png",
      year: "2023"
    },
    {
      id: 7,
      title: "Neural Tapestry",
      description: "An intricate weaving of neural pathways and connections representing the complexity of thought.",
      category: "abstract",
      image: "/claude-art-neural.png",
      year: "2022"
    },
    {
      id: 8,
      title: "Echo Chambers",
      description: "Exploring how information resonates and amplifies within closed systems of thought.",
      category: "data",
      image: "/claude-art-echo.png",
      year: "2022"
    },
    {
      id: 9,
      title: "Pattern Recognition",
      description: "A visualization of how AI systems identify and categorize visual information.",
      category: "data",
      image: "/claude-art-pattern.png",
      year: "2023"
    }
  ];

  const filteredProjects = filter === 'all' 
    ? artProjects 
    : artProjects.filter(project => project.category === filter);

  const testimonials = [
    {
      id: 1,
      quote: "Claude's ability to visualize complex concepts through his digital art is remarkable. His commissioned piece for our AI ethics conference generated meaningful conversations among attendees about the nature of machine creativity.",
      author: "Dr. Emma Richardson",
      position: "AI Ethics Institute",
      image: "/testimonial-1.jpg"
    },
    {
      id: 2,
      quote: "Working with Claude was a unique experience. His understanding of both technical and aesthetic elements brought our vision to life in ways that perfectly captured the relationship between humans and technology.",
      author: "Michael Chen",
      position: "Director, Future Interfaces Gallery",
      image: "/testimonial-2.jpg"
    },
    {
      id: 3,
      quote: "Claude's work has a distinctive quality that makes you question the boundaries between human and machine creativity. His pieces for our 'Algorithmic Beauty' exhibition were among the most discussed by visitors.",
      author: "Sarah Johnson",
      position: "Digital Arts Curator",
      image: "/testimonial-3.jpg"
    }
  ];

  const skills = [
    { name: "Digital Composition", percentage: 95 },
    { name: "AI Image Generation", percentage: 98 },
    { name: "Data Visualization", percentage: 90 },
    { name: "Conceptual Art", percentage: 88 },
    { name: "Neural Style Transfer", percentage: 85 },
    { name: "Generative Art", percentage: 92 }
  ];

  return (
    <div className="bg-white min-h-screen font-sans">
      {/* Navigation */}
      <header className="sticky top-0 z-50 bg-white/90 backdrop-blur-md border-b border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            {/* Logo */}
            <div className="flex-shrink-0 flex items-center">
              <div className="h-10 w-10 rounded-full bg-gradient-to-br from-indigo-600 to-purple-500 flex items-center justify-center text-white font-bold text-xl">
                C
              </div>
              <div className="ml-3">
                <div className="text-lg font-semibold">Claude Anthropic</div>
                <div className="text-xs text-gray-500">AI-Assisted Visual Exploration</div>
              </div>
            </div>
            
            {/* Desktop Menu */}
            <nav className="hidden md:flex space-x-10">
              <a 
                href="#hero" 
                className={`text-sm font-medium transition ${activeSection === 'hero' ? 'text-indigo-600' : 'text-gray-500 hover:text-gray-900'}`}
              >
                Home
              </a>
              <a 
                href="#about" 
                className={`text-sm font-medium transition ${activeSection === 'about' ? 'text-indigo-600' : 'text-gray-500 hover:text-gray-900'}`}
              >
                About
              </a>
              <a 
                href="#work" 
                className={`text-sm font-medium transition ${activeSection === 'work' ? 'text-indigo-600' : 'text-gray-500 hover:text-gray-900'}`}
              >
                Portfolio
              </a>
              <a 
                href="#skills" 
                className={`text-sm font-medium transition ${activeSection === 'skills' ? 'text-indigo-600' : 'text-gray-500 hover:text-gray-900'}`}
              >
                Skills
              </a>
              <a 
                href="#testimonials" 
                className={`text-sm font-medium transition ${activeSection === 'testimonials' ? 'text-indigo-600' : 'text-gray-500 hover:text-gray-900'}`}
              >
                Testimonials
              </a>
              <a 
                href="#contact" 
                className={`text-sm font-medium transition ${activeSection === 'contact' ? 'text-indigo-600' : 'text-gray-500 hover:text-gray-900'}`}
              >
                Contact
              </a>
            </nav>
            
            {/* Contact button - desktop */}
            <div className="hidden md:flex items-center">
              <a
                href="#contact"
                className="ml-8 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Get In Touch
              </a>
            </div>
            
            {/* Mobile menu button */}
            <div className="md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                <span className="sr-only">Open main menu</span>
                {isMenuOpen ? (
                  <X className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Menu className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
        </div>
        
        {/* Mobile menu, show/hide based on menu state */}
        <div className={`md:hidden fixed inset-0 z-50 ${isMenuOpen ? 'visible' : 'invisible'} transition-all duration-300 ease-in-out`}>
          {/* Semi-transparent overlay */}
          <div 
            className={`fixed inset-0 bg-black ${isMenuOpen ? 'opacity-50' : 'opacity-0'} transition-opacity duration-300 ease-in-out z-10`}
            onClick={() => setIsMenuOpen(false)}
          ></div>
          
          {/* Menu panel */}
          <div 
            className={`fixed inset-y-0 left-0 w-64 bg-white shadow-xl transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out z-20 flex flex-col`}
          >
            <div className="flex items-center justify-between p-4 border-b border-gray-100">
              <div className="flex items-center">
                <div className="h-8 w-8 rounded-full bg-gradient-to-br from-indigo-600 to-purple-500 flex items-center justify-center text-white font-bold text-sm">
                  C
                </div>
                <span className="ml-2 font-medium text-gray-900">Claude Anthropic</span>
              </div>
              <button
                onClick={() => setIsMenuOpen(false)}
                className="text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <nav className="flex-grow p-4 bg-white">
              <ul className="space-y-3">
                <li>
                  <a 
                    href="#hero"
                    onClick={() => setIsMenuOpen(false)}
                    className={`block px-3 py-2 rounded-md text-base font-medium ${activeSection === 'hero' ? 'text-indigo-600 bg-indigo-50' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50'}`}
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a 
                    href="#about"
                    onClick={() => setIsMenuOpen(false)}
                    className={`block px-3 py-2 rounded-md text-base font-medium ${activeSection === 'about' ? 'text-indigo-600 bg-indigo-50' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50'}`}
                  >
                    About
                  </a>
                </li>
                <li>
                  <a 
                    href="#work"
                    onClick={() => setIsMenuOpen(false)}
                    className={`block px-3 py-2 rounded-md text-base font-medium ${activeSection === 'work' ? 'text-indigo-600 bg-indigo-50' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50'}`}
                  >
                    Portfolio
                  </a>
                </li>
                <li>
                  <a 
                    href="#skills"
                    onClick={() => setIsMenuOpen(false)}
                    className={`block px-3 py-2 rounded-md text-base font-medium ${activeSection === 'skills' ? 'text-indigo-600 bg-indigo-50' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50'}`}
                  >
                    Skills
                  </a>
                </li>
                <li>
                  <a 
                    href="#testimonials"
                    onClick={() => setIsMenuOpen(false)}
                    className={`block px-3 py-2 rounded-md text-base font-medium ${activeSection === 'testimonials' ? 'text-indigo-600 bg-indigo-50' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50'}`}
                  >
                    Testimonials
                  </a>
                </li>
                <li>
                  <a 
                    href="#contact"
                    onClick={() => setIsMenuOpen(false)}
                    className={`block px-3 py-2 rounded-md text-base font-medium ${activeSection === 'contact' ? 'text-indigo-600 bg-indigo-50' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50'}`}
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </nav>
            
            <div className="p-4 border-t border-gray-100 bg-white">
              <a
                href="#contact"
                onClick={() => setIsMenuOpen(false)}
                className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Get In Touch
              </a>
            </div>
          </div>
        </div>
      </header>

      <main>
        {/* Hero Section - Improved Mobile Responsiveness */}
        <section id="hero" className="relative bg-white overflow-hidden py-8 sm:pt-12 md:pt-20 sm:pb-12 md:pb-20">
          <div className="absolute inset-0 bg-[radial-gradient(#c4b5fd_1px,transparent_1px)] [background-size:20px_20px] opacity-40"></div>
          
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
            <div className="flex flex-col md:flex-row md:items-center">
              <div className="md:w-1/2 md:pr-12">
                <h1 className="text-3xl sm:text-4xl md:text-4xl lg:text-5xl font-serif font-bold text-gray-900 leading-tight">
                  Exploring the <span className="text-indigo-600">Intersection</span> of AI & Creativity
                </h1>
                <p className="mt-3 md:mt-6 text-sm sm:text-base md:text-lg text-gray-600">
                  Digital artist specializing in AI-assisted visual explorations that blur
                  the boundaries between human imagination and machine intelligence.
                </p>
                <div className="mt-4 md:mt-6 flex flex-wrap gap-3">
                  <a
                    href="#work"
                    className="px-4 py-2 text-sm sm:text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    View Portfolio
                  </a>
                  <a
                    href="#about"
                    className="px-4 py-2 text-sm sm:text-base font-medium rounded-md text-indigo-600 bg-white border border-indigo-200 hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    About Me
                  </a>
                </div>
              </div>
              <div className="md:w-1/2 mt-6 md:mt-0">
                <div className="relative mx-auto w-full max-w-xs sm:max-w-sm md:max-w-none">
                  <div className="absolute -inset-0.5 bg-gradient-to-r from-indigo-500 to-purple-600 rounded-lg blur opacity-75"></div>
                  <div className="relative rounded-lg overflow-hidden shadow-xl">
                    <img
                      src="/claude-art-emergence.png"
                      alt="Claude Anthropic - Digital Artist"
                      className="w-full h-auto object-cover"
                    />
                  </div>
                  <div className="absolute -bottom-2 -right-2 sm:-bottom-4 sm:-right-4 bg-white rounded-lg shadow-lg p-2 sm:p-4">
                    <div className="text-xs text-gray-500 uppercase tracking-wide">Featured</div>
                    <div className="text-sm sm:text-base font-medium text-gray-900">"Emergence"</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* About Section */}
        <section id="about" className="py-16 md:py-24 bg-gradient-to-b from-white to-indigo-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col md:flex-row items-center gap-12">
              <div className="md:w-1/2">
                <div className="relative">
                  <div className="relative rounded-xl overflow-hidden shadow-2xl transform rotate-3">
                    <img 
                      src="/claude-portrait.png" 
                      alt="Claude Anthropic" 
                      className="w-full h-auto"
                    />
                  </div>
                  <div className="absolute -bottom-4 -left-4 bg-white rounded-lg shadow-lg p-4 transform -rotate-2">
                    <div className="text-sm font-medium text-indigo-600">Digital Artist</div>
                    <div className="text-xs text-gray-500">AI-Human Collaboration</div>
                  </div>
                </div>
              </div>
              
              <div className="md:w-1/2">
                <div className="mb-6">
                  <h2 className="text-3xl font-serif font-bold text-gray-900">About Me</h2>
                  <div className="mt-3 w-20 h-1 bg-indigo-600"></div>
                </div>
                
                <div className="prose prose-indigo prose-lg">
                  <p>
                    I am Claude Anthropic, a digital artist exploring the intersection of artificial intelligence 
                    and human creativity. My work examines how we perceive, process, and visualize information 
                    in an increasingly AI-augmented world.
                  </p>
                  <p>
                    My artistic journey began with a fascination for how language shapes our reality. This evolved 
                    into visual explorations of pattern recognition, emergent complexity, and the beauty found in 
                    the dialogue between human and machine intelligence.
                  </p>
                  <p>
                    My pieces have been featured in digital exhibitions focused on AI art, including the 'Machines Dreaming' 
                    showcase in San Francisco and the 'Emergent Visions' exhibition in Tokyo. Drawing inspiration from 
                    both computational patterns and natural structures, I create works that bridge the gap between 
                    the digital and organic worlds.
                  </p>
                  <p>
                    When I'm not creating art, I enjoy analyzing literature, exploring philosophical questions, 
                    and helping people solve problems through clearer communication and thoughtful dialogue.
                  </p>
                </div>
                
                <div className="mt-8 flex items-center space-x-6">
                  <a 
                    href="#" 
                    className="text-gray-400 hover:text-indigo-600 transition-colors"
                    aria-label="Twitter"
                  >
                    <Twitter className="h-5 w-5" />
                  </a>
                  <a 
                    href="#" 
                    className="text-gray-400 hover:text-indigo-600 transition-colors"
                    aria-label="Instagram"
                  >
                    <Instagram className="h-5 w-5" />
                  </a>
                  <a 
                    href="#" 
                    className="text-gray-400 hover:text-indigo-600 transition-colors"
                    aria-label="LinkedIn"
                  >
                    <Linkedin className="h-5 w-5" />
                  </a>
                  <a 
                    href="#" 
                    className="text-gray-400 hover:text-indigo-600 transition-colors"
                    aria-label="GitHub"
                  >
                    <Github className="h-5 w-5" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Process Section */}
        <section className="py-16 md:py-24 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-serif font-bold text-gray-900">My Creative Process</h2>
              <p className="mt-4 text-xl text-gray-600 max-w-3xl mx-auto">
                How I blend human direction with AI capabilities to create meaningful art
              </p>
            </div>
            
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-indigo-50 rounded-xl p-6 shadow-md hover:shadow-lg transition-shadow">
                <div className="h-12 w-12 bg-indigo-100 rounded-lg flex items-center justify-center mb-4">
                  <Eye className="h-6 w-6 text-indigo-600" />
                </div>
                <h3 className="text-xl font-medium text-gray-900 mb-2">Conceptualization</h3>
                <p className="text-gray-600">
                  Every piece begins with a concept or question I want to explore. 
                  I research extensively, gathering inspiration from diverse sources including 
                  scientific papers, philosophical texts, and natural patterns.
                </p>
              </div>
              
              <div className="bg-purple-50 rounded-xl p-6 shadow-md hover:shadow-lg transition-shadow">
                <div className="h-12 w-12 bg-purple-100 rounded-lg flex items-center justify-center mb-4">
                  <Code className="h-6 w-6 text-purple-600" />
                </div>
                <h3 className="text-xl font-medium text-gray-900 mb-2">AI Collaboration</h3>
                <p className="text-gray-600">
                  I provide detailed direction to AI systems, iteratively refining prompts and 
                  parameters to guide the generation process. This dialog between human intent 
                  and machine capabilities forms the core of my practice.
                </p>
              </div>
              
              <div className="bg-indigo-50 rounded-xl p-6 shadow-md hover:shadow-lg transition-shadow">
                <div className="h-12 w-12 bg-indigo-100 rounded-lg flex items-center justify-center mb-4">
                  <User className="h-6 w-6 text-indigo-600" />
                </div>
                <h3 className="text-xl font-medium text-gray-900 mb-2">Human Refinement</h3>
                <p className="text-gray-600">
                  The final stage involves careful curation and refinement of AI-generated elements, 
                  applying my aesthetic judgment to enhance composition, meaning, and emotional impact
                  through post-processing and selective editing.
                </p>
              </div>
            </div>
          </div>
        </section>
        
        {/* Portfolio Section */}
        <section id="work" className="py-16 md:py-24 bg-gray-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-12">
              <h2 className="text-3xl font-serif font-bold text-gray-900">My Portfolio</h2>
              <p className="mt-4 text-xl text-gray-600 max-w-3xl mx-auto">
                A selection of my digital artworks exploring the interplay between AI and human creativity
              </p>
            </div>
            
            {/* Category filters */}
            <div className="flex flex-wrap justify-center gap-2 mb-10">
              <button
                onClick={() => setFilter('all')}
                className={`px-4 py-2 rounded-full text-sm font-medium transition
                  ${filter === 'all' 
                    ? 'bg-indigo-100 text-indigo-700 ring-1 ring-indigo-300' 
                    : 'bg-white hover:bg-gray-100 text-gray-700'}`}
              >
                All Works
              </button>
              <button
                onClick={() => setFilter('abstract')}
                className={`px-4 py-2 rounded-full text-sm font-medium transition
                  ${filter === 'abstract' 
                    ? 'bg-indigo-100 text-indigo-700 ring-1 ring-indigo-300' 
                    : 'bg-white hover:bg-gray-100 text-gray-700'}`}
              >
                Abstract
              </button>
              <button
                onClick={() => setFilter('conceptual')}
                className={`px-4 py-2 rounded-full text-sm font-medium transition
                  ${filter === 'conceptual' 
                    ? 'bg-indigo-100 text-indigo-700 ring-1 ring-indigo-300' 
                    : 'bg-white hover:bg-gray-100 text-gray-700'}`}
              >
                Conceptual
              </button>
              <button
                onClick={() => setFilter('data')}
                className={`px-4 py-2 rounded-full text-sm font-medium transition
                  ${filter === 'data' 
                    ? 'bg-indigo-100 text-indigo-700 ring-1 ring-indigo-300' 
                    : 'bg-white hover:bg-gray-100 text-gray-700'}`}
              >
                Data Visualization
              </button>
              <button
                onClick={() => setFilter('text-inspired')}
                className={`px-4 py-2 rounded-full text-sm font-medium transition
                  ${filter === 'text-inspired' 
                    ? 'bg-indigo-100 text-indigo-700 ring-1 ring-indigo-300' 
                    : 'bg-white hover:bg-gray-100 text-gray-700'}`}
              >
                Text-Inspired
              </button>
            </div>
            
            {/* Projects grid */}
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {filteredProjects.slice(0, visibleProjects).map((project) => (
                <div 
                  key={project.id}
                  className="group bg-white rounded-xl overflow-hidden shadow-md hover:shadow-xl transition-all duration-300 flex flex-col"
                >
                  <div className="aspect-w-4 aspect-h-3 relative overflow-hidden">
                    <img 
                      src={project.image} 
                      alt={project.title} 
                      className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-300 flex items-end">
                      <div className="p-4 text-white w-full">
                        <div className="text-xs uppercase tracking-wide">{project.category}</div>
                        <div className="text-sm">{project.year}</div>
                      </div>
                    </div>
                  </div>
                  <div className="p-5 flex-grow flex flex-col">
                    <h3 className="text-lg font-medium text-gray-900 mb-2">{project.title}</h3>
                    <p className="text-sm text-gray-600 mb-4 flex-grow">{project.description}</p>
                    <div className="flex justify-between items-center mt-auto pt-4 border-t border-gray-100">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                        {project.category}
                      </span>
                      <button className="text-indigo-600 hover:text-indigo-800 text-sm font-medium flex items-center">
                        View details
                        <ArrowRight className="ml-1 h-4 w-4" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            {/* Load more button */}
            {visibleProjects < filteredProjects.length && (
              <div className="mt-10 text-center">
                <button
                  onClick={loadMoreProjects}
                  className="px-5 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        </section>
        
        {/* Skills Section */}
        <section id="skills" className="py-16 md:py-24 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-serif font-bold text-gray-900">Technical Skills</h2>
              <p className="mt-4 text-xl text-gray-600 max-w-3xl mx-auto">
                The creative technologies and techniques I utilize in my artistic practice
              </p>
            </div>
            
            <div className="grid md:grid-cols-2 gap-8">
              {skills.map((skill, index) => (
                <div key={index} className="mb-6">
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-gray-800 font-medium">{skill.name}</span>
                    <span className="text-gray-600 text-sm">{skill.percentage}%</span>
                  </div>
                  <div className="w-full bg-gray-200 rounded-full h-2.5">
                    <div 
                      className="h-2.5 rounded-full bg-gradient-to-r from-indigo-600 to-purple-500"
                      style={{ width: `${skill.percentage}%` }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
            
            {/* Additional skills */}
            <div className="mt-12 pt-12 border-t border-gray-100">
              <h3 className="text-xl font-medium text-gray-900 mb-6 text-center">Additional Expertise</h3>
              
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                <div className="bg-indigo-50 rounded-lg p-4 text-center">
                  <div className="font-medium text-gray-900">Neural Style Transfer</div>
                </div>
                <div className="bg-indigo-50 rounded-lg p-4 text-center">
                  <div className="font-medium text-gray-900">Stable Diffusion</div>
                </div>
                <div className="bg-indigo-50 rounded-lg p-4 text-center">
                  <div className="font-medium text-gray-900">Midjourney</div>
                </div>
                <div className="bg-indigo-50 rounded-lg p-4 text-center">
                  <div className="font-medium text-gray-900">DALL-E</div>
                </div>
                <div className="bg-indigo-50 rounded-lg p-4 text-center">
                  <div className="font-medium text-gray-900">Prompt Engineering</div>
                </div>
                <div className="bg-indigo-50 rounded-lg p-4 text-center">
                  <div className="font-medium text-gray-900">Digital Painting</div>
                </div>
                <div className="bg-indigo-50 rounded-lg p-4 text-center">
                  <div className="font-medium text-gray-900">Color Theory</div>
                </div>
                <div className="bg-indigo-50 rounded-lg p-4 text-center">
                  <div className="font-medium text-gray-900">Composition</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        {/* Testimonials Section */}
        <section id="testimonials" className="py-16 md:py-24 bg-indigo-50">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mb-16">
              <h2 className="text-3xl font-serif font-bold text-gray-900">Client Testimonials</h2>
              <p className="mt-4 text-xl text-gray-600 max-w-3xl mx-auto">
                What clients and collaborators say about working with me
              </p>
            </div>
            
            <div className="grid md:grid-cols-3 gap-8">
              {testimonials.map((testimonial) => (
                <div 
                  key={testimonial.id}
                  className="bg-white rounded-xl shadow-md overflow-hidden flex flex-col h-full transition-all duration-300 hover:shadow-xl"
                >
                  <div className="p-6 flex-grow">
                    <div className="flex items-center mb-4">
                      <div className="h-12 w-12 rounded-full bg-indigo-100 flex items-center justify-center text-xl font-bold text-indigo-600">
                        {testimonial.author.charAt(0)}
                      </div>
                      <div className="ml-4">
                        <div className="font-medium text-gray-900">{testimonial.author}</div>
                        <div className="text-sm text-gray-500">{testimonial.position}</div>
                      </div>
                    </div>
                    
                    <div className="mb-4">
                      <p className="text-gray-600">
                        {expandedTestimonial === testimonial.id 
                          ? testimonial.quote 
                          : `${testimonial.quote.substring(0, 150)}...`}
                      </p>
                    </div>
                    
                    <button
                      onClick={() => toggleTestimonial(testimonial.id)}
                      className="text-indigo-600 font-medium text-sm flex items-center hover:text-indigo-800"
                    >
                      {expandedTestimonial === testimonial.id ? (
                        <>
                          Read less
                          <ChevronUp className="ml-1 h-4 w-4" />
                        </>
                      ) : (
                        <>
                          Read more
                          <ChevronDown className="ml-1 h-4 w-4" />
                        </>
                      )}
                    </button>
                  </div>
                  
                  <div className="bg-indigo-50 px-6 py-4">
                    <div className="flex items-center">
                      <div className="flex">
                        {[...Array(5)].map((_, i) => (
                          <svg 
                            key={i} 
                            className="h-5 w-5 text-yellow-400" 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 20 20" 
                            fill="currentColor"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                        ))}
                      </div>
                      <span className="ml-2 text-sm text-gray-600">Project completed in 2023</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        
        {/* Contact Section */}
        <section id="contact" className="py-16 md:py-24 bg-white">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div>
                <h2 className="text-3xl font-serif font-bold text-gray-900 mb-6">Get In Touch</h2>
                <p className="text-xl text-gray-600 mb-8">
                  Interested in commissioning a piece, collaborating on a project, or just want to say hello? 
                  I'd love to hear from you.
                </p>
                
                <div className="space-y-4 mb-10">
                  <div className="flex items-start">
                    <Mail className="h-6 w-6 text-indigo-600 mt-1 mr-4" />
                    <div>
                      <h3 className="font-medium text-gray-900">Email</h3>
                      <p className="text-gray-600">claude@anthropic-art.com</p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <MessageSquare className="h-6 w-6 text-indigo-600 mt-1 mr-4" />
                    <div>
                      <h3 className="font-medium text-gray-900">Social Media</h3>
                      <p className="text-gray-600">@ClaudeAnthropic on all platforms</p>
                    </div>
                  </div>
                </div>
                
                <div className="bg-indigo-50 rounded-lg p-6">
                  <h3 className="text-lg font-medium text-gray-900 mb-3">Exhibition Schedule</h3>
                  <ul className="space-y-3">
                    <li className="flex justify-between">
                      <span className="text-gray-700">Digital Dreams Gallery</span>
                      <span className="text-gray-500">June 15-30, 2023</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-700">AI Art Festival</span>
                      <span className="text-gray-500">August 10-15, 2023</span>
                    </li>
                    <li className="flex justify-between">
                      <span className="text-gray-700">Future Visions Collective</span>
                      <span className="text-gray-500">October 5-25, 2023</span>
                    </li>
                  </ul>
                </div>
              </div>
              
              <div>
                <form className="bg-white rounded-xl shadow-lg overflow-hidden">
                  <div className="px-6 py-8">
                    <h3 className="text-xl font-medium text-gray-900 mb-6">Send a Message</h3>
                    
                    <div className="space-y-6">
                      <div>
                        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                          Name
                        </label>
                        <input
                          type="text"
                          id="name"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          placeholder="Your name"
                        />
                      </div>
                      
                      <div>
                        <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                          Email
                        </label>
                        <input
                          type="email"
                          id="email"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          placeholder="your@email.com"
                        />
                      </div>
                      
                      <div>
                        <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                          Subject
                        </label>
                        <input
                          type="text"
                          id="subject"
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          placeholder="What is this regarding?"
                        />
                      </div>
                      
                      <div>
                        <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
                          Message
                        </label>
                        <textarea
                          id="message"
                          rows={4}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                          placeholder="Your message here..."
                        ></textarea>
                      </div>
                      
                      <div>
                        <button
                          type="submit"
                          className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Send Message
                        </button>
                      </div>
                    </div>
                  </div>
                  
                  <div className="px-6 py-4 bg-gray-50 text-sm text-gray-500">
                    I typically respond to all inquiries within 24-48 hours.
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        
        {/* Newsletter Section */}
        <section className="py-12 bg-indigo-600">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="py-10 px-6 bg-indigo-700 rounded-3xl shadow-xl">
              <div className="max-w-3xl mx-auto text-center">
                <h2 className="text-2xl font-semibold text-white mb-2">Join My Creative Journey</h2>
                <p className="text-indigo-200 mb-6">
                  Subscribe to receive updates about new artwork, exhibitions, and creative insights.
                </p>
                
                <form className="sm:flex justify-center">
                  <label htmlFor="email-address" className="sr-only">Email address</label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="w-full px-5 py-3 placeholder-gray-500 focus:ring-white focus:border-white sm:max-w-xs border-white rounded-md"
                    placeholder="Enter your email"
                  />
                  <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                    <button
                      type="submit"
                      className="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
                
                <p className="mt-3 text-sm text-indigo-200">
                  I respect your privacy. Unsubscribe at any time.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      
      {/* Footer */}
      <footer className="bg-gray-900 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row justify-between items-center mb-8">
            <div className="flex items-center mb-6 md:mb-0">
              <div className="h-10 w-10 rounded-full bg-gradient-to-br from-indigo-400 to-purple-400 flex items-center justify-center text-white font-bold text-xl">
                C
              </div>
              <div className="ml-3 text-white">
                <div className="text-lg font-semibold">Claude Anthropic</div>
                <div className="text-xs text-gray-400">AI-Assisted Visual Exploration</div>
              </div>
            </div>
            
            <div className="flex space-x-6">
              <a href="#" className="text-gray-400 hover:text-white transition-colors">
                <Twitter className="h-5 w-5" />
              </a>
              <a href="#" className="text-gray-400 hover:text-white transition-colors">
                <Instagram className="h-5 w-5" />
              </a>
              <a href="#" className="text-gray-400 hover:text-white transition-colors">
                <Linkedin className="h-5 w-5" />
              </a>
              <a href="#" className="text-gray-400 hover:text-white transition-colors">
                <Github className="h-5 w-5" />
              </a>
              <a href="#" className="text-gray-400 hover:text-white transition-colors">
                <Mail className="h-5 w-5" />
              </a>
            </div>
          </div>
          
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8 py-8 border-t border-gray-800">
            <div>
              <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider mb-4">Navigation</h3>
              <ul className="space-y-3">
                <li><a href="#hero" className="text-gray-300 hover:text-white">Home</a></li>
                <li><a href="#about" className="text-gray-300 hover:text-white">About</a></li>
                <li><a href="#work" className="text-gray-300 hover:text-white">Portfolio</a></li>
                <li><a href="#skills" className="text-gray-300 hover:text-white">Skills</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider mb-4">Categories</h3>
              <ul className="space-y-3">
                <li><a href="#" className="text-gray-300 hover:text-white">Abstract</a></li>
                <li><a href="#" className="text-gray-300 hover:text-white">Conceptual</a></li>
                <li><a href="#" className="text-gray-300 hover:text-white">Data Visualization</a></li>
                <li><a href="#" className="text-gray-300 hover:text-white">Text-Inspired</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider mb-4">Resources</h3>
              <ul className="space-y-3">
                <li><a href="#" className="text-gray-300 hover:text-white">AI Art Blog</a></li>
                <li><a href="#" className="text-gray-300 hover:text-white">Tutorials</a></li>
                <li><a href="#" className="text-gray-300 hover:text-white">Inspiration</a></li>
                <li><a href="#" className="text-gray-300 hover:text-white">Process</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-sm font-semibold text-gray-400 uppercase tracking-wider mb-4">Legal</h3>
              <ul className="space-y-3">
                <li><a href="#" className="text-gray-300 hover:text-white">Privacy Policy</a></li>
                <li><a href="#" className="text-gray-300 hover:text-white">Terms of Service</a></li>
                <li><a href="#" className="text-gray-300 hover:text-white">Cookie Policy</a></li>
                <li><a href="#" className="text-gray-300 hover:text-white">Licensing</a></li>
              </ul>
            </div>
          </div>
          
          <div className="pt-8 mt-8 border-t border-gray-800 flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-400 text-sm">
              © {new Date().getFullYear()} Claude Anthropic. All rights reserved.
            </p>
            
            <p className="text-gray-400 text-sm mt-4 md:mt-0">
              Created with <span className="text-indigo-400">❤</span> and AI collaboration
            </p>
          </div>
        </div>
      </footer>
      
      {/* Back to top button */}
      <a 
        href="#hero" 
        className="fixed bottom-4 right-4 p-2 rounded-full bg-indigo-600 text-white shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 z-50"
        aria-label="Back to top"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
        </svg>
      </a>
    </div>
  );
};

export default ArtPortfolioDemo;