import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Code,
  Zap,
  Users,
  Layout,
  Server,
  ShoppingBag,
  CheckCircle,
  ArrowRight,
  Star,
  ChevronRight,
  Globe,
  Smartphone,
  PenTool,
  Clock,
  Award,
  BarChart2,
  MessageSquare,
  FileText,
  Settings,
  Database,
  Menu,
  X,
  ArrowUpRight,
  Shield,
  BookOpen,
  Briefcase,
  ArrowBigUpDash,
  Laptop,
  Tablet,
} from 'lucide-react';

// Import the demo component
import LandingPage from './LandingPage';
import RestaurantDemo from './Demo/RestaurantDemo';
import EcommerceDemo from './Demo/EcommerceDemo';
import ProfessionalDemo from './Demo/ProfessionalDemo';
import LocalBusinessDemo from './Demo/LocalBusinessDemo';
import ProfessionalResourcesPage from "./Demo/ProfessionalResourcesPage"; 
import ProfessionalServicesPage from "./Demo/ProfessionalServicesPage";
import ArtPortfolioDemo from './Demo/ArtPortfolioDemo.js';
import QualificationSetupPage from './Demo/QualificationSetupPage';
import IntegrationsPage from './Demo/IntegrationsPage';
import SaasDemo from './Demo/SaasDemo.js';
const WebDevLandingPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [expandedFaqs, setExpandedFaqs] = useState(new Set());
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('hero');
  const [scrollProgress, setScrollProgress] = useState(0);
  
  // Demo modal states
  const [isDemoModalOpen, setIsDemoModalOpen] = useState(false);
  const [activeDemoSite, setActiveDemoSite] = useState(null);
  const [activeDemoTab, setActiveDemoTab] = useState('home');
  const [activeDemoDevice, setActiveDemoDevice] = useState('desktop');

  // Track scroll position for animations and active section
  useEffect(() => {
    const handleScroll = () => {
      // Calculate scroll progress percentage
      const totalHeight = document.body.scrollHeight - window.innerHeight;
      const progress = (window.scrollY / totalHeight) * 100;
      setScrollProgress(progress);
      
      // Determine active section for nav highlighting
      const sections = ['hero', 'services', 'process', 'portfolio', 'pricing'];
      for (const section of sections) {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 100 && rect.bottom >= 100) {
            setActiveSection(section);
            break;
          }
        }
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleEmailSignup = (e) => {
    e.preventDefault();
    if (email) {
      navigate('/contact', { state: { prefillEmail: email } });
    }
  };

  // Replace the existing toggleFaq function with this:
  const toggleFaq = (id) => {
    setExpandedFaqs(prev => {
      const newSet = new Set();
      if (!prev.has(id)) {
        newSet.add(id);
      }
      return newSet;
    });
  };

// At the top of your component with other state declarations
const [activeCategory, setActiveCategory] = useState('all');

// Update the sampleWork array to include type property
const sampleWork = [
  {
    id: "restaurant",
    title: "Local Restaurant",
    description: "Modern, mobile-optimized design with online ordering capability",
    features: [
      "Visual menu galleries with mouth-watering photography",
      "Integrated reservation systems",
      "Mobile-friendly ordering for takeout",
      "Location maps and hours prominently displayed"
    ],
    type: "single-page",
    imageSrc: "/Capture.PNG"
  },
  {
    id: "professional",
    title: "Professional Services",
    description: "Sophisticated design focused on building trust and generating leads",
    features: [
      "Professional biography sections that build credibility",
      "Strategic contact forms positioned for maximum conversion",
      "Service descriptions that address client pain points",
      "Testimonial frameworks that showcase client success"
    ],
    type: "multi-page",
    imageSrc: "/ProfessionalServices.PNG"
  },
  {
    id: "ecommerce",
    title: "E-Commerce Shop",
    description: "Full-featured online store with seamless checkout experience",
    features: [
      "Product galleries with zoom functionality",
      "Secure, streamlined checkout process",
      "Inventory management system integration",
      "Related product recommendations"
    ],
    type: "multi-page",
    imageSrc: "/Ecom.PNG"
  },
  {
    id: "business",
    title: "Local Business",
    description: "Clean, effective web presence for service-based businesses",
    features: [
      "Service area maps and location targeting",
      "Appointment scheduling integration",
      "Customer review showcase section",
      "Mobile-optimized contact options"
    ],
    type: "single-page",
    imageSrc: "/localbus.PNG"
  },
  {
    id: "portfolio",
    title: "Artist Portfolio",
    description: "Elegant showcase for digital and traditional artists",
    features: [
      "Filterable art gallery with category navigation",
      "Interactive skill proficiency visualization",
      "Client testimonial showcases with elegant styling",
      "Comprehensive contact section with social media integration"
    ],
    type: "single-page",
    imageSrc: "/Art.PNG",
    placeholderText: "Art Portfolio Preview",
    gradientBg: "bg-gradient-to-br from-indigo-400 to-purple-600"
  },
  {
    id: "saas",
    title: "SaaS Landing Page",
    description: "Conversion-focused design for software-as-a-service products",
    features: [
      "Lead qualification dashboard with visual analytics",
      "Campaign performance tracking with interactive metrics",
      "Modern data visualization and progress indicators",
      "Responsive admin interface with intuitive navigation"
    ],
    type: "multi-page",
    imageSrc: "/SaasDemo.PNG"
  }
];

  // Services offered - outcome focused
  const services = [
    {
      icon: <Layout className="w-6 h-6 text-indigo-500" />,
      title: "Small Business Websites",
      description: "Professional 3-5 page website that attracts customers and generates leads, delivered in just 7 days."
    },
    {
      icon: <ShoppingBag className="w-6 h-6 text-indigo-500" />,
      title: "E-Commerce Stores",
      description: "Start selling products online with a custom store that processes orders 24/7, even while you sleep."
    },
    {
      icon: <Server className="w-6 h-6 text-indigo-500" />,
      title: "Lead Generation Websites",
      description: "Turn visitors into customers with strategically designed contact forms and landing pages."
    },
    {
      icon: <Smartphone className="w-6 h-6 text-indigo-500" />,
      title: "Mobile-Optimized Design",
      description: "Capture business from phone users with websites that look perfect on any device."
    }
  ];

  // Pricing packages - Updated with new pricing
  const pricingPackages = [
    {
      name: "One-Page Website",
      price: "$1,500",
      timeline: "Delivered in 7 days",
      description: "Perfect for businesses needing a professional online presence quickly",
      features: [
        "Professional one-page website",
        "Mobile-responsive design",
        "Contact form with lead notifications",
        "Google Maps integration",
        "Social media links",
        "Basic SEO setup",
        "Google Analytics tracking",
        "30 days of free support"
      ],
      popular: false
    },
    {
      name: "Small Business Website",
      price: "$3,000",
      timeline: "Delivered in 14 days",
      description: "Comprehensive solution for established businesses ready to grow",
      features: [
        "3-5 page professional website",
        "Content management system",
        "Lead capture forms",
        "Email marketing integration",
        "Blog/news section",
        "Advanced SEO implementation",
        "Google Business Profile setup",
        "Speed optimization",
        "60 days of free support"
      ],
      popular: true
    },
    {
      name: "E-Commerce Complete",
      price: "$5,000",
      timeline: "Delivered in 21 days",
      description: "Everything you need to start selling products online",
      features: [
        "Full online store with up to 50 products",
        "Secure payment processing",
        "Inventory management",
        "Automated order emails",
        "Customer accounts",
        "Product reviews",
        "Discount code system",
        "Shipping calculation",
        "90 days of free support"
      ],
      popular: false
    }
  ];

  // Process steps - customer-focused, not technical
  const processSteps = [
    {
      icon: <MessageSquare className="w-10 h-10 text-indigo-500" />,
      title: "Quick Consultation",
      description: "We learn about your business goals and what your website needs to achieve"
    },
    {
      icon: <PenTool className="w-10 h-10 text-indigo-500" />,
      title: "Custom Design Creation",
      description: "You receive design mockups tailored to your brand within 2-3 business days"
    },
    {
      icon: <FileText className="w-10 h-10 text-indigo-500" />,
      title: "Content Integration",
      description: "Your content is expertly organized to engage visitors and drive actions"
    },
    {
      icon: <Zap className="w-10 h-10 text-indigo-500" />,
      title: "Rapid Launch",
      description: "Your website goes live and starts working for your business right away"
    }
  ];

  // Our Approach elements
  const approachElements = [
    {
      title: "Strategy First, Design Second",
      icon: <Briefcase className="w-6 h-6 text-indigo-500" />,
      description: "We begin by understanding your business goals, target audience, and desired actions before designing a single element."
    },
    {
      title: "Built on Solid Foundations",
      icon: <Shield className="w-6 h-6 text-indigo-500" />,
      description: "We use industry-leading technologies to ensure your site is lightning fast, mobile-optimized, and secure."
    },
    {
      title: "Focused on Business Results",
      icon: <BarChart2 className="w-6 h-6 text-indigo-500" />,
      description: "Strategic implementation of calls-to-action, forms, and content hierarchy to drive conversions and leads."
    },
    {
      title: "Continuous Improvement",
      icon: <ArrowBigUpDash className="w-6 h-6 text-indigo-500" />,
      description: "We implement analytics and tracking to measure performance and identify opportunities for improvement."
    }
  ];

  // What Sets Us Apart elements with added 5th differentiator
  const differentiators = [
    {
      icon: <Clock className="h-6 w-6 text-indigo-600" />,
      title: "Rapid Delivery Without Sacrificing Quality",
      description: "While most agencies take months, our streamlined process allows us to build and launch websites in as little as 7 days with no compromise on quality."
    },
    {
      icon: <Users className="h-6 w-6 text-indigo-600" />,
      title: "Direct Communication",
      description: "You speak directly with the people building your website—no account managers or complicated communication chains."
    },
    {
      icon: <Globe className="h-6 w-6 text-indigo-600" />,
      title: "Complete Transparency",
      description: "No surprises or hidden costs. You'll always know exactly what you're getting, when, and from whom throughout the entire project."
    },
    {
      icon: <ShoppingBag className="h-6 w-6 text-indigo-600" />,
      title: "Business-Focused Design",
      description: "Our websites aren't just visually appealing—they're strategic business tools designed to generate leads and convert visitors into customers."
    },
    {
      icon: <Award className="h-6 w-6 text-indigo-600" />,
      title: "Dedicated Revision Rounds",
      description: "Every project includes two comprehensive revision rounds so you can provide detailed feedback and ensure your website perfectly matches your vision before launch."
    }
  ];

  // Getting Started Steps
  const gettingStartedSteps = [
    {
      number: "01",
      title: "Book a Free Consultation Call",
      description: "Schedule a 30-minute call where we'll discuss your business goals, website needs, timeline, and budget considerations."
    },
    {
      number: "02",
      title: "Receive Your Custom Proposal",
      description: "Within 24 hours, you'll receive a detailed proposal outlining recommended solutions, transparent pricing, and a clear timeline."
    },
    {
      number: "03",
      title: "Kickoff Your Project",
      description: "Once approved, we'll schedule a kickoff meeting, gather necessary information, and begin working on initial design concepts."
    }
  ];

  // FAQs with benefit-focused answers - Revised to emphasize value
  const faqs = [
    {
      id: 1,
      question: "How quickly can I get my website up and running?",
      answer: "Most small business websites can be completed and launched in just 7 days from our initial consultation. Larger websites with 5+ pages typically take 14 days, and e-commerce stores are usually ready within 21 days. Our streamlined process ensures you can start attracting customers and generating leads as quickly as possible."
    },
    {
      id: 2,
      question: "Do I need to provide content for my website?",
      answer: "We make this easy for you. Simply provide basic information about your business, and we'll handle the rest. Many clients share existing materials, then our team organizes it effectively on your website. If you need completely new content, our professional copywriting services can be added to any package to create compelling messaging that resonates with your customers."
    },
    {
      id: 3,
      question: "Will my website help my business appear in Google searches?",
      answer: "Absolutely! Every website we build includes essential SEO (Search Engine Optimization) fundamentals that help local customers find your business. We implement proper site structure, metadata, schema markup, and mobile optimization that signals to Google what your business offers and where you're located. This gives you a strong foundation for visibility in local search results."
    },
    {
      id: 4,
      question: "What happens if I need changes after my website launches?",
      answer: "All our packages include a support period (30-90 days depending on your package) where we'll make minor text and image updates at no additional cost. For ongoing updates and site management, our affordable maintenance package at $150/month includes regular updates, security monitoring, and performance optimization to keep your site running at its best."
    },
    {
      id: 5,
      question: "How do you ensure my website will meet my expectations?",
      answer: "Our collaborative process is designed to ensure satisfaction. You'll see design concepts early and provide feedback before development begins. Every project includes two comprehensive revision rounds to refine your website. We maintain open communication throughout the process and don't consider the project complete until you're fully satisfied with the result."
    },
    {
      id: 6,
      question: "What information will you need from me to get started?",
      answer: "We've streamlined this process to be as efficient as possible. Typically, we need your logo, basic information about your services, any specific photos you'd like included, and preferred contact information. During our initial consultation, we'll guide you through exactly what's needed and provide a simple checklist. Many clients can provide everything required in less than an hour."
    },
    {
      id: 7,
      question: "Do you only build new websites, or can you improve my existing one?",
      answer: "We offer both new website development and redesign services. If you have an existing website that isn't performing well, we'll analyze what's working, what isn't, and implement targeted improvements. Our redesign process preserves valuable content and SEO equity while enhancing design, functionality, and conversion potential."
    }
  ];

  // Guarantee elements - Revised for stronger confidence building
  const guaranteeElements = [
    {
      title: "7-Day Delivery Promise",
      description: "For standard small business websites, we commit to delivering your complete, ready-to-launch website within 7 days of receiving your content—so you can start generating business results faster."
    },
    {
      title: "Satisfaction Guarantee",
      description: "Your website will go through two comprehensive revision rounds to ensure it perfectly meets your expectations before launch. We're not satisfied until you are completely happy with your website."
    },
    {
      title: "Mobile Perfection Guarantee",
      description: "Your website will look and function flawlessly on all devices—smartphones, tablets, and computers. If you find any display issues, we'll fix them immediately at no charge."
    },
    {
      title: "No-Hassle Support",
      description: "All packages include free support during the guarantee period (30-90 days depending on your package). Questions, small updates, or technical issues? We'll handle them promptly with clear communication."
    }
  ];

  // Function to toggle fullscreen
  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch(err => {
        console.log(`Error attempting to enable full-screen mode: ${err.message}`);
      });
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };
  // Add this function to check if the document is in fullscreen mode
  const isFullscreen = () => {
    return (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    );
  };

  // Add this function to exit fullscreen with cross-browser support
  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  // Create a new function to close the demo modal
  const closeModal = () => {
    // Check if we're in fullscreen mode and exit if needed
    if (isFullscreen()) {
      exitFullscreen();
    }
    // Close the modal
    setIsDemoModalOpen(false);
  };
  const DemoModal = () => {
    if (!isDemoModalOpen) return null;
          
    const renderDemoContent = () => {
      // Create a device-specific wrapper with proper styling
      const deviceWrapperClass = `demo-viewport relative h-full w-full overflow-hidden ${
        activeDemoDevice === 'mobile'
          ? 'mobile-viewport'
          : activeDemoDevice === 'tablet'
            ? 'tablet-viewport'
            : 'desktop-viewport'
      }`;
        
      // Common style overrides with updated fixed button handling
      const commonStyles = `
      .demo-viewport {
        position: relative !important;
        overflow: auto;
        height: 100% !important;
        max-height: 100% !important;
      }
      /* General fixed element handling */
      .demo-viewport nav[class*="fixed"],
      .demo-viewport div[class*="fixed"],
      .demo-viewport header[class*="fixed"],
      .demo-viewport .fixed,
      .demo-viewport [style*="position:fixed"],
      .demo-viewport [style*="position: fixed"] {
        position: absolute !important;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 50;
      }
      /* Proper handling of sticky elements */
      .demo-viewport .sticky,
      .demo-viewport .sticky.top-0,
      .demo-viewport header.sticky,
      .demo-viewport nav.sticky {
        position: sticky !important;
      }
      /* Special handling for fixed floating buttons */
      .demo-viewport .fixed.bottom-4.right-4,
      .demo-viewport .fixed.bottom-4.right-4.z-20,
      .demo-viewport .fixed.bottom-4.right-4.z-30,
      .demo-viewport a[class*="fixed"][class*="bottom-4"][class*="right-4"],
      .demo-viewport button[class*="fixed"][class*="bottom-4"][class*="right-4"] {
        position: fixed !important;
        bottom: 16px !important;
        right: 16px !important;
        top: auto !important;
        left: auto !important;
        width: auto !important;
        z-index: 100 !important;
      }
      /* Special handling for fixed scroll-to-top buttons */
      .demo-viewport .fixed.bottom-4.left-4,
      .demo-viewport .fixed.bottom-4.left-4.z-20,
      .demo-viewport .fixed.bottom-4.left-4.z-30,
      .demo-viewport button[class*="fixed"][class*="bottom-4"][class*="left-4"],
      .demo-viewport a[class*="fixed"][class*="bottom-4"][class*="left-4"] {
        position: fixed !important;
        bottom: 16px !important;
        left: 16px !important;
        top: auto !important;
        right: auto !important;
        width: auto !important;
        z-index: 100 !important;
      }
      /* Handle fixed bottom elements */
      .demo-viewport [class*="fixed bottom-"],
      .demo-viewport [style*="position:fixed"][style*="bottom:"] {
        top: auto !important;
      }
      /* Handle modal-related fixed elements */
      .demo-viewport .fixed.inset-0,
      .demo-viewport .fixed.z-50 {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        z-index: 500 !important;
      }
      /* Special handling for hero sections */
      .demo-viewport .relative.pt-24,
      .demo-viewport .relative.pt-16,
      .demo-viewport section.pt-24,
      .demo-viewport section.pt-16,
      .demo-viewport section.pt-28,
      .demo-viewport section.pt-36 {
        padding-top: 8rem !important;
      }
      /* Fix for gradient overlays */
      .demo-viewport .absolute.inset-0.bg-gradient-to-b,
      .demo-viewport .absolute.inset-0.bg-gradient-to-br,
      .demo-viewport .absolute.inset-0.bg-gradient-to-r {
        position: absolute !important;
      }
      /* Handle menu overlays */
      .demo-viewport .fixed.inset-0.z-50.lg\\:hidden {
        overflow: auto !important;
      }
      `;
            
      // Updated mobile styles with better handling of navigation and hero sections
      // Updated mobile styles to hide the consultation button
      const mobileStyles = `
      .mobile-viewport {
        -webkit-text-size-adjust: 100%;
        max-width: 100%;
      }
        
      /* Fix for grid layouts */
      .mobile-viewport .md\\:flex,
      .mobile-viewport .md\\:grid-cols-2,
      .mobile-viewport .md\\:grid-cols-3,
      .mobile-viewport .md\\:grid-cols-4,
      .mobile-viewport .lg\\:grid-cols-2,
      .mobile-viewport .lg\\:grid-cols-3,
      .mobile-viewport .lg\\:grid-cols-4 {
        display: block !important;
      }
        
      /* Fix spacing issues */
      .mobile-viewport .md\\:space-x-8,
      .mobile-viewport .md\\:space-y-0 {
        margin: 0 !important;
      }
        
      /* Fix visibility issues */
      .mobile-viewport .hidden.md\\:flex,
      .mobile-viewport .hidden.md\\:block,
      .mobile-viewport .hidden.lg\\:flex,
      .mobile-viewport .hidden.lg\\:block {
        display: none !important;
      }
        
      .mobile-viewport .md\\:hidden,
      .mobile-viewport .lg\\:hidden {
        display: flex !important;
      }
        
      /* Fix flex direction */
      .mobile-viewport .flex-col.md\\:flex-row {
        flex-direction: column !important;
      }
        
      /* Fix mobile navigation */
      .mobile-viewport .fixed.inset-0.z-50.lg\\:hidden {
        position: absolute !important;
        width: 100% !important;
        height: 100% !important;
        max-height: 100% !important;
        overflow: visible !important;
        z-index: 999 !important;
      }
        
      /* Fix for mobile menu overlay */
      .mobile-viewport .absolute.inset-0.bg-gray-900.opacity-50 {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        background-color: rgba(17, 24, 39, 0.5) !important;
      }
        
      /* Fix for mobile menu sidebar */
      .mobile-viewport .absolute.right-0.top-0.bottom-0.w-64.bg-white.shadow-lg {
        position: absolute !important;
        height: 100% !important;
        overflow: auto !important;
      }
        
      /* Fix for hero section layout */
      .mobile-viewport .md\\:flex-row {
        flex-direction: column !important;
      }
        
      /* Prevent hero image overlap */
      .mobile-viewport .md\\:w-1/2 {
        width: 100% !important;
        margin-top: 2rem !important;
      }
        
      /* Adjust hero padding */
      .mobile-viewport section.pt-24.sm\\:pt-28.md\\:pt-36.pb-12.sm\\:pb-16.md\\:pb-24 {
        padding-top: 6rem !important;
        padding-bottom: 3rem !important;
      }
        
      /* Hide floating consultation button on mobile */
      .mobile-viewport .fixed.bottom-4.right-4.z-20.shadow-lg.rounded-full,
      .mobile-viewport a[class*="fixed"][class*="bottom-4"][class*="right-4"] {
        display: none !important;
      }
      
      /* Art Portfolio Demo Specific Fixes */
      .mobile-viewport #hero h1 {
        font-size: 1.5rem !important;
        line-height: 1.3 !important;
        word-wrap: break-word !important;
        max-width: 100% !important;
        overflow-wrap: break-word !important;
      }
      
      .mobile-viewport #hero .md\\:pr-12 {
        padding-right: 0 !important;
      }
      
      .mobile-viewport #hero .md\\:pr-8 {
        padding-right: 0 !important;
      }
      
      .mobile-viewport #hero p {
        font-size: 0.875rem !important;
        margin-top: 0.5rem !important;
      }
      
      .mobile-viewport #hero .mt-3.md\\:mt-8,
      .mobile-viewport #hero .mt-3.md\\:mt-6 {
        margin-top: 0.75rem !important;
      }
      
      .mobile-viewport #hero a.text-xs,
      .mobile-viewport #hero a.text-sm,
      .mobile-viewport #hero button.text-xs,
      .mobile-viewport #hero button.text-sm {
        padding: 0.5rem 0.75rem !important;
        font-size: 0.75rem !important;
      }
      
      .mobile-viewport #hero .max-w-xl,
      .mobile-viewport #hero .max-w-2xl,
      .mobile-viewport #hero .max-w-3xl {
        max-width: 100% !important;
      }
    `;
    
      // Updated tablet styles for better header display
      const tabletStyles = `
      .tablet-viewport {
        -webkit-text-size-adjust: 100%;
        max-width: 100%;
      }
        
      /* Key fix: Use mobile layout for tablet view instead of broken desktop layout */
      .tablet-viewport .md\\:hidden {
        display: grid !important; /* Show mobile header instead */
      }
        
      .tablet-viewport .hidden.md\\:flex {
        display: none !important; /* Hide desktop header on tablet */
      }
        
      /* Ensure mobile header grid is properly spaced */
      .tablet-viewport .md\\:hidden.grid.grid-cols-3 {
        grid-template-columns: 1fr auto 1fr !important;
        width: 100% !important;
        align-items: center !important;
        padding: 0.5rem 1rem !important;
      }
        
      /* Improve logo size for tablet */
      .tablet-viewport .flex.justify-center a {
        font-size: 1.25rem !important;
      }
        
      /* Fix menu and cart buttons positioning */
      .tablet-viewport .flex.justify-start button,
      .tablet-viewport .flex.justify-end button {
        padding: 0.75rem !important;
      }
        
      /* Fix announcement bar text sizing */
      .tablet-viewport .bg-gray-900.text-white.py-2 {
        font-size: 0.875rem !important;
        padding: 0.5rem 1rem !important;
      }
        
      /* Fix container widths for proper spacing */
      .tablet-viewport .container,
      .tablet-viewport .max-w-7xl {
        width: 100% !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        max-width: 100% !important;
      }
        
      /* Fix grid layouts to ensure proper responsive columns */
      .tablet-viewport .lg\\:grid-cols-2,
      .tablet-viewport .lg\\:grid-cols-3,
      .tablet-viewport .lg\\:grid-cols-4 {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
      }
        
      /* Fix product grid spacing */
      .tablet-viewport .grid.grid-cols-2.md\\:grid-cols-3.lg\\:grid-cols-4 {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
        gap: 1rem !important;
      }
        
      /* Fix sticky header positioning */
      .tablet-viewport .sticky.top-0,
      .tablet-viewport header.sticky {
        position: sticky !important;
        top: 0;
        z-index: 50;
      }
      `;
    
      // Updated Professional Demo styles
      const professionalDemoStyles = `
      /* Extra handling for Professional Demo specific components */
      .demo-viewport .bg-gradient-to-br.from-slate-50.to-blue-50 {
        background: #f8fafc;
      }
        
      /* Handle sticky header in Professional Demo */
      .demo-viewport header.fixed.w-full.z-30 {
        position: sticky !important;
        top: 0;
      }
        
      /* Adjust padding for sections to accommodate fixed header */
      .demo-viewport section.pt-24.sm\\:pt-28.md\\:pt-36,
      .demo-viewport section.pt-24 {
        padding-top: 6rem !important;
      }
        
      /* Fix for hero section in Professional Demo */
      .demo-viewport section.pt-24.sm\\:pt-28.md\\:pt-36.pb-12.sm\\:pb-16.md\\:pb-24 {
        padding-top: 8rem !important;
      }
        
      /* Fix for floating consultation button */
      .demo-viewport .fixed.bottom-4.right-4.z-20.shadow-lg.rounded-full {
        position: fixed !important;
        bottom: 16px !important;
        right: 16px !important;
        z-index: 100 !important;
      }
        
      /* Mobile viewport - hide consultation button */
      .mobile-viewport .fixed.bottom-4.right-4.z-20.shadow-lg.rounded-full {
        display: none !important;
      }
        
      /* Fix for scroll-to-top button */
      .demo-viewport .fixed.bottom-4.left-4.z-20 {
        position: fixed !important;
        bottom: 16px !important;
        left: 16px !important;
        z-index: 100 !important;
      }
        
      /* Improved mobile menu handling */
      .demo-viewport .fixed.inset-0.z-50.lg\\:hidden {
        position: absolute !important;
        height: 100% !important;
        width: 100% !important;
        overflow: auto !important;
        display: block !important;
      }
        
      .demo-viewport .absolute.inset-0.bg-gray-900.opacity-50 {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
      }
        
      .demo-viewport .absolute.right-0.top-0.bottom-0.w-64.bg-white.shadow-lg {
        position: absolute !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        height: 100% !important;
        overflow: auto !important;
        z-index: 999 !important;
      }
        
      /* Fix for mobile navigation links */
      .demo-viewport .flex.flex-col.p-4 {
        display: flex !important;
        flex-direction: column !important;
        padding: 1rem !important;
      }
        
      /* Fix hero layout for different screen sizes */
      @media (max-width: 768px) {
        .demo-viewport .flex.flex-col.md\\:flex-row.md\\:items-center {
          flex-direction: column !important;
        }
          
        .demo-viewport .md\\:w-1/2.md\\:pr-8.lg\\:pr-12,
        .demo-viewport .md\\:w-1/2.mt-8.md\\:mt-0 {
          width: 100% !important;
          padding-right: 0 !important;
          margin-top: 2rem !important;
        }
      }
      `;
    
      const restaurantDemoStyles = `
      /* Extra handling for Restaurant Demo specific components */
      .demo-viewport .sticky.top-0 {
        position: sticky !important;
      }
      `;
    
      // Enhance ecommerce-specific styles
      const ecommerceDemoStyles = `
      /* Extra handling for E-commerce Demo specific components */
      .demo-viewport .sticky {
        position: sticky !important;
      }
        
      /* Fix for menu and cart overlays to stay within viewport */
      .tablet-viewport .fixed.inset-0 {
        position: absolute !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        height: 100% !important;
      }
        
      /* Ensure shopping cart sidebar doesn't overflow */
      .tablet-viewport .absolute.inset-y-0.right-0.w-full.max-w-md {
        width: 80% !important;
        max-width: 380px !important;
      }
        
      /* Fix bottom cart positioning */
      .demo-viewport .fixed.bottom-0 {
        position: absolute !important;
        bottom: 0 !important;
        top: auto !important;
      }
      `;
    
      const localBusinessDemoStyles = `
      /* Extra handling for Local Business Demo specific components */
      .demo-viewport .sticky.top-0 {
        position: sticky !important;
      }
      `;
      
      // Art Portfolio Demo specific fixes
      const artPortfolioDemoStyles = `
      /* Art Portfolio Demo specific styles - Mobile Hero Margin Fix */
      .demo-viewport #hero h1 {
        word-wrap: break-word !important;
        overflow-wrap: break-word !important;
        hyphens: auto !important;
      }

      /* Target the root container and all its children directly */
      .mobile-viewport #hero {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .mobile-viewport #hero > div {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        max-width: 100% !important;
        width: 100% !important;
      }

      /* Override any max-width settings to use full width */
      .mobile-viewport #hero .max-w-7xl,
      .mobile-viewport #hero .max-w-7xl > div,
      .mobile-viewport #hero .max-w-xl,
      .mobile-viewport #hero .max-w-2xl,
      .mobile-viewport #hero .max-w-3xl {
        max-width: 100% !important;
        width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      /* Target flex containers directly */
      .mobile-viewport #hero .flex.flex-col.md\\:flex-row,
      .mobile-viewport #hero .flex.flex-col.md\\:flex-row > div {
        width: 100% !important;
        max-width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      /* Make text fill the available space */
      .mobile-viewport #hero h1 {
        font-size: 1.75rem !important;
        line-height: 1.3 !important;
        text-align: left !important;
        width: 100% !important;
        max-width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }

      .mobile-viewport #hero p {
        font-size: 0.875rem !important;
        margin-top: 0.5rem !important;
        text-align: left !important;
        width: 100% !important;
        max-width: 100% !important;
      }

      /* Remove any specific margin/width classes that might be causing issues */
      .mobile-viewport #hero .md\\:w-1/2,
      .mobile-viewport #hero .md\\:w-1/2.md\\:pr-12,
      .mobile-viewport #hero .md\\:w-1/2.md\\:pr-8 {
        width: 100% !important;
        max-width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      /* !important on margin/padding values for container */
      .mobile-viewport #hero .max-w-7xl.mx-auto.px-4.sm\\:px-6.lg\\:px-8,
      .mobile-viewport #hero .relative.max-w-7xl.mx-auto.px-4.sm\\:px-6.lg\\:px-8 {
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
      }

      /* Fix button layouts */
      .mobile-viewport #hero .flex.flex-col.sm\\:flex-row.sm\\:space-x-4,
      .mobile-viewport #hero .flex.flex-wrap.gap-3 {
        width: 100% !important;
        gap: 0.5rem !important;
        margin-top: 1rem !important;
      }

      .mobile-viewport #hero a,
      .mobile-viewport #hero button {
        padding: 0.5rem 0.75rem !important;
        font-size: 0.75rem !important;
      }
      `;
    
      // For the home page of each demo, we'll show the actual component
      if (activeDemoTab === 'home' || (activeDemoSite.id === "ecommerce" && (activeDemoTab === 'category' || activeDemoTab === 'checkout'))) {
        if (activeDemoSite.id === "restaurant") {
          return (
            <div className={deviceWrapperClass}>
              <style dangerouslySetInnerHTML={{
                __html: `${commonStyles} ${restaurantDemoStyles} ${
                  activeDemoDevice === 'mobile'
                    ? mobileStyles
                    : activeDemoDevice === 'tablet'
                      ? tabletStyles
                      : ''
                }`
              }} />
              <RestaurantDemo />
            </div>
          );
        } else if (activeDemoSite.id === "professional") {
          return (
            <div className={deviceWrapperClass}>
              <style dangerouslySetInnerHTML={{
                __html: `${commonStyles} ${professionalDemoStyles} ${
                  activeDemoDevice === 'mobile'
                    ? mobileStyles
                    : activeDemoDevice === 'tablet'
                      ? tabletStyles
                      : ''
                }`
              }} />
              {activeDemoTab === 'home' ? (
                <ProfessionalDemo />
              ) : activeDemoTab === 'resources' ? (
                <ProfessionalResourcesPage />
              ) : activeDemoTab === 'service' ? (
                <ProfessionalServicesPage />
              ) : (
                <ProfessionalDemo />
              )}
            </div>
          );
        } else if (activeDemoSite.id === "ecommerce") {
          return (
            <div className={deviceWrapperClass}>
              <style dangerouslySetInnerHTML={{
                __html: `${commonStyles} ${ecommerceDemoStyles} ${
                  activeDemoDevice === 'mobile'
                    ? mobileStyles
                    : activeDemoDevice === 'tablet'
                      ? tabletStyles
                      : ''
                }`
              }} />
              <EcommerceDemo
                activeDemoTab={activeDemoTab}
                setActiveDemoTab={setActiveDemoTab}
              />
            </div>
          );
        } else if (activeDemoSite.id === "business") {
          return (
            <div className={deviceWrapperClass}>
              <style dangerouslySetInnerHTML={{
                __html: `${commonStyles} ${localBusinessDemoStyles} ${
                  activeDemoDevice === 'mobile'
                    ? mobileStyles
                    : activeDemoDevice === 'tablet'
                      ? tabletStyles
                      : ''
                }`
              }} />
              <LocalBusinessDemo />
            </div>
          );
        } else if (activeDemoSite.id === "portfolio") {
          return (
            <div className={deviceWrapperClass}>
              <style dangerouslySetInnerHTML={{
                __html: `${commonStyles} ${artPortfolioDemoStyles} ${
                  activeDemoDevice === 'mobile'
                    ? mobileStyles
                    : activeDemoDevice === 'tablet'
                      ? tabletStyles
                      : ''
                }`
              }} />
              <ArtPortfolioDemo />
            </div>
          );
        } else if (activeDemoSite.id === "saas") {
          return (
            <div className={deviceWrapperClass}>
              <style dangerouslySetInnerHTML={{
                __html: `${commonStyles} ${
                  activeDemoDevice === 'mobile'
                    ? mobileStyles
                    : activeDemoDevice === 'tablet'
                      ? tabletStyles
                      : ''
                }`
              }} />
              <SaasDemo 
                activeDemoTab={activeDemoTab}
                setActiveDemoTab={setActiveDemoTab}
              />
            </div>
          );
        } else {
          // For other demos that don't have specific components
          return (
            <div className="w-full h-full bg-white">
              <div className="p-4">
                <p className="text-center text-gray-800 font-medium mb-6">
                  {activeDemoSite?.title} Home Page
                </p>
                
                {/* Basic mock content */}
                <div className="max-w-4xl mx-auto">
                  <header className="py-4 border-b border-gray-200 mb-8">
                    <div className="flex justify-between items-center">
                      <div className="font-bold text-xl">LOGO</div>
                      <nav>
                        <ul className="flex space-x-6">
                          <li className="font-medium text-indigo-600">Home</li>
                          {activeDemoSite.type === 'multi-page' && (
                            <>
                              <li className="text-gray-600 cursor-pointer hover:text-indigo-500"
                                  onClick={() => setActiveDemoTab('about')}>About</li>
                              <li className="text-gray-600 cursor-pointer hover:text-indigo-500"
                                  onClick={() => setActiveDemoTab('services')}>Services</li>
                              <li className="text-gray-600 cursor-pointer hover:text-indigo-500"
                                  onClick={() => setActiveDemoTab('contact')}>Contact</li>
                            </>
                          )}
                        </ul>
                      </nav>
                    </div>
                  </header>
                  
                  <section className="mb-12">
                    <h1 className="text-4xl font-bold mb-4">Welcome to {activeDemoSite?.title}</h1>
                    <p className="text-gray-600">This is a demo of how your website could look and function.</p>
                  </section>
                  
                  <section className="grid grid-cols-2 gap-8 mb-12">
                    <div className="bg-gray-100 p-6 rounded-lg">
                      <h2 className="text-xl font-bold mb-2">Our Services</h2>
                      <p className="text-gray-600">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <div className="bg-gray-100 p-6 rounded-lg">
                      <h2 className="text-xl font-bold mb-2">About Us</h2>
                      <p className="text-gray-600">Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          );
        }
      } else if (activeDemoSite.id === "saas" && activeDemoTab === 'filters') {
        return (
          <div className={deviceWrapperClass}>
            <style dangerouslySetInnerHTML={{
              __html: `${commonStyles} ${
                activeDemoDevice === 'mobile'
                  ? mobileStyles
                  : activeDemoDevice === 'tablet'
                    ? tabletStyles
                    : ''
              }`
            }} />
            <QualificationSetupPage />
          </div>
        );
      } else if (activeDemoSite.id === "saas" && activeDemoTab === 'integrations') {
        return (
          <div className={deviceWrapperClass}>
            <style dangerouslySetInnerHTML={{
              __html: `${commonStyles} ${
                activeDemoDevice === 'mobile'
                  ? mobileStyles
                  : activeDemoDevice === 'tablet'
                    ? tabletStyles
                    : ''
              }`
            }} />
            <IntegrationsPage />
          </div>
        );
      }
    };
  
    return (
      <div className="fixed inset-0 z-50 overflow-hidden" aria-labelledby="demo-modal" role="dialog" aria-modal="true">
        <div className="flex items-stretch justify-center min-h-screen p-0 sm:p-0">
          {/* Background overlay */}
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"
              onClick={closeModal}></div>
            
          {/* Modal panel - make this take up the full screen */}
          <div className="inline-block align-bottom bg-white w-full h-full shadow-xl transform transition-all sm:align-middle sm:max-w-full sm:w-full sm:h-full">
            <div className="bg-white h-[calc(100vh-70px)] px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-col">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium text-gray-900">{activeDemoSite?.title} Preview</h3>
                <div className="flex items-center space-x-2">
                  <button
                    className="text-gray-500 hover:text-gray-700 p-1"
                    onClick={toggleFullScreen}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5v-4m0 4h-4m4 0l-5-5" />
                    </svg>
                  </button>
                  <button onClick={closeModal} className="text-gray-400 hover:text-gray-500">
                    <X className="h-6 w-6" />
                  </button>
                </div>
              </div>
                                
              {/* Device selector */}
              <div className="flex space-x-2 mb-4">
                <button
                  onClick={() => setActiveDemoDevice('desktop')}
                  className={`px-3 py-1 text-sm rounded-md ${activeDemoDevice === 'desktop' ? 'bg-indigo-100 text-indigo-700' : 'bg-gray-100'}`}
                >
                  <Laptop className="h-4 w-4 inline mr-1" />
                  Desktop
                </button>
                <button
                  onClick={() => setActiveDemoDevice('tablet')}
                  className={`px-3 py-1 text-sm rounded-md ${activeDemoDevice === 'tablet' ? 'bg-indigo-100 text-indigo-700' : 'bg-gray-100'}`}
                >
                  <Tablet className="h-4 w-4 inline mr-1" />
                  Tablet
                </button>
                <button
                  onClick={() => setActiveDemoDevice('mobile')}
                  className={`px-3 py-1 text-sm rounded-md ${activeDemoDevice === 'mobile' ? 'bg-indigo-100 text-indigo-700' : 'bg-gray-100'}`}
                >
                  <Smartphone className="h-4 w-4 inline mr-1" />
                  Mobile
                </button>
              </div>
                                
              {/* Page navigation tabs for multi-page sites */}
              {activeDemoSite.type === 'multi-page' && (
                <div className="border-b border-gray-200 mb-4">
                  <nav className="-mb-px flex space-x-8">
                    {activeDemoSite.id === "ecommerce" ? (
                      // E-commerce site tabs
                      <>
                        <button
                          onClick={() => setActiveDemoTab('home')}
                          className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                            ${activeDemoTab === 'home'
                              ? 'border-indigo-500 text-indigo-600'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            }`}
                        >
                          Home
                        </button>
                        <button
                          onClick={() => setActiveDemoTab('category')}
                          className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                            ${activeDemoTab === 'category'
                              ? 'border-indigo-500 text-indigo-600'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            }`}
                        >
                          Collections
                        </button>
                        <button
                          onClick={() => setActiveDemoTab('checkout')}
                          className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                            ${activeDemoTab === 'checkout'
                              ? 'border-indigo-500 text-indigo-600'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            }`}
                        >
                          Checkout
                        </button>
                      </>
                    ) : activeDemoSite.id === "professional" ? (
                      // Professional services site tabs
                      <>
                        <button
                          onClick={() => setActiveDemoTab('home')}
                          className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                            ${activeDemoTab === 'home'
                              ? 'border-indigo-500 text-indigo-600'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            }`}
                        >
                          Home
                        </button>
                        <button
                          onClick={() => setActiveDemoTab('service')}
                          className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                            ${activeDemoTab === 'service'
                              ? 'border-indigo-500 text-indigo-600'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            }`}
                        >
                          Services
                        </button>
                        <button
                          onClick={() => setActiveDemoTab('resources')}
                          className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                            ${activeDemoTab === 'resources'
                              ? 'border-indigo-500 text-indigo-600'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            }`}
                        >
                          Resources
                        </button>
                      </>
                    ) : (
                      // Default tabs for other multi-page sites with Filters and Integrations replacing About, Services, Contact
                      ['home', 'filters', 'integrations'].map((tab) => (
                        <button
                          key={tab}
                          onClick={() => {
                            console.log("Setting active tab to:", tab);
                            setActiveDemoTab(tab);
                          }}
                          className={`whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm
                            ${activeDemoTab === tab
                              ? 'border-indigo-500 text-indigo-600'
                              : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                            }`}
                        >
                          {tab.charAt(0).toUpperCase() + tab.slice(1)}
                        </button>
                      ))
                    )}
                  </nav>
                </div>
              )}
                                
              {/* Demo content wrapper with device frame - using flex-grow to fill available space */}
              <div
                className={`border border-gray-200 rounded-lg overflow-hidden bg-gray-100 flex-grow
                  ${activeDemoDevice === 'desktop' ? 'w-full' :
                  activeDemoDevice === 'tablet' ? 'w-[768px] mx-auto tablet-frame' :
                  'w-[375px] mx-auto mobile-frame'}`}
              >
                {/* Add device frame elements */}
                {activeDemoDevice === 'mobile' && (
                  <div className="device-notch"></div>
                )}
                
                {/* Key forces re-render when device changes */}
                <div key={`device-${activeDemoDevice}-${activeDemoSite?.id}-${activeDemoTab}`}
                    className="w-full h-full overflow-auto">
                  {renderDemoContent()}
                </div>
              </div>
                                
              {/* Device frame styling */}
              <style dangerouslySetInnerHTML={{ __html: `
                /* Device frames with fixed positioning context */
                .mobile-frame, .tablet-frame {
                  transform-style: preserve-3d;
                  perspective: 1px;
                  position: relative;
                  contain: paint;
                }
                
                .mobile-frame {
                  border-radius: 36px;
                  border: 14px solid #121212;
                  position: relative;
                }
                
                .tablet-frame {
                  border-radius: 20px;
                  border: 12px solid #121212;
                  position: relative;
                }
                
                .device-notch {
                  position: absolute;
                  top: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 150px;
                  height: 24px;
                  background: #121212;
                  border-bottom-left-radius: 14px;
                  border-bottom-right-radius: 14px;
                  z-index: 10;
                }
                
                /* Demo viewport container */
                .demo-viewport {
                  position: relative !important;
                  overflow: auto;
                  height: 100% !important;
                  max-height: 100% !important;
                  transform: translateZ(0);
                  will-change: transform;
                }
              `}} />
            </div>
                          
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => navigate('/contact')}
              >
                Get a Similar Website
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-white text-gray-900">
      {/* Demo Modal */}
      <DemoModal />
      
      {/* Progress bar */}
      <div 
        className="fixed top-0 left-0 h-1 bg-indigo-500 z-50 transition-all duration-200 ease-out"
        style={{ width: `${scrollProgress}%` }}
      />
      {/* Navbar */}
      <nav className="sticky top-0 z-40 bg-white/90 backdrop-blur-md border-b border-gray-100 transition-all duration-300">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <img src="/logo512_indigo2.png" alt="Henosis Logo" className="h-8 w-8 mr-2" />
              <span className="text-xl font-bold">henosis</span>
              <span className="text-indigo-600 ml-2 font-medium">web studio</span>
            </div>
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              <a 
                href="#services"
                className={`font-medium transition-colors duration-200 ${activeSection === 'services' ? 'text-indigo-600' : 'text-gray-500 hover:text-gray-900'}`}
              >
                Services
              </a>
              <a 
                href="#process"
                className={`font-medium transition-colors duration-200 ${activeSection === 'process' ? 'text-indigo-600' : 'text-gray-500 hover:text-gray-900'}`}
              >
                Process
              </a>
              <a 
                href="#portfolio"
                className={`font-medium transition-colors duration-200 ${activeSection === 'portfolio' ? 'text-indigo-600' : 'text-gray-500 hover:text-gray-900'}`}
              >
                Our Work
              </a>
              <a 
                href="#pricing"
                className={`font-medium transition-colors duration-200 ${activeSection === 'pricing' ? 'text-indigo-600' : 'text-gray-500 hover:text-gray-900'}`}
              >
                Pricing
              </a>
              <button
                onClick={() => navigate('/contact')}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
              >
                Get Your Website
                <ArrowUpRight className="ml-1 h-4 w-4" />
              </button>
            </div>
            
            {/* Mobile menu button */}
            <div className="md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                <span className="sr-only">Open main menu</span>
                {isMenuOpen ? (
                  <X className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <Menu className="block h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>
          </div>
        </div>
        {/* Mobile Navigation */}
        <div className={`md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
          <div className="pt-2 pb-4 space-y-1 bg-white border-t border-gray-100">
            <a
              href="#services"
              onClick={() => setIsMenuOpen(false)}
              className="block pl-3 pr-4 py-2 border-l-4 border-indigo-500 text-base font-medium text-indigo-700 bg-indigo-50"
            >
              Services
            </a>
            <a
              href="#process"
              onClick={() => setIsMenuOpen(false)}
              className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800"
            >
              Process
            </a>
            <a
              href="#portfolio"
              onClick={() => setIsMenuOpen(false)}
              className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800"
            >
              Our Work
            </a>
            <a
              href="#pricing"
              onClick={() => setIsMenuOpen(false)}
              className="block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800"
            >
              Pricing
            </a>
            <div className="mt-4 px-3">
              <button
                onClick={() => navigate('/contact')}
                className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Get Your Website
                <ArrowUpRight className="ml-1 h-4 w-4" />
              </button>
            </div>
          </div>
        </div>
      </nav>
      {/* Hero Section */}
      <header id="hero" className="relative pt-16 pb-32 overflow-hidden bg-white">
        <div className="absolute inset-0 bg-[radial-gradient(#e0e7ff_1px,transparent_1px)] [background-size:20px_20px] opacity-50"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid lg:grid-cols-2 gap-12 lg:gap-20 items-center">
            {/* Left Column - Main Content */}
            <div className="text-left max-w-xl z-10">
              <div className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-indigo-100 text-indigo-800 mb-6">
                <Zap className="h-4 w-4 mr-1" />
                Websites delivered in 7 days
              </div>
              <h1 className="text-5xl md:text-6xl font-bold tracking-tight mb-6">
                Websites that <span className="text-indigo-600">grow</span> your business
              </h1>
              
              <p className="text-xl text-gray-600 mb-8">
                Stop losing customers. Get a professional, high-converting 
                website built and launched in as little as 7 days.
              </p>
              {/* Feature Quick Links */}
              <div className="space-y-4 mb-8">
                <div className="flex items-start space-x-4 group">
                  <div className="h-10 w-10 rounded-full bg-indigo-100 flex items-center justify-center flex-shrink-0 mt-1 transition-colors duration-200 group-hover:bg-indigo-200">
                    <Clock className="h-5 w-5 text-indigo-600" />
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900 group-hover:text-indigo-600 transition-colors duration-200">Lightning-Fast Delivery</h3>
                    <p className="text-gray-500">3-page websites in 7 days, custom e-commerce in 21 days</p>
                  </div>
                </div>
                <div className="flex items-start space-x-4 group">
                  <div className="h-10 w-10 rounded-full bg-indigo-100 flex items-center justify-center flex-shrink-0 mt-1 transition-colors duration-200 group-hover:bg-indigo-200">
                    <BarChart2 className="h-5 w-5 text-indigo-600" />
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900 group-hover:text-indigo-600 transition-colors duration-200">Business-Focused Results</h3>
                    <p className="text-gray-500">Designed to generate leads and increase sales</p>
                  </div>
                </div>
                <div className="flex items-start space-x-4 group">
                  <div className="h-10 w-10 rounded-full bg-indigo-100 flex items-center justify-center flex-shrink-0 mt-1 transition-colors duration-200 group-hover:bg-indigo-200">
                    <Smartphone className="h-5 w-5 text-indigo-600" />
                  </div>
                  <div>
                    <h3 className="font-medium text-gray-900 group-hover:text-indigo-600 transition-colors duration-200">Mobile-First Websites</h3>
                    <p className="text-gray-500">Perfect on all devices - phones, tablets, and desktops</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-4">
                <button
                  onClick={() => navigate('/contact')}
                  className="inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-lg shadow-lg text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
                >
                  Get Your Website in 7 Days
                  <ArrowRight className="ml-2 h-5 w-5" />
                </button>
                <a
                  href="#portfolio"
                  className="inline-flex items-center justify-center px-6 py-3 border border-gray-300 rounded-lg text-base font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
                >
                  See Our Work
                </a>
              </div>
            </div>
            {/* Right Column - Form */}
            <div className="relative z-10">
              <div className="bg-white rounded-2xl shadow-xl overflow-hidden transform transition-all duration-500 hover:shadow-2xl border border-gray-100">
                <div className="p-8">
                  <h3 className="text-2xl font-bold mb-2 text-gray-900">Tell Us About Your Project</h3>
                  <p className="text-gray-600 mb-6">
                    Get a custom quote and timeline for your website. We'll respond within 24 hours.
                  </p>
                  
                  {/* Quick Contact Form */}
                  <form onSubmit={handleEmailSignup} className="space-y-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-700">Your name</label>
                      <input
                        type="text"
                        id="name"
                        placeholder="Jane Smith"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        required
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">Your email</label>
                      <input
                        type="email"
                        id="email"
                        placeholder="jane@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        required
                      />
                    </div>
                    
                    <div>
                      <label htmlFor="website-type" className="block text-sm font-medium text-gray-700">
                        Website type
                      </label>
                      <select
                        id="website-type"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        required
                      >
                        <option value="">What type of website do you need?</option>
                        <option value="small">Small Business Website (3-5 pages)</option>
                        <option value="larger">Larger Business Website (6+ pages)</option>
                        <option value="ecommerce">Online Store / E-Commerce</option>
                        <option value="redesign">Website Redesign</option>
                        <option value="other">Something Else</option>
                      </select>
                    </div>
                    
                    <button
                      type="submit"
                      className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
                    >
                      Get Your Free Quote
                    </button>
                  </form>
                </div>
                {/* Trust Signals */}
                <div className="bg-gray-50 px-8 py-4 border-t border-gray-100">
                  <div className="grid grid-cols-2 gap-3">
                    <div className="flex items-center text-sm text-gray-600">
                      <CheckCircle className="w-4 h-4 text-green-500 mr-2 flex-shrink-0" />
                      <span>7-day delivery guarantee</span>
                    </div>
                    <div className="flex items-center text-sm text-gray-600">
                      <CheckCircle className="w-4 h-4 text-green-500 mr-2 flex-shrink-0" />
                      <span>No obligation quote</span>
                    </div>
                    <div className="flex items-center text-sm text-gray-600">
                      <CheckCircle className="w-4 h-4 text-green-500 mr-2 flex-shrink-0" />
                      <span>Fixed pricing - no surprises</span>
                    </div>
                    <div className="flex items-center text-sm text-gray-600">
                      <CheckCircle className="w-4 h-4 text-green-500 mr-2 flex-shrink-0" />
                      <span>Dedicated project manager</span>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Decorative elements */}
              <div className="absolute top-10 -right-20 w-40 h-40 bg-indigo-400 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob"></div>
              <div className="absolute -bottom-8 -left-10 w-32 h-32 bg-purple-400 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob animation-delay-2000"></div>
            </div>
          </div>
        </div>
      </header>
      
      {/* Services Section */}
      <section id="services" className="py-24 bg-white relative overflow-hidden">
        <div className="absolute top-0 inset-x-0 h-40 bg-gradient-to-b from-gray-50 to-white"></div>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Websites That Work For Your Business
            </h2>
            <p className="mt-4 text-xl text-gray-500">
              No matter what type of business you run, we build websites that help you 
              attract more customers and grow your revenue.
            </p>
          </div>
          
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-10">
            {services.map((service, index) => (
              <div 
                key={index}
                className="relative bg-white rounded-xl overflow-hidden hover:shadow-lg transition-all duration-300 group"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-indigo-50 to-white opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                <div className="p-6 relative z-10">
                  <div className="h-12 w-12 rounded-lg bg-indigo-100 flex items-center justify-center mb-5 transform group-hover:scale-110 transition-transform duration-300">
                    {service.icon}
                  </div>
                  <h3 className="text-lg font-semibold mb-3 text-gray-900">{service.title}</h3>
                  <p className="text-gray-600">{service.description}</p>
                  <div className="mt-6 text-indigo-600 font-medium flex items-center text-sm opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                    Learn more
                    <ArrowRight className="ml-1 h-3 w-3" />
                  </div>
                </div>
                <div className="absolute bottom-0 left-0 right-0 h-1 bg-indigo-500 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></div>
              </div>
            ))}
          </div>
          
          <div className="mt-16 text-center">
            <button
              onClick={() => navigate('/services')}
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-all duration-200"
            >
              See All Services
              <ArrowRight className="ml-2 h-4 w-4" />
            </button>
          </div>
        </div>
      </section>
      {/* What Sets Us Apart Section (Replaces Results Showcase) */}
      <section className="py-24 bg-gray-50 relative overflow-hidden">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#f0f9ff_1px,transparent_1px),linear-gradient(to_bottom,#f0f9ff_1px,transparent_1px)] bg-[size:4rem_4rem] opacity-40"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              What Sets Henosis Web Studio Apart
            </h2>
            <p className="mt-4 text-xl text-gray-500">
              We're not your typical web design agency. Here's how we deliver exceptional 
              websites with speed and precision.
            </p>
          </div>
          <div className="grid lg:grid-cols-2 gap-12">
            {/* Differentiators */}
            <div className="bg-white rounded-xl overflow-hidden shadow-lg p-8">
              <h3 className="text-xl font-bold mb-8 text-gray-900">How We Deliver Better Results</h3>
              
              <div className="space-y-8">
                {differentiators.map((item, index) => (
                  <div key={index} className="flex items-start">
                    <div className="h-12 w-12 rounded-lg bg-indigo-100 flex items-center justify-center mr-5 flex-shrink-0">
                      {item.icon}
                    </div>
                    <div>
                      <h4 className="font-bold text-lg mb-2">{item.title}</h4>
                      <p className="text-gray-600">
                        {item.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            
            {/* Approach Showcase */}
            <div className="bg-white rounded-xl overflow-hidden shadow-lg">
              <div className="border-b border-gray-100">
                <div className="h-48 bg-gradient-to-r from-indigo-500 to-purple-600 flex items-center justify-center">
                  <h3 className="text-2xl font-bold text-white px-8 text-center">Our Approach to Building Effective Websites</h3>
                </div>
              </div>
              <div className="p-8">
                <div className="grid md:grid-cols-2 gap-6 mb-8">
                  {approachElements.map((element, index) => (
                    <div key={index} className="bg-gray-50 p-5 rounded-lg">
                      <div className="flex items-center mb-3">
                        <div className="h-8 w-8 rounded-lg bg-indigo-100 flex items-center justify-center mr-3">
                          {element.icon}
                        </div>
                        <h4 className="font-semibold text-gray-900">{element.title}</h4>
                      </div>
                      <p className="text-sm text-gray-600">{element.description}</p>
                    </div>
                  ))}
                </div>
                
                <div className="bg-indigo-50 p-4 rounded-lg italic text-indigo-900 border-l-4 border-indigo-500">
                  "We believe websites should be more than just digital brochures—they should be
                   powerful marketing tools that actively generate business results."
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Process Section */}
      <section id="process" className="py-24 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Simple 4-Step Process
            </h2>
            <p className="mt-4 text-xl text-gray-500">
              We've streamlined website creation to get you up and running fast, 
              with minimal time required from you.
            </p>
          </div>
          
          <div className="relative">
            {/* Process Timeline Line */}
            <div className="absolute top-0 bottom-0 left-1/2 w-0.5 bg-gray-200 transform -translate-x-1/2 hidden md:block"></div>
            
            <div className="space-y-16 relative">
              {processSteps.map((step, index) => (
                <div key={index} className="relative z-10">
                  <div className={`flex flex-col md:flex-row items-center gap-8 ${index % 2 === 1 ? 'md:flex-row-reverse' : ''}`}>
                    {/* Step marker on timeline */}
                    <div className="md:absolute md:left-1/2 md:transform md:-translate-x-1/2 h-16 w-16 rounded-full bg-white border-2 border-indigo-500 shadow-lg flex items-center justify-center z-20">
                      <span className="text-xl font-bold text-indigo-600">{index + 1}</span>
                    </div>
                    
                    <div className={`md:w-1/2 ${index % 2 === 0 ? 'md:pr-16 md:text-right' : 'md:pl-16'}`}>
                      <div className="bg-white rounded-xl shadow-lg p-8 transition-transform duration-500 hover:shadow-xl hover:-translate-y-1">
                        <div className={`mb-6 flex ${index % 2 === 0 ? 'md:justify-end' : ''}`}>
                          <div className="h-14 w-14 rounded-lg bg-indigo-100 flex items-center justify-center">
                            {step.icon}
                          </div>
                        </div>
                        <h3 className="text-xl font-bold mb-3 text-gray-900">{step.title}</h3>
                        <p className="text-gray-600">{step.description}</p>
                      </div>
                    </div>
                    
                    <div className="md:w-1/2"></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          <div className="mt-20 text-center">
            <div className="inline-flex items-center px-6 py-3 border border-transparent rounded-full text-base font-medium bg-indigo-100 text-indigo-800">
              <Clock className="h-5 w-5 mr-2" />
              Most sites delivered in just 7 days
            </div>
          </div>
        </div>
      </section>
      {/* Sample Work Section with Functioning Category Filter */}
      <section id="portfolio" className="py-24 bg-gray-50 relative overflow-hidden">
        <div className="absolute inset-0 bg-[radial-gradient(#e0e7ff_1px,transparent_1px)] [background-size:20px_20px] opacity-30"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Design Capabilities
            </h2>
            <p className="mt-4 text-xl text-gray-500">
              While we're building our client portfolio, we've created several demonstration websites to showcase our design capabilities and technical expertise.
            </p>
          </div>
          
          {/* Category tabs - now with state and onClick handlers */}
          <div className="flex justify-center mb-10">
            <div className="inline-flex space-x-2 rounded-lg bg-gray-100 p-1">
              <button 
                className={`px-4 py-2 text-sm font-medium rounded-md transition ${
                  activeCategory === 'all' ? 'bg-white shadow-sm text-indigo-600' : 'text-gray-700 hover:bg-white hover:text-indigo-600'
                }`}
                onClick={() => setActiveCategory('all')}
              >
                All Designs
              </button>
              <button 
                className={`px-4 py-2 text-sm font-medium rounded-md transition ${
                  activeCategory === 'multi-page' ? 'bg-white shadow-sm text-indigo-600' : 'text-gray-700 hover:bg-white hover:text-indigo-600'
                }`}
                onClick={() => setActiveCategory('multi-page')}
              >
                Multi-page
              </button>
              <button 
                className={`px-4 py-2 text-sm font-medium rounded-md transition ${
                  activeCategory === 'single-page' ? 'bg-white shadow-sm text-indigo-600' : 'text-gray-700 hover:bg-white hover:text-indigo-600'
                }`}
                onClick={() => setActiveCategory('single-page')}
              >
                Single-page
              </button>
            </div>
          </div>
          
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {sampleWork.filter(item => {
              if (activeCategory === 'all') return true;
              return item.type === activeCategory;
            }).map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-xl overflow-hidden shadow-lg group transition-all duration-300 hover:shadow-xl flex flex-col h-full"
              >
                <div className="h-52 bg-gray-200 overflow-hidden relative">
                  <div className="w-full h-full flex items-center justify-center">
                    {item.imageSrc ? (
                      <img
                        src={item.imageSrc}
                        alt={`${item.title} Preview`}
                        className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
                      />
                    ) : (
                      <div className={`w-full h-full flex items-center justify-center ${item.gradientBg || 'bg-gradient-to-br from-indigo-400 to-purple-600'}`}>
                        <div className="text-white text-lg font-medium">{item.placeholderText || 'Preview'}</div>
                      </div>
                    )}
                  </div>
                  <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                  <div className="absolute bottom-0 left-0 right-0 p-4 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300">
                    <button
                      onClick={() => {
                        setActiveDemoSite(item);
                        setActiveDemoTab('home');
                        setActiveDemoDevice('desktop');
                        setIsDemoModalOpen(true);
                      }}
                      className="bg-white text-gray-900 px-4 py-2 rounded-lg text-sm font-medium hover:bg-gray-100"
                    >
                      View Demo
                    </button>
                  </div>
                </div>
                <div className="p-6 flex-grow flex flex-col">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-xl font-bold text-gray-900">{item.title}</h3>
                    <span className={`px-2 py-1 ${
                      item.type === 'single-page' 
                        ? 'bg-blue-100 text-blue-800' 
                        : 'bg-purple-100 text-purple-800'
                    } rounded-full text-xs font-medium`}>
                      {item.type === 'single-page' ? 'Single Page' : 'Multi-page'}
                    </span>
                  </div>
                  <p className="text-gray-600 mb-4">{item.description}</p>
                  <div className="space-y-2 mb-4 flex-grow">
                    {item.features.slice(0, 2).map((feature, featureIndex) => (
                      <div key={featureIndex} className="flex items-start">
                        <CheckCircle className="w-5 h-5 text-indigo-500 mr-2 flex-shrink-0 mt-0.5" />
                        <span className="text-gray-700 text-sm">{feature}</span>
                      </div>
                    ))}
                  </div>
                  <div className="mt-auto pt-2">
                    <button
                      onClick={() => {
                        setActiveDemoSite(item);
                        setActiveDemoTab('home');
                        setActiveDemoDevice('desktop');
                        setIsDemoModalOpen(true);
                      }}
                      className="inline-flex items-center text-sm font-medium text-indigo-600 hover:text-indigo-800"
                    >
                      Explore design
                      <ArrowRight className="ml-1 h-4 w-4" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          <div className="mt-16 bg-white rounded-xl shadow-lg p-8 text-center">
            <h3 className="text-xl font-bold mb-4 text-gray-900">Let's Discuss Your Project</h3>
            <p className="text-gray-600 mb-6 max-w-2xl mx-auto">
              We'd be happy to walk you through these example sites and discuss how we can customize a similar approach for your business needs.
            </p>
            <button
              onClick={() => navigate('/contact')}
              className="inline-flex items-center px-6 py-3 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Schedule a Demo Walkthrough
              <ArrowRight className="ml-2 h-5 w-5" />
            </button>
          </div>
        </div>
      </section>
      {/* Getting Started Section (New Section) */}
      <section className="py-24 bg-white relative overflow-hidden">
        <div className="absolute right-0 top-0 w-1/2 h-1/2 bg-indigo-50 opacity-50"></div>
        <div className="absolute left-0 bottom-0 w-1/3 h-1/3 bg-indigo-50 opacity-50"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              How to Get Started
            </h2>
            <p className="mt-4 text-xl text-gray-500">
              We've made starting your website project as simple as possible with our 3-step process.
            </p>
          </div>
          
          <div className="grid md:grid-cols-3 gap-8">
            {gettingStartedSteps.map((step, index) => (
              <div 
                key={index}
                className="bg-white rounded-xl shadow-lg p-8 relative overflow-hidden hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
              >
                <div className="absolute -right-4 -top-4 w-24 h-24 text-gray-100 font-extrabold text-6xl -rotate-12">
                  {step.number}
                </div>
                <div className="relative">
                  <h3 className="text-xl font-bold mb-4 text-gray-900">{step.title}</h3>
                  <p className="text-gray-600 mb-6">{step.description}</p>
                  {index === 0 && (
                    <button
                      onClick={() => navigate('/contact')}
                      className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Book Free Consultation
                      <ArrowRight className="ml-2 h-4 w-4" />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* Pricing Section */}
      <section id="pricing" className="py-24 bg-gray-50 relative overflow-hidden">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#f0f9ff_1px,transparent_1px),linear-gradient(to_bottom,#f0f9ff_1px,transparent_1px)] bg-[size:4rem_4rem] opacity-40"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Simple, Transparent Pricing
            </h2>
            <p className="mt-4 text-xl text-gray-500">
              All-inclusive packages with fixed pricing—no surprises or hidden fees.
            </p>
          </div>
          
          <div className="grid md:grid-cols-3 gap-8">
            {pricingPackages.map((pkg, index) => (
              <div
                key={index}
                className={`bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl transform hover:-translate-y-1 relative ${
                  pkg.popular ? 'ring-2 ring-indigo-500 md:scale-105' : ''
                }`}
              >
                {pkg.popular && (
                  <div className="absolute top-0 right-0 bg-indigo-500 text-white text-xs font-bold px-3 py-1 uppercase tracking-wide">
                    Most Popular
                  </div>
                )}
                <div className="p-8">
                  <h3 className="text-xl font-bold mb-2 text-gray-900">{pkg.name}</h3>
                  <div className="flex items-baseline mb-2">
                    <div className="text-4xl font-extrabold text-gray-900">{pkg.price}</div>
                    <div className="ml-1 text-gray-500 text-sm">one-time</div>
                  </div>
                  <div className="text-indigo-600 font-medium mb-4">{pkg.timeline}</div>
                  <p className="text-gray-600 mb-6 min-h-[60px]">{pkg.description}</p>
                  
                  <ul className="space-y-3 mb-8">
                    {pkg.features.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex items-start">
                        <CheckCircle className="w-5 h-5 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                        <span className="text-gray-600">{feature}</span>
                      </li>
                    ))}
                  </ul>
                  
                  <button
                    onClick={() => navigate('/contact')}
                    className={`w-full py-3 px-4 rounded-lg transition-all duration-200 text-center font-medium ${
                      pkg.popular
                        ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                        : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                    }`}
                  >
                    Get Started
                  </button>
                </div>
              </div>
            ))}
          </div>
          
          <div className="mt-12 bg-white rounded-xl shadow-lg p-8">
            <h3 className="text-xl font-bold mb-2 text-gray-900 text-center">Website Maintenance & Support</h3>
            <p className="text-gray-600 mb-6 text-center max-w-2xl mx-auto">
              Keep your website secure, up-to-date, and performing optimally with our monthly maintenance plan.
            </p>
            
            <div className="max-w-3xl mx-auto bg-indigo-50 rounded-lg p-6">
              <div className="flex flex-col md:flex-row md:items-center justify-between">
                <div>
                  <div className="flex items-baseline mb-2">
                    <div className="text-2xl font-bold text-gray-900">$150</div>
                    <div className="ml-1 text-gray-600 text-sm">per month</div>
                  </div>
                  <h4 className="font-medium text-gray-900 mb-2">Monthly Maintenance Plan Includes:</h4>
                  <ul className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                    <li className="flex items-start">
                      <CheckCircle className="w-4 h-4 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-600 text-sm">Up to 1 hour of website updates</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="w-4 h-4 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-600 text-sm">Security monitoring & updates</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="w-4 h-4 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-600 text-sm">Website hosting management</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="w-4 h-4 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-600 text-sm">Regular backups</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="w-4 h-4 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-600 text-sm">Performance optimization</span>
                    </li>
                    <li className="flex items-start">
                      <CheckCircle className="w-4 h-4 text-green-500 mr-2 flex-shrink-0 mt-0.5" />
                      <span className="text-gray-600 text-sm">Email/phone technical support</span>
                    </li>
                  </ul>
                </div>
                <div className="mt-6 md:mt-0">
                  <button
                    onClick={() => navigate('/contact')}
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Learn More
                    <ArrowRight className="ml-2 h-4 w-4" />
                  </button>
                </div>
              </div>
            </div>
            
            <div className="mt-6 text-center text-gray-500 text-sm">
              Custom maintenance packages available for businesses with specific needs.
            </div>
          </div>
          
          <div className="mt-16 bg-white rounded-xl shadow-lg p-8 text-center">
            <h3 className="text-xl font-bold mb-4 text-gray-900">Need something custom?</h3>
            <p className="text-gray-600 mb-6 max-w-2xl mx-auto">
              We also build custom solutions for unique business needs. Contact us for a personalized quote.
            </p>
            <button
              onClick={() => navigate('/contact')}
              className="inline-flex items-center px-6 py-3 border border-transparent rounded-lg shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Request Custom Quote
              <ArrowRight className="ml-2 h-5 w-5" />
            </button>
          </div>
        </div>
      </section>
      {/* Our Guarantee Section (New section) */}
        <section className="py-16 bg-white border-t border-b border-gray-100">
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
        <div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 mb-6">
            The Henosis Promise
            </h2>
            <p className="text-lg text-gray-600 mb-8">
            We stand behind our work with guarantees that ensure your complete satisfaction.
            Our promises are commitments we take seriously to deliver exceptional results for your business.
            </p>
            <div className="space-y-6">
            {guaranteeElements.map((element, index) => (
                <div key={index} className="flex items-start">
                <div className="flex-shrink-0 h-6 w-6 rounded-full bg-green-100 flex items-center justify-center mr-3 mt-0.5">
                    <CheckCircle className="h-4 w-4 text-green-600" />
                </div>
                <div>
                    <h3 className="font-medium text-gray-900">{element.title}</h3>
                    <p className="text-gray-500">{element.description}</p>
                </div>
                </div>
            ))}
            </div>
            <div className="mt-8">
            <button
                onClick={() => navigate('/contact')}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Get Started Today
            </button>
            </div>
        </div>
        <div className="relative">
            <div className="absolute inset-0 flex items-center justify-center">
            <div className="h-64 w-64 bg-indigo-100 rounded-full opacity-70 filter blur-2xl"></div>
            </div>
            <div className="relative bg-white rounded-2xl shadow-xl overflow-hidden border border-gray-100 transform rotate-3">
            <div className="p-8">
                <div className="flex justify-between items-center mb-8">
                <div className="flex items-center">
                    <div className="h-10 w-10 bg-indigo-600 rounded-full flex items-center justify-center mr-3">
                    <span className="text-white font-bold">7</span>
                    </div>
                    <div className="font-bold text-gray-900">Day Delivery</div>
                </div>
                <div className="text-gray-500 text-sm">Guaranteed</div>
                </div>
                <div className="rounded-lg bg-gray-50 p-6 mb-6">
                <div className="text-center">
                    <div className="text-5xl font-bold text-indigo-600 mb-2">100%</div>
                    <div className="text-gray-600">Satisfaction Guarantee</div>
                </div>
                </div>
                <div className="text-sm text-gray-500 text-center">
                No risk, no worries. We're committed to your complete satisfaction.
                </div>
            </div>
            </div>
            <div className="absolute top-1/2 right-10 transform -translate-y-1/2 rotate-12">
            <div className="bg-white rounded-lg shadow-lg p-4 w-32">
                <div className="text-center">
                <div className="text-green-500 font-bold text-xl mb-1">24/7</div>
                <div className="text-xs text-gray-500">Site Monitoring</div>
                </div>
            </div>
            </div>
        </div>
        </div>
    </div>
    </section>
    {/* FAQs Section - Updated Layout with Proper Single Expansion */}
    <section className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Frequently Asked Questions
          </h2>
          <p className="mt-4 text-xl text-gray-500">
            Everything you need to know about our website services.
          </p>
        </div>
        
        <div className="max-w-4xl mx-auto">
          {/* Adding a new state to track a single expanded FAQ */}
          {/* This would go in your component's state declarations: */}
          {/* const [expandedFaq, setExpandedFaq] = useState(null); */}
          
          {/* Two-column layout for FAQs */}
          <div className="grid md:grid-cols-2 gap-6">
            {/* Left Column */}
            <div className="space-y-4">
              {/* First 4 FAQs */}
              {[...faqs.slice(0, 4)].map((faq) => (
                <div
                  key={faq.id}
                  className={`bg-white rounded-lg border transition-all duration-200 overflow-hidden
                      ${expandedFaqs.has(faq.id) ? 'border-indigo-200 shadow-md' : 'border-gray-200'}`}
                >
                  <button
                    onClick={() => toggleFaq(faq.id)}
                    className="w-full p-4 text-left flex justify-between items-start"
                  >
                    <h3 className="text-base font-medium text-gray-900">{faq.question}</h3>
                    <div className={`ml-2 flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-gray-100 transition-transform duration-200 ${
                      expandedFaqs.has(faq.id) ? 'rotate-180 bg-indigo-100' : ''
                    }`}>
                      <ChevronRight
                        className={`h-4 w-4 text-gray-500 transform rotate-90 ${
                          expandedFaqs.has(faq.id) ? 'text-indigo-600' : ''
                        }`}
                      />
                    </div>
                  </button>
                  
                  <div
                    className={`overflow-hidden transition-all duration-200 ${
                      expandedFaqs.has(faq.id) ? 'max-h-96' : 'max-h-0'
                    }`}
                  >
                    <div className="p-4 pt-0 text-sm text-gray-600 border-t border-gray-100">
                      {faq.answer}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            
            {/* Right Column */}
            <div className="space-y-4">
              {/* Last 3 FAQs + new FAQ */}
              {[...faqs.slice(4), {
                id: 8,
                question: "Do you offer ongoing website maintenance?",
                answer: "Yes, we offer a comprehensive maintenance package for $150/month that includes regular updates, security monitoring, performance optimization, and technical support. This ensures your website stays secure, up-to-date, and performing at its best. We can also create custom maintenance plans tailored to your specific business needs."
              }].map((faq) => (
                <div
                  key={faq.id}
                  className={`bg-white rounded-lg border transition-all duration-200 overflow-hidden
                      ${expandedFaqs.has(faq.id) ? 'border-indigo-200 shadow-md' : 'border-gray-200'}`}
                >
                  <button
                    onClick={() => toggleFaq(faq.id)}
                    className="w-full p-4 text-left flex justify-between items-start"
                  >
                    <h3 className="text-base font-medium text-gray-900">{faq.question}</h3>
                    <div className={`ml-2 flex-shrink-0 flex items-center justify-center h-6 w-6 rounded-full bg-gray-100 transition-transform duration-200 ${
                      expandedFaqs.has(faq.id) ? 'rotate-180 bg-indigo-100' : ''
                    }`}>
                      <ChevronRight
                        className={`h-4 w-4 text-gray-500 transform rotate-90 ${
                          expandedFaqs.has(faq.id) ? 'text-indigo-600' : ''
                        }`}
                      />
                    </div>
                  </button>
                  
                  <div
                    className={`overflow-hidden transition-all duration-200 ${
                      expandedFaqs.has(faq.id) ? 'max-h-96' : 'max-h-0'
                    }`}
                  >
                    <div className="p-4 pt-0 text-sm text-gray-600 border-t border-gray-100">
                      {faq.answer}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          
          <div className="mt-10 text-center">
            <p className="text-gray-600">
              Have other questions? {' '}
              <a
                href="mailto:contact@henosis.dev"
                className="text-indigo-600 font-medium hover:text-indigo-500 transition-colors"
              >
                Contact our team →
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
      {/* Final CTA */}
      <section className="py-20 px-6 bg-indigo-600 text-center relative overflow-hidden">
        <div className="absolute inset-0 bg-[linear-gradient(120deg,rgba(255,255,255,0.2)_0%,transparent_40%)] opacity-50"></div>
        <div className="max-w-5xl mx-auto relative z-10">
          <h2 className="text-4xl font-bold mb-6 text-white">Ready to Grow Your Business Online?</h2>
          <p className="text-xl text-indigo-100 mb-8 max-w-3xl mx-auto">
            Stop losing customers to outdated websites. Get a professional, high-converting
            website built and launched in as little as 7 days.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button
              onClick={() => navigate('/contact')}
              className="px-8 py-4 bg-white text-indigo-700 rounded-lg shadow-lg
                      transition-all duration-200 text-lg font-medium hover:bg-gray-50"
            >
              Get Your Website Now
            </button>
            <a
              href="#services"
              className="px-8 py-4 bg-transparent border-2 border-white text-white rounded-lg
                      transition-all duration-200 text-lg font-medium hover:bg-white/10"
            >
              Explore Services
            </a>
          </div>
        </div>
      </section>
      {/* Footer */}
      <footer className="bg-white border-t border-gray-100">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-4 gap-8">
            <div>
              <div className="flex items-center mb-6">
                <img src="/logo512_indigo2.png" alt="Henosis Logo" className="h-8 w-8 mr-2" />
                <span className="text-xl font-bold">henosis</span>
                <span className="text-indigo-600 ml-2 font-medium">web studio</span>
              </div>
              <p className="text-gray-500 mb-6">
                We build professional websites that help small businesses attract customers and increase sales.
                Get your new website in as little as 7 days.
              </p>
              <div className="flex space-x-5">
                <a href="#" className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Facebook</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path fillRule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clipRule="evenodd" />
                  </svg>
                </a>
                <a href="#" className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Instagram</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
                  </svg>
                </a>
                <a href="#" className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">Twitter</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                  </svg>
                </a>
                <a href="#" className="text-gray-400 hover:text-gray-500">
                  <span className="sr-only">LinkedIn</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                  </svg>
                </a>
              </div>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase mb-4">Services</h3>
              <ul className="space-y-3">
                <li><a href="#" className="text-gray-500 hover:text-gray-900 transition-colors">Small Business Websites</a></li>
                <li><a href="#" className="text-gray-500 hover:text-gray-900 transition-colors">E-Commerce Stores</a></li>
                <li><a href="#" className="text-gray-500 hover:text-gray-900 transition-colors">Lead Generation</a></li>
                <li><a href="#" className="text-gray-500 hover:text-gray-900 transition-colors">Local SEO</a></li>
                <li><a href="#" className="text-gray-500 hover:text-gray-900 transition-colors">Website Maintenance</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase mb-4">Company</h3>
              <ul className="space-y-3">
                <li><a href="#" className="text-gray-500 hover:text-gray-900 transition-colors">About Us</a></li>
                <li><a href="#" className="text-gray-500 hover:text-gray-900 transition-colors">Our Work</a></li>
                <li><a href="#" className="text-gray-500 hover:text-gray-900 transition-colors">Pricing</a></li>
                <li><a href="#" className="text-gray-500 hover:text-gray-900 transition-colors">Testimonials</a></li>
                <li><a href="#" className="text-gray-500 hover:text-gray-900 transition-colors">Contact</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase mb-4">Contact Us</h3>
              <p className="text-gray-500 mb-3">contact@henosis.dev</p>
              <p className="text-gray-500 mb-3">(555) 123-4567</p>
              <p className="text-gray-500">
                123 Tech Lane<br />
                San Francisco, CA 94107
              </p>
            </div>
          </div>
          <div className="border-t border-gray-200 pt-8 mt-12 flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-400 text-sm">© 2025 Henosis Web Studio. All rights reserved.</p>
            <div className="flex space-x-6 mt-4 md:mt-0">
              <Link to="/privacy" className="text-gray-400 hover:text-gray-500 text-sm">Privacy</Link>
              <Link to="/terms" className="text-gray-400 hover:text-gray-500 text-sm">Terms</Link>
              <Link to="/sitemap" className="text-gray-400 hover:text-gray-500 text-sm">Sitemap</Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default WebDevLandingPage;