// MessageItem.js
import React, { useEffect, useRef, useCallback, useState } from 'react';
import UserMessage from '../UserMessage';
import AssistantMessage from '../AssistantMessage';
import LoadingBubble from '../LoadingBubble';

/** 
 * MessageItem component renders individual messages in the chat interface
 * It handles both user and assistant messages, as well as loading states
 * @param {Object} props - Component props
 * @param {number} props.index - Index of the message in the list
 * @param {Object} props.style - Style object for the message container
 * @param {Object} props.data - Data object containing messages and utility functions
 */
const MessageItem = React.memo(({ index, style, data }) => {
  const { messages, setMessageHeight, listRef } = data;
  const message = messages[index];
  const ref = useRef(null);
  const [height, setHeight] = useState(100);
  const [isVisible, setIsVisible] = useState(false);
  
  // Determine if this is the latest message of its type (user or assistant)
  const isLatestOfType = (() => {
    if (message.role === 'user' || message.role === 'assistant') {
      // Start from the end of the messages array and work backwards
      for (let i = messages.length - 1; i > index; i--) {
        // If we find a message with the same role, this isn't the latest
        if (messages[i].role === message.role) {
          return false;
        }
      }
      return true;
    }
    return false;
  })();

  const measureHeight = useCallback(() => {
    if (ref.current) {
      const newHeight = ref.current.getBoundingClientRect().height;
      if (newHeight !== height) {
        setHeight(newHeight);
        setMessageHeight(index, newHeight);
      }
    }
  }, [index, setMessageHeight, height]);

  // Initialize intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setIsVisible(entry.isIntersecting);
        });
      },
      { threshold: 0.1 }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => observer.disconnect();
  }, []);

  // Measure height on mount and content changes
  useEffect(() => {
    measureHeight();
    
    const timer = setTimeout(measureHeight, 50);
    return () => clearTimeout(timer);
  }, [measureHeight, message.content, isVisible]);

  // Setup resize observer
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      measureHeight();
    });
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => observer.disconnect();
  }, [measureHeight]);

  const handleTileToggle = useCallback(() => {
    setTimeout(measureHeight, 0);
  }, [measureHeight]);

  return (
    <div
      style={{
        ...style,
        padding: '8px 16px',
        marginBottom: '16px',
      }}
      className={`${message.role === 'user' ? 'text-right' : 'text-left'}`}
    >
      <div ref={ref}>
        {message.type === 'loading' ? (
          <LoadingBubble />
        ) : message.role === 'user' ? (
          <UserMessage
            content={typeof message.content === 'string' ? message.content : JSON.stringify(message.content)}
            files={message.files}
            pastes={message.pastes}
            onTileToggle={handleTileToggle}
            isLatest={isLatestOfType}
          />
        ) : (
          <AssistantMessage
            content={typeof message.content === 'string' ? message.content : JSON.stringify(message.content)}
            files={message.files}
            pastes={message.pastes}
            onTileToggle={handleTileToggle}
            isLatest={isLatestOfType}
          />
        )}
      </div>
    </div>
  );
});

export default MessageItem;