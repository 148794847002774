// hooks/useAudioRecording.js
import { useState, useCallback, useEffect } from 'react';

const useAudioRecording = (onError) => {
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordingTime, setRecordingTime] = useState(0);
  const [error, setError] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);

  // Cleanup effect
  useEffect(() => {
    return () => {
      if (mediaRecorder) {
        mediaRecorder.stream.getTracks().forEach(track => track.stop());
      }
    };
  }, [mediaRecorder]);

  // Recording timer
  useEffect(() => {
    let interval;
    if (isRecording) {
      interval = setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRecording]);

  const startRecording = useCallback(async () => {
    try {
      setError(null);
      const stream = await navigator.mediaDevices.getUserMedia({ 
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 16000
        }
      });
      
      const recorder = new MediaRecorder(stream, {
        mimeType: 'audio/webm;codecs=opus'
      });

      let chunks = [];
      recorder.ondataavailable = (e) => {
        chunks.push(e.data);
        setAudioChunks(prev => [...prev, e.data]);
      };

      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/webm' });
        setAudioBlob(blob);
        setAudioChunks([]);
        chunks = [];
      };

      recorder.onerror = (e) => {
        setError(e.error);
        onError?.(e.error);
      };

      recorder.start(1000); // Collect data every second
      setMediaRecorder(recorder);
      setIsRecording(true);
      setRecordingTime(0);

    } catch (err) {
      setError(err);
      onError?.(err);
      setIsRecording(false);
      if (err.name === 'NotAllowedError') {
        console.error('Microphone access denied');
      }
    }
  }, [onError]);

  const stopRecording = useCallback(() => {
    if (mediaRecorder && isRecording) {
      mediaRecorder.stop();
      setIsRecording(false);
      mediaRecorder.stream.getTracks().forEach(track => track.stop());
    }
  }, [mediaRecorder, isRecording]);

  const resetRecording = useCallback(() => {
    setAudioBlob(null);
    setRecordingTime(0);
    setAudioChunks([]);
  }, []);

  const getAudioData = useCallback(async () => {
    if (!audioBlob) return null;
    
    try {
      const arrayBuffer = await audioBlob.arrayBuffer();
      const base64String = btoa(
        String.fromCharCode(...new Uint8Array(arrayBuffer))
      );
      return {
        blob: audioBlob,
        base64: base64String,
        duration: recordingTime,
        mimeType: audioBlob.type
      };
    } catch (err) {
      setError(err);
      return null;
    }
  }, [audioBlob, recordingTime]);

  return {
    isRecording,
    audioBlob,
    recordingTime,
    error,
    audioChunks,
    startRecording,
    stopRecording,
    resetRecording,
    getAudioData
  };
};

export default useAudioRecording;
