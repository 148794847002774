// ActionBar.js
import React, { useState } from 'react';
import { Clipboard, RotateCcw, Check } from 'lucide-react';

/**
 * ActionBar component that appears below messages when hovered
 *
 * @param {Object} props
 * @param {string} props.messageContent - The content of the message to copy
 * @param {Function} props.onRetry - Function to call when retry button is clicked
 * @param {string} props.messageRole - The role of the message (user/assistant)
 * @param {boolean} props.isLatest - Whether this is the latest message in the conversation
 */
const ActionBar = ({ messageContent, onRetry, messageRole, isLatest }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      let textToCopy = messageContent;
      
      // Handle different content types
      if (typeof messageContent === 'object') {
        textToCopy = JSON.stringify(messageContent);
      }
      
      await navigator.clipboard.writeText(textToCopy);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <div className="action-bar opacity-0 group-hover:opacity-100 transition-opacity duration-200 flex items-center justify-end gap-2 mt-2 py-1 px-2">
      <button
        onClick={handleCopy}
        className="p-1.5 rounded-md hover:bg-zinc-700 text-gray-400 hover:text-white transition-colors"
        title="Copy message"
        aria-label="Copy message"
      >
        {copied ? <Check size={16} className="text-green-500" /> : <Clipboard size={16} />}
      </button>
      
      <button
        onClick={() => {/* No action for now */}}
        className="p-1.5 rounded-md hover:bg-zinc-700 text-gray-400 hover:text-white transition-colors"
        title={messageRole === 'user' ? "Re-send message" : "Regenerate response"}
        aria-label={messageRole === 'user' ? "Re-send message" : "Regenerate response"}
      >
        <RotateCcw size={16} />
      </button>
    </div>
  );
};

export default ActionBar;