import React, { useState, useEffect } from 'react';
import {
  Menu, X, ChevronRight, Users, Briefcase, 
  FileText, Award, Star, ArrowRight, MessageSquare,
  Phone, Mail, MapPin, Linkedin, Twitter, Facebook,
  Instagram, Youtube, Clock, Check, Calendar, ArrowDown
} from 'lucide-react';

const ProfessionalDemo = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [activeTab, setActiveTab] = useState('individuals');
  const [expandedFaqs, setExpandedFaqs] = useState(new Set([1]));
  const [activeTeamMember, setActiveTeamMember] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: '',
    message: ''
  });
  const [activeDemoTab, setActiveDemoTab] = useState('home');
  // Track scroll position for sticky header
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Handle FAQ expansion
  const toggleFaq = (id) => {
    setExpandedFaqs(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  // Form input change handler
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Form submission handler
  const handleFormSubmit = (e) => {
    e.preventDefault();
    // This would handle the form submission in a real implementation
    console.log('Form submitted:', formData);
    alert('Thank you for your inquiry. We will contact you shortly.');
    // Reset form
    setFormData({
      name: '',
      email: '',
      phone: '',
      service: '',
      message: ''
    });
  };

  // Our Services data
  const services = {
    individuals: [
      {
        title: "Personal Income Tax",
        description: "Comprehensive tax preparation and planning to minimize your tax liability and maximize your refund.",
        features: [
          "Individual tax return preparation",
          "Tax planning strategies",
          "IRS representation",
          "Audit support"
        ],
        icon: <FileText className="h-12 w-12 text-blue-600" />
      },
      {
        title: "Financial Planning",
        description: "Customized financial strategies to help you achieve your short and long-term financial goals.",
        features: [
          "Retirement planning",
          "Investment advice",
          "Education funding",
          "Estate planning"
        ],
        icon: <Users className="h-12 w-12 text-blue-600" />
      },
      {
        title: "Wealth Management",
        description: "Comprehensive wealth management solutions to protect and grow your assets.",
        features: [
          "Portfolio management",
          "Risk assessment",
          "Asset allocation strategies",
          "Regular performance reviews"
        ],
        icon: <Award className="h-12 w-12 text-blue-600" />
      }
    ],
    businesses: [
      {
        title: "Business Advisory",
        description: "Strategic guidance to help your business thrive and achieve its financial goals.",
        features: [
          "Business structure optimization",
          "Cash flow management",
          "Profitability analysis",
          "Growth strategies"
        ],
        icon: <Briefcase className="h-12 w-12 text-blue-600" />
      },
      {
        title: "Tax Compliance",
        description: "Ensure your business meets all tax obligations while minimizing tax burden legally.",
        features: [
          "Business tax preparation",
          "Sales tax compliance",
          "Payroll tax management",
          "Tax credits identification"
        ],
        icon: <FileText className="h-12 w-12 text-blue-600" />
      },
      {
        title: "Accounting Services",
        description: "Accurate financial reporting and bookkeeping to keep your business on track.",
        features: [
          "Financial statement preparation",
          "Bookkeeping services",
          "Accounts payable/receivable",
          "Payroll processing"
        ],
        icon: <Users className="h-12 w-12 text-blue-600" />
      }
    ]
  };

  // Team Members data
  const teamMembers = [
    {
      id: 1,
      name: "Sarah Johnson",
      title: "CPA, Managing Partner",
      image: "https://randomuser.me/api/portraits/women/64.jpg",
      bio: "Sarah brings over 20 years of accounting and tax experience to Alpine Financial. She specializes in complex tax strategies for high net worth individuals and business owners. Prior to founding Alpine Financial, Sarah was a senior tax manager at a Big Four accounting firm.",
      education: "MBA in Finance, University of Colorado\nBS in Accounting, University of Denver",
      certifications: ["Certified Public Accountant (CPA)", "Certified Financial Planner (CFP)"],
      expertise: ["Tax Strategy", "Financial Planning", "Business Advisory"]
    },
    {
      id: 2,
      name: "Michael Chen",
      title: "Tax Director",
      image: "https://randomuser.me/api/portraits/men/54.jpg",
      bio: "Michael has dedicated his 15-year career to helping clients navigate complex tax situations. His expertise includes individual and business tax planning, estate planning, and multi-state taxation issues. He takes pride in finding creative solutions to minimize tax liabilities while ensuring full compliance.",
      education: "MS in Taxation, Golden Gate University\nBS in Accounting, University of Washington",
      certifications: ["Certified Public Accountant (CPA)", "Enrolled Agent (EA)"],
      expertise: ["Tax Compliance", "Multi-State Taxation", "IRS Representation"]
    },
    {
      id: 3,
      name: "Emily Rodriguez",
      title: "Wealth Management Advisor",
      image: "https://randomuser.me/api/portraits/women/45.jpg",
      bio: "Emily helps clients build and preserve wealth through carefully crafted investment strategies. With 12 years in financial services, she specializes in retirement planning, portfolio management, and risk mitigation. Emily is passionate about helping clients achieve financial independence.",
      education: "MBA, Stanford University\nBA in Economics, University of California, Berkeley",
      certifications: ["Certified Financial Planner (CFP)", "Chartered Financial Analyst (CFA)"],
      expertise: ["Retirement Planning", "Investment Management", "Risk Assessment"]
    },
    {
      id: 4,
      name: "David Wilson",
      title: "Business Advisory Director",
      image: "https://randomuser.me/api/portraits/men/22.jpg",
      bio: "David specializes in helping small to medium-sized businesses optimize their operations and achieve sustainable growth. With experience as both a consultant and former business owner, he brings practical insights to help clients navigate challenges and capitalize on opportunities.",
      education: "MBA, University of Michigan\nBS in Business Administration, Colorado State University",
      certifications: ["Certified Management Accountant (CMA)", "Certified Business Advisor (CBA)"],
      expertise: ["Business Strategy", "Process Optimization", "Cash Flow Management"]
    }
  ];

  // Client Testimonials
  const testimonials = [
    {
      quote: "Alpine Financial transformed our business's financial management. Their strategic tax planning saved us nearly $45,000 last year, and their ongoing advisory services have been invaluable for our growth.",
      author: "Jennifer Smith",
      position: "CEO, Mountain View Technologies",
      image: "https://randomuser.me/api/portraits/women/33.jpg"
    },
    {
      quote: "I've been working with Alpine Financial for my personal and business finances for over five years. Their attention to detail and personalized approach has made a significant difference in my financial health.",
      author: "Robert Martinez",
      position: "Owner, Elevation Construction",
      image: "https://randomuser.me/api/portraits/men/42.jpg"
    },
    {
      quote: "As someone who previously dreaded tax season, I can't recommend Alpine Financial enough. They've made the process painless while maximizing my returns. Their financial planning services have also given me confidence about my retirement.",
      author: "Lisa Thompson",
      position: "Medical Professional",
      image: "https://randomuser.me/api/portraits/women/28.jpg"
    }
  ];

  // FAQ items
  const faqs = [
    {
      id: 1,
      question: "What services do you offer for individuals?",
      answer: "For individuals, we provide comprehensive tax preparation and planning, personal financial planning, retirement strategy development, investment management, estate planning, and risk management. Our approach is tailored to your specific financial situation and goals, whether you're looking to minimize taxes, save for retirement, or build long-term wealth."
    },
    {
      id: 2,
      question: "How can your firm help my small business?",
      answer: "Our small business services include tax compliance and planning, accounting and bookkeeping, payroll processing, business advisory, cash flow management, and strategic planning. We become your financial partner, helping you navigate challenges, identify opportunities, and implement strategies that drive profitability and sustainable growth."
    },
    {
      id: 3,
      question: "What is your fee structure?",
      answer: "We offer transparent fee structures tailored to each client's needs. For tax preparation, we typically charge flat fees based on the complexity of your return. For ongoing services like accounting or advisory, we offer monthly retainer options. Wealth management services are generally fee-based as a percentage of assets under management. During our initial consultation, we'll provide a clear quote based on your specific needs."
    },
    {
      id: 4,
      question: "How do you ensure the security of my financial information?",
      answer: "We take data security extremely seriously. Our firm employs bank-level encryption, secure client portals, multi-factor authentication, and regular security audits. Our staff undergoes regular training on data privacy and security protocols. We maintain strict internal controls and comply with all professional standards regarding confidentiality and data protection."
    },
    {
      id: 5,
      question: "What makes Alpine Financial different from other accounting firms?",
      answer: "Our difference lies in our approach: we combine technical expertise with genuine relationship building. Unlike larger firms where you might be just another client, our partners and senior staff work directly with you. We're proactive rather than reactive, reaching out throughout the year with strategies and recommendations. We also offer integrated services across tax, accounting, and financial planning, providing comprehensive solutions under one roof."
    }
  ];

  return (
    <div className="font-sans text-gray-800 bg-white">
      {/* Header/Navigation */}
        <header className={`fixed w-full z-30 transition-all duration-300 ${scrolled ? 'bg-white shadow-md py-3' : 'bg-white py-4'}`}>
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center">
              {/* Logo */}
              <div className="flex items-center">
                <div className="mr-2 flex-shrink-0">
                  <svg width="36" height="36" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 14L20 8L32 14V26L20 32L8 26V14Z" fill="#EBF5FF" />
                    <path d="M20 8L32 14V26L20 32L8 26V14L20 8Z" stroke="#2563EB" strokeWidth="2" />
                    <path d="M20 15V25" stroke="#2563EB" strokeWidth="2" />
                    <path d="M13 18V22" stroke="#2563EB" strokeWidth="2" />
                    <path d="M27 18V22" stroke="#2563EB" strokeWidth="2" />
                  </svg>
                </div>
                <div className="flex-shrink-0">
                  <h1 className="text-xl sm:text-2xl font-bold text-gray-900 leading-none">Alpine Financial</h1>
                  <p className="text-xs text-blue-600 font-medium hidden xs:block">Accounting & Financial Advisory</p>
                </div>
              </div>
              {/* Desktop Navigation */}
                <nav className="hidden lg:flex items-center space-x-8">
                  <a 
                    href="#" 
                    className={`text-gray-700 hover:text-blue-600 font-medium ${activeDemoTab === 'home' ? 'text-blue-600' : ''}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveDemoTab('home');
                    }}
                  >
                    Home
                  </a>
                  <a 
                    href="#" 
                    className={`text-gray-700 hover:text-blue-600 font-medium ${activeDemoTab === 'service' ? 'text-blue-600' : ''}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveDemoTab('service');
                    }}
                  >
                    Services
                  </a>
                  <a 
                    href="#" 
                    className={`text-gray-700 hover:text-blue-600 font-medium ${activeDemoTab === 'resources' ? 'text-blue-600' : ''}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveDemoTab('resources');
                    }}
                  >
                    Resources
                  </a>
                  <a href="#contact" className="bg-blue-600 hover:bg-blue-700 text-white px-5 py-2 rounded-md transition duration-300">Contact Us</a>
                </nav>
              {/* Mobile menu button */}
              <button
                className="lg:hidden text-gray-700 focus:outline-none"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                aria-label="Menu"
              >
                <Menu className="h-6 w-6" />
              </button>
            </div>
          </div>
          {/* Mobile Navigation Menu */}
          {isMenuOpen && (
            <div className="fixed inset-0 z-50 lg:hidden">
              <div className="absolute inset-0 bg-gray-900 opacity-50" onClick={() => setIsMenuOpen(false)}></div>
              <div className="absolute right-0 top-0 bottom-0 w-64 bg-white shadow-lg">
                <div className="p-4 border-b border-gray-200 flex justify-between items-center">
                  <div className="font-bold text-lg text-gray-900">Menu</div>
                  <button
                    onClick={() => setIsMenuOpen(false)}
                    className="text-gray-500 hover:text-gray-700"
                    aria-label="Close menu"
                  >
                    <X className="h-6 w-6" />
                  </button>
                </div>
                <nav className="flex flex-col p-4">
                  <a href="#about" className="text-gray-700 hover:text-blue-600 py-3 px-3 font-medium border-b border-gray-100" onClick={() => setIsMenuOpen(false)}>About</a>
                  <a href="#services" className="text-gray-700 hover:text-blue-600 py-3 px-3 font-medium border-b border-gray-100" onClick={() => setIsMenuOpen(false)}>Services</a>
                  <a href="#team" className="text-gray-700 hover:text-blue-600 py-3 px-3 font-medium border-b border-gray-100" onClick={() => setIsMenuOpen(false)}>Our Team</a>
                  <a href="#testimonials" className="text-gray-700 hover:text-blue-600 py-3 px-3 font-medium border-b border-gray-100" onClick={() => setIsMenuOpen(false)}>Testimonials</a>
                  <a href="#faq" className="text-gray-700 hover:text-blue-600 py-3 px-3 font-medium border-b border-gray-100" onClick={() => setIsMenuOpen(false)}>FAQ</a>
                  <a href="#contact" className="bg-blue-600 hover:bg-blue-700 text-white py-3 px-3 rounded-md transition duration-300 text-center mt-4 flex items-center justify-center" onClick={() => setIsMenuOpen(false)}>
                    Contact Us
                  </a>
                </nav>
              </div>
            </div>
          )}
        </header>

        {/* Hero Section */}
        <section className="pt-24 sm:pt-28 md:pt-36 pb-12 sm:pb-16 md:pb-24 bg-gradient-to-br from-slate-50 to-blue-50">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col md:flex-row md:items-center">
              <div className="md:w-1/2 md:pr-8 lg:pr-12">
                <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-gray-900 leading-tight mb-4">
                  Financial Clarity for Your <span className="text-blue-600">Future</span>
                </h1>
                <p className="text-lg sm:text-xl text-gray-600 mb-6 sm:mb-8 leading-relaxed">
                  Expert accounting, tax, and financial advisory services tailored to your personal and business needs. Let us help you navigate your financial journey with confidence.
                </p>
                <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mb-8 sm:mb-12">
                  <a href="#contact" className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-md transition duration-300 text-center font-medium flex-1 sm:flex-none flex items-center justify-center">
                    Schedule a Consultation
                  </a>
                  <a href="#services" className="border border-gray-300 hover:border-gray-400 bg-white text-gray-800 px-6 py-3 rounded-md transition duration-300 text-center font-medium flex-1 sm:flex-none flex items-center justify-center">
                    Explore Our Services
                  </a>
                </div>
                <div className="flex flex-col xs:flex-row items-center xs:space-x-4 mb-8 md:mb-0">
                  <div className="flex -space-x-2 mb-3 xs:mb-0">
                    {testimonials.map((testimonial, index) => (
                      <img 
                        key={index}
                        src={testimonial.image} 
                        alt={testimonial.author} 
                        className="w-8 h-8 sm:w-10 sm:h-10 rounded-full border-2 border-white"
                      />
                    ))}
                  </div>
                  <div className="text-sm text-center xs:text-left">
                    <div className="flex items-center justify-center xs:justify-start">
                      <div className="flex text-yellow-400">
                        {[...Array(5)].map((_, i) => (
                          <Star key={i} className="h-4 w-4 fill-current" />
                        ))}
                      </div>
                      <span className="text-gray-600 ml-2">200+ reviews</span>
                    </div>
                    <div className="text-gray-500">Trusted by 500+ clients</div>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 mt-8 md:mt-0">
                <div className="relative mx-auto max-w-md md:max-w-none">
                  <div className="absolute -left-4 -top-4 w-24 h-24 bg-blue-100 rounded-lg hidden sm:block"></div>
                  <div className="absolute -right-4 -bottom-4 w-32 h-32 bg-blue-50 rounded-lg hidden sm:block"></div>
                  <img 
                    src="https://images.unsplash.com/photo-1556761175-5973dc0f32e7?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1632&q=80"
                    alt="Professional accounting team"
                    className="relative z-10 rounded-lg shadow-lg object-cover w-full h-64 sm:h-80 md:h-96"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

      {/* Trust Indicators */}
      <section className="py-8 bg-white border-b border-gray-200">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-wrap justify-between items-center gap-6">
            <div className="flex items-center space-x-2">
              <Award className="h-6 w-6 text-blue-600" />
              <span className="font-medium">20+ Years Experience</span>
            </div>
            <div className="flex items-center space-x-2">
              <Users className="h-6 w-6 text-blue-600" />
              <span className="font-medium">500+ Satisfied Clients</span>
            </div>
            <div className="flex items-center space-x-2">
              <Check className="h-6 w-6 text-blue-600" />
              <span className="font-medium">Certified Professionals</span>
            </div>
            <div className="flex items-center space-x-2">
              <Briefcase className="h-6 w-6 text-blue-600" />
              <span className="font-medium">Comprehensive Solutions</span>
            </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="py-20 bg-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">About Alpine Financial</h2>
            <div className="w-20 h-1 bg-blue-600 mx-auto mb-6"></div>
            <p className="text-gray-600 text-lg">
              Providing expert financial guidance and accounting services to individuals and businesses in Colorado since 2002.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h3 className="text-2xl font-bold text-gray-900 mb-4">Our Approach</h3>
              <p className="text-gray-600 mb-6">
                At Alpine Financial, we believe financial success comes from personalized strategies built on a foundation of mutual trust and understanding. We take the time to know you, your goals, and your challenges before recommending solutions.
              </p>
              <p className="text-gray-600 mb-8">
                Whether you're an individual planning for retirement, a small business owner navigating growth, or a corporation requiring complex tax strategies, our team provides the expertise and attention to detail you deserve.
              </p>

              <div className="grid grid-cols-2 gap-4 mb-6">
                <div className="flex items-start">
                  <div className="flex-shrink-0 h-5 w-5 rounded-full bg-blue-100 flex items-center justify-center mt-1 mr-3">
                    <Check className="h-3 w-3 text-blue-600" />
                  </div>
                  <span className="text-gray-700">Personalized Service</span>
                </div>
                <div className="flex items-start">
                  <div className="flex-shrink-0 h-5 w-5 rounded-full bg-blue-100 flex items-center justify-center mt-1 mr-3">
                    <Check className="h-3 w-3 text-blue-600" />
                  </div>
                  <span className="text-gray-700">Proactive Approach</span>
                </div>
                <div className="flex items-start">
                  <div className="flex-shrink-0 h-5 w-5 rounded-full bg-blue-100 flex items-center justify-center mt-1 mr-3">
                    <Check className="h-3 w-3 text-blue-600" />
                  </div>
                  <span className="text-gray-700">Industry Expertise</span>
                </div>
                <div className="flex items-start">
                  <div className="flex-shrink-0 h-5 w-5 rounded-full bg-blue-100 flex items-center justify-center mt-1 mr-3">
                    <Check className="h-3 w-3 text-blue-600" />
                  </div>
                  <span className="text-gray-700">Transparent Communication</span>
                </div>
              </div>

              <a href="#contact" className="inline-flex items-center text-blue-600 hover:text-blue-800 font-medium">
                Learn more about our values
                <ChevronRight className="h-5 w-5 ml-1" />
              </a>
            </div>

            <div className="relative">
              <div className="grid grid-cols-2 gap-4">
                <img 
                  src="https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80" 
                  alt="Client meeting" 
                  className="rounded-lg shadow-md h-64 object-cover"
                />
                <img 
                  src="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80" 
                  alt="Office building" 
                  className="rounded-lg shadow-md h-64 object-cover mt-8"
                />
              </div>
              <div className="absolute -bottom-6 -right-6 bg-blue-600 text-white p-4 rounded-lg shadow-lg">
                <div className="text-4xl font-bold">20+</div>
                <div className="text-sm">Years of Excellence</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="py-20 bg-gray-50">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Services</h2>
            <div className="w-20 h-1 bg-blue-600 mx-auto mb-6"></div>
            <p className="text-gray-600 text-lg">
              Comprehensive financial services tailored to your unique needs, whether you're an individual, family, or business owner.
            </p>
          </div>

          {/* Service Tabs */}
          <div className="flex justify-center mb-12">
            <div className="inline-flex p-1 bg-gray-200 rounded-lg">
              <button
                onClick={() => setActiveTab('individuals')}
                className={`px-4 py-2 rounded-md transition ${
                  activeTab === 'individuals'
                    ? 'bg-white shadow-sm text-blue-600 font-medium'
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                For Individuals
              </button>
              <button
                onClick={() => setActiveTab('businesses')}
                className={`px-4 py-2 rounded-md transition ${
                  activeTab === 'businesses'
                    ? 'bg-white shadow-sm text-blue-600 font-medium'
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                For Businesses
              </button>
            </div>
          </div>

          {/* Service Cards */}
          <div className="grid md:grid-cols-3 gap-8">
            {services[activeTab].map((service, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300">
                <div className="h-16 w-16 bg-blue-100 rounded-lg flex items-center justify-center mb-6">
                  {service.icon}
                </div>
                <h3 className="text-xl font-bold text-gray-900 mb-3">{service.title}</h3>
                <p className="text-gray-600 mb-4">{service.description}</p>
                <div className="space-y-2 mb-6">
                  {service.features.map((feature, idx) => (
                    <div key={idx} className="flex items-center">
                      <Check className="h-4 w-4 text-blue-600 mr-2 flex-shrink-0" />
                      <span className="text-gray-700 text-sm">{feature}</span>
                    </div>
                  ))}
                </div>
                <a href="#contact" className="inline-flex items-center text-blue-600 hover:text-blue-800 font-medium">
                  Learn More
                  <ChevronRight className="h-5 w-5 ml-1" />
                </a>
              </div>
            ))}
          </div>

          <div className="text-center mt-12">
            <a href="#contact" className="inline-flex items-center bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-md transition duration-300 font-medium">
              Schedule a Consultation
              <ArrowRight className="h-5 w-5 ml-2" />
            </a>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section id="team" className="py-20 bg-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Meet Our Team</h2>
            <div className="w-20 h-1 bg-blue-600 mx-auto mb-6"></div>
            <p className="text-gray-600 text-lg">
              Our experienced professionals are dedicated to providing exceptional service and expertise to help you achieve your financial goals.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {teamMembers.map((member) => (
              <div 
                key={member.id} 
                className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300 group"
                onClick={() => setActiveTeamMember(member)}
              >
                <div className="aspect-square overflow-hidden">
                  <img 
                    src={member.image} 
                    alt={member.name} 
                    className="w-full h-full object-cover object-center group-hover:scale-105 transition-transform duration-300" 
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-bold text-gray-900 mb-1">{member.name}</h3>
                  <p className="text-blue-600 font-medium mb-4">{member.title}</p>
                  <p className="text-gray-600 line-clamp-3 mb-4">{member.bio}</p>
                  <button 
                    className="text-blue-600 hover:text-blue-800 font-medium flex items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveTeamMember(member);
                    }}
                  >
                    View Profile
                    <ChevronRight className="h-5 w-5 ml-1" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Team Member Modal */}
      {activeTeamMember && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-4xl w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center p-6 border-b">
              <h3 className="text-2xl font-bold text-gray-900">{activeTeamMember.name}</h3>
              <button 
                onClick={() => setActiveTeamMember(null)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            <div className="p-6">
              <div className="flex flex-col md:flex-row gap-8">
                <div className="md:w-1/3">
                  <img 
                    src={activeTeamMember.image} 
                    alt={activeTeamMember.name} 
                    className="rounded-lg w-full h-auto" 
                  />
                  <div className="mt-4">
                    <h4 className="font-bold text-gray-900">{activeTeamMember.title}</h4>
                    <div className="mt-4">
                      <h5 className="font-medium text-gray-800 mb-2">Areas of Expertise:</h5>
                      <ul className="space-y-1">
                        {activeTeamMember.expertise.map((item, index) => (
                          <li key={index} className="flex items-center">
                            <div className="h-2 w-2 bg-blue-600 rounded-full mr-2"></div>
                            <span>{item}</span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="md:w-2/3">
                  <h4 className="font-bold text-gray-900 mb-3">Biography</h4>
                  <p className="text-gray-600 mb-6">{activeTeamMember.bio}</p>
                  
                  <h4 className="font-bold text-gray-900 mb-3">Education</h4>
                  <p className="text-gray-600 mb-6 whitespace-pre-line">{activeTeamMember.education}</p>
                  
                  <h4 className="font-bold text-gray-900 mb-3">Certifications</h4>
                  <ul className="space-y-1 mb-6">
                    {activeTeamMember.certifications.map((cert, index) => (
                      <li key={index} className="flex items-center">
                        <Check className="h-5 w-5 text-blue-600 mr-2" />
                        <span>{cert}</span>
                      </li>
                    ))}
                  </ul>
                  
                  <div className="mt-6 pt-6 border-t border-gray-200">
                    <h4 className="font-bold text-gray-900 mb-3">Schedule a Meeting</h4>
                    <a 
                      href="#contact" 
                      onClick={() => setActiveTeamMember(null)}
                      className="inline-flex items-center bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md transition duration-300"
                    >
                      <Calendar className="h-4 w-4 mr-2" />
                      Book a Consultation
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Testimonials Section */}
      <section id="testimonials" className="py-20 bg-gray-50">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Client Testimonials</h2>
            <div className="w-20 h-1 bg-blue-600 mx-auto mb-6"></div>
            <p className="text-gray-600 text-lg">
              Hear what our clients have to say about their experience working with Alpine Financial.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {testimonials.map((testimonial, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-8 relative">
                <div className="text-6xl text-blue-100 absolute top-4 left-4 leading-none">"</div>
                <div className="relative">
                  <div className="flex text-yellow-400 mb-4">
                    {[...Array(5)].map((_, i) => (
                      <Star key={i} className="h-5 w-5 fill-current" />
                    ))}
                  </div>
                  <p className="text-gray-600 italic mb-8">{testimonial.quote}</p>
                  <div className="flex items-center">
                    <img 
                      src={testimonial.image} 
                      alt={testimonial.author} 
                      className="h-12 w-12 rounded-full mr-4 object-cover" 
                    />
                    <div>
                      <div className="font-bold text-gray-900">{testimonial.author}</div>
                      <div className="text-gray-500 text-sm">{testimonial.position}</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="text-center mt-12">
            <a 
              href="#" 
              className="inline-flex items-center bg-white border border-gray-300 hover:bg-gray-50 text-gray-700 px-6 py-3 rounded-md transition duration-300 font-medium shadow-sm"
            >
              <Star className="h-5 w-5 text-yellow-400 mr-2" />
              Read More Client Reviews
              <ArrowRight className="h-5 w-5 ml-2" />
            </a>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section id="faq" className="py-20 bg-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Frequently Asked Questions</h2>
            <div className="w-20 h-1 bg-blue-600 mx-auto mb-6"></div>
            <p className="text-gray-600 text-lg">
              Find answers to common questions about our services and processes.
            </p>
          </div>

          <div className="max-w-3xl mx-auto">
            <div className="space-y-6">
              {faqs.map((faq) => (
                <div 
                  key={faq.id} 
                  className={`border rounded-lg transition-all duration-200 ${
                    expandedFaqs.has(faq.id) ? 'border-blue-200 shadow-md' : 'border-gray-200'
                  }`}
                >
                  <button
                    className="flex justify-between items-center w-full px-6 py-4 text-left focus:outline-none"
                    onClick={() => toggleFaq(faq.id)}
                  >
                    <h3 className="font-medium text-gray-900">{faq.question}</h3>
                    <div className={`h-6 w-6 rounded-full flex items-center justify-center transition-all duration-200 ${
                      expandedFaqs.has(faq.id) ? 'bg-blue-100' : 'bg-gray-100'
                    }`}>
                      {expandedFaqs.has(faq.id) ? (
                        <svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <rect width="10" height="2" fill="#2563EB"/>
                        </svg>
                      ) : (
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5 0V10" stroke="#6B7280" strokeWidth="2"/>
                          <path d="M0 5H10" stroke="#6B7280" strokeWidth="2"/>
                        </svg>
                      )}
                    </div>
                  </button>
                  <div className={`overflow-hidden transition-all duration-200 ${
                    expandedFaqs.has(faq.id) ? 'max-h-96' : 'max-h-0'
                  }`}>
                    <div className="px-6 pb-4 text-gray-600">
                      {faq.answer}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="text-center mt-10">
              <p className="text-gray-600 mb-4">Have more questions? We're here to help.</p>
              <a href="#contact" className="inline-flex items-center bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-md transition duration-300 font-medium">
                Contact Us
                <ArrowRight className="h-5 w-5 ml-2" />
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-20 bg-gray-50">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Get in Touch</h2>
            <div className="w-20 h-1 bg-blue-600 mx-auto mb-6"></div>
            <p className="text-gray-600 text-lg">
              We're here to answer your questions and help you achieve your financial goals. Reach out to us today.
            </p>
          </div>

          <div className="grid md:grid-cols-2 gap-12">
            <div>
              <h3 className="text-xl font-bold text-gray-900 mb-6">Send Us a Message</h3>
              <form onSubmit={handleFormSubmit} className="space-y-4">
                <div>
                  <label htmlFor="name" className="block text-gray-700 font-medium mb-1">Your Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                  />
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <label htmlFor="email" className="block text-gray-700 font-medium mb-1">Email Address</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="phone" className="block text-gray-700 font-medium mb-1">Phone Number</label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="service" className="block text-gray-700 font-medium mb-1">Service of Interest</label>
                  <select
                    id="service"
                    name="service"
                    value={formData.service}
                    onChange={handleInputChange}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  >
                    <option value="">Select a Service</option>
                    <option value="tax">Tax Services</option>
                    <option value="accounting">Accounting & Bookkeeping</option>
                    <option value="planning">Financial Planning</option>
                    <option value="wealth">Wealth Management</option>
                    <option value="business">Business Advisory</option>
                    <option value="other">Other Services</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="message" className="block text-gray-700 font-medium mb-1">Your Message</label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    rows="4"
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    required
                  ></textarea>
                </div>

                <div className="flex items-start">
                  <input
                    id="privacy"
                    type="checkbox"
                    className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500 mt-1"
                    required
                  />
                  <label htmlFor="privacy" className="ml-2 block text-sm text-gray-600">
                    I agree to the privacy policy and terms of service. Your information will never be shared with third parties.
                  </label>
                </div>

                <button
                  type="submit"
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-md transition duration-300 font-medium"
                >
                  Send Message
                </button>
              </form>
            </div>

            <div>
              <h3 className="text-xl font-bold text-gray-900 mb-6">Contact Information</h3>
              <div className="bg-white rounded-lg shadow-md p-6 mb-8">
                <div className="space-y-4">
                  <div className="flex items-start">
                    <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 mt-1 flex-shrink-0">
                      <MapPin className="h-5 w-5" />
                    </div>
                    <div className="ml-4">
                      <h4 className="font-bold text-gray-900">Our Office</h4>
                      <p className="text-gray-600">123 Financial Avenue, Suite 400<br />Denver, CO 80202</p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 mt-1 flex-shrink-0">
                      <Phone className="h-5 w-5" />
                    </div>
                    <div className="ml-4">
                      <h4 className="font-bold text-gray-900">Phone</h4>
                      <p className="text-gray-600">(303) 555-1234</p>
                      <p className="text-gray-500 text-sm">Monday-Friday: 8:00 AM - 5:00 PM</p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 mt-1 flex-shrink-0">
                      <Mail className="h-5 w-5" />
                    </div>
                    <div className="ml-4">
                      <h4 className="font-bold text-gray-900">Email</h4>
                      <p className="text-gray-600">info@alpinefinancial.com</p>
                      <p className="text-gray-500 text-sm">We respond within 24 hours</p>
                    </div>
                  </div>

                  <div className="flex items-start">
                    <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 mt-1 flex-shrink-0">
                      <Clock className="h-5 w-5" />
                    </div>
                    <div className="ml-4">
                      <h4 className="font-bold text-gray-900">Business Hours</h4>
                      <p className="text-gray-600">Monday-Friday: 8:00 AM - 5:00 PM<br />Saturday-Sunday: Closed</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-lg shadow-md h-64 overflow-hidden">
                {/* This would be an embedded Google Map in a real implementation */}
                <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                  <div className="text-center p-6">
                    <MapPin className="h-8 w-8 text-blue-600 mx-auto mb-2" />
                    <p className="text-gray-700 font-medium">Google Map would be embedded here</p>
                    <p className="text-gray-500 text-sm">123 Financial Avenue, Denver, CO</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 bg-gradient-to-r from-blue-600 to-blue-800 text-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-4">Ready to Take Control of Your Financial Future?</h2>
            <p className="text-blue-100 mb-8 text-lg">
              Schedule a complimentary 30-minute consultation to discuss your financial needs and goals.
            </p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <a href="#contact" className="bg-white text-blue-600 hover:bg-gray-100 px-6 py-3 rounded-md transition duration-300 text-center font-medium">
                Schedule a Consultation
              </a>
              <a href="tel:3035551234" className="border border-white hover:bg-blue-700 px-6 py-3 rounded-md transition duration-300 text-center font-medium inline-flex items-center justify-center">
                <Phone className="h-5 w-5 mr-2" />
                Call (303) 555-1234
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 pt-16 pb-8">
          <div className="grid md:grid-cols-4 gap-8 mb-12">
            <div>
              <div className="flex items-center mb-4">
                <div className="mr-2">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 14L20 8L32 14V26L20 32L8 26V14Z" fill="#3B82F6" fillOpacity="0.2" />
                    <path d="M20 8L32 14V26L20 32L8 26V14L20 8Z" stroke="#3B82F6" strokeWidth="2" />
                    <path d="M20 15V25" stroke="#3B82F6" strokeWidth="2" />
                    <path d="M13 18V22" stroke="#3B82F6" strokeWidth="2" />
                    <path d="M27 18V22" stroke="#3B82F6" strokeWidth="2" />
                  </svg>
                </div>
                <div>
                  <h3 className="text-xl font-bold">Alpine Financial</h3>
                  <p className="text-xs text-blue-400 -mt-1">Accounting & Financial Advisory</p>
                </div>
              </div>
              <p className="text-gray-400 mb-4">
                Providing professional financial services to individuals and businesses throughout Colorado since 2002.
              </p>
              <div className="flex space-x-4">
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <Facebook className="h-5 w-5" />
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <Twitter className="h-5 w-5" />
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <Linkedin className="h-5 w-5" />
                </a>
              </div>
            </div>

            <div>
              <h4 className="font-bold text-lg mb-4">Services</h4>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Tax Services</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Accounting & Bookkeeping</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Financial Planning</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Wealth Management</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Business Advisory</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">IRS Representation</a></li>
              </ul>
            </div>

            <div>
              <h4 className="font-bold text-lg mb-4">Resources</h4>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Tax Resources</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Financial Calculators</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Client Portal</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Industry News</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Newsletter Signup</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Tax Calendar</a></li>
              </ul>
            </div>

            <div>
              <h4 className="font-bold text-lg mb-4">Contact Us</h4>
              <div className="space-y-3">
                <div className="flex items-start">
                  <MapPin className="h-5 w-5 text-blue-400 mr-2 mt-0.5" />
                  <span className="text-gray-400">123 Financial Avenue, Suite 400<br />Denver, CO 80202</span>
                </div>
                <div className="flex items-center">
                  <Phone className="h-5 w-5 text-blue-400 mr-2" />
                  <span className="text-gray-400">(303) 555-1234</span>
                </div>
                <div className="flex items-center">
                  <Mail className="h-5 w-5 text-blue-400 mr-2" />
                  <span className="text-gray-400">info@alpinefinancial.com</span>
                </div>
                <div className="flex items-center">
                  <Clock className="h-5 w-5 text-blue-400 mr-2" />
                  <span className="text-gray-400">Mon-Fri: 8AM-5PM</span>
                </div>
              </div>
            </div>
          </div>

          <div className="border-t border-gray-800 pt-8 flex flex-col md:flex-row justify-between">
            <p className="text-gray-500 text-sm mb-4 md:mb-0">
              &copy; {new Date().getFullYear()} Alpine Financial. All rights reserved.
            </p>
            <div className="flex flex-wrap gap-4">
              <a href="#" className="text-gray-500 hover:text-gray-400 text-sm">Privacy Policy</a>
              <a href="#" className="text-gray-500 hover:text-gray-400 text-sm">Terms of Service</a>
              <a href="#" className="text-gray-500 hover:text-gray-400 text-sm">Disclaimer</a>
              <a href="#" className="text-gray-500 hover:text-gray-400 text-sm">Sitemap</a>
            </div>
          </div>
        </div>
      </footer>

      {/* Appointment CTA Button */}
      <div className="fixed bottom-4 right-4 z-20 shadow-lg rounded-full">
        <a 
          href="#contact" 
          className="bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded-full shadow-lg flex items-center transition-all duration-300"
        >
          <Calendar className="h-5 w-5 mr-2" />
          <span className="font-medium">Book a Consultation</span>
        </a>
      </div>

      {/* Scroll to Top Button */}
      <button 
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} 
        className="fixed bottom-4 left-4 z-20 bg-white border border-gray-300 text-gray-700 p-2 rounded-full shadow-lg hover:bg-gray-100 transition-all duration-300"
        aria-label="Scroll to top"
      >
        <ArrowDown className="h-5 w-5 transform rotate-180" />
      </button>
    </div>
  );
};

export default ProfessionalDemo;