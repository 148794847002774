import React, { useState } from 'react';
import {
  Save,
  Settings,
  PlusCircle,
  Edit,
  Trash2,
  ChevronUp,
  ChevronDown,
  Info,
  FileText,
  AlertTriangle,
  CheckCircle,
  Code,
  Sliders,
  BarChart2,
  HelpCircle,
  Copy,
  Flag,
  Tag,
  User,
  Mail,
  Clock,
  Globe,
  ArrowRight,
  ThumbsUp,
  Database,
  Zap,
} from 'lucide-react';

const QualificationSetupPage = () => {
  const [activeTab, setActiveTab] = useState('scoring');
  const [scoringModel, setScoringModel] = useState('default');
  const [expandedSection, setExpandedSection] = useState('demographic');
  
  const toggleExpand = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };
  
  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Header */}
      <header className="bg-white border-b border-gray-200 sticky top-0 z-40">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <div className="bg-teal-500 h-8 w-8 rounded-md flex items-center justify-center mr-2">
                  <Sliders className="h-5 w-5 text-white" />
                </div>
                <span className="font-bold text-xl text-gray-900">Lead<span className="text-teal-500">Qualify</span></span>
              </div>
              <nav className="hidden sm:ml-6 sm:flex sm:space-x-8">
                <button
                  onClick={() => setActiveTab('scoring')}
                  className={`border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                    activeTab === 'scoring' ? 'border-teal-500 text-teal-600' : ''
                  }`}
                >
                  Lead Scoring
                </button>
                <button
                  onClick={() => setActiveTab('rules')}
                  className={`border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                    activeTab === 'rules' ? 'border-teal-500 text-teal-600' : ''
                  }`}
                >
                  Qualification Rules
                </button>
                <button
                  onClick={() => setActiveTab('workflows')}
                  className={`border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                    activeTab === 'workflows' ? 'border-teal-500 text-teal-600' : ''
                  }`}
                >
                  Automation Workflows
                </button>
                <button
                  onClick={() => setActiveTab('history')}
                  className={`border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                    activeTab === 'history' ? 'border-teal-500 text-teal-600' : ''
                  }`}
                >
                  History & Logs
                </button>
              </nav>
            </div>
            <div className="flex items-center">
              <button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                <span className="sr-only">Help</span>
                <HelpCircle className="h-6 w-6" aria-hidden="true" />
              </button>
              <button className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                <Save className="h-4 w-4 mr-2" />
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Main content */}
      <main className="py-6">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          {activeTab === 'scoring' && (
            <div>
              {/* Intro section */}
              <div className="bg-white shadow rounded-lg mb-6">
                <div className="px-4 py-5 sm:p-6">
                  <h2 className="text-xl font-semibold text-gray-900 mb-2">Lead Scoring Model Configuration</h2>
                  <p className="text-gray-500 mb-4">
                    Define how leads are scored based on their attributes, behaviors, and engagement. Higher scores indicate higher quality leads that are more likely to convert.
                  </p>
                  
                  <div className="bg-teal-50 border border-teal-200 rounded-md p-4 flex items-start">
                    <div className="flex-shrink-0">
                      <Info className="h-5 w-5 text-teal-600" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-teal-800">
                        Active model information
                      </h3>
                      <div className="mt-2 text-sm text-teal-700">
                        <p>Current model: <span className="font-medium">Default Scoring Model v2</span></p>
                        <p>Last updated: August 15, 2023 by admin@company.com</p>
                        <p>Current average qualification rate: 28.9%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Model selection */}
              <div className="bg-white shadow rounded-lg mb-6">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Scoring Model</h3>
                  <p className="mt-1 text-sm text-gray-500">Select or create a scoring model to use for lead qualification.</p>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div 
                      className={`border-2 rounded-lg p-4 cursor-pointer transition ${
                        scoringModel === 'default' ? 'border-teal-500 bg-teal-50' : 'border-gray-200 hover:border-gray-300'
                      }`}
                      onClick={() => setScoringModel('default')}
                    >
                      <div className="flex justify-between items-start mb-2">
                        <h3 className="font-medium text-gray-900">Default Model</h3>
                        {scoringModel === 'default' && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-teal-100 text-teal-800">
                            Active
                          </span>
                        )}
                      </div>
                      <p className="text-sm text-gray-500 mb-2">Our standard lead scoring model based on industry best practices.</p>
                      <div className="text-xs text-gray-500">Last modified: 2 months ago</div>
                    </div>
                    
                    <div 
                      className={`border-2 rounded-lg p-4 cursor-pointer transition ${
                        scoringModel === 'enhanced' ? 'border-teal-500 bg-teal-50' : 'border-gray-200 hover:border-gray-300'
                      }`}
                      onClick={() => setScoringModel('enhanced')}
                    >
                      <div className="flex justify-between items-start mb-2">
                        <h3 className="font-medium text-gray-900">Enhanced Model</h3>
                        {scoringModel === 'enhanced' && (
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-teal-100 text-teal-800">
                            Active
                          </span>
                        )}
                      </div>
                      <p className="text-sm text-gray-500 mb-2">Advanced model with behavioral analysis and engagement scoring.</p>
                      <div className="text-xs text-gray-500">Last modified: 2 weeks ago</div>
                    </div>
                    
                    <div 
                      className={`border-2 rounded-lg p-4 cursor-pointer border-dashed flex flex-col items-center justify-center text-center transition ${
                        scoringModel === 'new' ? 'border-teal-500 bg-teal-50' : 'border-gray-300 hover:border-gray-400'
                      }`}
                      onClick={() => setScoringModel('new')}
                    >
                      <PlusCircle className="h-8 w-8 text-gray-400 mb-2" />
                      <h3 className="font-medium text-gray-900">Create New Model</h3>
                      <p className="text-sm text-gray-500 mt-1">Start from scratch or duplicate an existing model</p>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Scoring criteria */}
              <div className="bg-white shadow rounded-lg mb-6">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200 flex justify-between items-center">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Scoring Criteria</h3>
                    <p className="mt-1 text-sm text-gray-500">Configure how different attributes affect lead scores.</p>
                  </div>
                  <button className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-teal-700 bg-teal-100 hover:bg-teal-200">
                    <PlusCircle className="h-4 w-4 mr-1" />
                    Add Criteria
                  </button>
                </div>
                
                {/* Demographic section */}
                <div className="border-b border-gray-200">
                  <button
                    className="w-full px-4 py-5 sm:px-6 text-left flex justify-between items-center focus:outline-none"
                    onClick={() => toggleExpand('demographic')}
                  >
                    <div className="flex items-center">
                      <User className="h-5 w-5 text-gray-400 mr-3" />
                      <h3 className="text-base font-medium text-gray-900">Demographic Information</h3>
                    </div>
                    {expandedSection === 'demographic' ? (
                      <ChevronUp className="h-5 w-5 text-gray-500" />
                    ) : (
                      <ChevronDown className="h-5 w-5 text-gray-500" />
                    )}
                  </button>
                  
                  {expandedSection === 'demographic' && (
                    <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                      <div className="space-y-4">
                        <div>
                          <div className="flex justify-between items-center mb-2">
                            <label className="block text-sm font-medium text-gray-700">Company Size</label>
                            <span className="text-sm text-gray-500">Max 20 points</span>
                          </div>
                          <div className="bg-gray-100 p-4 rounded-md space-y-2">
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <label className="block text-xs text-gray-500 mb-1">Value</label>
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">1-50 employees</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <label className="block text-xs text-gray-500 mb-1">Points</label>
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="5" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">51-200 employees</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="10" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">201-1000 employees</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="15" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">1000+ employees</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="20" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <button className="mt-2 text-sm text-teal-600 hover:text-teal-700 flex items-center">
                              <PlusCircle className="h-4 w-4 mr-1" />
                              Add another condition
                            </button>
                          </div>
                        </div>
                        
                        <div>
                          <div className="flex justify-between items-center mb-2">
                            <label className="block text-sm font-medium text-gray-700">Industry</label>
                            <span className="text-sm text-gray-500">Max 15 points</span>
                          </div>
                          <div className="bg-gray-100 p-4 rounded-md space-y-2">
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <label className="block text-xs text-gray-500 mb-1">Value</label>
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">Technology</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <label className="block text-xs text-gray-500 mb-1">Points</label>
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="15" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">Finance</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="12" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">Healthcare</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="10" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <button className="mt-2 text-sm text-teal-600 hover:text-teal-700 flex items-center">
                              <PlusCircle className="h-4 w-4 mr-1" />
                              Add another condition
                            </button>
                          </div>
                        </div>
                        
                        <div>
                          <div className="flex justify-between items-center mb-2">
                            <label className="block text-sm font-medium text-gray-700">Job Title</label>
                            <span className="text-sm text-gray-500">Max 15 points</span>
                          </div>
                          <div className="bg-gray-100 p-4 rounded-md space-y-2">
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <label className="block text-xs text-gray-500 mb-1">Value</label>
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">C-Level (CEO, CTO, etc.)</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <label className="block text-xs text-gray-500 mb-1">Points</label>
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="15" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">VP / Director</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="10" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">Manager</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="5" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <button className="mt-2 text-sm text-teal-600 hover:text-teal-700 flex items-center">
                              <PlusCircle className="h-4 w-4 mr-1" />
                              Add another condition
                            </button>
                          </div>
                        </div>
                        
                        <button className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-teal-700 bg-teal-100 hover:bg-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                          <PlusCircle className="h-4 w-4 mr-1" />
                          Add Demographic Criteria
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                
                {/* Behavior section */}
                <div className="border-b border-gray-200">
                  <button
                    className="w-full px-4 py-5 sm:px-6 text-left flex justify-between items-center focus:outline-none"
                    onClick={() => toggleExpand('behavior')}
                  >
                    <div className="flex items-center">
                      <BarChart2 className="h-5 w-5 text-gray-400 mr-3" />
                      <h3 className="text-base font-medium text-gray-900">Behavioral Activity</h3>
                    </div>
                    {expandedSection === 'behavior' ? (
                      <ChevronUp className="h-5 w-5 text-gray-500" />
                    ) : (
                      <ChevronDown className="h-5 w-5 text-gray-500" />
                    )}
                  </button>
                  
                  {expandedSection === 'behavior' && (
                    <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                      <div className="space-y-4">
                        <div>
                          <div className="flex justify-between items-center mb-2">
                            <label className="block text-sm font-medium text-gray-700">Website Visits</label>
                            <span className="text-sm text-gray-500">Max 25 points</span>
                          </div>
                          <div className="bg-gray-100 p-4 rounded-md space-y-2">
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <label className="block text-xs text-gray-500 mb-1">Value</label>
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">1-2 visits</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <label className="block text-xs text-gray-500 mb-1">Points</label>
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="5" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">3-5 visits</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="10" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">6-10 visits</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="15" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">11+ visits</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="25" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div>
                          <div className="flex justify-between items-center mb-2">
                            <label className="block text-sm font-medium text-gray-700">Content Downloads</label>
                            <span className="text-sm text-gray-500">Max 20 points</span>
                          </div>
                          <div className="bg-gray-100 p-4 rounded-md space-y-2">
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <label className="block text-xs text-gray-500 mb-1">Value</label>
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">Whitepaper download</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <label className="block text-xs text-gray-500 mb-1">Points</label>
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="10" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">Case study download</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="15" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">Pricing guide download</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="20" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <button className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-teal-700 bg-teal-100 hover:bg-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                          <PlusCircle className="h-4 w-4 mr-1" />
                          Add Behavioral Criteria
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                
                {/* Engagement section */}
                <div className="border-b border-gray-200">
                  <button
                    className="w-full px-4 py-5 sm:px-6 text-left flex justify-between items-center focus:outline-none"
                    onClick={() => toggleExpand('engagement')}
                  >
                    <div className="flex items-center">
                      <Mail className="h-5 w-5 text-gray-400 mr-3" />
                      <h3 className="text-base font-medium text-gray-900">Engagement Metrics</h3>
                    </div>
                    {expandedSection === 'engagement' ? (
                      <ChevronUp className="h-5 w-5 text-gray-500" />
                    ) : (
                      <ChevronDown className="h-5 w-5 text-gray-500" />
                    )}
                  </button>
                  
                  {expandedSection === 'engagement' && (
                    <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                      <div className="space-y-4">
                        <div>
                          <div className="flex justify-between items-center mb-2">
                            <label className="block text-sm font-medium text-gray-700">Email Engagement</label>
                            <span className="text-sm text-gray-500">Max 20 points</span>
                          </div>
                          <div className="bg-gray-100 p-4 rounded-md space-y-2">
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <label className="block text-xs text-gray-500 mb-1">Value</label>
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">Opens email</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <label className="block text-xs text-gray-500 mb-1">Points</label>
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="2" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">Clicks link in email</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="5" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">Replies to email</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="10" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div>
                          <div className="flex justify-between items-center mb-2">
                            <label className="block text-sm font-medium text-gray-700">Form Submissions</label>
                            <span className="text-sm text-gray-500">Max 25 points</span>
                          </div>
                          <div className="bg-gray-100 p-4 rounded-md space-y-2">
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <label className="block text-xs text-gray-500 mb-1">Value</label>
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">Newsletter signup</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <label className="block text-xs text-gray-500 mb-1">Points</label>
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="5" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">Contact form submission</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="15" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                            
                            <div className="grid grid-cols-5 gap-4">
                              <div className="col-span-3">
                                <div className="flex items-center justify-between">
                                  <span className="text-sm text-gray-700">Demo request</span>
                                </div>
                              </div>
                              <div className="col-span-1">
                                <input type="number" className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="25" />
                              </div>
                              <div className="col-span-1 flex items-end">
                                <button className="p-2 text-gray-400 hover:text-gray-500">
                                  <Trash2 className="h-4 w-4" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <button className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-teal-700 bg-teal-100 hover:bg-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                          <PlusCircle className="h-4 w-4 mr-1" />
                          Add Engagement Criteria
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                
                {/* Custom section */}
                <div className="border-b border-gray-200">
                  <button
                    className="w-full px-4 py-5 sm:px-6 text-left flex justify-between items-center focus:outline-none"
                    onClick={() => toggleExpand('custom')}
                  >
                    <div className="flex items-center">
                      <Tag className="h-5 w-5 text-gray-400 mr-3" />
                      <h3 className="text-base font-medium text-gray-900">Custom Criteria</h3>
                    </div>
                    {expandedSection === 'custom' ? (
                      <ChevronUp className="h-5 w-5 text-gray-500" />
                    ) : (
                      <ChevronDown className="h-5 w-5 text-gray-500" />
                    )}
                  </button>
                  
                  {expandedSection === 'custom' && (
                    <div className="px-4 pb-5 sm:px-6 sm:pb-6">
                      <div className="space-y-4">
                        <div className="bg-gray-100 rounded-md p-6 flex items-center justify-center text-center">
                          <div>
                            <PlusCircle className="h-10 w-10 text-gray-400 mx-auto mb-3" />
                            <h4 className="text-gray-900 font-medium mb-1">Add Custom Scoring Criteria</h4>
                            <p className="text-gray-500 text-sm max-w-md mx-auto mb-4">
                              Create custom criteria based on your specific business requirements or integrate with your CRM data.
                            </p>
                            <button className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-teal-700 bg-teal-100 hover:bg-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                              <PlusCircle className="h-4 w-4 mr-1" />
                              Create Custom Criteria
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              
              {/* Threshold settings */}
              <div className="bg-white shadow rounded-lg mb-6">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Qualification Thresholds</h3>
                  <p className="mt-1 text-sm text-gray-500">Define score ranges for lead classification.</p>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <div className="space-y-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Qualified Lead Threshold
                      </label>
                      <div className="flex items-center">
                        <input type="number" className="max-w-[120px] block border-gray-300 rounded-md shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:text-sm" defaultValue="80" />
                        <span className="ml-2 text-sm text-gray-500">points or higher</span>
                      </div>
                      <p className="mt-1 text-xs text-gray-500">Leads with scores at or above this threshold will be marked as "Qualified"</p>
                    </div>
                    
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Lead Classification Ranges
                      </label>
                      <div className="bg-gray-100 p-4 rounded-md space-y-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                          <div>
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              Qualified
                            </span>
                            <div className="mt-1 flex items-center">
                              <span className="text-sm text-gray-500">80-100 points</span>
                              <button className="ml-1 text-gray-400 hover:text-gray-500">
                                <Edit className="h-4 w-4" />
                              </button>
                            </div>
                          </div>
                          
                          <div>
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                              Nurturing
                            </span>
                            <div className="mt-1 flex items-center">
                              <span className="text-sm text-gray-500">60-79 points</span>
                              <button className="ml-1 text-gray-400 hover:text-gray-500">
                                <Edit className="h-4 w-4" />
                              </button>
                            </div>
                          </div>
                          
                          <div>
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                              Unqualified
                            </span>
                            <div className="mt-1 flex items-center">
                              <span className="text-sm text-gray-500">0-59 points</span>
                              <button className="ml-1 text-gray-400 hover:text-gray-500">
                                <Edit className="h-4 w-4" />
                              </button>
                            </div>
                          </div>
                          
                          <div>
                            <button className="inline-flex items-center px-3 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                              <PlusCircle className="h-4 w-4 mr-1" />
                              Add Classification
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Actions and validations */}
              <div className="flex justify-end">
                <button className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 mr-3">
                  Cancel
                </button>
                <button className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                  <Save className="h-4 w-4 mr-2" />
                  Save Lead Scoring Model
                </button>
              </div>
            </div>
          )}
          
          {activeTab === 'rules' && (
            <div className="bg-white shadow rounded-lg py-6 px-6">
              <div className="text-center py-20">
                <div className="mx-auto h-12 w-12 rounded-full bg-teal-100 flex items-center justify-center">
                  <FileText className="h-6 w-6 text-teal-600" />
                </div>
                <h3 className="mt-2 text-lg font-medium text-gray-900">Qualification Rules</h3>
                <p className="mt-1 text-gray-500 max-w-xl mx-auto">
                  This section allows you to configure automated qualification rules based on lead criteria.
                </p>
                <div className="mt-6">
                  <p className="text-sm text-gray-500">Coming soon in the next product update.</p>
                </div>
              </div>
            </div>
          )}
          
          {activeTab === 'workflows' && (
            <div className="bg-white shadow rounded-lg py-6 px-6">
              <div className="text-center py-20">
                <div className="mx-auto h-12 w-12 rounded-full bg-teal-100 flex items-center justify-center">
                  <Zap className="h-6 w-6 text-teal-600" />
                </div>
                <h3 className="mt-2 text-lg font-medium text-gray-900">Automation Workflows</h3>
                <p className="mt-1 text-gray-500 max-w-xl mx-auto">
                  This section allows you to create automated workflows triggered by lead qualification events.
                </p>
                <div className="mt-6">
                  <p className="text-sm text-gray-500">Coming soon in the next product update.</p>
                </div>
              </div>
            </div>
          )}
          
          {activeTab === 'history' && (
            <div className="bg-white shadow rounded-lg py-6 px-6">
              <div className="text-center py-20">
                <div className="mx-auto h-12 w-12 rounded-full bg-teal-100 flex items-center justify-center">
                  <Clock className="h-6 w-6 text-teal-600" />
                </div>
                <h3 className="mt-2 text-lg font-medium text-gray-900">History & Logs</h3>
                <p className="mt-1 text-gray-500 max-w-xl mx-auto">
                  This section provides a changelog of qualification model updates and scoring changes.
                </p>
                <div className="mt-6">
                  <p className="text-sm text-gray-500">Coming soon in the next product update.</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default QualificationSetupPage;