import React, { useState } from 'react';
import {
  BarChart2,
  Users,
  Filter,
  Settings,
  Bell,
  Search,
  User,
  ArrowRight,
  CheckCircle,
  X,
  Sliders,
  PieChart,
  BarChart,
  Calendar,
  Clock,
  ChevronDown,
  LogOut,
  Tag,
} from 'lucide-react';

const SaasDemo = ({ activeDemoTab, setActiveDemoTab }) => {
  const [activeTab, setActiveTab] = useState('dashboard');
  const [showNotifications, setShowNotifications] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  
  return (
    <div className="bg-gray-50 min-h-screen flex flex-col">
      {/* Header */}
      <header className="bg-white border-b border-gray-200 sticky top-0 z-40">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <div className="bg-teal-500 h-8 w-8 rounded-md flex items-center justify-center mr-2">
                  <Filter className="h-5 w-5 text-white" />
                </div>
                <span className="font-bold text-xl text-gray-900">Lead<span className="text-teal-500">Qualify</span></span>
              </div>
              <nav className="hidden sm:ml-6 sm:flex sm:space-x-8">
                <button
                  onClick={() => setActiveTab('dashboard')}
                  className={`border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                    activeTab === 'dashboard' ? 'border-teal-500 text-teal-600' : ''
                  }`}
                >
                  Dashboard
                </button>
                <button
                  onClick={() => setActiveTab('leads')}
                  className={`border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                    activeTab === 'leads' ? 'border-teal-500 text-teal-600' : ''
                  }`}
                >
                  Leads
                </button>
                <button
                  onClick={() => setActiveTab('campaigns')}
                  className={`border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                    activeTab === 'campaigns' ? 'border-teal-500 text-teal-600' : ''
                  }`}
                >
                  Campaigns
                </button>
                <button
                  onClick={() => setActiveTab('analytics')}
                  className={`border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                    activeTab === 'analytics' ? 'border-teal-500 text-teal-600' : ''
                  }`}
                >
                  Analytics
                </button>
              </nav>
            </div>
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <button className="bg-teal-500 p-1 rounded-full text-white hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                  <span className="sr-only">Search</span>
                  <Search className="h-5 w-5" />
                </button>
              </div>
              <div className="hidden md:ml-4 md:flex-shrink-0 md:flex md:items-center">
                <div className="ml-3 relative">
                  <button
                    onClick={() => setShowNotifications(!showNotifications)}
                    className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                  >
                    <span className="sr-only">View notifications</span>
                    <Bell className="h-5 w-5" />
                  </button>
                  {showNotifications && (
                    <div className="origin-top-right absolute right-0 mt-2 w-80 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="px-4 py-2 border-b border-gray-100">
                        <h3 className="text-sm font-medium text-gray-900">Notifications</h3>
                      </div>
                      <div className="py-1">
                        <div className="px-4 py-2 hover:bg-gray-50 border-l-4 border-teal-500">
                          <p className="text-sm font-medium text-gray-900">New lead qualification</p>
                          <p className="text-xs text-gray-500">Acme Corp. - Enterprise</p>
                          <p className="text-xs text-gray-400">5 mins ago</p>
                        </div>
                        <div className="px-4 py-2 hover:bg-gray-50">
                          <p className="text-sm font-medium text-gray-900">Campaign milestone reached</p>
                          <p className="text-xs text-gray-500">Summer Promotion - 100 leads</p>
                          <p className="text-xs text-gray-400">1 hour ago</p>
                        </div>
                        <div className="px-4 py-2 hover:bg-gray-50">
                          <p className="text-sm font-medium text-gray-900">Lead scoring updated</p>
                          <p className="text-xs text-gray-500">Scoring model v2 now active</p>
                          <p className="text-xs text-gray-400">3 hours ago</p>
                        </div>
                      </div>
                      <div className="border-t border-gray-100 py-1">
                        <button className="block w-full text-left px-4 py-2 text-sm text-teal-500 hover:bg-gray-50">
                          View all notifications
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="ml-3 relative">
                  <div>
                    <button
                      onClick={() => setShowUserMenu(!showUserMenu)}
                      className="flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
                    >
                      <span className="sr-only">Open user menu</span>
                      <div className="h-8 w-8 rounded-full bg-teal-100 flex items-center justify-center">
                        <User className="h-5 w-5 text-teal-700" />
                      </div>
                    </button>
                  </div>
                  {showUserMenu && (
                    <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50">Your Profile</a>
                      <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50">Settings</a>
                      <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50">Sign out</a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Main content */}
      <main className="flex-1">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          {activeTab === 'dashboard' && (
            <div>
              <div className="px-4 sm:px-0 mb-4">
                <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
                <p className="mt-1 text-sm text-gray-600">Your lead generation and qualification at a glance.</p>
              </div>
              
              {/* Overview Cards */}
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-6">
                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 bg-teal-100 rounded-md p-3">
                        <Users className="h-6 w-6 text-teal-600" />
                      </div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-medium text-gray-500 truncate">Total Leads</dt>
                          <dd>
                            <div className="text-xl font-semibold text-gray-900">1,482</div>
                            <div className="flex items-center mt-1">
                              <span className="text-sm text-green-600 font-medium">+12.5%</span>
                              <span className="text-sm text-gray-500 ml-1">from last month</span>
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 bg-teal-100 rounded-md p-3">
                        <Filter className="h-6 w-6 text-teal-600" />
                      </div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-medium text-gray-500 truncate">Qualified Leads</dt>
                          <dd>
                            <div className="text-xl font-semibold text-gray-900">428</div>
                            <div className="flex items-center mt-1">
                              <span className="text-sm text-green-600 font-medium">+8.2%</span>
                              <span className="text-sm text-gray-500 ml-1">from last month</span>
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 bg-teal-100 rounded-md p-3">
                        <BarChart2 className="h-6 w-6 text-teal-600" />
                      </div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-medium text-gray-500 truncate">Conversion Rate</dt>
                          <dd>
                            <div className="text-xl font-semibold text-gray-900">28.9%</div>
                            <div className="flex items-center mt-1">
                              <span className="text-sm text-green-600 font-medium">+2.4%</span>
                              <span className="text-sm text-gray-500 ml-1">from last month</span>
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 bg-teal-100 rounded-md p-3">
                        <Calendar className="h-6 w-6 text-teal-600" />
                      </div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-medium text-gray-500 truncate">Active Campaigns</dt>
                          <dd>
                            <div className="text-xl font-semibold text-gray-900">5</div>
                            <div className="flex items-center mt-1">
                              <span className="text-sm text-gray-600 font-medium">2 ending this week</span>
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Charts Section */}
              <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 mb-6">
                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Lead Quality Distribution</h3>
                  </div>
                  <div className="px-4 py-5 sm:p-6 flex flex-col items-center">
                    {/* Simulated Pie Chart */}
                    <div className="w-48 h-48 relative my-4">
                      <div className="w-full h-full rounded-full border-8 border-gray-200"></div>
                      <div className="absolute top-0 left-0 w-full h-full rounded-full border-8 border-teal-500" style={{ clipPath: 'polygon(50% 50%, 50% 0%, 100% 0%, 100% 100%, 50% 100%)' }}></div>
                      <div className="absolute top-0 left-0 w-full h-full rounded-full border-8 border-yellow-400" style={{ clipPath: 'polygon(50% 50%, 50% 0%, 0% 0%, 0% 50%)' }}></div>
                      <div className="absolute top-0 left-0 w-full h-full rounded-full border-8 border-red-400" style={{ clipPath: 'polygon(50% 50%, 0% 50%, 0% 100%, 30% 100%)' }}></div>
                      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
                        <span className="text-xl font-bold text-gray-700">1,482</span>
                      </div>
                    </div>
                    <div className="flex justify-center space-x-6 mt-4">
                      <div className="flex items-center">
                        <span className="h-3 w-3 rounded-full bg-teal-500 mr-2"></span>
                        <span className="text-sm text-gray-700">High Quality (42%)</span>
                      </div>
                      <div className="flex items-center">
                        <span className="h-3 w-3 rounded-full bg-yellow-400 mr-2"></span>
                        <span className="text-sm text-gray-700">Medium (35%)</span>
                      </div>
                      <div className="flex items-center">
                        <span className="h-3 w-3 rounded-full bg-red-400 mr-2"></span>
                        <span className="text-sm text-gray-700">Low (23%)</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Lead Acquisition Trend</h3>
                  </div>
                  <div className="px-4 py-5 sm:p-6">
                    {/* Simulated Bar Chart */}
                    <div className="h-64 flex items-end space-x-2">
                      {[35, 48, 42, 65, 80, 95, 112].map((value, index) => (
                        <div key={index} className="flex-1 flex flex-col items-center">
                          <div 
                            className="w-full bg-teal-500 rounded-t" 
                            style={{ height: `${value / 1.2}%` }}
                          ></div>
                          <div className="text-xs text-gray-500 mt-1">
                            {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'][index]}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              {/* Recent Leads */}
              <div className="bg-white shadow rounded-lg mb-6">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200 flex justify-between items-center">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Recent Leads</h3>
                    <p className="mt-1 text-sm text-gray-500">Latest lead acquisitions across all channels.</p>
                  </div>
                  <button className="text-sm font-medium text-teal-600 hover:text-teal-500 flex items-center">
                    View all
                    <ArrowRight className="ml-1 h-4 w-4" />
                  </button>
                </div>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Source
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Score
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Date
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="h-10 w-10 rounded-full bg-teal-100 flex items-center justify-center">
                              <span className="text-teal-800 font-medium">JD</span>
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">John Doe</div>
                              <div className="text-sm text-gray-500">john.doe@example.com</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">Contact Form</div>
                          <div className="text-sm text-gray-500">Website</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">92/100</div>
                          <div className="w-24 bg-gray-200 rounded-full h-2">
                            <div className="bg-teal-500 h-2 rounded-full" style={{ width: '92%' }}></div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            Qualified
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Just now
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-teal-600 hover:text-teal-900">Details</button>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center">
                              <span className="text-blue-800 font-medium">MS</span>
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">Mary Smith</div>
                              <div className="text-sm text-gray-500">m.smith@acmecorp.com</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">LinkedIn Campaign</div>
                          <div className="text-sm text-gray-500">Social Media</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">78/100</div>
                          <div className="w-24 bg-gray-200 rounded-full h-2">
                            <div className="bg-teal-500 h-2 rounded-full" style={{ width: '78%' }}></div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            Qualified
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          2 hours ago
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-teal-600 hover:text-teal-900">Details</button>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="h-10 w-10 rounded-full bg-purple-100 flex items-center justify-center">
                              <span className="text-purple-800 font-medium">RJ</span>
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">Robert Johnson</div>
                              <div className="text-sm text-gray-500">robert@techfirm.io</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">Webinar Signup</div>
                          <div className="text-sm text-gray-500">Marketing Event</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">65/100</div>
                          <div className="w-24 bg-gray-200 rounded-full h-2">
                            <div className="bg-yellow-400 h-2 rounded-full" style={{ width: '65%' }}></div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                            Nurturing
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          5 hours ago
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-teal-600 hover:text-teal-900">Details</button>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="h-10 w-10 rounded-full bg-pink-100 flex items-center justify-center">
                              <span className="text-pink-800 font-medium">SW</span>
                            </div>
                            <div className="ml-4">
                              <div className="text-sm font-medium text-gray-900">Sarah Wilson</div>
                              <div className="text-sm text-gray-500">swilson@gmail.com</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">Free Trial</div>
                          <div className="text-sm text-gray-500">Product</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">35/100</div>
                          <div className="w-24 bg-gray-200 rounded-full h-2">
                            <div className="bg-red-400 h-2 rounded-full" style={{ width: '35%' }}></div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                            Unqualified
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          1 day ago
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-teal-600 hover:text-teal-900">Details</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'leads' && (
            <div>
              <div className="px-4 sm:px-0 mb-4">
                <h1 className="text-2xl font-semibold text-gray-900">Lead Management</h1>
                <p className="mt-1 text-sm text-gray-600">View, filter, and qualify your leads.</p>
              </div>

              {/* Filter Section */}
              <div className="bg-white shadow rounded-lg mb-6">
                <div className="px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                    <div>
                      <label htmlFor="source" className="block text-sm font-medium text-gray-700">Source</label>
                      <select id="source" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm">
                        <option>All Sources</option>
                        <option>Website</option>
                        <option>Social Media</option>
                        <option>Email Campaign</option>
                        <option>Events</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status</label>
                      <select id="status" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm">
                        <option>All Statuses</option>
                        <option>New</option>
                        <option>Qualified</option>
                        <option>Nurturing</option>
                        <option>Unqualified</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="timeframe" className="block text-sm font-medium text-gray-700">Time Frame</label>
                      <select id="timeframe" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm">
                        <option>All Time</option>
                        <option>Today</option>
                        <option>This Week</option>
                        <option>This Month</option>
                        <option>Last 30 Days</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="score" className="block text-sm font-medium text-gray-700">Score</label>
                      <select id="score" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm">
                        <option>All Scores</option>
                        <option>High (80-100)</option>
                        <option>Medium (60-79)</option>
                        <option>Low (0-59)</option>
                      </select>
                    </div>
                  </div>
                  <div className="mt-4 flex justify-end">
                    <button className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 mr-3">
                      Reset
                    </button>
                    <button className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                      Apply Filters
                    </button>
                  </div>
                </div>
              </div>

              {/* Leads Table */}
              <div className="bg-white shadow rounded-lg">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200 flex justify-between items-center">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">All Leads</h3>
                    <p className="mt-1 text-sm text-gray-500">Showing 1,482 leads</p>
                  </div>
                  <div className="flex space-x-3">
                    <button className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-teal-700 bg-teal-100 hover:bg-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                      <Sliders className="h-4 w-4 mr-1" />
                      Advanced Filters
                    </button>
                    <button className="inline-flex items-center px-3 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                      <Tag className="h-4 w-4 mr-1" />
                      Bulk Tag
                    </button>
                  </div>
                </div>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          <div className="flex items-center">
                            <input
                              id="select-all"
                              type="checkbox"
                              className="h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded"
                            />
                            <label htmlFor="select-all" className="ml-2 block text-xs">
                              Name
                            </label>
                          </div>
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Contact Info
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Source
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Score
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Created
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {[...Array(8)].map((_, idx) => {
                        const randomNames = [
                          "John Doe", "Mary Smith", "Robert Johnson", "Sarah Wilson", 
                          "Michael Brown", "Jessica Davis", "William Miller", "Elizabeth Taylor"
                        ];
                        const randomEmails = [
                          "john.doe@example.com", "m.smith@acmecorp.com", "robert@techfirm.io", "swilson@gmail.com",
                          "mbrown@outlook.com", "jessica.d@company.org", "will.miller@business.net", "elizabeth@startup.co"
                        ];
                        const randomPhones = [
                          "(555) 123-4567", "(555) 987-6543", "(555) 234-5678", "(555) 876-5432",
                          "(555) 345-6789", "(555) 765-4321", "(555) 456-7890", "(555) 654-3210"
                        ];
                        const randomSources = [
                          "Contact Form", "LinkedIn Ad", "Google Search", "Email Campaign", 
                          "Webinar", "Referral", "Trade Show", "Cold Call"
                        ];
                        const randomScores = [
                          95, 82, 76, 64, 58, 45, 37, 88
                        ];
                        const randomStatuses = [
                          "Qualified", "Qualified", "Nurturing", "Nurturing", 
                          "Unqualified", "Qualified", "New", "New"
                        ];
                        const randomDates = [
                          "Today", "Yesterday", "2 days ago", "3 days ago", 
                          "1 week ago", "2 weeks ago", "1 month ago", "2 months ago"
                        ];
                        
                        return (
                          <tr key={idx}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  className="h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded"
                                />
                                <div className="h-10 w-10 rounded-full bg-teal-100 flex items-center justify-center ml-3">
                                  <span className="text-teal-800 font-medium">
                                    {randomNames[idx].split(' ').map(name => name[0]).join('')}
                                  </span>
                                </div>
                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">{randomNames[idx]}</div>
                                  <div className="text-xs text-gray-500">
                                    {idx % 3 === 0 ? "Enterprise" : idx % 3 === 1 ? "Mid-Market" : "Small Business"}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">{randomEmails[idx]}</div>
                              <div className="text-sm text-gray-500">{randomPhones[idx]}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-gray-900">{randomSources[idx]}</div>
                              <div className="text-sm text-gray-500">{idx % 2 === 0 ? "Organic" : "Paid"}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm font-medium text-gray-900">{randomScores[idx]}/100</div>
                              <div className="w-16 bg-gray-200 rounded-full h-2">
                                <div 
                                  className={`${
                                    randomScores[idx] >= 80 ? 'bg-teal-500' : 
                                    randomScores[idx] >= 60 ? 'bg-yellow-400' : 'bg-red-400'
                                  } h-2 rounded-full`} 
                                  style={{ width: `${randomScores[idx]}%` }}
                                ></div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                randomStatuses[idx] === 'Qualified' ? 'bg-green-100 text-green-800' :
                                randomStatuses[idx] === 'Nurturing' ? 'bg-yellow-100 text-yellow-800' :
                                randomStatuses[idx] === 'Unqualified' ? 'bg-red-100 text-red-800' :
                                'bg-blue-100 text-blue-800'
                              }`}>
                                {randomStatuses[idx]}
                              </span>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {randomDates[idx]}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <button className="text-teal-600 hover:text-teal-900 mr-3">Edit</button>
                              <button className="text-gray-600 hover:text-gray-900">View</button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
                  <div className="flex-1 flex justify-between sm:hidden">
                    <button className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                      Previous
                    </button>
                    <button className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                      Next
                    </button>
                  </div>
                  <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                    <div>
                      <p className="text-sm text-gray-700">
                        Showing <span className="font-medium">1</span> to <span className="font-medium">10</span> of <span className="font-medium">1,482</span> leads
                      </p>
                    </div>
                    <div>
                      <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                        <button className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                          <span className="sr-only">Previous</span>
                          <ChevronDown className="h-5 w-5 transform rotate-90" />
                        </button>
                        <button className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                          1
                        </button>
                        <button className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-teal-50 text-sm font-medium text-teal-600 hover:bg-teal-100">
                          2
                        </button>
                        <button className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                          3
                        </button>
                        <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                          ...
                        </span>
                        <button className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50">
                          149
                        </button>
                        <button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                          <span className="sr-only">Next</span>
                          <ChevronDown className="h-5 w-5 transform -rotate-90" />
                        </button>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'campaigns' && (
            <div>
              <div className="px-4 sm:px-0 mb-4">
                <h1 className="text-2xl font-semibold text-gray-900">Lead Generation Campaigns</h1>
                <p className="mt-1 text-sm text-gray-600">Create and monitor your lead acquisition campaigns.</p>
              </div>
              
              {/* Campaign Cards */}
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 mb-6">
                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="text-lg font-medium text-gray-900 mb-1">Summer Promotion</h3>
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          Active
                        </span>
                      </div>
                      <div className="bg-teal-100 text-teal-800 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium">
                        Jun 1 - Jul 31
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex justify-between items-center mb-1">
                        <span className="text-sm font-medium text-gray-500">Progress</span>
                        <span className="text-sm font-medium text-gray-900">67%</span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div className="bg-teal-500 h-2 rounded-full" style={{ width: '67%' }}></div>
                      </div>
                    </div>
                    <div className="mt-4 grid grid-cols-2 gap-4">
                      <div>
                        <span className="block text-sm font-medium text-gray-500">Goal</span>
                        <span className="block text-xl font-semibold text-gray-900">500</span>
                        <span className="block text-xs text-gray-500">qualified leads</span>
                      </div>
                      <div>
                        <span className="block text-sm font-medium text-gray-500">Current</span>
                        <span className="block text-xl font-semibold text-gray-900">336</span>
                        <span className="block text-xs text-green-600">+52 this week</span>
                      </div>
                    </div>
                    <div className="mt-5">
                      <button className="w-full inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                        View Campaign Details
                      </button>
                    </div>
                  </div>
                </div>

                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="text-lg font-medium text-gray-900 mb-1">Product Launch</h3>
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          Active
                        </span>
                      </div>
                      <div className="bg-teal-100 text-teal-800 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium">
                        Jul 15 - Aug 15
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex justify-between items-center mb-1">
                        <span className="text-sm font-medium text-gray-500">Progress</span>
                        <span className="text-sm font-medium text-gray-900">23%</span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div className="bg-teal-500 h-2 rounded-full" style={{ width: '23%' }}></div>
                      </div>
                    </div>
                    <div className="mt-4 grid grid-cols-2 gap-4">
                      <div>
                        <span className="block text-sm font-medium text-gray-500">Goal</span>
                        <span className="block text-xl font-semibold text-gray-900">750</span>
                        <span className="block text-xs text-gray-500">qualified leads</span>
                      </div>
                      <div>
                        <span className="block text-sm font-medium text-gray-500">Current</span>
                        <span className="block text-xl font-semibold text-gray-900">175</span>
                        <span className="block text-xs text-green-600">+83 this week</span>
                      </div>
                    </div>
                    <div className="mt-5">
                      <button className="w-full inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                        View Campaign Details
                      </button>
                    </div>
                  </div>
                </div>

                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="text-lg font-medium text-gray-900 mb-1">Free Trial</h3>
                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                          Active
                        </span>
                      </div>
                      <div className="bg-teal-100 text-teal-800 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium">
                        Ongoing
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex justify-between items-center mb-1">
                        <span className="text-sm font-medium text-gray-500">Month to date</span>
                        <span className="text-sm font-medium text-gray-900">85%</span>
                      </div>
                      <div className="w-full bg-gray-200 rounded-full h-2">
                        <div className="bg-teal-500 h-2 rounded-full" style={{ width: '85%' }}></div>
                      </div>
                    </div>
                    <div className="mt-4 grid grid-cols-2 gap-4">
                      <div>
                        <span className="block text-sm font-medium text-gray-500">Monthly Goal</span>
                        <span className="block text-xl font-semibold text-gray-900">200</span>
                        <span className="block text-xs text-gray-500">qualified leads</span>
                      </div>
                      <div>
                        <span className="block text-sm font-medium text-gray-500">Current</span>
                        <span className="block text-xl font-semibold text-gray-900">170</span>
                        <span className="block text-xs text-green-600">+28 this week</span>
                      </div>
                    </div>
                    <div className="mt-5">
                      <button className="w-full inline-flex justify-center items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50">
                        View Campaign Details
                      </button>
                    </div>
                  </div>
                </div>

                <div className="bg-white overflow-hidden shadow rounded-lg border border-dashed border-gray-300 flex flex-col items-center justify-center p-6">
                  <div className="h-12 w-12 rounded-full bg-teal-100 flex items-center justify-center mb-3">
                    <PlusIcon className="h-6 w-6 text-teal-600" />
                  </div>
                  <h3 className="text-lg font-medium text-gray-900 mb-1">Create New Campaign</h3>
                  <p className="text-sm text-gray-500 text-center mb-4">
                    Set up goals, tracking, and qualification rules
                  </p>
                  <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700">
                    Start New Campaign
                  </button>
                </div>
              </div>

              {/* Campaign Performance */}
              <div className="bg-white shadow rounded-lg">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Campaign Performance</h3>
                  <p className="mt-1 text-sm text-gray-500">Comparative analysis of your lead generation campaigns.</p>
                </div>
                <div className="p-6">
                  {/* Simulated chart/graphs */}
                  <div className="h-80 flex items-end space-x-4">
                    <div className="h-full flex flex-col justify-between pr-10 py-2">
                      <div className="text-xs text-gray-500">100%</div>
                      <div className="text-xs text-gray-500">75%</div>
                      <div className="text-xs text-gray-500">50%</div>
                      <div className="text-xs text-gray-500">25%</div>
                      <div className="text-xs text-gray-500">0%</div>
                    </div>
                    <div className="flex-1 flex items-end space-x-10">
                      <div className="flex-1 flex flex-col items-center">
                        <div className="relative w-full max-w-xs flex">
                          <div className="w-16 bg-teal-300 rounded-t-lg" style={{ height: '120px' }}></div>
                          <div className="w-16 bg-teal-500 rounded-t-lg ml-2" style={{ height: '180px' }}></div>
                        </div>
                        <div className="text-sm font-medium text-gray-700 mt-2">Summer Promotion</div>
                      </div>
                      <div className="flex-1 flex flex-col items-center">
                        <div className="relative w-full max-w-xs flex">
                          <div className="w-16 bg-teal-300 rounded-t-lg" style={{ height: '220px' }}></div>
                          <div className="w-16 bg-teal-500 rounded-t-lg ml-2" style={{ height: '80px' }}></div>
                        </div>
                        <div className="text-sm font-medium text-gray-700 mt-2">Product Launch</div>
                      </div>
                      <div className="flex-1 flex flex-col items-center">
                        <div className="relative w-full max-w-xs flex">
                          <div className="w-16 bg-teal-300 rounded-t-lg" style={{ height: '150px' }}></div>
                          <div className="w-16 bg-teal-500 rounded-t-lg ml-2" style={{ height: '140px' }}></div>
                        </div>
                        <div className="text-sm font-medium text-gray-700 mt-2">Free Trial</div>
                      </div>
                      <div className="flex-1 flex flex-col items-center">
                        <div className="relative w-full max-w-xs flex">
                          <div className="w-16 bg-teal-300 rounded-t-lg" style={{ height: '90px' }}></div>
                          <div className="w-16 bg-teal-500 rounded-t-lg ml-2" style={{ height: '200px' }}></div>
                        </div>
                        <div className="text-sm font-medium text-gray-700 mt-2">Webinar Series</div>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-center mt-6 space-x-10">
                    <div className="flex items-center">
                      <div className="w-4 h-4 bg-teal-300 mr-2"></div>
                      <span className="text-sm text-gray-600">Total Leads</span>
                    </div>
                    <div className="flex items-center">
                      <div className="w-4 h-4 bg-teal-500 mr-2"></div>
                      <span className="text-sm text-gray-600">Qualified Leads</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === 'analytics' && (
            <div>
              <div className="px-4 sm:px-0 mb-4">
                <h1 className="text-2xl font-semibold text-gray-900">Lead Analytics</h1>
                <p className="mt-1 text-sm text-gray-600">Detailed insights into your lead generation performance.</p>
              </div>
              
              {/* Time Period Filter */}
              <div className="bg-white shadow rounded-lg p-4 mb-6 flex flex-wrap justify-between items-center">
                <div className="flex space-x-2">
                  <button className="px-3 py-1 bg-teal-100 text-teal-800 rounded-md text-sm font-medium">
                    Today
                  </button>
                  <button className="px-3 py-1 bg-gray-100 text-gray-800 rounded-md text-sm font-medium hover:bg-gray-200">
                    This Week
                  </button>
                  <button className="px-3 py-1 bg-gray-100 text-gray-800 rounded-md text-sm font-medium hover:bg-gray-200">
                    This Month
                  </button>
                  <button className="px-3 py-1 bg-gray-100 text-gray-800 rounded-md text-sm font-medium hover:bg-gray-200">
                    Last Quarter
                  </button>
                </div>
                <div className="flex items-center space-x-2 mt-2 sm:mt-0">
                  <label className="text-sm text-gray-600 mr-2">Custom Range:</label>
                  <input type="date" className="rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm" />
                  <span className="text-gray-500">to</span>
                  <input type="date" className="rounded-md border-gray-300 shadow-sm focus:border-teal-500 focus:ring-teal-500 sm:text-sm" />
                  <button className="px-2 py-1 bg-teal-600 text-white rounded-md text-sm font-medium hover:bg-teal-700">
                    Apply
                  </button>
                </div>
              </div>
              
              {/* Analytics Overview Cards */}
              <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-6">
                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 bg-teal-100 rounded-md p-3">
                        <Users className="h-6 w-6 text-teal-600" />
                      </div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-medium text-gray-500 truncate">Conversion Rate</dt>
                          <dd>
                            <div className="text-xl font-semibold text-gray-900">28.9%</div>
                            <div className="flex items-center mt-1">
                              <span className="text-sm text-green-600 font-medium">↑ 2.4%</span>
                              <span className="text-sm text-gray-500 ml-1">from last month</span>
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 bg-teal-100 rounded-md p-3">
                        <Clock className="h-6 w-6 text-teal-600" />
                      </div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-medium text-gray-500 truncate">Avg. Qualification Time</dt>
                          <dd>
                            <div className="text-xl font-semibold text-gray-900">3.2 days</div>
                            <div className="flex items-center mt-1">
                              <span className="text-sm text-red-600 font-medium">↑ 0.5 days</span>
                              <span className="text-sm text-gray-500 ml-1">from last month</span>
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 bg-teal-100 rounded-md p-3">
                        <PieChart className="h-6 w-6 text-teal-600" />
                      </div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-medium text-gray-500 truncate">Best Lead Source</dt>
                          <dd>
                            <div className="text-xl font-semibold text-gray-900">Webinars</div>
                            <div className="flex items-center mt-1">
                              <span className="text-sm text-green-600 font-medium">64% qualification rate</span>
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 bg-teal-100 rounded-md p-3">
                        <BarChart className="h-6 w-6 text-teal-600" />
                      </div>
                      <div className="ml-5 w-0 flex-1">
                        <dl>
                          <dt className="text-sm font-medium text-gray-500 truncate">Cost per Lead</dt>
                          <dd>
                            <div className="text-xl font-semibold text-gray-900">$32.17</div>
                            <div className="flex items-center mt-1">
                              <span className="text-sm text-green-600 font-medium">↓ $5.44</span>
                              <span className="text-sm text-gray-500 ml-1">from last month</span>
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Lead Quality Distribution Chart */}
              <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 mb-6">
                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Lead Acquisition Trend</h3>
                  </div>
                  <div className="px-4 py-5 sm:p-6">
                    {/* Simulated Line Chart */}
                    <div className="h-64 relative">
                      {/* Y-axis labels */}
                      <div className="absolute left-0 inset-y-0 flex flex-col justify-between text-xs text-gray-500 py-2">
                        <div>100</div>
                        <div>75</div>
                        <div>50</div>
                        <div>25</div>
                        <div>0</div>
                      </div>
                      
                      {/* Grid lines */}
                      <div className="absolute left-8 right-0 inset-y-0">
                        <div className="h-full flex flex-col justify-between">
                          <div className="border-b border-gray-200 h-0"></div>
                          <div className="border-b border-gray-200 h-0"></div>
                          <div className="border-b border-gray-200 h-0"></div>
                          <div className="border-b border-gray-200 h-0"></div>
                          <div className="border-b border-gray-200 h-0"></div>
                        </div>
                      </div>
                      
                      {/* Chart lines */}
                      <div className="absolute left-10 right-0 top-0 bottom-5">
                        {/* Total leads line */}
                        <svg viewBox="0 0 100 100" preserveAspectRatio="none" className="absolute inset-0 h-full w-full">
                          <path 
                            d="M0,80 L10,75 L20,60 L30,65 L40,50 L50,30 L60,35 L70,20 L80,25 L90,15 L100,10" 
                            fill="none" 
                            stroke="#38B2AC" 
                            strokeWidth="2"
                          />
                        </svg>
                        
                        {/* Qualified leads line */}
                        <svg viewBox="0 0 100 100" preserveAspectRatio="none" className="absolute inset-0 h-full w-full">
                          <path 
                            d="M0,90 L10,85 L20,80 L30,75 L40,70 L50,60 L60,65 L70,50 L80,55 L90,45 L100,40" 
                            fill="none" 
                            stroke="#805AD5" 
                            strokeWidth="2"
                          />
                        </svg>
                      </div>
                      
                      {/* X-axis labels */}
                      <div className="absolute left-10 right-0 bottom-0 flex justify-between text-xs text-gray-500">
                        <div>Jan</div>
                        <div>Feb</div>
                        <div>Mar</div>
                        <div>Apr</div>
                        <div>May</div>
                        <div>Jun</div>
                        <div>Jul</div>
                        <div>Aug</div>
                        <div>Sep</div>
                        <div>Oct</div>
                        <div>Nov</div>
                      </div>
                    </div>
                    
                    {/* Legend */}
                    <div className="flex justify-center space-x-6 mt-4">
                      <div className="flex items-center">
                        <span className="h-3 w-3 bg-teal-500 rounded-full mr-2"></span>
                        <span className="text-sm text-gray-700">Total Leads</span>
                      </div>
                      <div className="flex items-center">
                        <span className="h-3 w-3 bg-purple-500 rounded-full mr-2"></span>
                        <span className="text-sm text-gray-700">Qualified Leads</span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="bg-white overflow-hidden shadow rounded-lg">
                  <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Lead Source Effectiveness</h3>
                  </div>
                  <div className="px-4 py-5 sm:p-6">
                    <div className="space-y-4">
                      <div>
                        <div className="flex justify-between items-center mb-1">
                          <div className="flex items-center">
                            <div className="w-3 h-3 rounded-full bg-teal-500 mr-2"></div>
                            <span className="text-sm font-medium text-gray-700">Webinars</span>
                          </div>
                          <span className="text-sm font-medium text-gray-900">64%</span>
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-2">
                          <div className="bg-teal-500 h-2 rounded-full" style={{ width: '64%' }}></div>
                        </div>
                      </div>
                      
                      <div>
                        <div className="flex justify-between items-center mb-1">
                          <div className="flex items-center">
                            <div className="w-3 h-3 rounded-full bg-blue-500 mr-2"></div>
                            <span className="text-sm font-medium text-gray-700">Content Downloads</span>
                          </div>
                          <span className="text-sm font-medium text-gray-900">52%</span>
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-2">
                          <div className="bg-blue-500 h-2 rounded-full" style={{ width: '52%' }}></div>
                        </div>
                      </div>
                      
                      <div>
                        <div className="flex justify-between items-center mb-1">
                          <div className="flex items-center">
                            <div className="w-3 h-3 rounded-full bg-purple-500 mr-2"></div>
                            <span className="text-sm font-medium text-gray-700">Referrals</span>
                          </div>
                          <span className="text-sm font-medium text-gray-900">48%</span>
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-2">
                          <div className="bg-purple-500 h-2 rounded-full" style={{ width: '48%' }}></div>
                        </div>
                      </div>
                      
                      <div>
                        <div className="flex justify-between items-center mb-1">
                          <div className="flex items-center">
                            <div className="w-3 h-3 rounded-full bg-yellow-500 mr-2"></div>
                            <span className="text-sm font-medium text-gray-700">Email Campaigns</span>
                          </div>
                          <span className="text-sm font-medium text-gray-900">39%</span>
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-2">
                          <div className="bg-yellow-500 h-2 rounded-full" style={{ width: '39%' }}></div>
                        </div>
                      </div>
                      
                      <div>
                        <div className="flex justify-between items-center mb-1">
                          <div className="flex items-center">
                            <div className="w-3 h-3 rounded-full bg-green-500 mr-2"></div>
                            <span className="text-sm font-medium text-gray-700">Organic Search</span>
                          </div>
                          <span className="text-sm font-medium text-gray-900">32%</span>
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-2">
                          <div className="bg-green-500 h-2 rounded-full" style={{ width: '32%' }}></div>
                        </div>
                      </div>
                      
                      <div>
                        <div className="flex justify-between items-center mb-1">
                          <div className="flex items-center">
                            <div className="w-3 h-3 rounded-full bg-red-500 mr-2"></div>
                            <span className="text-sm font-medium text-gray-700">Paid Advertising</span>
                          </div>
                          <span className="text-sm font-medium text-gray-900">27%</span>
                        </div>
                        <div className="w-full bg-gray-200 rounded-full h-2">
                          <div className="bg-red-500 h-2 rounded-full" style={{ width: '27%' }}></div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="mt-4 pt-4 border-t border-gray-200 text-sm text-gray-500">
                      Percentage represents lead qualification rate from each source.
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Lead Scoring Analysis */}
              <div className="bg-white shadow rounded-lg">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200 flex justify-between">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Lead Scoring Analysis</h3>
                    <p className="mt-1 text-sm text-gray-500">Breakdown of lead scores for better qualification insights.</p>
                  </div>
                  <button className="text-teal-600 hover:text-teal-700 font-medium text-sm">
                    Adjust Scoring Model
                  </button>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Score Range
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Lead Count
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Qualification Rate
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Avg. Time to Qualify
                          </th>
                          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Top Source
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white divide-y divide-gray-200">
                        <tr>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="h-3 w-3 rounded-full bg-teal-500 mr-2"></div>
                              <span className="text-sm font-medium text-gray-900">90-100</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            156
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">97%</div>
                            <div className="w-24 bg-gray-200 rounded-full h-2">
                              <div className="bg-teal-500 h-2 rounded-full" style={{ width: '97%' }}></div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            1.2 days
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            Webinar Registrations
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="h-3 w-3 rounded-full bg-teal-400 mr-2"></div>
                              <span className="text-sm font-medium text-gray-900">80-89</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            284
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">85%</div>
                            <div className="w-24 bg-gray-200 rounded-full h-2">
                              <div className="bg-teal-500 h-2 rounded-full" style={{ width: '85%' }}></div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            2.5 days
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            Content Downloads
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="h-3 w-3 rounded-full bg-yellow-400 mr-2"></div>
                              <span className="text-sm font-medium text-gray-900">70-79</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            347
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">62%</div>
                            <div className="w-24 bg-gray-200 rounded-full h-2">
                              <div className="bg-yellow-400 h-2 rounded-full" style={{ width: '62%' }}></div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            4.1 days
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            Email Campaigns
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="h-3 w-3 rounded-full bg-yellow-500 mr-2"></div>
                              <span className="text-sm font-medium text-gray-900">60-69</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            412
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">38%</div>
                            <div className="w-24 bg-gray-200 rounded-full h-2">
                              <div className="bg-yellow-500 h-2 rounded-full" style={{ width: '38%' }}></div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            5.3 days
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            Social Media
                          </td>
                        </tr>
                        <tr>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="h-3 w-3 rounded-full bg-red-400 mr-2"></div>
                              <span className="text-sm font-medium text-gray-900">Below 60</span>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            283
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">8%</div>
                            <div className="w-24 bg-gray-200 rounded-full h-2">
                              <div className="bg-red-400 h-2 rounded-full" style={{ width: '8%' }}></div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            9.7 days
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            Paid Advertising
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

// Simple Plus icon component for the create new campaign card
const PlusIcon = ({ className }) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    className={className}
    fill="none" 
    viewBox="0 0 24 24" 
    stroke="currentColor"
  >
    <path 
      strokeLinecap="round" 
      strokeLinejoin="round" 
      strokeWidth={2} 
      d="M12 4v16m8-8H4" 
    />
  </svg>
);

export default SaasDemo;