// src/components/Landing/LandingPage.js
import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Bot,
  Shield,
  Users,
  Brain,
  MessageSquare,
  Image,
  FileAudio,
  Zap,
  ChevronRight,
  Sparkles,
  ArrowRight,
  Star,
  CheckCircle,
  Code,
  Music,
  FolderPlus,
  Lock,
  PieChart,
  Smartphone,
  Globe,
  Smile,
  Layers,
  ArrowLeftRight,
  AlertTriangle,
  Lightbulb,
  Search,
  XCircle,
  FileText,
  Clock,
  AudioLines,
  Play,
  ChevronDown,
  Menu,
  X,
  GitFork
} from 'lucide-react';
import { pricingTiers } from '../Pricing/PricingData';

// New component for anchor navigation
const AnchorNavigation = ({ activeSection }) => {
  const sections = [
    { id: 'hero', label: 'Home' },
    { id: 'pricing', label: 'Pricing' },
    { id: 'models', label: 'AI Models' },
    { id: 'document-analysis', label: 'Document Analysis' },
    { id: 'content-analysis', label: 'Content Analysis' },
    { id: 'code-context', label: 'Code Context' },
    { id: 'search-comparison', label: 'Search' },
    { id: 'features', label: 'Features' },
    { id: 'faq', label: 'FAQ' }
  ];

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const navHeight = 80;
      const sectionTop = section.offsetTop - navHeight;
      window.scrollTo({
        top: sectionTop,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="fixed left-4 top-1/2 transform -translate-y-1/2 z-40 hidden lg:block">
      <div className="bg-[#2a2a2a] rounded-lg p-2 shadow-lg shadow-black/20">
        <ul className="space-y-1">
          {sections.map((section) => (
            <li key={section.id}>
              <button
                onClick={() => scrollToSection(section.id)}
                className={`p-2 rounded-md transition-all duration-200 text-sm ${
                  activeSection === section.id
                    ? 'bg-orange-500 text-white'
                    : 'text-gray-400 hover:text-white hover:bg-[#3a3a3a]'
                }`}
              >
                {section.label}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const SimplifiedPricing = () => {
  const [billingCycle, setBillingCycle] = useState('yearly');
  const [showFullPricing, setShowFullPricing] = useState(false);
  
  // Filter for just the main tiers
  const mainTiers = pricingTiers.filter(tier => 
    ['Personal', 'Personal+', 'Pro'].includes(tier.name)
  );
  
  // Team tiers
  const teamTiers = pricingTiers.filter(tier => 
    ['Team', 'Team+', 'Enterprise'].includes(tier.name)
  );

  return (
    <section id="pricing" className="py-20 px-6 bg-[#1a1a1a] border-t border-[#2a2a2a]">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent">
            Simple, Transparent Pricing
          </h2>
          <p className="text-xl text-gray-400 mb-8">
            Choose the plan that works best for your needs
          </p>
          
          {/* Billing Toggle */}
          <div className="flex items-center justify-center gap-4 mt-6 mb-12">
            <button
              onClick={() => setBillingCycle('monthly')}
              className={`px-4 py-2 rounded-lg transition-all duration-200 ${
                billingCycle === 'monthly'
                  ? 'bg-[#2a2a2a] text-white'
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              Monthly
            </button>
            <div className="relative">
              <button
                onClick={() => setBillingCycle('yearly')}
                className={`px-4 py-2 rounded-lg transition-all duration-200 ${
                  billingCycle === 'yearly'
                    ? 'bg-[#2a2a2a] text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                Yearly
              </button>
              <span className="absolute -top-4 right-0 text-xs text-orange-500 font-medium">
                Save 20%
              </span>
            </div>
          </div>
        </div>

        {/* Main Pricing Cards */}
        <div className="grid md:grid-cols-3 gap-8 mx-auto max-w-5xl">
          {mainTiers.map((tier) => {
            const price = billingCycle === 'yearly' ? tier.yearlyPrice : tier.monthlyPrice;
            return (
              <div
                key={tier.name}
                className={`
                  relative rounded-xl bg-[#2a2a2a] border p-6
                  transition-all duration-300
                  ${tier.highlight
                    ? 'border-orange-500 shadow-lg shadow-orange-500/10'
                    : 'border-[#3a3a3a] hover:border-[#4a4a4a]'
                  }
                `}
              >
                {tier.highlight && (
                  <div className="absolute -top-4 left-0 right-0 flex justify-center">
                    <span className="bg-orange-500 text-white px-3 py-1 rounded-full text-sm">
                      Most Popular
                    </span>
                  </div>
                )}
                <div className="flex items-center gap-3 mb-4">
                  {tier.icon}
                  <h3 className="text-xl font-bold">{tier.name}</h3>
                </div>
                <div className="mb-6">
                  <span className="text-3xl font-bold">${price}</span>
                  <span className="text-gray-400 ml-2">/mo</span>
                  {billingCycle === 'yearly' && (
                    <span className="text-sm text-orange-500 ml-2">
                      (billed yearly)
                    </span>
                  )}
                  <p className="text-gray-400 mt-2">{tier.description}</p>
                </div>
                {/* Key Features */}
                <ul className="mb-6 space-y-2">
                  {tier.features.slice(0, 4).map((feature, index) => (
                    <li key={index} className="flex items-center gap-2 text-sm">
                      <CheckCircle className="w-4 h-4 text-orange-500" />
                      <span className="text-gray-300">{feature.name}</span>
                    </li>
                  ))}
                </ul>
                <button 
                  className={`block w-full py-3 px-4 rounded-lg font-medium text-center
                    transition-all duration-200
                    ${tier.highlight
                      ? 'bg-orange-500 hover:bg-orange-600 text-white'
                      : 'bg-[#3a3a3a] hover:bg-[#404040] text-gray-200'
                    }`}
                  onClick={() => window.open('/register', '_blank')}
                >
                  Get Started
                </button>
              </div>
            );
          })}
        </div>
        
        <div className="mt-8 text-center">
          <button 
            onClick={() => setShowFullPricing(!showFullPricing)}
            className="text-orange-500 hover:text-orange-400 inline-flex items-center transition-colors"
          >
            {showFullPricing ? "Hide detailed pricing" : "See team plans and detailed pricing"} 
            <ChevronDown className={`ml-2 w-4 h-4 transition-transform duration-300 ${showFullPricing ? 'rotate-180' : ''}`} />
          </button>
        </div>
        
        {/* Expanded Pricing Details */}
        <div className={`transition-all duration-500 overflow-hidden ${showFullPricing ? 'max-h-[2000px] opacity-100 mt-12' : 'max-h-0 opacity-0'}`}>
          {/* Team Plans */}
          <h3 className="text-2xl font-semibold mb-8 text-center">Team & Enterprise Plans</h3>
          <div className="grid md:grid-cols-3 gap-8 mx-auto max-w-5xl mb-12">
            {teamTiers.map((tier) => {
              const price = billingCycle === 'yearly' ? tier.yearlyPrice : tier.monthlyPrice;
              return (
                <div
                  key={tier.name}
                  className={`
                    relative rounded-xl bg-[#2a2a2a] border p-6
                    transition-all duration-300
                    ${tier.highlight
                      ? 'border-orange-500 shadow-lg shadow-orange-500/10'
                      : 'border-[#3a3a3a] hover:border-[#4a4a4a]'
                    }
                  `}
                >
                  <div className="flex items-center gap-3 mb-4">
                    {tier.icon}
                    <h3 className="text-xl font-bold">{tier.name}</h3>
                  </div>
                  <div className="mb-6">
                    {tier.name === 'Enterprise' ? (
                      <span className="text-2xl font-bold">Custom</span>
                    ) : (
                      <>
                        <span className="text-3xl font-bold">${price}</span>
                        <span className="text-gray-400 ml-2">/mo</span>
                        {billingCycle === 'yearly' && (
                          <span className="text-sm text-orange-500 ml-2">
                            (billed yearly)
                          </span>
                        )}
                      </>
                    )}
                    <p className="text-gray-400 mt-2">{tier.description}</p>
                  </div>
                  {/* Key Features */}
                  <ul className="mb-6 space-y-2">
                    {tier.features.slice(0, 4).map((feature, index) => (
                      <li key={index} className="flex items-center gap-2 text-sm">
                        <CheckCircle className="w-4 h-4 text-orange-500" />
                        <span className="text-gray-300">{feature.name}</span>
                      </li>
                    ))}
                  </ul>
                  <button 
                    className={`block w-full py-3 px-4 rounded-lg font-medium text-center
                      transition-all duration-200
                      ${tier.highlight
                        ? 'bg-orange-500 hover:bg-orange-600 text-white'
                        : 'bg-[#3a3a3a] hover:bg-[#404040] text-gray-200'
                      }`}
                    onClick={() => {
                      if (tier.name === 'Enterprise') {
                        window.location.href = 'mailto:henosischat@gmail.com?subject=Enterprise Plan Inquiry';
                      } else {
                        window.open('/register', '_blank');
                      }
                    }}
                  >
                    {tier.name === 'Enterprise' ? 'Contact Sales' : 'Get Started'}
                  </button>
                </div>
              );
            })}
          </div>
          
          {/* Pricing Details & Model Usage */}
          <div className="bg-[#2a2a2a] p-8 rounded-lg max-w-5xl mx-auto">
            <h3 className="text-xl font-semibold mb-6">Token Usage & Model Comparisons</h3>
            <div className="overflow-x-auto">
              <table className="w-full text-left">
                <thead>
                  <tr className="border-b border-[#3a3a3a]">
                    <th className="pb-4 pr-4 font-medium">Plan</th>
                    <th className="pb-4 px-4 font-medium">Price</th>
                    <th className="pb-4 px-4 font-medium">Efficient Models<br/><span className="text-xs text-gray-400">(Gemini Flash)</span></th>
                    <th className="pb-4 px-4 font-medium">Standard Models<br/><span className="text-xs text-gray-400">(Claude 3.5 Sonnet)</span></th>
                    <th className="pb-4 pl-4 font-medium">Team Members</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="border-b border-[#3a3a3a]">
                    <td className="py-4 pr-4">Personal</td>
                    <td className="py-4 px-4">${billingCycle === 'yearly' ? '8' : '10'}/mo</td>
                    <td className="py-4 px-4">~190M tokens</td>
                    <td className="py-4 px-4">~4.8M tokens</td>
                    <td className="py-4 pl-4">1</td>
                  </tr>
                  <tr className="border-b border-[#3a3a3a]">
                    <td className="py-4 pr-4">Personal+</td>
                    <td className="py-4 px-4">${billingCycle === 'yearly' ? '32' : '40'}/mo</td>
                    <td className="py-4 px-4">~760M tokens</td>
                    <td className="py-4 px-4">~19.2M tokens</td>
                    <td className="py-4 pl-4">1</td>
                  </tr>
                  <tr className="border-b border-[#3a3a3a]">
                    <td className="py-4 pr-4">Pro</td>
                    <td className="py-4 px-4">${billingCycle === 'yearly' ? '72' : '90'}/mo</td>
                    <td className="py-4 px-4">~1.7B tokens</td>
                    <td className="py-4 px-4">~43.2M tokens</td>
                    <td className="py-4 pl-4">1</td>
                  </tr>
                  <tr className="border-b border-[#3a3a3a]">
                    <td className="py-4 pr-4">Team</td>
                    <td className="py-4 px-4">${billingCycle === 'yearly' ? '160' : '200'}/mo</td>
                    <td className="py-4 px-4">~3.8B tokens</td>
                    <td className="py-4 px-4">~96M tokens</td>
                    <td className="py-4 pl-4">15</td>
                  </tr>
                  <tr>
                    <td className="py-4 pr-4">Team+</td>
                    <td className="py-4 px-4">${billingCycle === 'yearly' ? '320' : '400'}/mo</td>
                    <td className="py-4 px-4">~7.6B tokens</td>
                    <td className="py-4 px-4">~192M tokens</td>
                    <td className="py-4 pl-4">50</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="mt-6 text-sm text-gray-400">
              <p>* Token usage estimates are approximate and will vary based on the specific models used.</p>
              <p>* All plans include access to all available AI models with their full context lengths.</p>
              <p>* Additional tokens can be purchased on top of your plan if needed.</p>
            </div>
          </div>
          
          {/* All plans include section */}
          <div className="mt-12 bg-[#2a2a2a] p-6 rounded-lg max-w-5xl mx-auto">
            <h3 className="text-xl font-semibold mb-4">All plans include:</h3>
            <div className="grid md:grid-cols-3 gap-6">
              <div className="flex items-start space-x-3">
                <CheckCircle className="w-5 h-5 text-orange-500 mt-0.5" />
                <div>
                  <p className="font-medium">Access to all models</p>
                  <p className="text-sm text-gray-400">From Claude to GPT-4o to Gemini</p>
                </div>
              </div>
              <div className="flex items-start space-x-3">
                <CheckCircle className="w-5 h-5 text-orange-500 mt-0.5" />
                <div>
                  <p className="font-medium">Full context windows</p>
                  <p className="text-sm text-gray-400">No artificial token limits</p>
                </div>
              </div>
              <div className="flex items-start space-x-3">
                <CheckCircle className="w-5 h-5 text-orange-500 mt-0.5" />
                <div>
                  <p className="font-medium">Multi-modal capabilities</p>
                  <p className="text-sm text-gray-400">Text, image, and audio processing</p>
                </div>
              </div>
            </div>
            <div className="mt-4 p-4 bg-[#1a1a1a] rounded-lg text-sm text-gray-400">
              Need more tokens? You can buy additional tokens on top of your plan at any time to extend your usage.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const DocumentAnalysisSection = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  return (
    <section id="document-analysis" className="py-20 px-6 bg-[#1a1a1a] border-t border-[#2a2a2a]">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent">
            Turn Documents Into Complete Insights
          </h2>
          <p className="text-xl text-gray-400">
            Tired of unreliable document analysis? When AI sees everything, you get actionable insights.
          </p>
        </div>
        
        <div className="grid lg:grid-cols-2 gap-12">
          {/* Henosis Example */}
          <div className="bg-[#2a2a2a] p-8 rounded-lg hover:bg-[#333333] transition-all duration-200">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <img src="/output.webp" alt="Henosis Logo" className="h-6 w-6 mr-2" />
                <h3 className="font-semibold">Henosis Analysis</h3>
              </div>
              <span className="text-green-500 text-sm">Full Document Access</span>
            </div>
            <div className="space-y-4 mb-6">
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <span className="text-gray-400">User Query</span>
                </div>
                <div className="flex items-center space-x-2 mb-2">
                  <FileText className="w-4 h-4 text-blue-400" />
                  <span className="text-blue-400">genesis.txt</span>
                </div>
                <div className="text-white">What are the key differences between Chapter 1 and the final chapter of this document?</div>
              </div>
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  <span className="text-gray-400">Analysis Result</span>
                </div>
                <div className="text-gray-300">
                  <p>Analyzing complete document structure:</p>
                  <div className="mt-3 space-y-2">
                    <div className="text-sm">
                      <span className="text-blue-400">Chapter 1:</span>
                      <p>Focuses on creation narrative with structured day-by-day format...</p>
                    </div>
                    <div className="text-sm">
                      <span className="text-blue-400">Final Chapter:</span>
                      <p>Shifts to narrative style describing consequences and outcomes...</p>
                    </div>
                    <div className="mt-4 text-sm">
                      <p>Key Thematic Evolution:</p>
                      <ul className="list-disc pl-4">
                        <li>Opening establishes order and creation</li>
                        <li>Closing addresses human condition</li>
                        <li>Complete narrative arc visible across chapters</li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-3 text-sm text-green-500">
                    Analyzed: Full document content (200K+ tokens)
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-3">
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Full document access</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Complete context</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Actionable insights</span>
              </div>
            </div>
          </div>
          
          {/* Perplexity Example */}
          <div className="bg-[#2a2a2a] p-8 rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <img src="/perplexity_logo.webp" alt="Perplexity Logo" className="h-6 w-6 mr-2" />
                <h3 className="font-semibold">Perplexity Pro</h3>
              </div>
              <span className="text-yellow-500 text-sm">32K Token Limit</span>
            </div>
            <div className="space-y-4 mb-6">
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <span className="text-gray-400">User Query</span>
                </div>
                <div className="flex items-center space-x-2 mb-2">
                  <FileText className="w-4 h-4 text-blue-400" />
                  <span className="text-blue-400">genesis.txt</span>
                </div>
                <div className="text-white">What are the key differences between Chapter 1 and the final chapter of this document?</div>
              </div>
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <AlertTriangle className="w-4 h-4 text-yellow-500" />
                  <span className="text-gray-400">Analysis Result</span>
                </div>
                <div className="text-gray-300">
                  <p>"I can only access portions of the document that match your query. For a complete chapter comparison, please share relevant sections from both chapters."</p>
                  <div className="mt-3">
                    <div className="text-sm">
                      <p>Limitations of current access:</p>
                      <ul className="list-disc pl-4 text-yellow-500">
                        <li>Content must be specifically provided or searchable</li>
                        <li>Requires multiple queries for comprehensive analysis</li>
                        <li>May miss subtle thematic connections across content</li>
                        <li>Limited to 32K tokens per query, restricting full document analysis</li>
                      </ul>
                    </div>
                  </div>
                  <div className="mt-3 text-sm text-yellow-500">
                    Note: RAG retrieves relevant snippets based on query, but cannot analyze the full document.
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-3">
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Multiple queries needed</span>
              </div>
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Context must be explicit</span>
              </div>
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Token limit restricts full analysis</span>
              </div>
            </div>
          </div>
        </div>
        
        <div className="mt-12 bg-[#2a2a2a] p-6 rounded-lg">
          <div className="mb-4">
            <h3 className="text-xl font-semibold mb-2">Why Full Context Matters</h3>
            <p className="text-gray-400">Complete document analysis unlocks insights that partial analysis misses</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h4 className="font-medium mb-4 text-green-500 flex items-center">
                <CheckCircle className="w-5 h-5 mr-2" />
                With Complete Context
              </h4>
              <ul className="text-gray-400 space-y-4">
                <li className="flex items-start">
                  <div className="bg-green-500/10 p-2 rounded mr-3 mt-1">
                    <CheckCircle className="w-4 h-4 text-green-500" />
                  </div>
                  <div>
                    <span className="font-medium text-gray-300">Make confident decisions</span>
                    <p className="text-sm mt-1">Base your actions on the entire document, not just fragments</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="bg-green-500/10 p-2 rounded mr-3 mt-1">
                    <CheckCircle className="w-4 h-4 text-green-500" />
                  </div>
                  <div>
                    <span className="font-medium text-gray-300">Discover hidden connections</span>
                    <p className="text-sm mt-1">Identify patterns and insights others miss with partial analysis</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="bg-green-500/10 p-2 rounded mr-3 mt-1">
                    <CheckCircle className="w-4 h-4 text-green-500" />
                  </div>
                  <div>
                    <span className="font-medium text-gray-300">Save time with one-shot answers</span>
                    <p className="text-sm mt-1">Get comprehensive responses without back-and-forth queries</p>
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-medium mb-4 text-yellow-500 flex items-center">
                <AlertTriangle className="w-5 h-5 mr-2" />
                With Limited Context
              </h4>
              <ul className="text-gray-400 space-y-4">
                <li className="flex items-start">
                  <div className="bg-yellow-500/10 p-2 rounded mr-3 mt-1">
                    <AlertTriangle className="w-4 h-4 text-yellow-500" />
                  </div>
                  <div>
                    <span className="font-medium text-gray-300">Risk missing critical connections</span>
                    <p className="text-sm mt-1">Important links between sections remain hidden with fragmented analysis</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="bg-yellow-500/10 p-2 rounded mr-3 mt-1">
                    <AlertTriangle className="w-4 h-4 text-yellow-500" />
                  </div>
                  <div>
                    <span className="font-medium text-gray-300">Waste time with multiple queries</span>
                    <p className="text-sm mt-1">Constantly modify and repeat questions to build complete understanding</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="bg-yellow-500/10 p-2 rounded mr-3 mt-1">
                    <AlertTriangle className="w-4 h-4 text-yellow-500" />
                  </div>
                  <div>
                    <span className="font-medium text-gray-300">Struggle with piecemeal insights</span>
                    <p className="text-sm mt-1">Draw conclusions based on incomplete information</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ContentAnalysisSection = () => {
  return (
    <section id="content-analysis" className="py-20 px-6 bg-[#1a1a1a] border-t border-[#2a2a2a]">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent">
            Complete Media Understanding
          </h2>
          <p className="text-xl text-gray-400">
            Compare how AI assistants analyze long-form audio and video content,
            from quick summaries to deep insights
          </p>
        </div>
        
        <div className="grid lg:grid-cols-2 gap-12">
          {/* Henosis Example */}
          <div className="bg-[#2a2a2a] p-8 rounded-lg hover:bg-[#333333] transition-all duration-200">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <img src="/output.webp" alt="Henosis Logo" className="h-6 w-6 mr-2" />
                <h3 className="font-semibold">Henosis Analysis</h3>
              </div>
              <span className="text-green-500 text-sm">Full Transcript Access</span>
            </div>
            <div className="space-y-4 mb-6">
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <FileText className="w-4 h-4 text-gray-400" />
                  <span className="text-gray-400">User Query</span>
                </div>
                <div className="text-white">
                  https://youtu.be/0CBMlzHo0AU summarize this podcast
                </div>
              </div>
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  <span className="text-gray-400">Analysis Result</span>
                </div>
                <div className="text-gray-300 space-y-3">
                  <p>The podcast discusses various topics, focusing on the political landscape following the 2024 U.S. presidential elections:</p>
                  
                  <div className="space-y-4 pl-4">
                    <div>
                      <div className="text-blue-400">Host Insights</div>
                      <p className="text-sm">All-in Podcast crew (Jason Calacanis, Chamath Palihapitiya, David Sacks, David Friedberg) discuss topics with insider perspectives. Includes Chamath's global tour experiences.</p>
                    </div>
                    <div>
                      <div className="text-blue-400">Political Impact</div>
                      <p className="text-sm">Analysis of Trump's victory effects on markets, especially crypto and finance sectors, with expected deregulatory policies.</p>
                    </div>
                    <div>
                      <div className="text-blue-400">Cabinet & Policy</div>
                      <p className="text-sm">Detailed discussion of key nominees like Tulsi Gabbard and Matt Gaetz, reflecting strategy to reform government bureaucracy.</p>
                    </div>
                    <div>
                      <div className="text-blue-400">Economic Outlook</div>
                      <p className="text-sm">Coverage of potential IPOs, market adaptations, and broader economic implications under the new administration.</p>
                    </div>
                    <div>
                      <div className="text-blue-400">Industry Analysis</div>
                      <p className="text-sm">Discussion of pharmaceutical advertising influence, prediction markets controversy, and strategies for economic growth.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-3">
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Complete content understanding</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Detailed context and examples</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Nuanced topic analysis</span>
              </div>
            </div>
          </div>
          
          {/* Perplexity Example */}
          <div className="bg-[#2a2a2a] p-8 rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <img src="/perplexity_logo.webp" alt="Perplexity Logo" className="h-6 w-6 mr-2" />
                <h3 className="font-semibold">Perplexity Pro</h3>
              </div>
              <span className="text-yellow-500 text-sm">Description Only</span>
            </div>
            <div className="space-y-4 mb-6">
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <FileText className="w-4 h-4 text-gray-400" />
                  <span className="text-gray-400">User Query</span>
                </div>
                <div className="text-white">
                  https://youtu.be/0CBMlzHo0AU summarize this podcast
                </div>
              </div>
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <AlertTriangle className="w-4 h-4 text-yellow-500" />
                  <span className="text-gray-400">Analysis Result</span>
                </div>
                <div className="text-gray-300 space-y-3">
                  <p>The podcast episode from the All-In Podcast covers various current events and topics:</p>
                  
                  <div className="space-y-4 pl-4">
                    <div>
                      <div className="flex items-center text-gray-400">
                        <Clock className="w-3 h-3 mr-2" />
                        <span className="text-sm">0:00</span>
                      </div>
                      <p className="text-sm">Bestie Intros: The hosts introduce themselves and set the tone for the episode.</p>
                    </div>
                    <div>
                      <div className="flex items-center text-gray-400">
                        <Clock className="w-3 h-3 mr-2" />
                        <span className="text-sm">7:57</span>
                      </div>
                      <p className="text-sm">Election Impact on Bitcoin, Crypto, and Fintech Stocks: Discussion of recent election results' effects.</p>
                    </div>
                    <div>
                      <div className="flex items-center text-gray-400">
                        <Clock className="w-3 h-3 mr-2" />
                        <span className="text-sm">21:56</span>
                      </div>
                      <p className="text-sm">Mergers & Acquisitions (M&A) and Initial Public Offerings (IPOs) in 2025: Future predictions.</p>
                    </div>
                    <div>
                      <div className="flex items-center text-gray-400">
                        <Clock className="w-3 h-3 mr-2" />
                        <span className="text-sm">39:52</span>
                      </div>
                      <p className="text-sm">Pharma Advertising on Cable TV: Debate on pharmaceutical advertising influence.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-3">
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Limited to video description</span>
              </div>
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Surface-level information only</span>
              </div>
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Missing key context and details</span>
              </div>
            </div>
          </div>
        </div>
        
        {/* Additional content insight */}
        <div className="mt-12 bg-[#2a2a2a] p-6 rounded-lg">
          <h3 className="text-xl font-semibold mb-4">Process Any Media Format</h3>
          <div className="grid md:grid-cols-3 gap-6">
            <div className="bg-[#1a1a1a] p-4 rounded-lg">
              <div className="flex items-center mb-4">
                <FileAudio className="w-6 h-6 text-orange-500 mr-3" />
                <h4 className="font-medium">Audio Files</h4>
              </div>
              <ul className="text-sm text-gray-400 space-y-2 pl-4 list-disc">
                <li>Process podcasts and audio interviews</li>
                <li>Support up to 9.5 hours of audio</li>
                <li>Automatic speaker identification</li>
                <li>Full transcript and highlights</li>
              </ul>
            </div>
            <div className="bg-[#1a1a1a] p-4 rounded-lg">
              <div className="flex items-center mb-4">
                <FileText className="w-6 h-6 text-orange-500 mr-3" />
                <h4 className="font-medium">Video Content</h4>
              </div>
              <ul className="text-sm text-gray-400 space-y-2 pl-4 list-disc">
                <li>Analyze YouTube videos and lectures</li>
                <li>Topic breakdown and summary</li>
                <li>Pull key insights instantly</li>
                <li>Content navigation with timestamps</li>
              </ul>
            </div>
            <div className="bg-[#1a1a1a] p-4 rounded-lg">
              <div className="flex items-center mb-4">
                <Layers className="w-6 h-6 text-orange-500 mr-3" />
                <h4 className="font-medium">Document Analysis</h4>
              </div>
              <ul className="text-sm text-gray-400 space-y-2 pl-4 list-disc">
                <li>Process files up to 100MB</li>
                <li>Complete document understanding</li>
                <li>Extract relationships across content</li>
                <li>Generate executive summaries</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
const modelsData = [
  // 1. Hot / Leading Edge 🔥
  { value: 'claude-3-7-sonnet-20250219', label: 'Claude 3.7 Sonnet', provider: 'Anthropic', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡', cost: '$$$', tags: ['✨', '</>', '🎨👑', '💬'], description: 'Excellent coding capabilities, excels at UI generation, strong mathematical reasoning and planning. Suitable for nuanced conversations.', notes: 'Higher cost model.', inputContextLength: 200000, outputContextLength: 128000, outputNote: '' },
  { value: 'claude-3-7-sonnet-thinking', label: 'Claude 3.7 Sonnet Thinking', provider: 'Anthropic', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡', cost: '$$$', tags: ['✨', '</>', '🎨👑', '💬', '📝', '🧠'], description: 'Enhanced version of 3.7 Sonnet. Excels at coding, UI generation, planning, and handling complex tasks and conversations. May output intermediate reasoning tokens before the final answer.', notes: 'Higher cost model. The "thinking" process may slightly increase latency.', inputContextLength: 200000, outputContextLength: 128000, outputNote: '' },
  { value: 'deepseek-chat', label: 'DeepSeek Chat', provider: 'Deepseek', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡', cost: '$', tags: ['⚙️', '</>', '🎨', '🧮', '💰', '⚠️'], description: 'Strong performance in coding, UI generation, and mathematics at a low cost.', notes: 'Both regional variants may have stricter content filtering and potential biases influenced by training data.', inputContextLength: 64000, outputContextLength: 8192 },
  { value: 'gemini-2.5-pro-exp-03-25', label: 'Gemini 2.5 Pro Latest', provider: 'Google', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡', cost: '$$', tags: ['✨', '</>', '📝', '💡'], description: 'Excellent coding, planning, and reasoning capabilities. Strong creative generation. Efficiently processes large documents and codebases.', notes: 'Mid-range cost model.', inputContextLength: 1048576, outputContextLength: 65536 }, // 1M Input, 64K Output
  { value: 'chatgpt-4o-latest', label: 'ChatGPT-4o Latest', provider: 'OpenAI', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡', cost: '$$$', tags: ['👍', '</>', '💬', '🧮', 'Vibe'], description: 'Strong coding and mathematical abilities. Adept at understanding conversational nuances. Suitable for general tasks. Engaging conversational style.', notes: 'Moderate performance regarding speed and latency. Less proficient in UI code generation compared to some peers.', inputContextLength: 128000, outputContextLength: 16384 }, // 128K Input, 16K Output
  { value: 'o3-mini', label: 'o3 Mini', provider: 'OpenAI', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡⚡', cost: '$$', tags: ['👍', '</>', '🧮', '📝', 'Web', '⚠️'], description: 'Fast response times, particularly for simpler queries. Good performance in math, instruction following, planning, and coding. Includes web access capabilities.', notes: 'May occasionally generate inaccurate information (hallucinate). Less effective for UI code generation.', inputContextLength: 200000, outputContextLength: 100000 }, // 200K Input, 100K Output

  // 2. Speed & Value ⚡💰
  { value: 'claude-3-haiku-20240307', label: 'Claude 3 Haiku 2024-03-07', provider: 'Anthropic', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚡', '💰', '📊'], description: 'Legacy model. Very high speed and very low cost. Effective for data structuring tasks.', notes: 'Limited capabilities for coding and UI generation.', inputContextLength: 200000, outputContextLength: 4096 }, // 200K Input, 4K Output
  { value: 'claude-3-5-haiku-20241022', label: 'Claude 3.5 Haiku 2024-10-22', provider: 'Anthropic', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$$', tags: ['</>', '🎨', '🗑️'], description: 'Offers reasonable coding/UI capabilities relative to its release date, with high speed.', notes: 'Performance is superseded by current Haiku/Sonnet versions. Weaker in mathematical reasoning and instruction following.', inputContextLength: 200000, outputContextLength: 8192 }, // 200K Input, 8K Output
  { value: 'command-r7b-12-2024', label: 'Command R7B 12-2024', provider: 'Cohere', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['💰', '🤔'], description: 'Very low cost. General capabilities are less advanced compared to leading models. Designed for lightweight RAG', notes: '', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'gemini-2.0-flash-001', label: 'Gemini 2.0 Flash', provider: 'Google', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚡', '💰', '</>', '📊'], description: 'Very high speed and low cost. Competent coding abilities. Strong performance in data structuring and context-based Q&A.', notes: 'Less effective for UI code generation.', inputContextLength: 1048576, outputContextLength: 8192 }, // 1M Input, 8K Output
  { value: 'gemini-2.0-flash-lite-preview', label: 'Gemini 2.0 Flash Lite Preview', provider: 'Google', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚡', '💰'], description: 'High speed and low cost. A less capable variant of Gemini 2.0 Flash.', notes: '', inputContextLength: 1048576, outputContextLength: 8192 }, // 1M Input, 8K Output
  { value: 'gemini-2.0-flash-thinking-exp', label: 'Gemini 2.0 Flash Thinking Exp', provider: 'Google', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚙️', '⚡', '💰', '🧠', '🧪'], description: 'Experimental variant of Flash with potentially enhanced reasoning. May output intermediate reasoning tokens before the final answer, potentially increasing unpredictability with the tradeoff of higher performance.', notes: 'Experimental status implies potential for instability. The "thinking" process may slightly increase perceived latency.', inputContextLength: 1048576, outputContextLength: 8192 }, // 1M Input, 8K Output
  { value: 'gpt-4o-mini', label: 'GPT-4O Mini', provider: 'OpenAI', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚡', '💰', 'Web', '🧠', '⚠️'], description: 'High speed and low cost. Includes web access capabilities.', notes: 'Prone to generating inaccurate information (hallucinations) more often than models like Gemini 2.0 Flash. Limited performance in mathematics and complex coding tasks.', inputContextLength: 128000, outputContextLength: 16384 }, // 128K Input, 16K Output

  // 3. Expensive Models 💸
  { value: 'claude-3-opus-20240229', label: 'Claude 3 Opus 2024-02-29', provider: 'Anthropic', category: 'Expensive Models 💸', speed: '⚡', cost: '$$$$', tags: ['✍️', '💬', 'Vibe', '🗑️'], description: 'Legacy state-of-the-art model. Excels in creative writing and engaging conversation.', notes: 'Superseded by newer models in coding, UI generation, and planning tasks.', inputContextLength: 200000, outputContextLength: 4096 }, // 200K Input, 4K Output
  { value: 'gpt-4-turbo', label: 'GPT-4 Turbo', provider: 'OpenAI', category: 'Expensive Models 💸', speed: '⚡', cost: '$$$$', tags: ['💬', 'Vibe', '🗑️'], description: 'Legacy model. Valued by some users for its conversational abilities & style.', notes: 'High cost. Current coding and UI generation capabilities are considered subpar compared to newer alternatives.', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'o1', label: 'o1', provider: 'OpenAI', category: 'Expensive Models 💸', speed: '⚡', cost: '$$$$', tags: ['⚙️', '</>', '🧮', '📝', '💸', '🐢', '⚠️'], description: 'Exceptionally strong performance in coding, mathematics, report generation, and general knowledge recall.', notes: 'Very high cost, significantly slower response times, and high latency.', inputContextLength: 200000, outputContextLength: 100000 }, // 200K Input, 100K Output
  { value: 'o1-pro', label: 'o1 Pro', provider: 'OpenAI', category: 'Expensive Models 💸', speed: '⚡', cost: '$$$$$$', tags: ['🧠', '⚙️', '💸', '🐢'], description: 'High-compute version of o1 with enhanced reasoning capabilities. Uses reinforcement learning for complex reasoning tasks.', notes: 'WARNING: Extremely high cost 10x as expensive as o1. Very slow response times.', inputContextLength: 200000, outputContextLength: 100000 },
  { value: 'gpt-4.5-preview', label: 'GPT-4.5 Preview', provider: 'OpenAI', category: 'Expensive Models 💸', speed: '⚡', cost: '$$$$$', tags: ['✨', '🧠', '💬', 'Vibe'], description: 'Largest GPT model yet. Excels at creative tasks, agentic planning, and open-ended thinking. Strong world knowledge and understanding of user intent.', notes: 'WARNING: Very high cost more than 2x as expensive as o1. Sep 2023 knowledge cutoff.', inputContextLength: 128000, outputContextLength: 16384 },

  // 4. Specialized & Experimental ⚙️🧪
  { value: 'claude-3-5-sonnet-20241022', label: 'Claude 3.5 Sonnet 2024-10-22', provider: 'Anthropic', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$$', tags: ['⚙️', '💬', 'Vibe', '⏳', '⚠️'], description: 'Possesses a distinct conversational style. Performance is generally lower than Claude 3.7 Sonnet.', notes: 'Frequently fails to generate longer responses, which can be a significant limitation.', inputContextLength: 200000, outputContextLength: 8192 }, // 200K Input, 8K Output
  { value: 'command-r', label: 'Command R', provider: 'Cohere', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡⚡', cost: '$', tags: ['⚙️', 'RAG', 'COBOL', '🤔'], description: 'Less capable general-purpose model. Notable strengths in Retrieval-Augmented Generation (RAG) and support for less common programming languages (e.g., COBOL).', notes: '', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'command-r-plus-08-2024', label: 'Command R Plus 08-2024', provider: 'Cohere', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$$', tags: ['⚙️', 'RAG', '🤔'], description: 'A larger, more capable model than Command R, offering enhanced performance with a focus on Retrieval-Augmented Generation (RAG) applications.', notes: 'Mid-to-high range cost and performance level.', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'deepseek-reasoner', label: 'DeepSeek R1 (china)', provider: 'Deepseek', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$', tags: ['⚙️', '</>', '🧮', '💰', '⚠️'], description: 'Improved coding and mathematical abilities compared to DeepSeek Chat, but less proficient in UI generation.', notes: 'Both regional variants may have stricter content filtering and potential biases influenced by training data (CCP). Standard context window size. Has 32K intermediate thinking tokens.', inputContextLength: 64000, outputContextLength: 8192 }, // 64K Input, 8K Output (+32K thinking)
  { value: 'DeepSeek-R1', label: 'DeepSeek R1 (usa)', provider: 'Deepseek', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$', tags: ['⚙️', '</>', '🧮', '💰', '⚠️'], description: 'Functionally equivalent to DeepSeek R1 (china) but typically exhibits higher latency due to US-based hosting.', notes: 'Both regional variants may have stricter content filtering and potential biases influenced by training data (CCP). Higher latency compared to the alternative region. Standard context window size. Has 32K intermediate thinking tokens.', inputContextLength: 64000, outputContextLength: 8192 }, // 64K Input, 8K Output (+32K thinking)
  { value: 'Meta-Llama-3.1-405B-Instruct', label: 'Meta Llama 3.1 405B Instruct', provider: 'Meta', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$$', tags: ['⚙️', '</>', '✍️'], description: 'Very large parameter model. Strong capabilities in creative writing and coding. Features a distinct conversational style.', notes: 'Limited effectiveness for UI code generation.', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'reka-core-20240904', label: 'Reka Core', provider: 'Reka', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$', tags: ['⚙️', '🖼️', '🤔'], description: 'Specialized in multimodal tasks, particularly image and video recognition/analysis (e.g., identifying objects). General chat and coding capabilities are limited.', notes: '', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'reka-edge-20240208', label: 'Reka Edge', provider: 'Reka', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚙️', '🖼️', '🤔'], description: 'Specialized in multimodal tasks, particularly image and video recognition/analysis. Optimized for speed, potentially suitable for edge devices.', notes: 'Low-cost option for multimodal analysis tasks.', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'reka-flash-3', label: 'Reka Flash 3', provider: 'Reka', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡⚡', cost: '$', tags: ['⚙️', '🖼️', '🤔'], description: 'Specialized in multimodal tasks, particularly image and video recognition/analysis. Offers a balance of speed and capability.', notes: 'Faster analysis option compared to Reka Core.', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output

  // 5. Legacy / Archive 🗑️ - We will filter these out dynamically
  { value: 'grok-beta', label: 'Grok Beta', provider: 'xAI', category: 'Legacy / Archive 🗑️', speed: '⚡⚡', cost: '$$$', tags: ['🤔', '🗑️'], description: 'Legacy beta version of Grok. Limited capabilities compared to contemporary models. Newer versions anticipated.', notes: '', inputContextLength: 128000, outputContextLength: 4096 },
  // Other legacy models exist in the full paste-2 but are omitted here for brevity; the filtering logic handles them.
];

// --- Helper Functions ---
const formatContextSize = (tokens) => {
  if (tokens === null || typeof tokens === 'undefined') return 'N/A';
  if (tokens >= 1000000) {
    return `${(tokens / 1000000).toFixed(tokens % 1000000 === 0 ? 0 : 1)}M`;
  }
  if (tokens >= 1000) {
    return `${(tokens / 1000).toFixed(tokens % 1000 === 0 ? 0 : 0)}K`;
  }
  return tokens.toString();
};

const mapCostToEfficiencyText = (cost) => {
  switch (cost) {
    case '$': return 'Very High';
    case '$$': return 'High';
    case '$$$': return 'Medium';
    case '$$$$': return 'Low';
    case '$$$$$': return 'Very Low';
    case '$$$$$$': return 'Extremely Low';
    default: return 'Unknown';
  }
};

const mapCostToEfficiencyValue = (cost) => {
    switch (cost) {
      case '$': return 1.0;
      case '$$': return 0.8;
      case '$$$': return 0.6;
      case '$$$$': return 0.4;
      case '$$$$$': return 0.2;
      case '$$$$$$': return 0.1;
      default: return 0;
    }
  };

// --- Provider Definitions ---
// You MUST replace placeholder logo paths with actual paths to your logo images
const providerDetails = {
  Anthropic: { logo: '/Anthropic.png', tagline: 'AI safety and research company creating reliable, interpretable, and steerable AI systems.', additionalNote: 'Advanced AI models balancing deep understanding with cutting-edge performance improvements' },
  OpenAI: { logo: '/openai-2.svg', tagline: 'Building safe and beneficial artificial general intelligence.', additionalNote: 'Suite of AI models optimized for different use cases, from high-performance computing to efficient processing.' },
  Google: { logo: '/Gemini2.webp', tagline: 'Organizing the world\'s information and making it universally accessible and useful, now with powerful AI.', additionalNote: 'Engineered for efficiency and scale across diverse computational challenges.' },
  Reka: { logo: '/reka_ai_logo.jfif', tagline: 'Building multimodal AI models.', additionalNote: 'Specialized AI solutions tailored for precise, domain-specific challenges, including multimodal tasks.' },
  Cohere: { logo: '/cohere.png', tagline: 'AI platform for enterprise.', additionalNote: 'Specialized in RAG technology and document intelligence processing for enterprise.' },
  Deepseek: { logo: '/deepseek.png', tagline: 'Developing general artificial intelligence for the good of humanity.', additionalNote: 'Focuses on open-source and high-performance models, particularly for coding and reasoning.' },
  Meta: { logo: '/meta-logo-placeholder.png', tagline: 'Connecting people and building community through technology, including AI.', additionalNote: 'Offers large open-source models with strong creative and coding capabilities.' }, // <-- REPLACE Placeholder logo
  xAI: { logo: '/xai-logo-placeholder.jpg', tagline: 'Developing AI to understand the universe.', additionalNote: 'AI from xAI, including models like Grok, designed with a unique perspective.' }, // <-- Added xAI, REPLACE Placeholder logo
};

// --- Component ---
const ModelAvailabilitySection = () => {
  const [expandedProvider, setExpandedProvider] = useState(null);

  const toggleProvider = (providerName) => {
    setExpandedProvider(expandedProvider === providerName ? null : providerName);
  };

  // Process modelsData to group by provider
  const activeProviders = Object.entries(providerDetails).map(([name, details]) => {
    // Special case for xAI - include legacy models
    const activeModels = modelsData
      .filter(model => model.provider === name && (name === 'xAI' || model.category !== 'Legacy / Archive 🗑️'))
      .map(model => ({
        name: model.label,
        description: model.description,
        speed: model.speed,
        costSymbol: model.cost,
        costText: mapCostToEfficiencyText(model.cost),
        inputContext: formatContextSize(model.inputContextLength),
        outputContext: formatContextSize(model.outputContextLength),
        tags: model.tags || [],
      }));

    const maxInputContext = Math.max(0, ...modelsData
        .filter(m => m.provider === name && (name === 'xAI' || m.category !== 'Legacy / Archive 🗑️') && typeof m.inputContextLength === 'number')
        .map(m => m.inputContextLength)
    );
    const maxOutputContext = Math.max(0, ...modelsData
        .filter(m => m.provider === name && (name === 'xAI' || m.category !== 'Legacy / Archive 🗑️') && typeof m.outputContextLength === 'number')
        .map(m => m.outputContextLength)
    );

    return {
      name: name,
      logo: details.logo,
      tagline: details.tagline,
      models: activeModels,
      displayContext: maxInputContext > 0 ? `Up to ${formatContextSize(maxInputContext)} Input` : 'Context Varies',
      additionalNote: details.additionalNote,
    };
  });

  // Select models for the comparison table (ensure non-legacy except for xAI)
  const comparisonModels = [
    modelsData.find(m => m.value === 'claude-3-7-sonnet-20250219'),
    modelsData.find(m => m.value === 'gemini-2.5-pro-exp-03-25'),
    modelsData.find(m => m.value === 'chatgpt-4o-latest'),
    modelsData.find(m => m.value === 'deepseek-chat'),
    modelsData.find(m => m.value === 'gemini-2.0-flash-001'),
    modelsData.find(m => m.value === 'o1'),
    modelsData.find(m => m.value === 'Meta-Llama-3.1-405B-Instruct'),
    modelsData.find(m => m.value === 'grok-beta'), // Include Grok Beta even though it's legacy
  ].filter(Boolean).filter(m => m.provider === 'xAI' || m.category !== 'Legacy / Archive 🗑️');

  return (
    <section id="models" className="py-20 px-6 bg-[#1a1a1a] border-t border-[#2a2a2a] text-gray-300">
      <div className="max-w-7xl mx-auto">
        {/* Section header */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent">
            Access Top AI Models
          </h2>
          <p className="text-xl text-gray-400 mb-8">
            Choose from leading AI models with their full context windows—no artificial limits.
          </p>
        </div>

        {/* Providers Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 mb-12">
          {activeProviders.map((provider) => (
            <div
              key={provider.name}
              className={`
                bg-[#2a2a2a] rounded-lg transition-all duration-200 overflow-hidden
                ${expandedProvider === provider.name ? 'ring-2 ring-orange-500' : 'hover:bg-[#333333]'}
                cursor-pointer flex flex-col
              `}
              onClick={() => toggleProvider(provider.name)}
            >
              <div className="p-4 flex flex-col items-center text-center flex-grow">
                <img
                  src={provider.logo}
                  alt={`${provider.name} Logo`}
                  className="w-12 h-12 object-contain mb-3"
                />
                <h3 className="font-semibold mb-1 text-gray-100">{provider.name}</h3>
                <p className="text-xs text-gray-400 mb-2 flex-grow">{provider.tagline}</p>
                <div className="text-orange-500 text-xs mt-auto font-medium">{provider.displayContext}</div>
              </div>
              <div className="flex justify-center pb-2 text-gray-400">
                <ChevronDown
                  className={`w-4 h-4 transition-transform duration-300 ${
                    expandedProvider === provider.name ? 'rotate-180' : ''
                  }`}
                />
              </div>
            </div>
          ))}
        </div>

        {/* Expanded Provider Details */}
        {expandedProvider && (
          <div className="bg-[#2a2a2a] rounded-lg p-6 mb-12 transition-all duration-300 animate-fadeIn">
            {activeProviders
              .filter(provider => provider.name === expandedProvider)
              .map(provider => (
                <div key={provider.name}>
                  {/* Expanded Header */}
                  <div className="flex flex-col sm:flex-row items-center mb-6 gap-4 pb-4 border-b border-[#3a3a3a]">
                    <img
                      src={provider.logo}
                      alt={`${provider.name} Logo`}
                      className="w-12 h-12 object-contain mr-0 sm:mr-4 flex-shrink-0"
                    />
                    <div className="text-center sm:text-left flex-grow">
                      <h3 className="text-xl font-semibold text-gray-100">{provider.name}</h3>
                      <p className="text-gray-400 text-sm">{provider.tagline}</p>
                    </div>
                    <div className="ml-0 sm:ml-auto mt-2 sm:mt-0 flex-shrink-0">
                      <span className="text-orange-500 text-sm font-medium">{provider.displayContext}</span>
                    </div>
                  </div>

                  {/* Models Grid */}
                  <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                    {provider.models.map(model => (
                      <div key={model.name} className="bg-[#1a1a1a] p-4 rounded-lg flex flex-col h-full border border-[#3a3a3a]">
                        {/* Model Header: Name & Speed */}
                        <div className='flex justify-between items-start mb-2'>
                            <h4 className="font-semibold text-gray-200 flex-1 mr-2 text-base">{model.name}</h4>
                            <span className='text-lg text-gray-400 whitespace-nowrap pt-0.5' title={`Speed Rating`}>{model.speed}</span>
                        </div>
                        {/* Model Description */}
                        <p className="text-gray-400 text-sm mb-3 flex-grow">{model.description}</p>

                        {/* Model Footer: Context (Input/Output) & Cost */}
                        <div className="flex justify-between items-center mt-auto pt-3 border-t border-[#333] gap-2 flex-wrap">
                            <div className='flex flex-col text-xs'>
                                <span className="text-orange-400 font-medium" title="Input Context Window">IN: {model.inputContext}</span>
                                <span className="text-purple-400 font-medium" title="Output Context Window">OUT: {model.outputContext}</span>
                            </div>
                            <span className="text-xs text-blue-400 font-medium" title={`Relative Cost/Efficiency: ${model.costText}`}>{model.costSymbol}</span>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Provider Footer Note */}
                  <div className="text-sm text-gray-400 mt-6 pt-4 border-t border-[#3a3a3a]">{provider.additionalNote}</div>
                </div>
            ))}

            {/* Close Button */}
            <button
              onClick={() => setExpandedProvider(null)}
              className="mt-6 text-orange-500 hover:text-orange-400 transition-colors text-sm flex items-center mx-auto"
            >
              Close details
              <X className="ml-2 w-4 h-4" />
            </button>
          </div>
        )}

        {/* Compact Features Summary (Unchanged) */}
        <div className="mt-8 bg-[#2a2a2a] p-8 rounded-lg">
          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center">
              <ArrowLeftRight className="w-8 h-8 text-orange-500 mx-auto mb-4" />
              <h3 className="font-semibold mb-2 text-gray-100">Switch Models Instantly</h3>
              <p className="text-sm text-gray-400">
                Change models mid-conversation while maintaining available context
              </p>
            </div>
            <div className="text-center">
              <Lock className="w-8 h-8 text-orange-500 mx-auto mb-4" />
              <h3 className="font-semibold mb-2 text-gray-100">No API Keys Needed</h3>
              <p className="text-sm text-gray-400">
                Direct access to all models without setup or configuration
              </p>
            </div>
            <div className="text-center">
              <Layers className="w-8 h-8 text-orange-500 mx-auto mb-4" />
              <h3 className="font-semibold mb-2 text-gray-100">Maximum Context Windows</h3>
              <p className="text-sm text-gray-400">
                Use each model's full context capacity without restrictions
              </p>
            </div>
          </div>
        </div>

        {/* Comparison Table - Updated */}
        <div className="mt-12 overflow-hidden rounded-lg border border-[#3a3a3a]">
          <div className="bg-[#2a2a2a] p-4">
            <h3 className="font-semibold text-gray-100">Model Comparison At-a-Glance</h3>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full text-sm">
              <thead className="bg-[#2a2a2a]">
                <tr>
                  <th className="p-3 text-left text-gray-300 whitespace-nowrap font-semibold">Model</th>
                  <th className="p-3 text-left text-gray-300 font-semibold">Provider</th>
                  <th className="p-3 text-left text-gray-300 whitespace-nowrap font-semibold">Context (In/Out)</th> {/* Combined Context Col */}
                  <th className="p-3 text-left text-gray-300 font-semibold">Primary Strengths</th>
                  <th className="p-3 text-left text-gray-300 font-semibold">Speed</th>
                  <th className="p-3 text-left text-gray-300 whitespace-nowrap font-semibold">Cost Efficiency</th>
                </tr>
              </thead>
              <tbody className="bg-[#1a1a1a]">
                {comparisonModels.map((model, index) => (
                  <tr key={model.value || index} className="border-b border-[#2a2a2a] last:border-b-0 hover:bg-[#222]">
                    <td className="p-3 font-medium text-gray-200">{model.label}</td>
                    <td className="p-3 text-gray-400">{model.provider}</td>
                    {/* Combined Input/Output Context Cell */}
                    <td className="p-3 text-gray-400 whitespace-nowrap">
                        {formatContextSize(model.inputContextLength)} / {formatContextSize(model.outputContextLength)}
                    </td>
                    <td className="p-3 text-gray-400 max-w-xs truncate" title={model.description}>{
                      model.description.split('.')[0]
                    }</td>
                    <td className="p-3 text-gray-400" title={`Speed Rating`}>{model.speed}</td>
                    <td className="p-3">
                      <div className="flex items-center">
                        <div className="w-16 h-2 bg-[#3a3a3a] rounded-full overflow-hidden mr-2" title={`Relative Cost: ${model.cost}`}>
                          <div
                            className="bg-gradient-to-r from-orange-400 to-orange-600 h-full"
                            style={{ width: `${mapCostToEfficiencyValue(model.cost) * 100}%` }}
                          ></div>
                        </div>
                        <span className="text-xs text-gray-400">{mapCostToEfficiencyText(model.cost)}</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

const SearchComparisonSection = () => {
  return (
    <section id="search-comparison" className="py-20 px-6 bg-[#1a1a1a] border-t border-[#2a2a2a]">
      <div className="max-w-7xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent">
            Search Results That Actually Help
          </h2>
          <p className="text-xl text-gray-400">
            Compare how different AI search assistants handle the same query
          </p>
        </div>
        
        <div className="grid lg:grid-cols-2 gap-12">
          {/* Henosis Example */}
          <div className="bg-[#2a2a2a] p-8 rounded-lg hover:bg-[#333333] transition-all duration-200">
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center">
                <img src="/output.webp" alt="Henosis Logo" className="h-6 w-6 mr-2" />
                <h3 className="font-semibold">Henosis Search</h3>
              </div>
              <span className="text-green-500 text-sm">Curated, Useful Results</span>
            </div>
            
            <div className="space-y-6 mb-6">
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <Search className="w-4 h-4 text-gray-400" />
                  <span className="text-gray-400">User Query</span>
                </div>
                <div className="text-white">trapping with my dad lyrics</div>
              </div>
              
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-4">
                  <CheckCircle className="w-4 h-4 text-green-500" />
                  <span className="text-gray-400">Response</span>
                </div>
                
                <div className="text-gray-300 mb-4">
                  <p>
                    Here are some sources where you can find the lyrics to "Trapping With My Dad" by Akira The Don:
                  </p>
                </div>
                
                <div className="space-y-4">
                  <div className="bg-[#222] p-3 rounded-lg">
                    <div className="text-blue-400 hover:underline cursor-pointer font-medium">Genius</div>
                    <div className="text-sm text-gray-400">Full lyrics with annotations explaining lines and themes</div>
                  </div>
                  
                  <div className="bg-[#222] p-3 rounded-lg">
                    <div className="text-blue-400 hover:underline cursor-pointer font-medium">JioSaavn</div>
                    <div className="text-sm text-gray-400">Listen to the song and read the lyrics</div>
                  </div>
                  
                  <div className="bg-[#222] p-3 rounded-lg">
                    <div className="text-blue-400 hover:underline cursor-pointer font-medium">Meaningwave</div>
                    <div className="text-sm text-gray-400">Lyrics with song background information</div>
                  </div>
                </div>
              </div>
            </div>
            
            {/* Sources Section for Henosis */}
            <div className="bg-[#1a1a1a] p-4 rounded mb-6">
              <div className="flex items-center space-x-2 mb-2">
                <CheckCircle className="w-4 h-4 text-green-500" />
                <span className="text-gray-400">Sources</span>
              </div>
              <div className="text-gray-300">
                <p>Henosis evaluates 10-20 relevant links and selects high-quality sources & reads them before using them to ensure trustworthy results.</p>
              </div>
            </div>
            
            {/* Additional Advantages */}
            <div className="space-y-3">
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Trusted, direct links</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Uncensored Search</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Context for each source</span>
              </div>
            </div>
          </div>
          
          {/* Perplexity Example */}
          <div className="bg-[#2a2a2a] p-8 rounded-lg">
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center">
                <img src="/perplexity_logo.webp" alt="Perplexity Logo" className="h-6 w-6 mr-2" />
                <h3 className="font-semibold">Perplexity Pro</h3>
              </div>
              <span className="text-yellow-500 text-sm">Limited Results</span>
            </div>
            
            <div className="space-y-6 mb-6">
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <Search className="w-4 h-4 text-gray-400" />
                  <span className="text-gray-400">User Query</span>
                </div>
                <div className="text-white">trapping with my dad lyrics</div>
              </div>
              
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-4">
                  <AlertTriangle className="w-4 h-4 text-yellow-500" />
                  <span className="text-gray-400">Response</span>
                </div>
                
                <div className="text-gray-300 space-y-4">
                  <p>"I apologize, but I cannot provide or reproduce the lyrics..."</p>
                  
                  <div className="bg-[#222] p-3 rounded-lg">
                    <p className="text-sm text-gray-400 mb-2">Alternative suggestions:</p>
                    <ul className="space-y-1 pl-4 text-sm">
                      <li>• Listen on Spotify</li>
                      <li>• Purchase through music platforms</li>
                      <li>• Check official lyric websites</li>
                    </ul>
                  </div>
                  
                  <p className="text-xs text-gray-500">Song length: 6:07, Album: "JBPWAVE: FATHER2019"</p>
                </div>
              </div>
              
              <div className="bg-[#1a1a1a] p-4 rounded">
                <div className="flex items-center space-x-2 mb-2">
                  <XCircle className="w-4 h-4 text-red-500" />
                  <span className="text-gray-400">Sources Found (4)</span>
                </div>
                <div className="text-gray-300 text-sm">
                  <div className="space-y-1">
                    <div>• Luke Combs song (Unrelated)</div>
                    <div>• Reddit JuiceWRLD thread (Unrelated)</div>
                    <div>• Spotify link (No content)</div>
                    <div>• Duplicate Spotify link</div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="space-y-3">
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Only reads snippets of source material</span>
              </div>
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Irrelevant search results, source material isn't chosen by agent</span>
              </div>
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Generic suggestions only</span>
              </div>
            </div>
          </div>
        </div>
        
        {/* Enhanced section on search insights */}
        <div className="mt-12 bg-[#2a2a2a] p-8 rounded-lg">
          <h3 className="text-xl font-semibold mb-6">Why Henosis Search is Different</h3>
          
          <div className="grid md:grid-cols-2 gap-8">
            <div className="space-y-4">
              <div className="flex items-start">
                <div className="bg-green-500/10 p-2 rounded mr-3 mt-1">
                  <Search className="w-5 h-5 text-green-500" />
                </div>
                <div>
                  <h4 className="font-medium text-gray-200">Content Intelligence</h4>
                  <p className="text-sm text-gray-400 mt-1">
                    Henosis AI reads and comprehends each source fully before including it, 
                    ensuring only high-quality, relevant information is provided.
                  </p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="bg-green-500/10 p-2 rounded mr-3 mt-1">
                  <CheckCircle className="w-5 h-5 text-green-500" />
                </div>
                <div>
                  <h4 className="font-medium text-gray-200">Uncensored Results</h4>
                  <p className="text-sm text-gray-400 mt-1">
                    Get complete, uncensored search results without arbitrary content
                    restrictions that other search engines impose.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="space-y-4">
              <div className="flex items-start">
                <div className="bg-green-500/10 p-2 rounded mr-3 mt-1">
                  <FileText className="w-5 h-5 text-green-500" />
                </div>
                <div>
                  <h4 className="font-medium text-gray-200">Deeper Source Understanding</h4>
                  <p className="text-sm text-gray-400 mt-1">
                    Unlike competitor RAG systems that only scan snippets, Henosis processes
                    entire webpages to extract true meaning and context.
                  </p>
                </div>
              </div>
              
              <div className="flex items-start">
                <div className="bg-green-500/10 p-2 rounded mr-3 mt-1">
                  <Lightbulb className="w-5 h-5 text-green-500" />
                </div>
                <div>
                  <h4 className="font-medium text-gray-200">Context-Aware Recommendations</h4>
                  <p className="text-sm text-gray-400 mt-1">
                    Sources are presented with explanations of why they're relevant to your 
                    query, giving you confidence in the results.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Hero = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const handleEmailSignup = (e) => {
    e.preventDefault();
    if (email) {
      navigate('/register', { state: { prefillEmail: email } });
    }
  };

  return (
    <header id="hero" className="relative py-24 px-6">
      <div className="max-w-7xl mx-auto">
        <div className="grid lg:grid-cols-2 gap-12 items-start mb-16">
          {/* Left Column - Main Content */}
          <div className="text-left">
            <h1 className="text-5xl sm:text-6xl font-bold mb-8">
              <span className="bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent">
                Turn Complex Work Into Solutions
              </span>
            </h1>
            <p className="text-xl text-gray-400 mb-8">
              AI chat without the handcuffs – no daily chat limits, full model context, better results.
            </p>
            <button
              onClick={() => navigate('/register')}
              className="px-8 py-4 bg-gradient-to-r from-orange-500 to-red-500 
                         hover:from-red-500 hover:to-orange-600 rounded-lg
                         transition-all duration-200 text-lg font-medium
                         shadow-lg shadow-orange-500/20 mb-8"
            >
              Get Started
            </button>
            {/* Key Value Propositions */}
            <div className="grid sm:grid-cols-2 gap-4 text-gray-300">
              <div className="flex items-start space-x-2">
                <Brain className="w-5 h-5 text-orange-500 mt-0.5" />
                <span>Access 15+ frontier AI models</span>
              </div>
              <div className="flex items-start space-x-2">
                <Layers className="w-5 h-5 text-orange-500 mt-0.5" />
                <span>Full model context windows</span>
              </div>
              <div className="flex items-start space-x-2">
                <ArrowLeftRight className="w-5 h-5 text-orange-500 mt-0.5" />
                <span>Switch models mid-conversation</span>
              </div>
              <div className="flex items-start space-x-2">
                <FileText className="w-5 h-5 text-orange-500 mt-0.5" />
                <span>Files up to 100MB</span>
              </div>
            </div>
          </div>

          {/* Right Column - Email Signup & Testimonial */}
          <div className="bg-[#2a2a2a] p-8 rounded-lg border border-[#3a3a3a]">
            <div className="mb-8">
              <h3 className="text-2xl font-semibold mb-4">Get Started with Henosis</h3>
              <p className="text-gray-400 mb-6">
                Access the full capabilities of top AI models without artificial limits.
              </p>
              <form onSubmit={handleEmailSignup} className="space-y-4">
                <input
                  type="email"
                  placeholder="Your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full px-6 py-4 bg-[#1a1a1a] rounded-lg border border-[#3a3a3a] focus:border-orange-500 focus:outline-none"
                  required
                />
                <button
                  type="submit"
                  className="w-full px-8 py-4 bg-gradient-to-r from-orange-500 to-red-500 
                             hover:from-red-500 hover:to-orange-600 rounded-lg transition-all duration-200 text-lg font-medium shadow-lg shadow-orange-500/20"
                >
                  Get Started
                </button>
              </form>
            </div>
            {/* Testimonial Block */}
            <div className="bg-[#222] p-4 rounded-lg border border-[#333]">
              <p className="text-gray-300 text-sm italic mb-3">
                "I built Henosis because no other platform could give me full access to the latest public models at full capability. I loved Cursor but to do more with these models you can't limit or censor the implementation. Henosis saves me hours over other tools and I can't live without it."
              </p>
              <div className="flex items-center">
                <div className="w-8 h-8 bg-gradient-to-br from-purple-500 to-pink-500 rounded-full mr-2"></div>
                <span className="text-sm font-medium">Matt Abrahamson - Creator of Henosis</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
const CodeComparisonSection = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  // Full Henosis code example
  const henosisCode = `
    <ParticleTypes.ts>
  export type ParticleType = 'spark' | 'smoke' | 'fire' | 'explosion';
  export interface ParticleConfig {
    emitRate: number;
    particleLife: number;
    particleDrag: number;
    particleType: ParticleType;
    initialSpeed: number;
    spreadAngle: number;
  }
  export interface Particle {
    position: Vector3;
    velocity: Vector3;
    lifetime: number;
    drag: number;
    type: ParticleType;
  }
  </ParticleTypes.ts>
  <Vector3.ts>
  export class Vector3 {
    constructor(
      public x: number = 0,
      public y: number = 0,
      public z: number = 0
    ) {}
    add(v: Vector3): Vector3 {
      this.x += v.x;
      this.y += v.y;
      this.z += v.z;
      return this;
    }
    multiply(scalar: number): Vector3 {
      this.x *= scalar;
      this.y *= scalar;
      this.z *= scalar;
      return this;
    }
    clone(): Vector3 {
      return new Vector3(this.x, this.y, this.z);
    }
    normalize(): Vector3 {
      const length = Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);
      if (length > 0) {
        this.x /= length;
        this.y /= length;
        this.z /= length;
      }
      return this;
    }
  }
  </Vector3.ts>
  <PhysicsEngine.ts>
  export class PhysicsEngine {
    private gravity: Vector3 = new Vector3(0, -9.81, 0);
    private wind: Vector3 = new Vector3();
    setWind(direction: Vector3, strength: number): void {
      this.wind = direction.clone().normalize().multiply(strength);
    }
    applyForces(particle: Particle): void {
      // Apply gravity
      const gravityForce = this.gravity.clone().multiply(deltaTime);
      particle.velocity.add(gravityForce);
      // Apply wind based on particle type
      if (particle.type === 'smoke' || particle.type === 'fire') {
        const windForce = this.wind.clone().multiply(deltaTime);
        particle.velocity.add(windForce);
      }
    }
  }
  </PhysicsEngine.ts>
  <ParticleEmitter.ts>
  export class ParticleEmitter {
    private particles: Particle[] = [];
    private position: Vector3;
    private config: ParticleConfig;
    private age: number = 0;
    constructor(position: Vector3, config: ParticleConfig) {
      this.position = position;
      this.config = config;
    }
    emit(deltaTime: number): void {
      // Remove dead particles
      this.particles = this.particles.filter(p => p.lifetime > 0);
              
      // Emit new particles
      const emitCount = Math.floor(this.config.emitRate * deltaTime);
      for (let i = 0; i < emitCount; i++) {
        this.particles.push(this.createParticle());
      }
      // Update existing particles
      for (const particle of this.particles) {
        particle.lifetime -= deltaTime;
      }
    }
    getParticles(): Particle[] {
      return this.particles;
    }
    private createParticle(): Particle {
      const velocity = this.getRandomVelocity();
      return {
        position: this.position.clone(),
        velocity,
        lifetime: this.config.particleLife,
        drag: this.config.particleDrag,
        type: this.config.particleType
      };
    }
    private getRandomVelocity(): Vector3 {
      const angle = Math.random() * this.config.spreadAngle;
      const direction = new Vector3(
        Math.sin(angle),
        Math.cos(angle),
        0
      ).normalize();
      return direction.multiply(this.config.initialSpeed);
    }
  }
  </ParticleEmitter.ts>
  <ParticleSystem.ts>
  export class ParticleSystem {
    private emitters: ParticleEmitter[] = [];
    private physics: PhysicsEngine;
    constructor(physics: PhysicsEngine) {
      this.physics = physics;
    }
    addEmitter(position: Vector3, config: ParticleConfig): ParticleEmitter {
      const emitter = new ParticleEmitter(position, config);
      this.emitters.push(emitter);
      return emitter;
    }
    update(deltaTime: number): void {
      for (const emitter of this.emitters) {
        // Update particle positions with physics
        const particles = emitter.getParticles();
        for (const particle of particles) {
          // Apply physics forces
          this.physics.applyForces(particle);
          // Update position
          particle.position.add(
            particle.velocity.multiply(deltaTime)
          );
          // Apply damping
          particle.velocity.multiply(1 - particle.drag);
        }
        // Emit new particles
        emitter.emit(deltaTime);
      }
    }
  }
  </ParticleSystem.ts>`;
    
  // Cursor code example
  const cursorCode = `// Currently open file:
// ParticleSystem.ts
export class ParticleSystem {
    private emitters: ParticleEmitter[] = [];
    private physics: PhysicsEngine;
      
    constructor(physics: PhysicsEngine) {
        this.physics = physics;
    }
      
    update(deltaTime: number): void {
        for (const emitter of this.emitters) {
            const particles = emitter.getParticles();
            for (const particle of particles) {
                this.physics.applyForces(particle);
                particle.position.add(
                    particle.velocity.multiply(deltaTime)
                );
                particle.velocity.multiply(1 - particle.drag);
            }
            emitter.emit(deltaTime);
        }
    }
}

// Potentially relevant code snippets from codebase:

// From ParticleEmitter.ts:
export class ParticleEmitter {
    emit(deltaTime: number): void {
        const emitCount = this.config.emitRate * deltaTime;
        for (let i = 0; i < emitCount; i++) {
            const particle = this.createParticle();
            this.particles.push(particle);
        }
    }
}

// From PhysicsEngine.ts:
export class PhysicsEngine {
    applyForces(particle: Particle): void {
        const gravityForce = this.gravity.clone().multiply(deltaTime);
        particle.velocity.add(gravityForce);
    }
}

// From Vector3.ts:
export class Vector3 {
    multiply(scalar: number): Vector3 {
        this.x *= scalar;
        this.y *= scalar;
        this.z *= scalar;
        return this;
    }
}`;

  return (
    <section id="code-context" className="py-20 px-6 bg-[#1a1a1a] border-t border-[#2a2a2a]">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent">
            Complete vs Limited Context
          </h2>
          <p className="text-xl text-gray-400">
            While other AI tools cut corners by only seeing pieces of your code,
            Henosis analyzes every file and dependency you share
          </p>
        </div>
        
        {/* Comparison Summary - Added new section at top */}
        <div className="bg-[#2a2a2a] p-6 rounded-lg mb-12">
          <h3 className="text-xl font-semibold mb-4">Understanding the Difference</h3>
          <div className="grid md:grid-cols-2 gap-6">
            <div>
              <h4 className="font-medium text-green-500 mb-4 flex items-center">
                <CheckCircle className="w-5 h-5 mr-2" />
                Henosis: Full Context
              </h4>
              <ul className="space-y-3 text-gray-400">
                <li className="flex items-start">
                  <div className="mt-1 mr-3">•</div>
                  <div>
                    <span className="text-gray-300 font-medium">Sees your entire codebase</span>
                    <p className="text-sm">All files, classes, and dependencies available to the AI</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="mt-1 mr-3">•</div>
                  <div>
                    <span className="text-gray-300 font-medium">Complete implementation details</span>
                    <p className="text-sm">Full understanding of your code structure and relationships</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="mt-1 mr-3">•</div>
                  <div>
                    <span className="text-gray-300 font-medium">True system-level insight</span>
                    <p className="text-sm">Provides solutions based on full architectural understanding</p>
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <h4 className="font-medium text-yellow-500 mb-4 flex items-center">
                <AlertTriangle className="w-5 h-5 mr-2" />
                Other Tools: Limited Context
              </h4>
              <ul className="space-y-3 text-gray-400">
                <li className="flex items-start">
                  <div className="mt-1 mr-3">•</div>
                  <div>
                    <span className="text-gray-300 font-medium">Only sees current file</span>
                    <p className="text-sm">Limited to what's currently visible in your editor</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="mt-1 mr-3">•</div>
                  <div>
                    <span className="text-gray-300 font-medium">Partial code snippets</span>
                    <p className="text-sm">Shows small pieces of related code without full context</p>
                  </div>
                </li>
                <li className="flex items-start">
                  <div className="mt-1 mr-3">•</div>
                  <div>
                    <span className="text-gray-300 font-medium">Limited relationship understanding</span>
                    <p className="text-sm">Makes assumptions about system connections with incomplete data</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="mt-6 flex items-center text-orange-500 hover:text-orange-400 transition-colors mx-auto"
          >
            {isExpanded ? "Hide code comparison" : "See detailed code comparison"}
            <ChevronDown className={`ml-2 w-5 h-5 transition-transform duration-200 ${isExpanded ? "transform rotate-180" : ""}`} />
          </button>
        </div>
          
        {/* Comparison Grid - Only shown when expanded */}
        <div className={`grid lg:grid-cols-2 gap-12 transition-all duration-500 overflow-hidden ${isExpanded ? "max-h-[1500px] opacity-100" : "max-h-0 opacity-0"}`}>
          {/* Henosis Side */}
          <div className="bg-[#2a2a2a] p-8 rounded-lg hover:bg-[#333333] transition-all duration-200">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <img src="/output.webp" alt="Henosis Logo" className="h-6 w-6 mr-2" />
                <h3 className="font-semibold">Henosis Chat</h3>
              </div>
              <span className="text-green-500 text-sm">Complete Context</span>
            </div>
              
            <div className="mb-6">
              <h4 className="text-lg font-medium mb-2">What the AI Sees:</h4>
              <ul className="text-gray-400 space-y-2 list-disc pl-5">
                <li>Your exact input, unmodified</li>
                <li>Full conversation history</li>
                <li>All shared code and examples</li>
                <li>Complete implementation details</li>
              </ul>
            </div>
              
            {/* Updated code container */}
            <div className="bg-[#1a1a1a] p-4 rounded">
              <div className="flex items-center justify-between mb-2">
                <Code className="w-5 h-5 text-green-500" />
                <span className="text-xs text-gray-500">Complete Context As Shared</span>
              </div>
              <div className="h-[400px] relative">
                <div className="absolute inset-0">
                  <div className="h-full overflow-y-auto scrollbar-thin scrollbar-thumb-[#3a3a3a] scrollbar-track-transparent hover:scrollbar-thumb-[#4a4a4a]">
                    <div className="overflow-x-auto scrollbar-thin scrollbar-thumb-[#3a3a3a] scrollbar-track-transparent hover:scrollbar-thumb-[#4a4a4a]">
                      <pre className="text-xs font-mono p-2 min-w-max">
                        <code>
                          {henosisCode}
                        </code>
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              
            <div className="mt-6 space-y-3">
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Sees complete implementation details</span>
              </div>
              <div className="flex items-center text-green-500">
                <CheckCircle className="w-5 h-5 mr-2" />
                <span>Understands full system relationships</span>
              </div>
            </div>
          </div>
            
          {/* Cursor Side */}
          <div className="bg-[#2a2a2a] p-8 rounded-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <img src="/cursor_logo.jpg" alt="Cursor Logo" className="h-6 w-6 mr-2" />
                <h3 className="font-semibold">Cursor</h3>
              </div>
              <span className="text-yellow-500 text-sm">Current File Context</span>
            </div>
              
            <div className="mb-6">
              <h4 className="text-lg font-medium mb-2">What the AI Sees:</h4>
              <ul className="text-gray-400 space-y-2 list-disc pl-5">
                <li>Currently open file contents</li>
                <li>Related code snippets from codebase</li>
                <li>Visible implementation details</li>
                <li>Current file dependencies</li>
              </ul>
            </div>
              
            {/* Updated code container */}
            <div className="bg-[#1a1a1a] p-4 rounded">
              <div className="flex items-center justify-between mb-2">
                <Code className="w-5 h-5 text-yellow-500" />
                <span className="text-xs text-gray-500">Current File + Relevant Snippets</span>
              </div>
              <div className="h-[400px] relative">
                <div className="absolute inset-0">
                  <div className="h-full overflow-y-auto scrollbar-thin scrollbar-thumb-[#3a3a3a] scrollbar-track-transparent hover:scrollbar-thumb-[#4a4a4a]">
                    <div className="overflow-x-auto scrollbar-thin scrollbar-thumb-[#3a3a3a] scrollbar-track-transparent hover:scrollbar-thumb-[#4a4a4a]">
                      <pre className="text-xs font-mono p-2">
                        {cursorCode}
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              
            <div className="mt-6 space-y-3">
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Context will be reduced</span>
              </div>
              <div className="flex items-center text-yellow-500">
                <AlertTriangle className="w-5 h-5 mr-2" />
                <span>Details may be lost in optimization</span>
              </div>
            </div>
          </div>
        </div>
          
        {/* Business Impact Section */}
        <div className="mt-12 bg-[#2a2a2a] p-6 rounded-lg">
          <h3 className="text-xl font-semibold mb-6">The Business Impact of Complete Context</h3>
          <div className="grid md:grid-cols-3 gap-6">
            <div className="bg-[#1a1a1a] p-4 rounded-lg">
              <Clock className="w-10 h-10 text-orange-500 mb-3" />
              <h4 className="font-medium mb-2">30-50% Time Savings</h4>
              <p className="text-sm text-gray-400">
                Developers save significant time with complete context understanding, 
                eliminating the need for multiple queries and constant refinement.
              </p>
            </div>
            <div className="bg-[#1a1a1a] p-4 rounded-lg">
              <Shield className="w-10 h-10 text-orange-500 mb-3" />
              <h4 className="font-medium mb-2">Better Code Quality</h4>
              <p className="text-sm text-gray-400">
                AI solutions consider your entire codebase architecture and standards,
                resulting in more consistent, higher-quality implementations.
              </p>
            </div>
            <div className="bg-[#1a1a1a] p-4 rounded-lg">
              <Brain className="w-10 h-10 text-orange-500 mb-3" />
              <h4 className="font-medium mb-2">Deeper Insights</h4>
              <p className="text-sm text-gray-400">
                Discover patterns, optimization opportunities, and architectural improvements
                that are impossible to detect with fragmented code analysis.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// Features Section
const FeaturesSection = () => {
  // Features
  const features = [
    {
      icon: <Bot className="w-6 h-6 text-orange-500" />,
      title: "Multiple AI Models",
      description: "Instant access to Claude 3, GPT-4, Gemini 1.5, Reka, and DeepSeek"
    },
    {
      icon: <ArrowLeftRight className="w-6 h-6 text-purple-500" />,
      title: "Seamless Model Switching",
      description: "Switch between models during conversations without losing context"
    },
    {
      icon: <Image className="w-6 h-6 text-green-500" />,
      title: "Image Analysis",
      description: "Understand and process images with compatible models"
    },
    {
      icon: <Music className="w-6 h-6 text-blue-500" />,
      title: "Audio Processing 🚧",
      description: "Process audio files up to 9.5 hours with speaker diarization 🏗️ (Coming Soon! 🔜)"
    },
    {
      icon: <Layers className="w-6 h-6 text-yellow-500" />,
      title: "Multi-Format Support",
      description: "Handle images, audio, and documents directly in chat"
    },
    {
      icon: <FolderPlus className="w-6 h-6 text-red-500" />,
      title: "Project-Based Workspaces 🚧",
      description: "Organize your work with custom threads and project spaces 🏗️ (Coming Soon! 🔜)"
    },
    {
      icon: <Users className="w-6 h-6 text-teal-500" />,
      title: "Team Collaboration",
      description: "Shared projects and scalable team access with usage monitoring"
    },
    {
      icon: <Lock className="w-6 h-6 text-indigo-500" />,
      title: "Data Control",
      description: "Your data stays yours - we don't use your data to train LLMs"
    },
    {
      icon: <PieChart className="w-6 h-6 text-pink-500" />,
      title: "Usage Analytics",
      description: "Real-time credit updates and detailed usage statistics"
    },
    {
      icon: <Smartphone className="w-6 h-6 text-lime-500" />,
      title: "Responsive Design",
      description: "Optimized for both mobile and desktop experiences"
    },
    {
      icon: <Globe className="w-6 h-6 text-orange-500" />,
      title: "Web Search & Image Generation",
      description: "Enhanced capabilities with GPT models for search and image creation"
    },
    {
      icon: <Smile className="w-6 h-6 text-purple-500" />,
      title: "Custom Instructions",
      description: "Set custom instructions per conversation for personalized interactions"
    }
  ];

  return (
    <section id="features" className="py-20 px-6 bg-[#1a1a1a]">
      <div className="max-w-6xl mx-auto">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent">
            Core AI Features
          </h2>
          <p className="text-xl text-gray-400 mb-6">
            Everything you need for advanced AI interactions in one platform
          </p>
        </div>
        
        <div className="grid md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-[#2a2a2a] p-6 rounded-lg hover:bg-[#333333]
                        transition-all duration-200"
            >
              <div className="mb-4">{feature.icon}</div>
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

// FAQs
const FaqSection = () => {
  const [expandedFaqs, setExpandedFaqs] = useState(new Set());
  
  const toggleFaq = (id) => {
    setExpandedFaqs(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };
  
  const faqs = [
    {
      id: 1,
      question: "How quickly can I get started?",
      answer: "Start chatting instantly after a 30-second signup. No API keys or complex setup required - just choose your model and begin. While you can start with simple queries, Henosis includes powerful features like custom AI instructions and project workspaces to help organize your work as you scale."
    },
    {
      id: 2,
      question: "How does Henosis compare to alternatives?",
      answer: "Unlike other tools that use RAG to reduce costs by showing AI only small pieces of context, Henosis provides models complete access to your entire codebase and conversation history up to their maximum context lengths. This means you get solutions based on full understanding, not partial context - especially critical for large projects and complex debugging."
    },
    {
      id: 3,
      question: "What are the available pricing tiers?",
      answer: `We offer flexible tiers to match your needs:<br/>
      • Personal ($10/mo): Standard usage limits<br/>
      • Personal+ ($40/mo): 4x usage limits, all features<br/>
      • Pro ($90/mo): 8x usage limits, priority support<br/>
      • Team ($200/mo): 20x usage, 15 team members<br/>
      • Team+ ($400/mo): 40x usage, 50 team members<br/>
      • Enterprise: Custom solutions<br/>
      Save 20% with annual billing. <a href="/pricing" class="text-orange-500 hover:text-orange-400 transition-colors">View detailed pricing →</a>`
    },
    {
      id: 4,
      question: "How do model usage limits work?",
      answer: `Your monthly credits can be used flexibly across models. For example, with the Personal plan:<br/>
      • Efficient models (Gemini Flash): ~190M tokens<br/>
      • Advanced models (Claude 3.5 Sonnet): ~4.8M tokens<br/>
      Higher tiers multiply these base limits:<br/>
      • Personal+: 4x<br/>
      • Pro: 8x<br/>
      • Team: 20x<br/>
      • Team+: 40x<br/>
      Additional credits can soon be purchased in addition to your plan. They have a 60 day expiration date and require an active subscription to use. Each model has provider-specific context limits (e.g., Claude 3 Opus: 200K tokens, GPT-4: 128K tokens).`
    },
    {
      id: 5,
      question: "What tasks is Henosis best for?",
      answer: `Henosis excels at:<br/>
      • Code Analysis: Full codebase review and debugging<br/>
      • AI Chat: Multi-model conversations<br/>
      • Project Organization: Separate workspaces<br/>
      • Team Collaboration: Shared context (Team+)<br/>
      • Model Comparison: Real-time model switching<br/>
      • Web Search: Enhanced GPT capabilities<br/>
      • Image Generation: AI image creation<br/>
      Future updates will expand these capabilities further.`
    },
    {
      id: 6,
      question: "How does project organization work?",
      answer: `Key project features:<br/>
      • Separate workspaces for different work streams<br/>
      • Seamless model switching<br/>
      • Custom instructions per project<br/>
      • Team sharing (Team tier+)<br/>
      • Usage analytics<br/>`
    }
  ];

  return (
    <section id="faq" className="py-20 px-6 bg-[#1a1a1a]">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-orange-500 to-red-500 bg-clip-text text-transparent">
          Frequently Asked Questions
        </h2>
        
        {/* FAQ items in a two-column grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Column */}
          <div className="space-y-6">
            {faqs.filter((_, idx) => idx % 2 === 0).map((faq) => (
              <div
                key={faq.id}
                className={`bg-[#2a2a2a] rounded-lg transition-all duration-200
                          ${expandedFaqs.has(faq.id) ? 'ring-2 ring-orange-500' : 'hover:bg-[#333333]'}`}
              >
                <button
                  onClick={() => toggleFaq(faq.id)}
                  className="w-full p-6 text-left flex justify-between items-start"
                >
                  <h3 className="font-semibold pr-8">{faq.question}</h3>
                  <ChevronRight
                    className={`w-5 h-5 flex-shrink-0 transition-transform duration-200
                              ${expandedFaqs.has(faq.id) ? 'transform rotate-90' : ''}`}
                  />
                </button>
                
                <div
                  className={`overflow-hidden transition-all duration-200
                            ${expandedFaqs.has(faq.id) ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}
                >
                  <div className="px-6 pb-6">
                    <div
                      className="text-gray-400 whitespace-pre-wrap"
                      dangerouslySetInnerHTML={{
                        __html: faq.answer.replace(/\n/g, '<br/>')
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          
          {/* Right Column */}
          <div className="space-y-6">
            {faqs.filter((_, idx) => idx % 2 === 1).map((faq) => (
              <div
                key={faq.id}
                className={`bg-[#2a2a2a] rounded-lg transition-all duration-200
                          ${expandedFaqs.has(faq.id) ? 'ring-2 ring-orange-500' : 'hover:bg-[#333333]'}`}
              >
                <button
                  onClick={() => toggleFaq(faq.id)}
                  className="w-full p-6 text-left flex justify-between items-start"
                >
                  <h3 className="font-semibold pr-8">{faq.question}</h3>
                  <ChevronRight
                    className={`w-5 h-5 flex-shrink-0 transition-transform duration-200
                              ${expandedFaqs.has(faq.id) ? 'transform rotate-90' : ''}`}
                  />
                </button>
                
                <div
                  className={`overflow-hidden transition-all duration-200
                            ${expandedFaqs.has(faq.id) ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'}`}
                >
                  <div className="px-6 pb-6">
                    <div
                      className="text-gray-400 whitespace-pre-wrap"
                      dangerouslySetInnerHTML={{
                        __html: faq.answer.replace(/\n/g, '<br/>')
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        
        <div className="mt-12 text-center">
          <p className="text-gray-400">
            Still have questions? {' '}
            <a
              href="mailto:henosischat@gmail.com"
              className="text-orange-500 hover:text-orange-400 transition-colors"
            >
              Contact our support team →
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

const Navbar = () => {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const navHeight = 80;
      const sectionTop = section.offsetTop - navHeight;
      window.scrollTo({
        top: sectionTop,
        behavior: 'smooth'
      });
      setMobileMenuOpen(false);
    }
  };
  
  return (
    <nav className="sticky top-0 z-50 flex justify-between items-center p-4 md:p-6
                    bg-[#1a1a1a] border-b border-[#2a2a2a]">
      <div className="flex items-center">
        <img src="/output.webp" alt="Henosis Logo" className="h-6 w-6 md:h-8 md:w-8 mr-2" />
        <span className="text-lg md:text-xl font-bold">henosis</span>
      </div>
      
      {/* Desktop navigation */}
      <div className="hidden md:flex items-center space-x-6">
        <button
          onClick={() => scrollToSection('hero')}
          className="text-gray-300 hover:text-white transition-colors"
        >
          Home
        </button>
        <button
          onClick={() => scrollToSection('pricing')}
          className="text-gray-300 hover:text-white transition-colors"
        >
          Pricing
        </button>
        <button
          onClick={() => scrollToSection('models')}
          className="text-gray-300 hover:text-white transition-colors"
        >
          Models
        </button>
        <button
          onClick={() => scrollToSection('features')}
          className="text-gray-300 hover:text-white transition-colors"
        >
          Features
        </button>
        <button
          onClick={() => navigate('/login')}
          className="text-gray-300 hover:text-white transition-colors"
        >
          Login
        </button>
        <button
          onClick={() => navigate('/register')}
          className="px-4 py-2 bg-gradient-to-r
                    from-orange-500 to-red-500 hover:from-red-500 hover:to-orange-600
                    rounded-lg transition-all duration-200"
        >
          Get Started
        </button>
      </div>
      
      {/* Mobile menu button */}
      <button 
        className="md:hidden p-2 rounded-md"
        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
      >
        {mobileMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
      </button>
      
      {/* Mobile menu */}
      <div className={`
        fixed inset-0 top-[72px] bg-[#1a1a1a] z-40 transform transition-transform duration-300 md:hidden
        ${mobileMenuOpen ? 'translate-x-0' : 'translate-x-full'}
      `}>
        <div className="flex flex-col p-6 space-y-4">
          <button
            onClick={() => scrollToSection('hero')}
            className="text-gray-300 hover:text-white transition-colors py-2"
          >
            Home
          </button>
          <button
            onClick={() => scrollToSection('pricing')}
            className="text-gray-300 hover:text-white transition-colors py-2"
          >
            Pricing
          </button>
          <button
            onClick={() => scrollToSection('models')}
            className="text-gray-300 hover:text-white transition-colors py-2"
          >
            Models
          </button>
          <button
            onClick={() => scrollToSection('features')}
            className="text-gray-300 hover:text-white transition-colors py-2"
          >
            Features
          </button>
          <button
            onClick={() => navigate('/login')}
            className="text-gray-300 hover:text-white transition-colors py-2"
          >
            Login
          </button>
          <button
            onClick={() => navigate('/register')}
            className="mt-4 py-3 px-4 bg-gradient-to-r
                      from-orange-500 to-red-500 hover:from-red-500 hover:to-orange-600
                      rounded-lg transition-all duration-200 text-center"
          >
            Get Started
          </button>
        </div>
      </div>
    </nav>
  );
};

/** 
 * LandingPage Component 
 * Main landing page showcasing Henosis Chat platform features and benefits 
 */
const LandingPage = () => {
  const [activeSection, setActiveSection] = useState('hero');
  
  // Track active section on scroll
  useEffect(() => {
    const handleScroll = () => {
      const sections = [
        'hero',
        'pricing',
        'models',
        'document-analysis',
        'content-analysis',
        'code-context',
        'search-comparison',
        'features',
        'faq'
      ];
      
      // Find the section that is currently in view
      const currentSection = sections.find(sectionId => {
        const section = document.getElementById(sectionId);
        if (section) {
          const rect = section.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });
      
      if (currentSection) {
        setActiveSection(currentSection);
      }
    };
    
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="min-h-screen bg-[#1a1a1a] text-white">
      <Navbar />
      
      {/* Side Navigation */}
      <AnchorNavigation activeSection={activeSection} />
      
      {/* Main Content */}
      <Hero />
      <SimplifiedPricing />
      <ModelAvailabilitySection />
      <DocumentAnalysisSection />
      <ContentAnalysisSection />
      <CodeComparisonSection />
      <SearchComparisonSection />
      <FeaturesSection />
      <FaqSection />
      
      {/* Final CTA */}
      <section className="py-20 px-6 bg-gradient-to-r from-orange-500 to-red-500 text-center">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-4xl font-bold mb-6">Ready to Elevate Your AI Experience?</h2>
          <p className="text-xl text-white mb-8 max-w-3xl mx-auto">
            Get started with Henosis Chat today and revolutionize your workflow with
            unrestricted AI capabilities and full context understanding.
          </p>
          <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
            <Link 
              to="/register"
              className="px-8 py-4 bg-white text-[#1a1a1a] rounded-lg
                      transition-all duration-200 text-lg font-medium"
            >
              Get Started
            </Link>
            <Link 
              to="/pricing"
              className="px-8 py-4 bg-transparent border-2 border-white text-white rounded-lg
                      hover:bg-white/10 transition-all duration-200 text-lg font-medium"
            >
              View Pricing
            </Link>
          </div>
        </div>
      </section>
      
      {/* Footer */}
      <footer className="border-t border-[#2a2a2a] py-12 px-6">
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <div className="flex items-center mb-4">
                <img src="/output.webp" alt="Henosis Logo" className="h-8 w-8 mr-2" />
                <span className="text-xl font-bold">henosis</span>
              </div>
              <p className="text-gray-400 text-sm mb-4">
                AI chat with maximum context and model flexibility.
              </p>
              <div className="flex space-x-4">
                <a href="https://twitter.com/henosischat" className="text-gray-400 hover:text-white">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                  </svg>
                </a>
                <a href="https://discord.gg/FS8hUmnkFv" className="text-gray-400 hover:text-white">
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3847-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0992 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.077 0 01-.0076-.1277c.1258-.0943.2517-.1923.3718-.2914a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.099.246.1981.3728.2924a.077.077 0 01-.0066.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.076 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.077 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.061 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9555-2.4189 2.157-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.9555 2.4189-2.1569 2.4189zm7.9748 0c-1.1825 0-2.1569-1.0857-2.1569-2.419 0-1.3332.9554-2.4189 2.1569-2.4189 1.2108 0 2.1757 1.0952 2.1568 2.419 0 1.3332-.946 2.4189-2.1568 2.4189Z"></path>
                  </svg>
                </a>
              </div>
            </div>
            
            <div>
              <h3 className="font-semibold mb-4">Product</h3>
              <ul className="space-y-2">
                <li><Link to="/pricing" className="text-gray-400 hover:text-white">Pricing</Link></li>
                <li><button onClick={() => document.getElementById('features').scrollIntoView()} className="text-gray-400 hover:text-white">Features</button></li>
                <li><button onClick={() => document.getElementById('models').scrollIntoView()} className="text-gray-400 hover:text-white">AI Models</button></li>
                <li><Link to="/changelog" className="text-gray-400 hover:text-white">Changelog</Link></li>
              </ul>
            </div>
            
            <div>
              <h3 className="font-semibold mb-4">Resources</h3>
              <ul className="space-y-2">
                <li><a href="https://discord.gg/FS8hUmnkFv" className="text-gray-400 hover:text-white">Community</a></li>
                <li><a href="/blog" className="text-gray-400 hover:text-white">Blog</a></li>
                <li><a href="/support" className="text-gray-400 hover:text-white">Support</a></li>
                <li><a href="mailto:henosischat@gmail.com" className="text-gray-400 hover:text-white">Contact</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="font-semibold mb-4">Legal</h3>
              <ul className="space-y-2">
                <li><Link to="/privacy" className="text-gray-400 hover:text-white">Privacy Policy</Link></li>
                <li><Link to="/terms" className="text-gray-400 hover:text-white">Terms of Service</Link></li>
              </ul>
            </div>
          </div>
          
          <div className="border-t border-[#2a2a2a] mt-8 pt-8 flex flex-col md:flex-row justify-between items-center">
            <span className="text-sm text-gray-400">© 2024 Henosis. All rights reserved.</span>
            <div className="mt-4 md:mt-0">
              <a href="mailto:henosischat@gmail.com" className="text-sm text-gray-400 hover:text-white">
                henosischat@gmail.com
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;