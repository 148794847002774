import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, Calendar, Clock, Share2 } from 'lucide-react';

// Dynamically import all blog post files from the current folder's "posts" directory.
// Each post file should export a "meta" object and a default component.
const postsContext = require.context('./posts', false, /\.js$/);

const postsArray = postsContext.keys()
  .map((key) => {
    const module = postsContext(key);
    // Expect each file to export a "meta" object.
    const meta = module.meta || {};
    // Derive an id from meta or from the filename if not provided
    const id = meta.id || key.replace('./', '').replace('.js', '');
    return {
      id,
      title: meta.title || 'Untitled',
      date: meta.date || '',
      readTime: meta.readTime || '',
      description: meta.description || '',
      ContentComponent: module.default,
    };
  })
  // Optionally sort your posts by date (newest first)
  .sort((a, b) => new Date(b.date) - new Date(a.date));

const POSTS = postsArray.reduce((acc, post) => {
  acc[post.id] = post;
  return acc;
}, {});

const Blog = () => {
  const [selectedPost, setSelectedPost] = useState(null);
  const [showShareNotification, setShowShareNotification] = useState(false);

  const handleCopyShareLink = () => {
    navigator.clipboard.writeText(window.location.href);
    setShowShareNotification(true);
    setTimeout(() => setShowShareNotification(false), 2000);
  };

  // Update the URL when a post is selected or deselected
  useEffect(() => {
    if (selectedPost) {
      window.history.pushState({}, '', `/blog/${selectedPost.id}`);
    } else {
      window.history.pushState({}, '', '/blog');
    }
  }, [selectedPost]);

  // Handle direct navigation to post URLs (e.g., /blog/<postId>)
  useEffect(() => {
    const path = window.location.pathname;
    const postId = path.split('/blog/')[1];
    if (postId && POSTS[postId] && !selectedPost) {
      setSelectedPost(POSTS[postId]);
    }
  }, [selectedPost]);

  return (
    <div className="flex flex-col min-h-screen bg-[#1a1a1a] text-white">
      {/* Header */}
      <header className="flex justify-between items-center p-3 bg-[#1a1a1a] w-full z-30 border-b border-[#2a2a2a]">
        <div className="flex items-center">
          <Link to="/" className="hidden lg:flex items-center cursor-pointer ml-2">
            <img src="/output.webp" alt="Logo" className="h-6 w-6 mr-2" />
            <div className="text-lg font-bold">henosis</div>
          </Link>
        </div>
        <div className="flex items-center space-x-4">
          <Link to="/chat" className="px-4 py-2 text-gray-300 hover:text-white transition-colors">
            Chat
          </Link>
          <Link to="/blog" className="px-4 py-2 text-purple-400 border-b-2 border-purple-500">
            Blog
          </Link>
        </div>
      </header>

      <main className="flex-grow flex justify-center px-4 py-8">
        <div className="max-w-3xl w-full">
          {selectedPost ? (
            // Single Post View
            <div className="space-y-6">
              <div className="flex items-center justify-between">
                <button
                  onClick={() => setSelectedPost(null)}
                  className="flex items-center text-gray-400 hover:text-white transition-colors"
                >
                  <ArrowLeft size={20} className="mr-2" />
                  Back to Posts
                </button>
                <div className="relative">
                  <button
                    onClick={handleCopyShareLink}
                    className="p-2 rounded hover:bg-[#2a2a2a] text-gray-400 hover:text-white transition-colors"
                    title="Share Post"
                  >
                    <Share2 size={20} />
                  </button>
                  {showShareNotification && (
                    <div className="absolute top-full right-0 mt-2 px-3 py-1 bg-purple-600 text-white text-sm rounded shadow-lg">
                      Copied!
                    </div>
                  )}
                </div>
              </div>

              <article className="prose prose-invert prose-purple max-w-none">
                <div className="flex items-center text-gray-400 mb-8 space-x-4">
                  <span className="flex items-center">
                    <Calendar size={16} className="mr-2" />
                    {selectedPost.date}
                  </span>
                  <span className="flex items-center">
                    <Clock size={16} className="mr-2" />
                    {selectedPost.readTime}
                  </span>
                </div>
                <div className="leading-relaxed text-gray-200">
                  <selectedPost.ContentComponent />
                </div>
              </article>
            </div>
          ) : (
            // Posts List View
            <div className="space-y-8">
              <h1 className="text-4xl font-bold mb-8">Blog Posts</h1>
              <div className="grid gap-6">
                {postsArray.map((post) => (
                  <div
                    key={post.id}
                    className="bg-[#2a2a2a] rounded-lg p-6 hover:bg-[#3a3a3a] transition-colors cursor-pointer"
                    onClick={() => setSelectedPost(post)}
                  >
                    <h2 className="text-2xl font-semibold mb-3 text-purple-400">
                      {post.title}
                    </h2>
                    <p className="text-gray-300 mb-4">{post.description}</p>
                    <div className="flex items-center text-gray-400 space-x-4">
                      <span className="flex items-center">
                        <Calendar size={16} className="mr-2" />
                        {post.date}
                      </span>
                      <span className="flex items-center">
                        <Clock size={16} className="mr-2" />
                        {post.readTime}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default Blog;
