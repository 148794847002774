import React, { useState } from 'react';
import {
  Search,
  Calendar,
  Clock,
  User,
  Tag,
  ArrowRight,
  FileText,
  Download,
  ChevronRight,
  MessageSquare,
  BookOpen,
  Mail
} from 'lucide-react';

const ProfessionalResourcesPage = () => {
  const [activeCategory, setActiveCategory] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedArticle, setSelectedArticle] = useState(null);
  
  // Resource categories
  const categories = [
    { id: 'all', name: 'All Resources' },
    { id: 'tax', name: 'Tax Updates' },
    { id: 'business', name: 'Business Strategy' },
    { id: 'personal', name: 'Personal Finance' },
    { id: 'retirement', name: 'Retirement Planning' }
  ];
  
  // Blog articles data
  const articles = [
    {
      id: 1,
      title: "2023 Tax Law Changes: What You Need to Know",
      category: "tax",
      date: "March 15, 2023",
      author: "Sarah Johnson, CPA",
      authorImage: "https://randomuser.me/api/portraits/women/64.jpg",
      readTime: 8,
      excerpt: "The tax landscape is constantly changing. Stay informed about the latest tax law updates and how they might impact your personal and business tax situation.",
      image: "https://images.unsplash.com/photo-1554224155-6726b3ff858f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1472&q=80",
      tags: ["Tax Planning", "Tax Law", "IRS Updates"],
      isFeatured: true,
      content: `
        <h2>Key Tax Changes for 2023</h2>
        <p>As we navigate the 2023 tax year, several significant changes to the tax code will affect both individuals and businesses. Understanding these changes is crucial for effective tax planning and compliance.</p>
        
        <h3>Individual Tax Changes</h3>
        <p>The IRS has announced inflation adjustments that will impact individual taxpayers in several ways:</p>
        <ul>
          <li>Standard deduction increases to $13,850 for single filers and $27,700 for married couples filing jointly</li>
          <li>Income tax brackets have been adjusted upward by approximately 7% to account for inflation</li>
          <li>The annual gift tax exclusion increases to $17,000 per recipient</li>
          <li>Retirement contribution limits have increased: $22,500 for 401(k) plans and $6,500 for IRAs, with catch-up contributions remaining at $7,500 and $1,000 respectively</li>
        </ul>
        
        <h3>Business Tax Changes</h3>
        <p>Business owners should be aware of these important modifications:</p>
        <ul>
          <li>The Section 179 deduction limit increases to $1,160,000, with a phase-out threshold beginning at $2,890,000</li>
          <li>Research and development expenses must now be amortized over 5 years for domestic expenses and 15 years for foreign expenses, rather than deducted immediately</li>
          <li>The business meal deduction returns to 50% after the temporary 100% deduction for restaurant meals expired at the end of 2022</li>
          <li>Enhanced reporting requirements for digital asset transactions take effect</li>
        </ul>
        
        <h3>Strategic Tax Planning Considerations</h3>
        <p>With these changes in mind, consider the following strategies to optimize your tax position:</p>
        <ul>
          <li>Review your withholding and estimated tax payments to ensure they align with the new brackets</li>
          <li>Evaluate the timing of income and deductions, particularly for business owners affected by the R&D amortization requirements</li>
          <li>Maximize retirement contributions to take advantage of increased limits</li>
          <li>For business owners, assess capital expenditure plans in light of current Section 179 and bonus depreciation rules</li>
          <li>Consider the impact of inflation adjustments on your estate planning strategy</li>
        </ul>
        
        <h3>Looking Ahead</h3>
        <p>Tax planning shouldn't be a once-a-year activity. Regular reviews with your tax professional throughout the year can help identify opportunities and ensure compliance with evolving requirements. At Alpine Financial, we provide proactive guidance to help our clients navigate the complex tax landscape and implement effective strategies for their unique situations.</p>
      `
    },
    {
      id: 2,
      title: "Strategic Financial Planning for Small Business Owners",
      category: "business",
      date: "February 8, 2023",
      author: "David Wilson",
      authorImage: "https://randomuser.me/api/portraits/men/22.jpg",
      readTime: 10,
      excerpt: "As a small business owner, strategic financial planning is essential for sustainable growth. Learn key strategies to improve cash flow, reduce tax liability, and build business value.",
      image: "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      tags: ["Small Business", "Financial Planning", "Business Strategy"],
      isFeatured: true,
      content: `
        <h2>Financial Planning Essentials for Business Success</h2>
        <p>Small business owners face unique financial challenges that require both short-term tactical management and long-term strategic planning. A comprehensive financial strategy addresses not only the day-to-day operations but also builds a foundation for sustainable growth and eventual transition or succession.</p>
        
        <h3>Cash Flow Management: The Lifeblood of Your Business</h3>
        <p>Cash flow challenges remain the primary reason small businesses fail. Implementing effective cash flow management involves:</p>
        <ul>
          <li>Creating and regularly updating cash flow projections to anticipate potential shortfalls</li>
          <li>Establishing clear payment terms and implementing efficient invoicing procedures</li>
          <li>Monitoring accounts receivable aging and developing strategies for faster collections</li>
          <li>Building a cash reserve to cover 3-6 months of operating expenses</li>
          <li>Negotiating favorable payment terms with vendors and suppliers</li>
        </ul>
        
        <h3>Tax Optimization Strategies</h3>
        <p>Strategic tax planning throughout the year can significantly reduce your business's tax burden:</p>
        <ul>
          <li>Select the optimal business structure for your specific situation (S-Corp, LLC, C-Corp)</li>
          <li>Maximize qualified business deductions and credits</li>
          <li>Implement retirement plans that provide both tax advantages and wealth-building opportunities</li>
          <li>Consider income timing strategies to manage tax brackets</li>
          <li>Explore cost segregation studies for property owners to accelerate depreciation</li>
        </ul>
        
        <h3>Building Business Value</h3>
        <p>Whether you plan to sell your business or pass it to the next generation, building its value should be a primary goal:</p>
        <ul>
          <li>Develop systems and processes that don't rely on your day-to-day involvement</li>
          <li>Diversify your customer base to reduce concentration risk</li>
          <li>Invest in technology that increases efficiency and scalability</li>
          <li>Build and retain a strong management team</li>
          <li>Protect intellectual property and other intangible assets</li>
          <li>Maintain clean, accurate financial records with forward-looking projections</li>
        </ul>
        
        <h3>Integrating Personal and Business Financial Planning</h3>
        <p>For most small business owners, business and personal finances are inextricably linked. A comprehensive approach includes:</p>
        <ul>
          <li>Establishing appropriate boundaries between business and personal finances</li>
          <li>Creating a personal financial safety net independent of the business</li>
          <li>Developing a retirement strategy that may include business equity</li>
          <li>Implementing risk management strategies including proper insurance coverage</li>
          <li>Estate planning that addresses business succession and minimizes transfer taxes</li>
        </ul>
        
        <h3>The Path Forward</h3>
        <p>Financial planning for your business is not a one-time event but an ongoing process that evolves as your business grows and market conditions change. Working with financial professionals who understand both business operations and personal finances ensures your strategies remain aligned with your goals.</p>
      `
    },
    {
      id: 3,
      title: "Retirement Planning in Your 40s and 50s: Catching Up and Maximizing Growth",
      category: "retirement",
      date: "January 20, 2023",
      author: "Emily Rodriguez",
      authorImage: "https://randomuser.me/api/portraits/women/45.jpg",
      readTime: 7,
      excerpt: "If your retirement savings aren't where you'd like them to be in your 40s or 50s, you still have time to make significant progress. Learn strategies to catch up and maximize your retirement nest egg.",
      image: "https://images.unsplash.com/photo-1535320903710-d993d3d77d29?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      tags: ["Retirement", "Financial Planning", "Investment Strategy"],
      isFeatured: false,
      content: `
        <h2>Making the Most of Your Peak Earning Years</h2>
        <p>Your 40s and 50s typically represent your peak earning years, providing a valuable opportunity to accelerate your retirement savings. Whether you're playing catch-up or fine-tuning an existing plan, strategic decisions during this critical period can significantly impact your financial security in retirement.</p>
        
        <h3>Retirement Catch-Up Strategies</h3>
        <p>If your retirement savings aren't where you'd like them to be, consider implementing these catch-up strategies:</p>
        <ul>
          <li>Maximize contributions to tax-advantaged accounts: The 2023 contribution limit for 401(k) plans is $22,500, with an additional $7,500 catch-up contribution allowed for those 50 and older</li>
          <li>Take advantage of IRA catch-up provisions: Those 50+ can contribute an extra $1,000 above the standard $6,500 limit</li>
          <li>Consider a Health Savings Account (HSA) as a supplemental retirement vehicle if you have a qualifying high-deductible health plan</li>
          <li>Evaluate whether you should prioritize retirement savings over college funding for children</li>
          <li>Explore options for additional income streams through side businesses or consulting work dedicated to retirement savings</li>
        </ul>
        
        <h3>Optimizing Investment Strategies</h3>
        <p>Your investment approach in this phase should balance growth potential with increasing focus on risk management:</p>
        <ul>
          <li>Review and adjust your asset allocation to ensure it aligns with your time horizon and risk tolerance</li>
          <li>Consider a "bucket strategy" that segments investments based on when you'll need the funds</li>
          <li>Evaluate whether traditional tax-deferred or Roth accounts are more advantageous based on your current and projected future tax situations</li>
          <li>Minimize investment fees and expenses that can significantly impact long-term returns</li>
          <li>Consider the role of guaranteed income products as part of a diversified retirement income strategy</li>
        </ul>
        
        <h3>Retirement Lifestyle Planning</h3>
        <p>Effective retirement planning extends beyond financial considerations to lifestyle planning:</p>
        <ul>
          <li>Develop a specific vision for your retirement lifestyle and associated costs</li>
          <li>Consider whether phased retirement might be preferable to a complete exit from the workforce</li>
          <li>Evaluate potential relocation to areas with lower costs of living or tax advantages</li>
          <li>Assess how healthcare costs, including long-term care, will impact your retirement needs</li>
          <li>Begin discussions with your spouse or partner to ensure alignment on retirement expectations and goals</li>
        </ul>
        
        <h3>Preparing for the Transition</h3>
        <p>As retirement approaches, preparation for the transition becomes increasingly important:</p>
        <ul>
          <li>Develop a detailed retirement income plan that identifies all income sources and projected expenses</li>
          <li>Create a Social Security claiming strategy that maximizes lifetime benefits</li>
          <li>Consider debt reduction strategies to enter retirement with minimal obligations</li>
          <li>Review and update estate planning documents to reflect current wishes and circumstances</li>
          <li>Assess insurance needs including life, health, and long-term care coverage</li>
        </ul>
        
        <h3>The Path Forward</h3>
        <p>While starting retirement planning earlier is ideal, your 40s and 50s still provide significant opportunities to strengthen your financial position. The key is taking decisive action now and maintaining discipline as you implement your strategy. Working with a financial advisor who specializes in retirement planning can help you identify the most impactful approaches for your specific situation.</p>
      `
    },
    {
      id: 4,
      title: "Smart Strategies for Managing and Reducing Debt",
      category: "personal",
      date: "December 10, 2022",
      author: "Michael Chen",
      authorImage: "https://randomuser.me/api/portraits/men/54.jpg",
      readTime: 6,
      excerpt: "Debt management is a crucial component of financial health. Learn effective strategies for prioritizing debt repayment, reducing interest costs, and achieving financial freedom faster.",
      image: "https://images.unsplash.com/photo-1579621970588-a35d0e7ab9b6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      tags: ["Debt Management", "Personal Finance", "Financial Planning"],
      isFeatured: false,
      content: `
        <h2>Taking Control of Your Debt</h2>
        <p>Debt has become a common element in most Americans' financial lives, from mortgages and auto loans to credit cards and student loans. While not all debt is harmful, excessive or high-interest debt can significantly impede your progress toward financial goals. Implementing effective debt management strategies can accelerate your journey to financial freedom.</p>
        
        <h3>Understanding Your Debt Landscape</h3>
        <p>Before developing a debt reduction strategy, gain a clear understanding of your current situation:</p>
        <ul>
          <li>Create a comprehensive inventory of all debts, including balances, interest rates, minimum payments, and terms</li>
          <li>Calculate your debt-to-income ratio to assess the overall impact on your financial health</li>
          <li>Distinguish between "good debt" (potentially wealth-building, like mortgages) and "bad debt" (high-interest consumer debt)</li>
          <li>Review credit reports to ensure accuracy and identify all outstanding obligations</li>
          <li>Understand the terms and conditions of each debt, including prepayment penalties or variable interest provisions</li>
        </ul>
        
        <h3>Prioritizing Debt Repayment</h3>
        <p>Two primary approaches to debt repayment have proven effective:</p>
        <ul>
          <li><strong>Avalanche Method:</strong> Focus on the highest-interest debt first while making minimum payments on others. This approach minimizes total interest paid over time.</li>
          <li><strong>Snowball Method:</strong> Start with the smallest balance regardless of interest rate to build momentum with quick wins. While potentially more expensive, the psychological benefits can improve long-term success for many people.</li>
        </ul>
        
        <h3>Strategies to Reduce Interest Costs</h3>
        <p>Lowering the interest rate on your existing debt can significantly accelerate payoff:</p>
        <ul>
          <li>Consider balance transfer offers for high-interest credit card debt, being mindful of transfer fees and promotional period deadlines</li>
          <li>Explore personal loan consolidation for multiple high-interest debts</li>
          <li>Investigate refinancing options for mortgages, auto loans, or student loans when interest rate environments are favorable</li>
          <li>Contact creditors directly to negotiate lower interest rates, especially if you have a strong payment history</li>
          <li>For federal student loans, evaluate income-driven repayment plans or forgiveness programs if eligible</li>
        </ul>
        
        <h3>Creating a Sustainable Debt Reduction Plan</h3>
        <p>Effective debt management requires a comprehensive approach:</p>
        <ul>
          <li>Build a realistic budget that prioritizes debt repayment while maintaining emergency savings</li>
          <li>Consider the "debt snowball+" approach: combine debt avalanche and snowball methods by focusing on high-interest debt that can be eliminated quickly</li>
          <li>Identify opportunities to increase income temporarily through overtime, side gigs, or selling unused items</li>
          <li>Implement spending controls to prevent accumulating new debt while paying down existing balances</li>
          <li>Automate payments to ensure consistency and avoid late fees</li>
        </ul>
        
        <h3>Beyond Debt Reduction: Building Financial Resilience</h3>
        <p>As you make progress on debt reduction, focus on building financial stability:</p>
        <ul>
          <li>Maintain an emergency fund of 3-6 months of expenses to avoid relying on credit for unexpected costs</li>
          <li>Develop a personalized debt ceiling guideline (such as keeping total debt payments below 36% of gross income)</li>
          <li>Implement systems to track spending and identify potential budget leaks</li>
          <li>As debt decreases, gradually increase retirement savings contributions</li>
          <li>Consider working with a financial advisor to develop a comprehensive financial plan that incorporates debt management</li>
        </ul>
        
        <h3>The Path Forward</h3>
        <p>Becoming debt-free requires patience, discipline, and strategic planning. By implementing these strategies and maintaining focus on your long-term financial goals, you can transform your relationship with debt and create a foundation for lasting financial security.</p>
      `
    },
    {
      id: 5,
      title: "Navigating the Complexities of Estate Planning: Beyond the Basics",
      category: "personal",
      date: "November 15, 2022",
      author: "Sarah Johnson, CPA",
      authorImage: "https://randomuser.me/api/portraits/women/64.jpg",
      readTime: 9,
      excerpt: "Comprehensive estate planning goes far beyond creating a simple will. Learn about advanced strategies that can protect your assets, minimize taxes, and ensure your wishes are carried out.",
      image: "https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      tags: ["Estate Planning", "Wealth Management", "Tax Planning"],
      isFeatured: false,
      content: `
        <h2>Estate Planning for Modern Families</h2>
        <p>Estate planning has evolved significantly from the days when a simple will was sufficient for most people. Today's complex family structures, digital assets, and changing tax laws require a more sophisticated approach to ensure your wishes are honored and your loved ones protected.</p>
        
        <h3>Beyond the Basic Will: Essential Estate Planning Documents</h3>
        <p>A comprehensive estate plan typically includes multiple legal instruments:</p>
        <ul>
          <li><strong>Last Will and Testament:</strong> The foundation document that directs distribution of probate assets and names guardians for minor children</li>
          <li><strong>Revocable Living Trust:</strong> Provides for management of assets during incapacity and allows for private transfer of assets outside of probate</li>
          <li><strong>Financial Power of Attorney:</strong> Designates someone to manage financial affairs if you become incapacitated</li>
          <li><strong>Healthcare Power of Attorney:</strong> Appoints someone to make medical decisions on your behalf when you cannot</li>
          <li><strong>Advance Healthcare Directive/Living Will:</strong> Specifies your wishes regarding life-sustaining treatments</li>
          <li><strong>HIPAA Authorization:</strong> Allows specified individuals to access your medical information</li>
        </ul>
        
        <h3>Strategies for Tax Efficiency and Asset Protection</h3>
        <p>Advanced planning can help minimize estate taxes and protect assets for your beneficiaries:</p>
        <ul>
          <li><strong>Irrevocable Life Insurance Trust (ILIT):</strong> Keeps life insurance proceeds outside your taxable estate while providing liquidity for estate taxes</li>
          <li><strong>Charitable Remainder Trusts:</strong> Provide income during your lifetime while benefiting charities upon your death</li>
          <li><strong>Family Limited Partnerships:</strong> Can facilitate the transfer of business interests while maintaining control and potentially achieving valuation discounts</li>
          <li><strong>Spousal Lifetime Access Trusts (SLATs):</strong> Allow married couples to utilize gift tax exemptions while maintaining indirect access to assets</li>
          <li><strong>Asset Protection Trusts:</strong> Structured properly, these can shield assets from future creditors while allowing for beneficial enjoyment</li>
        </ul>
        
        <h3>Planning for Special Circumstances</h3>
        <p>Many families have unique situations requiring specialized planning:</p>
        <ul>
          <li><strong>Special Needs Planning:</strong> Structured properly, these trusts can provide for loved ones with disabilities without jeopardizing government benefits</li>
          <li><strong>Blended Family Planning:</strong> Techniques to balance the needs of a current spouse with children from previous relationships</li>
          <li><strong>Business Succession Planning:</strong> Structured arrangements for the transition of family businesses, including buy-sell agreements and management succession</li>
          <li><strong>International Estate Planning:</strong> Strategies for those with assets or heirs in multiple countries</li>
          <li><strong>Digital Asset Planning:</strong> Ensuring proper management and transfer of online accounts, cryptocurrencies, and digital collections</li>
        </ul>
        
        <h3>The Importance of Regular Review and Updates</h3>
        <p>Estate planning is not a "set it and forget it" activity. Your plan should be reviewed and potentially updated after:</p>
        <ul>
          <li>Major life events (marriage, divorce, births, deaths)</li>
          <li>Significant changes in financial circumstances</li>
          <li>Relocation to a different state</li>
          <li>Changes in estate, gift, or income tax laws</li>
          <li>Changes in your wishes or beneficiary circumstances</li>
          <li>At minimum, every 3-5 years to ensure alignment with current laws and personal goals</li>
        </ul>
        
        <h3>The Path Forward</h3>
        <p>Effective estate planning requires collaboration among various professionals, including estate attorneys, financial advisors, tax professionals, and insurance specialists. Working with an experienced team ensures your plan addresses all aspects of your estate and remains current with changing laws and family circumstances.</p>
      `
    },
    {
      id: 6,
      title: "Year-End Tax Planning Strategies for Businesses",
      category: "tax",
      date: "October 25, 2022",
      author: "Michael Chen",
      authorImage: "https://randomuser.me/api/portraits/men/54.jpg",
      readTime: 8,
      excerpt: "Proactive year-end tax planning can significantly reduce your business's tax liability. Explore key strategies to implement before December 31st to optimize your tax position.",
      image: "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      tags: ["Tax Planning", "Business Strategy", "Year-End Planning"],
      isFeatured: false,
      content: `
        <h2>Maximizing Tax Savings Before Year-End</h2>
        <p>As the year draws to a close, business owners have a valuable opportunity to implement strategic tax planning measures that can significantly impact their overall tax liability. Taking action before December 31st allows you to take advantage of available deductions, credits, and timing strategies that might otherwise be lost.</p>
        
        <h3>Income and Expense Timing Strategies</h3>
        <p>Strategic timing of income recognition and expenses can optimize your tax position:</p>
        <ul>
          <li>For cash-basis businesses, consider deferring income by delaying December invoicing until January if you expect to be in a lower tax bracket next year</li>
          <li>Conversely, accelerate income into the current year if you anticipate being in a higher tax bracket next year</li>
          <li>Prepay deductible expenses such as insurance premiums, rent, or subscriptions that cover periods in the first few months of next year</li>
          <li>Purchase needed equipment or supplies before year-end to take advantage of Section 179 expensing or bonus depreciation</li>
          <li>Consider the timing of bonuses: accrual-basis businesses can generally deduct bonuses in the current year if declared before year-end and paid within 2½ months of year-end</li>
        </ul>
        
        <h3>Tax Credits and Deductions</h3>
        <p>Ensure you're maximizing available tax incentives:</p>
        <ul>
          <li><strong>Research and Development Credit:</strong> Document and claim qualified R&D activities, which extend beyond traditional scientific research to include process improvements and software development</li>
          <li><strong>Work Opportunity Tax Credit:</strong> For hiring individuals from targeted groups facing barriers to employment</li>
          <li><strong>Business Energy Credits:</strong> For installation of solar, wind, or other renewable energy sources</li>
          <li><strong>Retirement Plan Establishment:</strong> Set up a qualified retirement plan before December 31st (though contributions for some plans can be made until the tax filing deadline)</li>
          <li><strong>Vehicle Deductions:</strong> If purchasing a business vehicle, consider the timing and weight (vehicles over 6,000 pounds may qualify for more favorable depreciation)</li>
        </ul>
        
        <h3>Business Structure and Owner Considerations</h3>
        <p>Year-end is an ideal time to evaluate structural and owner-related tax matters:</p>
        <ul>
          <li>Review your business entity choice to ensure it remains optimal for current tax laws and business circumstances</li>
          <li>For S corporations, ensure owner-employee compensation is "reasonable" to avoid IRS scrutiny</li>
          <li>Consider owner retirement contributions to SEP IRAs, Solo 401(k)s, or defined benefit plans</li>
          <li>Evaluate the potential benefit of establishing a accountable plan for employee expense reimbursements</li>
          <li>For businesses with international operations, review transfer pricing and foreign income reporting requirements</li>
        </ul>
        
        <h3>Strategic Loss Harvesting and Entity Planning</h3>
        <p>Additional strategies for businesses with multiple entities or investment holdings:</p>
        <ul>
          <li>Harvest losses in investment portfolios to offset capital gains</li>
          <li>Consider restructuring entity relationships to optimize overall tax efficiency</li>
          <li>Evaluate whether to elect out of 163(j) interest expense limitations for real estate businesses</li>
          <li>For businesses with net operating losses, carefully plan their utilization to maximize tax benefits</li>
          <li>Consider establishing additional business entities before year-end if beneficial for liability protection or tax purposes</li>
        </ul>
        
        <h3>State and Local Tax Planning</h3>
        <p>Don't overlook opportunities at the state and local level:</p>
        <ul>
          <li>Review state tax nexus issues, especially if your business has expanded its geographic footprint</li>
          <li>Identify state-specific credits and incentives for which your business might qualify</li>
          <li>Consider the implications of remote workers on state withholding and unemployment insurance obligations</li>
          <li>Evaluate whether your current apportionment methodology is most advantageous</li>
          <li>For businesses with significant state tax liabilities, consider whether a separate entity structure could provide benefits</li>
        </ul>
        
        <h3>The Path Forward</h3>
        <p>Year-end tax planning is most effective when integrated with your overall business strategy and long-term goals. Working with experienced tax professionals can help identify the most impactful strategies for your specific situation and ensure compliance with increasingly complex tax regulations.</p>
      `
    }
  ];
  
  // Filter articles based on active category and search query
  const filteredArticles = articles.filter(article => {
    const matchesCategory = activeCategory === 'all' || article.category === activeCategory;
    const matchesSearch = searchQuery === '' || 
      article.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      article.excerpt.toLowerCase().includes(searchQuery.toLowerCase()) ||
      article.tags.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase()));
    
    return matchesCategory && matchesSearch;
  });
  
  // Sample downloadable resources
  const downloadableResources = [
    {
      id: 1,
      title: "2023 Tax Planning Guide",
      description: "Comprehensive guide to tax planning strategies for individuals and businesses.",
      fileType: "PDF",
      fileSize: "2.4 MB",
      thumbnail: "https://images.unsplash.com/photo-1553484771-47a3dae7bf1d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      categories: ["tax"]
    },
    {
      id: 2,
      title: "Retirement Income Calculator",
      description: "Interactive spreadsheet to estimate your retirement income needs and current readiness.",
      fileType: "Excel",
      fileSize: "1.8 MB",
      thumbnail: "https://images.unsplash.com/photo-1593538312308-d4c29d8dc7f1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      categories: ["retirement", "personal"]
    },
    {
      id: 3,
      title: "Small Business Cash Flow Projection Template",
      description: "Template to forecast and manage your business cash flow for the next 12 months.",
      fileType: "Excel",
      fileSize: "1.2 MB",
      thumbnail: "https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      categories: ["business"]
    }
  ];
  
  // Filter downloadable resources based on active category
  const filteredResources = activeCategory === 'all' 
    ? downloadableResources 
    : downloadableResources.filter(resource => resource.categories.includes(activeCategory));
  
  // Function to handle article selection
  const handleArticleSelect = (article) => {
    setSelectedArticle(article);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  // Function to format date
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Header Space - to account for fixed header */}
      <div className="h-20"></div>
      
      {/* Page Header */}
      <section className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-16">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h1 className="text-3xl sm:text-4xl font-bold mb-4">
              {selectedArticle ? selectedArticle.title : 'Financial Resources & Insights'}
            </h1>
            <p className="text-xl text-blue-100">
              {selectedArticle 
                ? selectedArticle.excerpt 
                : 'Expert guidance, articles, and tools to help you navigate your financial journey with confidence.'
              }
            </p>
          </div>
        </div>
      </section>
      
      {selectedArticle ? (
        /* Single Article View */
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
          <button 
            onClick={() => setSelectedArticle(null)}
            className="flex items-center text-blue-600 hover:text-blue-800 mb-8"
          >
            <svg className="h-4 w-4 mr-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 12H5M5 12L12 19M5 12L12 5" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            Back to Resources
          </button>
          
          <div className="grid md:grid-cols-3 gap-8">
            <div className="md:col-span-2">
              <div className="bg-white rounded-lg shadow-md overflow-hidden mb-8">
                <img 
                  src={selectedArticle.image} 
                  alt={selectedArticle.title}
                  className="w-full h-80 object-cover"
                />
                <div className="p-8">
                  <div className="flex flex-wrap items-center text-sm text-gray-500 mb-4">
                    <span className="flex items-center mr-4">
                      <Calendar className="h-4 w-4 mr-1" />
                      {formatDate(selectedArticle.date)}
                    </span>
                    <span className="flex items-center mr-4">
                      <Clock className="h-4 w-4 mr-1" />
                      {selectedArticle.readTime} min read
                    </span>
                    <span className="flex items-center">
                      <Tag className="h-4 w-4 mr-1" />
                      {categories.find(c => c.id === selectedArticle.category)?.name}
                    </span>
                  </div>
                  
                  <div className="prose prose-blue max-w-none" dangerouslySetInnerHTML={{ __html: selectedArticle.content }}></div>
                  
                  <div className="border-t border-gray-200 mt-8 pt-8">
                    <div className="flex items-center">
                      <img 
                        src={selectedArticle.authorImage} 
                        alt={selectedArticle.author}
                        className="h-12 w-12 rounded-full mr-4"
                      />
                      <div>
                        <h4 className="font-bold text-gray-900">About the Author</h4>
                        <p className="text-gray-600">{selectedArticle.author}</p>
                      </div>
                    </div>
                  </div>
                  
                  <div className="border-t border-gray-200 mt-8 pt-8">
                    <h4 className="font-bold text-gray-900 mb-4">Related Tags</h4>
                    <div className="flex flex-wrap gap-2">
                      {selectedArticle.tags.map((tag, index) => (
                        <span key={index} className="bg-gray-100 text-gray-800 px-3 py-1 rounded-full text-sm">
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                  
                  <div className="border-t border-gray-200 mt-8 pt-8 flex justify-between">
                    <button className="text-blue-600 hover:text-blue-800">
                      Share Article
                    </button>
                    <button className="text-blue-600 hover:text-blue-800">
                      Print Article
                    </button>
                  </div>
                </div>
              </div>
              
              <h3 className="text-2xl font-bold text-gray-900 mb-6">More Articles You Might Like</h3>
              <div className="grid sm:grid-cols-2 gap-6">
                {articles
                  .filter(article => 
                    article.id !== selectedArticle.id && 
                    (article.category === selectedArticle.category || 
                     article.tags.some(tag => selectedArticle.tags.includes(tag)))
                  )
                  .slice(0, 2)
                  .map(article => (
                    <div key={article.id} className="bg-white rounded-lg shadow-md overflow-hidden">
                      <img 
                        src={article.image} 
                        alt={article.title}
                        className="w-full h-48 object-cover"
                      />
                      <div className="p-6">
                        <h4 className="font-bold text-gray-900 mb-2">{article.title}</h4>
                        <p className="text-gray-600 text-sm mb-4 line-clamp-2">{article.excerpt}</p>
                        <button
                          onClick={() => handleArticleSelect(article)}
                          className="flex items-center text-blue-600 hover:text-blue-800 text-sm font-medium"
                        >
                          Read Article
                          <ChevronRight className="h-4 w-4 ml-1" />
                        </button>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            
            <div className="md:col-span-1">
              <div className="bg-white rounded-lg shadow-md p-6 sticky top-24">
                <h3 className="text-lg font-bold text-gray-900 mb-4">Get Personalized Advice</h3>
                <p className="text-gray-600 mb-6">
                  Have questions about this topic? Schedule a complimentary consultation with one of our experts.
                </p>
                
                <a 
                  href="#contact" 
                  className="block w-full bg-blue-600 text-white text-center px-4 py-3 rounded-md hover:bg-blue-700 transition duration-200 mb-4"
                >
                  Schedule a Consultation
                </a>
                
                <div className="border-t border-gray-200 mt-8 pt-8">
                  <h4 className="font-bold text-gray-900 mb-4">Related Resources</h4>
                  <div className="space-y-4">
                    {downloadableResources
                      .filter(resource => 
                        resource.categories.includes(selectedArticle.category) ||
                        selectedArticle.tags.some(tag => 
                          tag.toLowerCase().includes(resource.title.toLowerCase())
                        )
                      )
                      .map(resource => (
                        <div key={resource.id} className="flex items-start">
                          <div className="h-10 w-10 bg-blue-100 rounded flex items-center justify-center mr-3 flex-shrink-0">
                            <FileText className="h-5 w-5 text-blue-600" />
                          </div>
                          <div>
                            <h5 className="font-medium text-gray-900">{resource.title}</h5>
                            <p className="text-xs text-gray-500 mb-1">{resource.fileType} · {resource.fileSize}</p>
                            <button className="text-blue-600 hover:text-blue-800 text-sm flex items-center">
                              Download
                              <Download className="h-3 w-3 ml-1" />
                            </button>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </div>
                
                <div className="border-t border-gray-200 mt-8 pt-8">
                  <h4 className="font-bold text-gray-900 mb-4">Subscribe to Our Newsletter</h4>
                  <p className="text-sm text-gray-600 mb-4">
                    Get the latest articles, resources, and financial tips delivered to your inbox.
                  </p>
                  <form className="space-y-3">
                    <input 
                      type="email" 
                      placeholder="Your email address" 
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <button className="w-full bg-gray-100 hover:bg-gray-200 text-gray-800 font-medium px-4 py-2 rounded-md transition duration-200">
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        /* Resources List View */
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-12">
          {/* Search and Filter Bar */}
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8 gap-4">
            <div className="relative flex-grow max-w-md">
              <input
                type="text"
                placeholder="Search resources..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            
            <div className="flex space-x-2 overflow-x-auto pb-2 md:pb-0 w-full md:w-auto">
              {categories.map((category) => (
                <button
                  key={category.id}
                  onClick={() => setActiveCategory(category.id)}
                  className={`px-4 py-2 rounded-md text-sm font-medium whitespace-nowrap ${
                    activeCategory === category.id
                      ? 'bg-blue-600 text-white'
                      : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                  }`}
                >
                  {category.name}
                </button>
              ))}
            </div>
          </div>
          
          {/* Featured Articles */}
          {activeCategory === 'all' && (
            <>
              <h2 className="text-2xl font-bold text-gray-900 mb-6">Featured Articles</h2>
              <div className="grid md:grid-cols-2 gap-8 mb-12">
                {articles.filter(article => article.isFeatured).map(article => (
                  <div key={article.id} className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col h-full hover:shadow-lg transition-shadow duration-300">
                    <img 
                      src={article.image} 
                      alt={article.title}
                      className="w-full h-60 object-cover"
                    />
                    <div className="p-6 flex-grow flex flex-col">
                      <div className="flex items-center text-sm text-gray-500 mb-3">
                        <Calendar className="h-4 w-4 mr-1" />
                        <span className="mr-4">{formatDate(article.date)}</span>
                        <Clock className="h-4 w-4 mr-1" />
                        <span>{article.readTime} min read</span>
                      </div>
                      <h3 className="text-xl font-bold text-gray-900 mb-2">{article.title}</h3>
                      <p className="text-gray-600 mb-4 flex-grow">{article.excerpt}</p>
                      <div className="flex justify-between items-center">
                        <div className="flex items-center">
                          <img 
                            src={article.authorImage} 
                            alt={article.author}
                            className="h-8 w-8 rounded-full mr-2"
                          />
                          <span className="text-sm text-gray-600">{article.author}</span>
                        </div>
                        <button
                          onClick={() => handleArticleSelect(article)}
                          className="flex items-center text-blue-600 hover:text-blue-800 font-medium"
                        >
                          Read More
                          <ChevronRight className="h-5 w-5 ml-1" />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          
          {/* All Articles */}
          <div className="grid lg:grid-cols-3 gap-8">
            {/* Articles Column */}
            <div className="lg:col-span-2">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">
                {activeCategory === 'all' ? 'All Articles' : categories.find(c => c.id === activeCategory)?.name}
              </h2>
              
              {filteredArticles.length === 0 ? (
                <div className="bg-gray-50 rounded-lg p-8 text-center">
                  <p className="text-gray-600 mb-4">No articles found matching your criteria.</p>
                  <button 
                    onClick={() => {
                      setActiveCategory('all');
                      setSearchQuery('');
                    }}
                    className="text-blue-600 hover:text-blue-800 font-medium"
                  >
                    Reset filters
                  </button>
                </div>
              ) : (
                <div className="space-y-8">
                  {filteredArticles.map(article => (
                    <div key={article.id} className="flex flex-col sm:flex-row bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
                      <div className="sm:w-1/3 h-48 sm:h-auto relative">
                        <img 
                          src={article.image} 
                          alt={article.title}
                          className="w-full h-full object-cover"
                        />
                        <div className="absolute top-0 left-0 bg-blue-600 text-white text-xs font-bold px-3 py-1 uppercase">
                          {categories.find(c => c.id === article.category)?.name}
                        </div>
                      </div>
                      <div className="sm:w-2/3 p-6">
                        <div className="flex items-center text-sm text-gray-500 mb-2">
                          <Calendar className="h-4 w-4 mr-1" />
                          <span className="mr-4">{formatDate(article.date)}</span>
                          <Clock className="h-4 w-4 mr-1" />
                          <span>{article.readTime} min read</span>
                        </div>
                        <h3 className="text-xl font-bold text-gray-900 mb-2">{article.title}</h3>
                        <p className="text-gray-600 mb-4 line-clamp-2">{article.excerpt}</p>
                        <div className="flex justify-between items-center">
                          <div className="flex items-center">
                            <img 
                              src={article.authorImage} 
                              alt={article.author}
                              className="h-8 w-8 rounded-full mr-2"
                            />
                            <span className="text-sm text-gray-600">{article.author}</span>
                          </div>
                          <button
                            onClick={() => handleArticleSelect(article)}
                            className="flex items-center text-blue-600 hover:text-blue-800 font-medium"
                          >
                            Read More
                            <ChevronRight className="h-5 w-5 ml-1" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            
            {/* Sidebar Column */}
            <div className="lg:col-span-1">
              {/* Downloadable Resources */}
              <div className="bg-white rounded-lg shadow-md p-6 mb-8">
                <h3 className="text-lg font-bold text-gray-900 mb-4 flex items-center">
                  <Download className="h-5 w-5 mr-2 text-blue-600" />
                  Downloadable Resources
                </h3>
                
                {filteredResources.length === 0 ? (
                  <p className="text-gray-600 text-sm">No resources available for the selected category.</p>
                ) : (
                  <div className="space-y-4">
                    {filteredResources.map(resource => (
                      <div key={resource.id} className="flex border-b border-gray-100 pb-4 last:border-0 last:pb-0">
                        <div className="h-16 w-16 bg-gray-100 rounded overflow-hidden mr-3 flex-shrink-0">
                          <img 
                            src={resource.thumbnail} 
                            alt={resource.title}
                            className="h-full w-full object-cover"
                          />
                        </div>
                        <div>
                          <h4 className="font-medium text-gray-900 text-sm mb-1">{resource.title}</h4>
                          <p className="text-xs text-gray-500 mb-2">{resource.fileType} · {resource.fileSize}</p>
                          <button className="text-blue-600 hover:text-blue-800 text-xs flex items-center">
                            Download Now
                            <Download className="h-3 w-3 ml-1" />
                          </button>
                        </div>
                      </div>
                    ))}
                    
                    <a href="#" className="block text-blue-600 hover:text-blue-800 text-sm font-medium mt-4">
                      View all resources
                    </a>
                  </div>
                )}
              </div>
              
              {/* Newsletter Signup */}
              <div className="bg-blue-50 rounded-lg shadow-md p-6 mb-8">
                <h3 className="text-lg font-bold text-gray-900 mb-2 flex items-center">
                  <Mail className="h-5 w-5 mr-2 text-blue-600" />
                  Stay Informed
                </h3>
                <p className="text-gray-600 text-sm mb-4">
                  Subscribe to our newsletter for the latest articles, resources, and financial tips.
                </p>
                <form className="space-y-3">
                  <input 
                    type="email" 
                    placeholder="Your email address" 
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <button className="w-full bg-blue-600 hover:bg-blue-700 text-white font-medium px-4 py-2 rounded-md transition duration-200">
                    Subscribe
                  </button>
                </form>
              </div>
              
              {/* Upcoming Webinars */}
              <div className="bg-white rounded-lg shadow-md p-6">
                <h3 className="text-lg font-bold text-gray-900 mb-4 flex items-center">
                  <BookOpen className="h-5 w-5 mr-2 text-blue-600" />
                  Upcoming Webinars
                </h3>
                <div className="space-y-4">
                  <div className="border-b border-gray-100 pb-4">
                    <div className="bg-blue-100 text-blue-800 text-xs font-bold px-2 py-1 rounded mb-2 inline-block">
                      MAY 15
                    </div>
                    <h4 className="font-medium text-gray-900 text-sm mb-1">Retirement Planning Essentials</h4>
                    <p className="text-xs text-gray-500 mb-2">1:00 PM - 2:00 PM MT · Online</p>
                    <button className="text-blue-600 hover:text-blue-800 text-xs font-medium flex items-center">
                      Register Now
                      <ArrowRight className="h-3 w-3 ml-1" />
                    </button>
                  </div>
                  
                  <div className="border-b border-gray-100 pb-4">
                    <div className="bg-blue-100 text-blue-800 text-xs font-bold px-2 py-1 rounded mb-2 inline-block">
                      MAY 22
                    </div>
                    <h4 className="font-medium text-gray-900 text-sm mb-1">Tax Strategies for Business Owners</h4>
                    <p className="text-xs text-gray-500 mb-2">10:00 AM - 11:30 AM MT · Online</p>
                    <button className="text-blue-600 hover:text-blue-800 text-xs font-medium flex items-center">
                      Register Now
                      <ArrowRight className="h-3 w-3 ml-1" />
                    </button>
                  </div>
                  
                  <div>
                    <div className="bg-blue-100 text-blue-800 text-xs font-bold px-2 py-1 rounded mb-2 inline-block">
                      JUN 7
                    </div>
                    <h4 className="font-medium text-gray-900 text-sm mb-1">Investment Basics Workshop</h4>
                    <p className="text-xs text-gray-500 mb-2">2:00 PM - 3:00 PM MT · Online</p>
                    <button className="text-blue-600 hover:text-blue-800 text-xs font-medium flex items-center">
                      Register Now
                      <ArrowRight className="h-3 w-3 ml-1" />
                    </button>
                  </div>
                  
                  <a href="#" className="block text-blue-600 hover:text-blue-800 text-sm font-medium mt-2">
                    View all webinars
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* CTA Section */}
      <section className="bg-blue-600 py-16">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-white mb-4">
              Have Questions About Your Financial Situation?
            </h2>
            <p className="text-xl text-blue-100 mb-8">
              Our team of experts is ready to provide personalized guidance tailored to your unique needs.
            </p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <a 
                href="#contact" 
                className="inline-flex items-center justify-center bg-white text-blue-600 hover:bg-gray-100 px-6 py-3 rounded-md transition duration-200 font-medium"
              >
                <MessageSquare className="mr-2 h-5 w-5" />
                Schedule a Consultation
              </a>
              <a 
                href="tel:3035551234" 
                className="inline-flex items-center justify-center border border-white text-white hover:bg-blue-700 px-6 py-3 rounded-md transition duration-200 font-medium"
              >
                Call (303) 555-1234
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProfessionalResourcesPage;