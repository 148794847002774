import React, { useState } from 'react';
import {
  Filter,
  ChevronDown,
  Star,
  Grid,
  List,
  ShoppingCart,
  Heart,
  Sliders
} from 'lucide-react';

const EcommerceCategory = () => {
  const [viewStyle, setViewStyle] = useState('grid');
  const [expandedFilters, setExpandedFilters] = useState({
    category: true,
    price: true,
    size: false,
    color: false
  });
  
  // Toggle filter sections
  const toggleFilter = (filter) => {
    setExpandedFilters(prev => ({
      ...prev,
      [filter]: !prev[filter]
    }));
  };
  
  // Sample categories for navigation
  const categories = [
    { id: 'all', name: 'All Products', count: 36 },
    { id: 'tops', name: 'Tops', count: 12 },
    { id: 'bottoms', name: 'Bottoms', count: 8 },
    { id: 'outerwear', name: 'Outerwear', count: 6 },
    { id: 'accessories', name: 'Accessories', count: 10 }
  ];
  
  // Sample products
  const products = [
    {
      id: 1,
      name: "Organic Cotton T-Shirt",
      price: 34.99,
      category: "tops",
      rating: 4.5,
      reviewCount: 28,
      image: "https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80",
      isNew: true,
      isSale: false
    },
    {
      id: 2,
      name: "Classic Denim Jacket",
      price: 89.95,
      category: "outerwear",
      rating: 4.8,
      reviewCount: 42,
      image: "https://images.unsplash.com/photo-1551537482-f2075a1d41f2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80",
      isNew: false,
      isSale: false
    },
    {
      id: 3,
      name: "Relaxed Fit Chinos",
      price: 59.99,
      category: "bottoms",
      rating: 4.3,
      reviewCount: 19,
      image: "https://images.unsplash.com/photo-1473966968600-fa801b869a1a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80",
      isNew: false,
      isSale: false
    },
    {
      id: 4,
      name: "Wool Blend Beanie",
      price: 29.95,
      salePrice: 19.95,
      category: "accessories",
      rating: 4.6,
      reviewCount: 34,
      image: "https://images.unsplash.com/photo-1576871337622-98d48d1cf531?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=987&q=80",
      isNew: false,
      isSale: true
    },
    {
      id: 5,
      name: "Mountain Parka",
      price: 199.99,
      category: "outerwear",
      rating: 4.9,
      reviewCount: 52,
      image: "https://images.unsplash.com/photo-1547949003-9792a18a2601?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      isNew: true,
      isSale: false
    },
    {
      id: 6,
      name: "Slim Fit Dress Shirt",
      price: 69.95,
      salePrice: 49.95,
      category: "tops",
      rating: 4.7,
      reviewCount: 23,
      image: "https://images.unsplash.com/photo-1598033129183-c4f50c736f10?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1525&q=80",
      isNew: false,
      isSale: true
    }
  ];

  return (
    <div className="bg-white">
      {/* Breadcrumb */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-4">
        <nav className="flex text-sm text-gray-500">
          <a href="#" className="hover:text-gray-900">Home</a>
          <span className="mx-2">/</span>
          <span className="text-gray-900">Collections</span>
        </nav>
      </div>
      
      {/* Main Content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 pb-16 pt-4">
        <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-8">Collections</h1>
        
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Filter Sidebar */}
          <div className="lg:w-1/4">
            <div className="bg-white p-4 border border-gray-200 rounded-lg sticky top-24">
              <div className="flex justify-between items-center mb-4">
                <h2 className="font-medium text-gray-900">Filters</h2>
                <button className="text-blue-600 hover:text-blue-800 text-sm font-medium">
                  Clear All
                </button>
              </div>
              
              {/* Category Filter */}
              <div className="mb-6 border-b border-gray-200 pb-6">
                <button 
                  className="flex w-full justify-between items-center"
                  onClick={() => toggleFilter('category')}
                >
                  <h3 className="font-medium text-gray-900">Category</h3>
                  <ChevronDown className={`h-5 w-5 text-gray-500 transform transition-transform ${expandedFilters.category ? 'rotate-180' : ''}`} />
                </button>
                {expandedFilters.category && (
                  <div className="mt-4 space-y-3">
                    {categories.map((category) => (
                      <div key={category.id} className="flex items-center">
                        <input 
                          id={`category-${category.id}`}
                          name="category" 
                          type="checkbox" 
                          className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                        />
                        <label htmlFor={`category-${category.id}`} className="ml-3 text-sm text-gray-600">
                          {category.name} ({category.count})
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              
              {/* Price Filter */}
              <div className="mb-6 border-b border-gray-200 pb-6">
                <button 
                  className="flex w-full justify-between items-center"
                  onClick={() => toggleFilter('price')}
                >
                  <h3 className="font-medium text-gray-900">Price</h3>
                  <ChevronDown className={`h-5 w-5 text-gray-500 transform transition-transform ${expandedFilters.price ? 'rotate-180' : ''}`} />
                </button>
                {expandedFilters.price && (
                  <div className="mt-4 space-y-3">
                    <div className="flex items-center">
                      <input 
                        id="price-under-50" 
                        name="price" 
                        type="checkbox" 
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <label htmlFor="price-under-50" className="ml-3 text-sm text-gray-600">
                        Under $50
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input 
                        id="price-50-100" 
                        name="price" 
                        type="checkbox" 
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <label htmlFor="price-50-100" className="ml-3 text-sm text-gray-600">
                        $50 to $100
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input 
                        id="price-100-200" 
                        name="price" 
                        type="checkbox" 
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <label htmlFor="price-100-200" className="ml-3 text-sm text-gray-600">
                        $100 to $200
                      </label>
                    </div>
                    <div className="flex items-center">
                      <input 
                        id="price-over-200" 
                        name="price" 
                        type="checkbox" 
                        className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                      />
                      <label htmlFor="price-over-200" className="ml-3 text-sm text-gray-600">
                        Over $200
                      </label>
                    </div>
                    <div className="mt-6">
                      <div className="flex items-center justify-between">
                        <input 
                          type="number" 
                          placeholder="Min" 
                          className="w-24 border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
                        />
                        <span className="text-gray-500 mx-2">-</span>
                        <input 
                          type="number" 
                          placeholder="Max" 
                          className="w-24 border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
                        />
                      </div>
                      <button className="mt-2 w-full bg-gray-100 text-gray-800 py-2 rounded-md text-sm hover:bg-gray-200 transition-colors">
                        Apply
                      </button>
                    </div>
                  </div>
                )}
              </div>
              
              {/* Size Filter */}
              <div className="mb-6 border-b border-gray-200 pb-6">
                <button 
                  className="flex w-full justify-between items-center"
                  onClick={() => toggleFilter('size')}
                >
                  <h3 className="font-medium text-gray-900">Size</h3>
                  <ChevronDown className={`h-5 w-5 text-gray-500 transform transition-transform ${expandedFilters.size ? 'rotate-180' : ''}`} />
                </button>
                {expandedFilters.size && (
                  <div className="mt-4 grid grid-cols-4 gap-2">
                    {['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'].map((size) => (
                      <div 
                        key={size}
                        className="border border-gray-300 rounded text-center py-2 cursor-pointer hover:border-blue-600 text-sm"
                      >
                        {size}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              
              {/* Color Filter */}
              <div className="mb-6">
                <button 
                  className="flex w-full justify-between items-center"
                  onClick={() => toggleFilter('color')}
                >
                  <h3 className="font-medium text-gray-900">Color</h3>
                  <ChevronDown className={`h-5 w-5 text-gray-500 transform transition-transform ${expandedFilters.color ? 'rotate-180' : ''}`} />
                </button>
                {expandedFilters.color && (
                  <div className="mt-4 grid grid-cols-5 gap-2">
                    {[
                      { name: 'Black', value: '#000000' },
                      { name: 'White', value: '#ffffff' },
                      { name: 'Gray', value: '#808080' },
                      { name: 'Blue', value: '#2563eb' },
                      { name: 'Red', value: '#dc2626' },
                      { name: 'Green', value: '#059669' },
                      { name: 'Yellow', value: '#fcd34d' },
                      { name: 'Purple', value: '#8b5cf6' },
                      { name: 'Brown', value: '#92400e' },
                      { name: 'Orange', value: '#f97316' }
                    ].map((color) => (
                      <div 
                        key={color.name}
                        className="flex flex-col items-center"
                      >
                        <div
                          className="h-8 w-8 rounded-full cursor-pointer border border-gray-300 hover:ring-2 hover:ring-blue-500"
                          style={{ backgroundColor: color.value }}
                          title={color.name}
                        ></div>
                        <span className="text-xs text-gray-600 mt-1">{color.name}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              
              {/* Apply Filters Button (Mobile) */}
              <div className="lg:hidden">
                <button className="w-full bg-blue-600 text-white py-2 rounded-md text-sm hover:bg-blue-700 transition-colors flex items-center justify-center">
                  <Sliders className="h-4 w-4 mr-2" />
                  Apply Filters
                </button>
              </div>
            </div>
          </div>
          
          {/* Products Grid */}
          <div className="lg:w-3/4">
            <div className="flex flex-wrap items-center justify-between mb-6">
              <div className="mb-4 lg:mb-0">
                <span className="text-gray-500">Showing 1-{products.length} of 36 products</span>
              </div>
              <div className="flex flex-wrap items-center space-x-4">
                <div className="flex items-center">
                  <button
                    className={`p-2 text-gray-600 hover:text-gray-900 ${viewStyle === 'grid' ? 'bg-gray-100 text-gray-900 rounded' : ''}`}
                    onClick={() => setViewStyle('grid')}
                    aria-label="Grid view"
                  >
                    <Grid className="h-5 w-5" />
                  </button>
                  <button
                    className={`p-2 text-gray-600 hover:text-gray-900 ${viewStyle === 'list' ? 'bg-gray-100 text-gray-900 rounded' : ''}`}
                    onClick={() => setViewStyle('list')}
                    aria-label="List view"
                  >
                    <List className="h-5 w-5" />
                  </button>
                </div>
                <div className="relative">
                  <select className="p-2 pr-8 border border-gray-300 rounded-md text-gray-700 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500">
                    <option>Sort: Featured</option>
                    <option>Price: Low to High</option>
                    <option>Price: High to Low</option>
                    <option>Newest First</option>
                    <option>Rating: High to Low</option>
                  </select>
                </div>
                <button className="lg:hidden p-2 text-gray-600 border border-gray-300 rounded">
                  <Filter className="h-5 w-5" />
                  <span className="sr-only">Filters</span>
                </button>
              </div>
            </div>
            
            {/* Active Filters */}
            <div className="flex flex-wrap gap-2 mb-6">
              <div className="bg-gray-100 px-3 py-1 rounded-full flex items-center text-sm text-gray-800">
                <span>Category: Tops</span>
                <button className="ml-1 text-gray-500 hover:text-gray-700">
                  <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
              <div className="bg-gray-100 px-3 py-1 rounded-full flex items-center text-sm text-gray-800">
                <span>Price: $50-$100</span>
                <button className="ml-1 text-gray-500 hover:text-gray-700">
                  <svg className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
            
            {/* Products Grid View */}
            {viewStyle === 'grid' ? (
              <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
                {products.map((product) => (
                  <div key={product.id} className="group">
                    <div className="bg-gray-100 rounded-lg overflow-hidden mb-3 relative aspect-square">
                      <img
                        src={product.image}
                        alt={product.name}
                        className="w-full h-full object-cover object-center group-hover:scale-105 transition-transform duration-300"
                      />
                      {product.isSale && (
                        <span className="absolute top-2 right-2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded">
                          SALE
                        </span>
                      )}
                      {product.isNew && (
                        <span className="absolute top-2 left-2 bg-blue-500 text-white text-xs font-bold px-2 py-1 rounded">
                          NEW
                        </span>
                      )}
                      <div className="absolute inset-0 bg-black bg-opacity-20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                        <button className="bg-white text-gray-900 px-4 py-2 rounded-lg text-sm font-medium transform -translate-y-2 group-hover:translate-y-0 transition-transform duration-300">
                          Quick View
                        </button>
                      </div>
                    </div>
                    <h3 className="font-medium text-gray-900 mb-1">{product.name}</h3>
                    <div className="flex items-center justify-between">
                      {product.salePrice ? (
                        <div className="flex items-center">
                          <span className="font-bold text-red-600 mr-2">${product.salePrice.toFixed(2)}</span>
                          <span className="text-sm text-gray-500 line-through">${product.price.toFixed(2)}</span>
                        </div>
                      ) : (
                        <span className="font-bold text-gray-900">${product.price.toFixed(2)}</span>
                      )}
                      <div className="flex text-yellow-400 text-xs">
                        {[...Array(5)].map((_, i) => (
                          <Star key={i} className={`h-3 w-3 ${i < Math.floor(product.rating) ? 'fill-current' : 'fill-none'}`} />
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              // List View
              <div className="space-y-6">
                {products.map((product) => (
                  <div key={product.id} className="flex flex-col sm:flex-row bg-white border border-gray-200 rounded-lg overflow-hidden hover:shadow-md transition-shadow duration-300">
                    <div className="sm:w-48 h-48 bg-gray-100 relative flex-shrink-0">
                      <img
                        src={product.image}
                        alt={product.name}
                        className="w-full h-full object-cover object-center"
                      />
                      {product.isSale && (
                        <span className="absolute top-2 right-2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded">
                          SALE
                        </span>
                      )}
                      {product.isNew && (
                        <span className="absolute top-2 left-2 bg-blue-500 text-white text-xs font-bold px-2 py-1 rounded">
                          NEW
                        </span>
                      )}
                    </div>
                    <div className="p-4 flex-1 flex flex-col justify-between">
                      <div>
                        <h3 className="font-medium text-gray-900 mb-1">{product.name}</h3>
                        <div className="flex items-center mb-2">
                          <div className="flex text-yellow-400 mr-2">
                            {[...Array(5)].map((_, i) => (
                              <Star key={i} className={`h-4 w-4 ${i < Math.floor(product.rating) ? 'fill-current' : 'fill-none'}`} />
                            ))}
                          </div>
                          <span className="text-gray-600 text-sm">{product.rating} ({product.reviewCount} reviews)</span>
                        </div>
                        <p className="text-gray-600 text-sm mb-4">
                          Premium quality {product.name.toLowerCase()} perfect for everyday wear. Made with sustainable materials and designed for comfort and durability.
                        </p>
                        <div className="flex flex-wrap gap-1 mb-4">
                          <span className="inline-block px-2 py-1 bg-gray-100 text-gray-700 text-xs rounded">
                            {product.category.charAt(0).toUpperCase() + product.category.slice(1)}
                          </span>
                          <span className="inline-block px-2 py-1 bg-gray-100 text-gray-700 text-xs rounded">
                            Multiple colors
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center justify-between">
                        <div>
                          {product.salePrice ? (
                            <div className="flex items-center">
                              <span className="font-bold text-red-600 mr-2">${product.salePrice.toFixed(2)}</span>
                              <span className="text-sm text-gray-500 line-through">${product.price.toFixed(2)}</span>
                            </div>
                          ) : (
                            <span className="font-bold text-gray-900">${product.price.toFixed(2)}</span>
                          )}
                        </div>
                        <div className="flex space-x-2">
                          <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors flex items-center">
                            <ShoppingCart className="h-4 w-4 mr-1" />
                            Add to Cart
                          </button>
                          <button className="bg-gray-100 text-gray-700 p-2 rounded hover:bg-gray-200 transition-colors">
                            <Heart className="h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            
            {/* Pagination */}
            <div className="mt-12 flex justify-center">
              <nav className="inline-flex rounded-md shadow">
                <a href="#" className="px-4 py-2 rounded-l-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-50">
                  Previous
                </a>
                <a href="#" className="px-4 py-2 border-t border-b border-gray-300 bg-blue-50 text-blue-600 font-medium">
                  1
                </a>
                <a href="#" className="px-4 py-2 border-t border-b border-gray-300 bg-white text-gray-700 hover:bg-gray-50">
                  2
                </a>
                <a href="#" className="px-4 py-2 border-t border-b border-gray-300 bg-white text-gray-700 hover:bg-gray-50">
                  3
                </a>
                <a href="#" className="px-4 py-2 rounded-r-md border border-gray-300 bg-white text-gray-700 hover:bg-gray-50">
                  Next
                </a>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcommerceCategory;