import React, { useState, useEffect } from 'react';
import {
  MapPin,
  Phone,
  Mail,
  Clock,
  Star,
  Calendar,
  User,
  Menu,
  X,
  ChevronRight,
  Award,
  Briefcase,
  toolbox,
  Home,
  Users,
  Check,
  ArrowRight,
  MessageSquare,
  Facebook,
  Twitter,
  Instagram,
  Linkedin,
  Youtube
} from 'lucide-react';

const LocalBusinessDemo = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeTestimonial, setActiveTestimonial] = useState(0);
  const [scrolled, setScrolled] = useState(false);
  const [activeTab, setActiveTab] = useState('residential');
  const [expandedFaqs, setExpandedFaqs] = useState(new Set([1]));

  // Track scroll for sticky header
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Auto-rotate testimonials
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTestimonial(prev => (prev + 1) % testimonials.length);
    }, 8000);
    return () => clearInterval(interval);
  }, []);

  // Toggle FAQ items
  const toggleFaq = (id) => {
    setExpandedFaqs(prev => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  };

  // Sample services data
  const services = {
    residential: [
      {
        title: "Emergency Repairs",
        description: "Fast, reliable service for urgent electrical issues. Our team responds quickly to ensure your family's safety.",
        icon: <toolbox className="h-10 w-10 text-blue-500" />,
        features: [
          "24/7 emergency response",
          "Safety inspections",
          "Circuit breaker repairs",
          "Power outage troubleshooting"
        ]
      },
      {
        title: "Home Rewiring",
        description: "Complete or partial home rewiring to update outdated systems and improve safety and efficiency.",
        icon: <Home className="h-10 w-10 text-blue-500" />,
        features: [
          "Aluminum wiring replacement",
          "Code compliance updates",
          "Outlet & switch replacement",
          "Panel upgrades"
        ]
      },
      {
        title: "Lighting Installation",
        description: "Enhance your home's ambiance and efficiency with expert lighting solutions and installations.",
        icon: <Briefcase className="h-10 w-10 text-blue-500" />,
        features: [
          "Recessed lighting",
          "Chandelier installation",
          "Outdoor lighting",
          "LED upgrades"
        ]
      }
    ],
    commercial: [
      {
        title: "Commercial Wiring",
        description: "Professional electrical installations for offices, retail spaces, and commercial properties.",
        icon: <Briefcase className="h-10 w-10 text-blue-500" />,
        features: [
          "Office electrical systems",
          "Retail lighting solutions",
          "Restaurant electrical services",
          "Code compliance"
        ]
      },
      {
        title: "Electrical Maintenance",
        description: "Preventative maintenance to keep your business running smoothly and prevent costly downtime.",
        icon: <toolbox className="h-10 w-10 text-blue-500" />,
        features: [
          "Regular safety inspections",
          "Equipment testing",
          "Preventative maintenance plans",
          "Emergency repairs"
        ]
      },
      {
        title: "Energy Efficiency",
        description: "Solutions to reduce your business's energy consumption and lower utility costs.",
        icon: <Users className="h-10 w-10 text-blue-500" />,
        features: [
          "LED lighting upgrades",
          "Smart system installation",
          "Energy usage assessment",
          "Cost reduction planning"
        ]
      }
    ]
  };

  // Testimonials
  const testimonials = [
    {
      content: "Summit Electric came to our rescue during a power outage. Their technician was at our door within an hour and quickly identified the issue with our electrical panel. Professional, courteous, and extremely knowledgeable. I highly recommend their services!",
      author: "Jennifer Wilson",
      location: "Homeowner in Boulder",
      rating: 5
    },
    {
      content: "As a restaurant owner, I can't afford electrical problems disrupting my business. Summit Electric has been handling all our electrical needs for three years now. Their commercial maintenance program has prevented issues before they happen, and their team always works around our busy hours.",
      author: "Michael Ramirez",
      location: "Owner, Taste of Italy Restaurant",
      rating: 5
    },
    {
      content: "We hired Summit to rewire our 1960s home, and the difference has been remarkable. The team was thorough in explaining what needed to be done, provided a detailed estimate, and completed the work on schedule. Our home is noticeably safer now, and we have peace of mind.",
      author: "Robert & Sarah Johnson",
      location: "Historic Home Renovation",
      rating: 5
    }
  ];

  // FAQs
  const faqs = [
    {
      id: 1,
      question: "How quickly can you respond to an electrical emergency?",
      answer: "We understand that electrical emergencies can't wait. Our team typically responds within 1-2 hours for emergency calls. We have technicians on standby 24/7, including weekends and holidays, to ensure we can address urgent situations promptly and keep your home or business safe."
    },
    {
      id: 2,
      question: "Are your electricians licensed and insured?",
      answer: "Yes, absolutely. All our electricians are fully licensed, insured, and certified. Each team member undergoes rigorous training and regular skills updates to stay current with the latest electrical codes and safety standards. We're proud to maintain a stellar safety record and full compliance with all local and national regulations."
    },
    {
      id: 3,
      question: "How much will my electrical project cost?",
      answer: "Each electrical project is unique, so costs vary based on your specific needs. We provide detailed, transparent quotes after assessing your requirements. For simple services like outlet installations, we can often provide estimates over the phone. For more complex projects, we offer free on-site evaluations to ensure accuracy. We're committed to no surprise fees or hidden costs."
    },
    {
      id: 4,
      question: "Do you offer warranties on your electrical work?",
      answer: "Yes, we stand behind our work with solid warranties. All our labor comes with a 1-year warranty, and we honor all manufacturer warranties on products and parts we install. For certain services like panel upgrades and whole-house rewiring, we offer extended 5-year warranties. Our goal is your complete satisfaction and peace of mind."
    },
    {
      id: 5,
      question: "Can you help make my home or business more energy efficient?",
      answer: "Absolutely! We specialize in energy-efficient electrical solutions. Our services include LED lighting upgrades, installation of programmable systems, energy usage assessments, and implementation of smart home/business automation that can significantly reduce your electricity consumption. Many clients see a 20-30% reduction in their energy bills after our efficiency upgrades."
    }
  ];

  return (
    <div className="font-sans text-gray-800 bg-white">
      {/* Header/Navigation */}
      <header className={`fixed w-full z-30 transition-all duration-300 ${scrolled ? 'bg-white shadow-md py-3' : 'bg-white py-4'}`}>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            {/* Logo */}
            <div className="flex items-center">
              <div className="text-blue-600 mr-2">
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 5L33.6603 12.5V27.5L20 35L6.33975 27.5V12.5L20 5Z" fill="currentColor" fillOpacity="0.2" />
                  <path d="M20 0L37.3205 10V30L20 40L2.67949 30V10L20 0Z" stroke="currentColor" strokeWidth="2" />
                  <path d="M8 20L20 26L32 20" stroke="currentColor" strokeWidth="2" />
                  <path d="M20 13V26" stroke="currentColor" strokeWidth="2" />
                </svg>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">Summit Electric</h1>
                <p className="text-xs text-blue-600 font-medium -mt-1">Licensed Electrical Contractors</p>
              </div>
            </div>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex items-center space-x-8">
              <a href="#about" className="text-gray-700 hover:text-blue-600 font-medium">About</a>
              <a href="#services" className="text-gray-700 hover:text-blue-600 font-medium">Services</a>
              <a href="#testimonials" className="text-gray-700 hover:text-blue-600 font-medium">Testimonials</a>
              <a href="#faq" className="text-gray-700 hover:text-blue-600 font-medium">FAQ</a>
              <a href="#contact" className="text-gray-700 hover:text-blue-600 font-medium">Contact</a>
              <button className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md transition duration-300 flex items-center">
                <Phone className="h-4 w-4 mr-2" />
                (555) 123-4567
              </button>
            </nav>

            {/* Mobile menu button */}
            <button
              className="md:hidden text-gray-700 focus:outline-none"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <Menu className="h-6 w-6" />
            </button>
          </div>
        </div>

        {/* Mobile Navigation Menu */}
        {isMenuOpen && (
          <div className="absolute top-full left-0 right-0 bg-white shadow-lg rounded-b-lg md:hidden">
            <div className="container mx-auto px-4 py-4">
              <div className="flex justify-end">
                <button
                  onClick={() => setIsMenuOpen(false)}
                  className="text-gray-500"
                >
                  <X className="h-6 w-6" />
                </button>
              </div>
              <nav className="flex flex-col space-y-4 mt-2">
                <a href="#about" className="text-gray-700 hover:text-blue-600 py-2 font-medium" onClick={() => setIsMenuOpen(false)}>About</a>
                <a href="#services" className="text-gray-700 hover:text-blue-600 py-2 font-medium" onClick={() => setIsMenuOpen(false)}>Services</a>
                <a href="#testimonials" className="text-gray-700 hover:text-blue-600 py-2 font-medium" onClick={() => setIsMenuOpen(false)}>Testimonials</a>
                <a href="#faq" className="text-gray-700 hover:text-blue-600 py-2 font-medium" onClick={() => setIsMenuOpen(false)}>FAQ</a>
                <a href="#contact" className="text-gray-700 hover:text-blue-600 py-2 font-medium" onClick={() => setIsMenuOpen(false)}>Contact</a>
                <a href="tel:5551234567" className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md transition duration-300 flex items-center justify-center">
                  <Phone className="h-4 w-4 mr-2" />
                  (555) 123-4567
                </a>
              </nav>
            </div>
          </div>
        )}
      </header>

      {/* Hero Section */}
      <section className="pt-32 pb-20 bg-gradient-to-br from-blue-50 to-gray-100">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 mb-10 md:mb-0 md:pr-10">
              <div className="inline-block px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-sm font-medium mb-4">
                Trusted by 1,000+ Colorado families
              </div>
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-4 leading-tight">
                Expert Electrical Services for Your Home & Business
              </h1>
              <p className="text-xl text-gray-600 mb-8">
                Professional licensed electricians delivering reliable service, fair pricing, and peace of mind throughout Boulder County since 2005.
              </p>
              <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
                <a href="#contact" className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-md transition duration-300 text-center font-medium">
                  Request a Free Quote
                </a>
                <a href="tel:5551234567" className="border border-gray-300 hover:border-gray-400 bg-white text-gray-700 px-6 py-3 rounded-md transition duration-300 text-center font-medium flex items-center justify-center">
                  <Phone className="h-5 w-5 mr-2" />
                  Call Us Now
                </a>
              </div>
              <div className="mt-8 flex items-center">
                <div className="flex -space-x-2 mr-3">
                  <div className="h-10 w-10 rounded-full border-2 border-white bg-blue-200 flex items-center justify-center text-blue-700 font-bold">JD</div>
                  <div className="h-10 w-10 rounded-full border-2 border-white bg-green-200 flex items-center justify-center text-green-700 font-bold">TR</div>
                  <div className="h-10 w-10 rounded-full border-2 border-white bg-amber-200 flex items-center justify-center text-amber-700 font-bold">MP</div>
                </div>
                <div className="text-sm">
                  <div className="font-medium">Trusted by local businesses</div>
                  <div className="text-gray-500">Over 500 5-star reviews</div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 relative">
              <div className="bg-white p-1 rounded-lg shadow-xl">
                <img 
                  src="https://images.unsplash.com/photo-1621905251189-08b45d6a269e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1469&q=80" 
                  alt="Electrician working" 
                  className="rounded-lg shadow-sm h-96 w-full object-cover"
                />
                <div className="absolute top-0 right-0 -mt-6 -mr-6 bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md transform rotate-3">
                  <div className="font-bold text-sm">24/7 Emergency Service</div>
                </div>
              </div>
              <div className="absolute bottom-4 left-4 bg-white rounded-lg shadow-lg p-4 max-w-xs">
                <div className="flex items-center mb-2">
                  <div className="bg-green-100 text-green-800 rounded-full p-1 mr-2">
                    <Check className="h-4 w-4" />
                  </div>
                  <div className="text-sm font-medium">Available Today</div>
                </div>
                <p className="text-gray-700 text-sm">Our technicians can be at your location within 2 hours for emergency service.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Trust Badges */}
      <section className="py-8 bg-white border-b border-gray-200">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-wrap justify-center items-center gap-8 md:gap-16">
            <div className="flex items-center">
              <Award className="h-8 w-8 text-amber-500 mr-2" />
              <div>
                <div className="font-medium">Licensed & Insured</div>
                <div className="text-xs text-gray-500">CO License #EL-12345</div>
              </div>
            </div>
            <div className="flex items-center">
              <Clock className="h-8 w-8 text-blue-500 mr-2" />
              <div>
                <div className="font-medium">Same-Day Service</div>
                <div className="text-xs text-gray-500">Fast response guaranteed</div>
              </div>
            </div>
            <div className="flex items-center">
              <Star className="h-8 w-8 text-yellow-500 mr-2 fill-current" />
              <div>
                <div className="font-medium">5-Star Rated</div>
                <div className="text-xs text-gray-500">500+ verified reviews</div>
              </div>
            </div>
            <div className="flex items-center">
              <Check className="h-8 w-8 text-green-500 mr-2" />
              <div>
                <div className="font-medium">Satisfaction Guarantee</div>
                <div className="text-xs text-gray-500">100% money-back promise</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* About Section */}
      <section id="about" className="py-20 bg-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-center md:space-x-12">
            <div className="md:w-1/2 mb-10 md:mb-0">
              <div className="relative">
                <div className="grid grid-cols-2 gap-4">
                  <img 
                    src="https://images.unsplash.com/photo-1574623452334-1e0ac2b3ccb4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80" 
                    alt="Office building" 
                    className="rounded-lg shadow-md h-64 object-cover"
                  />
                  <img 
                    src="https://images.unsplash.com/photo-1531482615713-2afd69097998?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80" 
                    alt="Electrician team" 
                    className="rounded-lg shadow-md h-64 object-cover mt-8"
                  />
                </div>
                <div className="absolute -bottom-6 -right-6 bg-blue-600 text-white p-4 rounded-lg shadow-lg">
                  <div className="text-4xl font-bold">18+</div>
                  <div className="text-sm">Years of Service</div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2">
              <div className="inline-block px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-sm font-medium mb-4">
                About Summit Electric
              </div>
              <h2 className="text-3xl font-bold text-gray-900 mb-4">Your Trusted Local Electrical Partner Since 2005</h2>
              <p className="text-gray-600 mb-6">
                Summit Electric was founded with a simple mission: to provide Boulder County residents and businesses with reliable, high-quality electrical services at fair prices. What began as a one-man operation has grown into a team of 15 certified professionals serving the entire Front Range area.
              </p>
              <p className="text-gray-600 mb-6">
                We specialize in both residential and commercial electrical services, from simple repairs to complete electrical system installations. Our licensed electricians are extensively trained, background-checked, and committed to exceeding your expectations on every job.
              </p>
              <div className="grid grid-cols-2 gap-4 mb-8">
                <div className="flex items-center">
                  <Check className="h-5 w-5 text-green-500 mr-2" />
                  <span>Licensed Electricians</span>
                </div>
                <div className="flex items-center">
                  <Check className="h-5 w-5 text-green-500 mr-2" />
                  <span>Upfront Pricing</span>
                </div>
                <div className="flex items-center">
                  <Check className="h-5 w-5 text-green-500 mr-2" />
                  <span>Background Checked</span>
                </div>
                <div className="flex items-center">
                  <Check className="h-5 w-5 text-green-500 mr-2" />
                  <span>Workmanship Warranty</span>
                </div>
              </div>
              <div className="flex items-center">
                <img 
                  src="https://randomuser.me/api/portraits/men/32.jpg" 
                  alt="James Wilson, Founder" 
                  className="h-14 w-14 rounded-full mr-4 object-cover border-2 border-blue-600"
                />
                <div>
                  <div className="font-bold text-gray-900">James Wilson</div>
                  <div className="text-blue-600 text-sm">Master Electrician & Founder</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="py-20 bg-gray-50">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <div className="inline-block px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-sm font-medium mb-4">
              Our Services
            </div>
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Professional Electrical Solutions</h2>
            <p className="text-gray-600">
              From emergency repairs to complete electrical installations, our skilled team provides comprehensive services for residential and commercial properties.
            </p>
          </div>

          {/* Service Tabs */}
          <div className="flex justify-center mb-12">
            <div className="inline-flex p-1 bg-gray-100 rounded-lg">
              <button
                onClick={() => setActiveTab('residential')}
                className={`px-4 py-2 rounded-md transition ${
                  activeTab === 'residential'
                    ? 'bg-white shadow-sm text-blue-600 font-medium'
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                Residential Services
              </button>
              <button
                onClick={() => setActiveTab('commercial')}
                className={`px-4 py-2 rounded-md transition ${
                  activeTab === 'commercial'
                    ? 'bg-white shadow-sm text-blue-600 font-medium'
                    : 'text-gray-600 hover:text-gray-900'
                }`}
              >
                Commercial Services
              </button>
            </div>
          </div>

          {/* Service Cards */}
          <div className="grid md:grid-cols-3 gap-8">
            {services[activeTab].map((service, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow duration-300">
                <div className="p-6">
                  <div className="w-16 h-16 bg-blue-100 rounded-lg flex items-center justify-center mb-4">
                    {service.icon}
                  </div>
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{service.title}</h3>
                  <p className="text-gray-600 mb-4">{service.description}</p>
                  <ul className="space-y-2 mb-6">
                    {service.features.map((feature, idx) => (
                      <li key={idx} className="flex items-start">
                        <Check className="h-5 w-5 text-green-500 mr-2 mt-0.5 flex-shrink-0" />
                        <span className="text-gray-700">{feature}</span>
                      </li>
                    ))}
                  </ul>
                  <a href="#contact" className="inline-flex items-center text-blue-600 hover:text-blue-800 font-medium">
                    Learn More
                    <ChevronRight className="h-5 w-5 ml-1" />
                  </a>
                </div>
              </div>
            ))}
          </div>

          <div className="text-center mt-12">
            <a href="#contact" className="inline-flex items-center bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-md transition duration-300 font-medium">
              Request a Service Quote
              <ArrowRight className="h-5 w-5 ml-2" />
            </a>
          </div>
        </div>
      </section>

      {/* Service Area Map */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-center">
            <div className="md:w-1/2 mb-10 md:mb-0 md:pr-12">
              <div className="inline-block px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-sm font-medium mb-4">
                Service Area
              </div>
              <h2 className="text-3xl font-bold text-gray-900 mb-4">Serving Boulder County & Beyond</h2>
              <p className="text-gray-600 mb-6">
                Our team of electricians provides prompt, professional service throughout Boulder County and surrounding areas. We're strategically located to ensure quick response times to all communities we serve.
              </p>
              <div className="mb-6">
                <h3 className="font-bold text-gray-900 mb-2">Areas We Serve:</h3>
                <div className="grid grid-cols-2 gap-y-2 gap-x-4">
                  <div className="flex items-center">
                    <MapPin className="h-5 w-5 text-blue-600 mr-2" />
                    <span>Boulder</span>
                  </div>
                  <div className="flex items-center">
                    <MapPin className="h-5 w-5 text-blue-600 mr-2" />
                    <span>Louisville</span>
                  </div>
                  <div className="flex items-center">
                    <MapPin className="h-5 w-5 text-blue-600 mr-2" />
                    <span>Lafayette</span>
                  </div>
                  <div className="flex items-center">
                    <MapPin className="h-5 w-5 text-blue-600 mr-2" />
                    <span>Superior</span>
                  </div>
                  <div className="flex items-center">
                    <MapPin className="h-5 w-5 text-blue-600 mr-2" />
                    <span>Longmont</span>
                  </div>
                  <div className="flex items-center">
                    <MapPin className="h-5 w-5 text-blue-600 mr-2" />
                    <span>Broomfield</span>
                  </div>
                  <div className="flex items-center">
                    <MapPin className="h-5 w-5 text-blue-600 mr-2" />
                    <span>Erie</span>
                  </div>
                  <div className="flex items-center">
                    <MapPin className="h-5 w-5 text-blue-600 mr-2" />
                    <span>Niwot</span>
                  </div>
                </div>
              </div>
              <a href="#contact" className="inline-flex items-center text-blue-600 hover:text-blue-800 font-medium">
                Check if we serve your area
                <ChevronRight className="h-5 w-5 ml-1" />
              </a>
            </div>
            <div className="md:w-1/2">
              <div className="bg-gray-200 rounded-lg overflow-hidden shadow-md h-80 w-full">
                {/* This would be an interactive map in a real implementation */}
                <div className="relative h-full w-full bg-blue-50 flex items-center justify-center">
                  <MapPin className="h-12 w-12 text-blue-600 absolute" style={{ top: '42%', left: '38%' }} />
                  <div className="absolute w-20 h-20 bg-blue-500 rounded-full opacity-10 animate-ping" style={{ top: '39%', left: '35%' }}></div>
                  <div className="absolute" style={{ top: '37%', left: '41%' }}>
                    <div className="bg-white shadow-md rounded-md px-2 py-1 text-xs font-medium">Boulder</div>
                  </div>
                  <MapPin className="h-8 w-8 text-blue-400 absolute" style={{ top: '50%', left: '52%' }} />
                  <MapPin className="h-8 w-8 text-blue-400 absolute" style={{ top: '35%', left: '58%' }} />
                  <MapPin className="h-8 w-8 text-blue-400 absolute" style={{ top: '55%', left: '45%' }} />
                  <p className="text-gray-500">Interactive service area map</p>
                </div>
              </div>
              <div className="bg-blue-600 text-white p-4 rounded-lg shadow-md -mt-6 ml-6 max-w-xs">
                <p className="text-sm">Not sure if we serve your area? Call us at (555) 123-4567 to find out!</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section id="testimonials" className="py-20 bg-gray-50">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <div className="inline-block px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-sm font-medium mb-4">
              Client Testimonials
            </div>
            <h2 className="text-3xl font-bold text-gray-900 mb-4">What Our Customers Say</h2>
            <p className="text-gray-600">
              Don't just take our word for it. Here's what our satisfied customers have to say about our electrical services.
            </p>
          </div>

          <div className="relative max-w-4xl mx-auto">
            {/* Testimonial Cards */}
            <div className="overflow-hidden">
              <div className="relative h-80">
                {testimonials.map((testimonial, index) => (
                  <div 
                    key={index}
                    className={`absolute top-0 left-0 w-full transition-all duration-700 ease-in-out ${
                      index === activeTestimonial 
                        ? 'opacity-100 translate-x-0' 
                        : 'opacity-0 translate-x-full'
                    }`}
                  >
                    <div className="bg-white rounded-lg shadow-lg p-8 relative">
                      <div className="text-6xl text-blue-100 absolute top-4 left-4 leading-none">"</div>
                      <div className="relative z-10">
                        <div className="flex text-yellow-400 mb-4">
                          {[...Array(testimonial.rating)].map((_, i) => (
                            <Star key={i} className="h-5 w-5 fill-current" />
                          ))}
                        </div>
                        <p className="text-gray-700 italic mb-6">{testimonial.content}</p>
                        <div className="flex items-center">
                          <div className="h-12 w-12 bg-blue-100 rounded-full mr-4 flex items-center justify-center text-blue-700 font-bold">
                            {testimonial.author.split(' ').map(n => n[0]).join('')}
                          </div>
                          <div>
                            <div className="font-bold text-gray-900">{testimonial.author}</div>
                            <div className="text-gray-500 text-sm">{testimonial.location}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Testimonial Navigation Dots */}
            <div className="flex justify-center mt-8 space-x-2">
              {testimonials.map((_, index) => (
                <button
                  key={index}
                  onClick={() => setActiveTestimonial(index)}
                  className={`h-3 w-3 rounded-full transition-all duration-300 ${
                    index === activeTestimonial ? 'bg-blue-600 w-6' : 'bg-gray-300 hover:bg-gray-400'
                  }`}
                  aria-label={`View testimonial ${index + 1}`}
                ></button>
              ))}
            </div>
          </div>

          <div className="text-center mt-12">
            <a 
              href="https://www.google.com/business" 
              target="_blank" 
              rel="noopener noreferrer"
              className="inline-flex items-center text-blue-600 hover:text-blue-800 font-medium"
            >
              See more reviews on Google
              <ArrowRight className="h-5 w-5 ml-1" />
            </a>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section id="faq" className="py-20 bg-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center max-w-3xl mx-auto mb-16">
            <div className="inline-block px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-sm font-medium mb-4">
              Frequently Asked Questions
            </div>
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Common Questions About Our Services</h2>
            <p className="text-gray-600">
              Find answers to the most common questions about our electrical services. If you don't see what you're looking for, please contact us.
            </p>
          </div>

          <div className="max-w-3xl mx-auto">
            <div className="space-y-4">
              {faqs.map((faq) => (
                <div 
                  key={faq.id} 
                  className={`border rounded-lg transition-all duration-200 ${
                    expandedFaqs.has(faq.id) ? 'border-blue-200 shadow-md' : 'border-gray-200'
                  }`}
                >
                  <button
                    className="flex justify-between items-center w-full px-6 py-4 text-left focus:outline-none"
                    onClick={() => toggleFaq(faq.id)}
                  >
                    <h3 className="font-medium text-gray-900">{faq.question}</h3>
                    <ChevronRight className={`h-5 w-5 text-gray-500 transition-transform duration-200 ${
                      expandedFaqs.has(faq.id) ? 'transform rotate-90 text-blue-600' : ''
                    }`} />
                  </button>
                  <div className={`overflow-hidden transition-all duration-200 ${
                    expandedFaqs.has(faq.id) ? 'max-h-80' : 'max-h-0'
                  }`}>
                    <div className="px-6 pb-4 text-gray-600 border-t border-gray-100">
                      {faq.answer}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="text-center mt-10">
              <p className="text-gray-600 mb-4">Still have questions? We're here to help!</p>
              <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                <a href="#contact" className="inline-flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white px-5 py-2 rounded-md transition duration-300">
                  Contact Us
                </a>
                <a href="tel:5551234567" className="inline-flex items-center justify-center border border-gray-300 hover:border-gray-400 bg-white text-gray-700 px-5 py-2 rounded-md transition duration-300">
                  <Phone className="h-4 w-4 mr-2" />
                  (555) 123-4567
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Call To Action */}
      <section className="py-16 bg-blue-600 text-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-4">Ready to Solve Your Electrical Problems?</h2>
            <p className="text-blue-100 mb-8 text-lg">
              Whether you need emergency repairs or are planning a renovation, our team of licensed electricians is ready to help.
            </p>
            <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
              <a href="#contact" className="bg-white text-blue-600 hover:bg-gray-100 px-6 py-3 rounded-md transition duration-300 text-center font-medium">
                Request a Quote
              </a>
              <a href="tel:5551234567" className="border border-white hover:bg-blue-700 px-6 py-3 rounded-md transition duration-300 text-center font-medium inline-flex items-center justify-center">
                <Phone className="h-5 w-5 mr-2" />
                Call (555) 123-4567
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section id="contact" className="py-20 bg-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-1/2 lg:pr-12 mb-10 lg:mb-0">
              <div className="inline-block px-3 py-1 bg-blue-100 text-blue-600 rounded-full text-sm font-medium mb-4">
                Contact Us
              </div>
              <h2 className="text-3xl font-bold text-gray-900 mb-4">Get in Touch with Our Team</h2>
              <p className="text-gray-600 mb-8">
                Have questions or ready to schedule service? Fill out the form, and we'll get back to you promptly. For emergencies, please call us directly.
              </p>

              <div className="space-y-6 mb-8">
                <div className="flex items-start">
                  <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 mt-1 flex-shrink-0">
                    <MapPin className="h-5 w-5" />
                  </div>
                  <div className="ml-4">
                    <h3 className="font-bold text-gray-900">Our Location</h3>
                    <p className="text-gray-600">1234 Electric Avenue, Boulder, CO 80301</p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 mt-1 flex-shrink-0">
                    <Clock className="h-5 w-5" />
                  </div>
                  <div className="ml-4">
                    <h3 className="font-bold text-gray-900">Business Hours</h3>
                    <p className="text-gray-600">Monday-Friday: 7:00 AM - 6:00 PM</p>
                    <p className="text-gray-600">Saturday: 8:00 AM - 2:00 PM</p>
                    <p className="text-gray-600">24/7 Emergency Service Available</p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 mt-1 flex-shrink-0">
                    <Phone className="h-5 w-5" />
                  </div>
                  <div className="ml-4">
                    <h3 className="font-bold text-gray-900">Phone</h3>
                    <p className="text-gray-600">Office: (555) 123-4567</p>
                    <p className="text-gray-600">Emergency: (555) 987-6543</p>
                  </div>
                </div>

                <div className="flex items-start">
                  <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 mt-1 flex-shrink-0">
                    <Mail className="h-5 w-5" />
                  </div>
                  <div className="ml-4">
                    <h3 className="font-bold text-gray-900">Email</h3>
                    <p className="text-gray-600">info@summitelectric.com</p>
                    <p className="text-gray-600">service@summitelectric.com</p>
                  </div>
                </div>
              </div>

              <div className="space-y-4">
                <h3 className="font-bold text-gray-900">Follow Us</h3>
                <div className="flex space-x-4">
                  <a href="#" className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 hover:bg-blue-200 transition-colors">
                    <Facebook className="h-5 w-5" />
                  </a>
                  <a href="#" className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 hover:bg-blue-200 transition-colors">
                    <Instagram className="h-5 w-5" />
                  </a>
                  <a href="#" className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 hover:bg-blue-200 transition-colors">
                    <Twitter className="h-5 w-5" />
                  </a>
                  <a href="#" className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 hover:bg-blue-200 transition-colors">
                    <Linkedin className="h-5 w-5" />
                  </a>
                  <a href="#" className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-600 hover:bg-blue-200 transition-colors">
                    <Youtube className="h-5 w-5" />
                  </a>
                </div>
              </div>
            </div>

            <div className="lg:w-1/2">
              <div className="bg-gray-50 rounded-lg p-8 shadow-md">
                <h3 className="font-bold text-gray-900 mb-6 text-xl">Request a Quote</h3>
                <form className="space-y-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="first-name" className="block text-gray-700 text-sm font-medium mb-1">First Name</label>
                      <input
                        type="text"
                        id="first-name"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="last-name" className="block text-gray-700 text-sm font-medium mb-1">Last Name</label>
                      <input
                        type="text"
                        id="last-name"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label htmlFor="email" className="block text-gray-700 text-sm font-medium mb-1">Email</label>
                      <input
                        type="email"
                        id="email"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="phone" className="block text-gray-700 text-sm font-medium mb-1">Phone Number</label>
                      <input
                        type="tel"
                        id="phone"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="address" className="block text-gray-700 text-sm font-medium mb-1">Address</label>
                    <input
                      type="text"
                      id="address"
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>

                  <div>
                    <label htmlFor="service" className="block text-gray-700 text-sm font-medium mb-1">Service Needed</label>
                    <select
                      id="service"
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    >
                      <option value="">Select a Service</option>
                      <option value="emergency">Emergency Repair</option>
                      <option value="inspection">Electrical Inspection</option>
                      <option value="installation">New Installation</option>
                      <option value="upgrade">Panel Upgrade</option>
                      <option value="lighting">Lighting Installation</option>
                      <option value="commercial">Commercial Service</option>
                      <option value="other">Other (Please Specify)</option>
                    </select>
                  </div>

                  <div>
                    <label htmlFor="message" className="block text-gray-700 text-sm font-medium mb-1">Project Details</label>
                    <textarea
                      id="message"
                      rows="4"
                      className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      required
                    ></textarea>
                  </div>

                  <div className="flex items-start">
                    <input
                      id="terms"
                      type="checkbox"
                      className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500 mt-1"
                      required
                    />
                    <label htmlFor="terms" className="ml-2 block text-sm text-gray-600">
                      I agree to the <a href="#" className="text-blue-600 hover:underline">Terms of Service</a> and <a href="#" className="text-blue-600 hover:underline">Privacy Policy</a>
                    </label>
                  </div>

                  <button
                    type="submit"
                    className="w-full bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-md transition duration-300 font-medium flex items-center justify-center"
                  >
                    <MessageSquare className="h-5 w-5 mr-2" />
                    Submit Request
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Appointment Scheduling Banner */}
      <section className="py-10 bg-gray-100 border-t border-gray-200">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="mb-6 md:mb-0">
              <h3 className="text-xl font-bold text-gray-900 mb-2">Schedule an Appointment Online</h3>
              <p className="text-gray-600">Choose a convenient time for your electrical service.</p>
            </div>
            <a 
              href="#"
              className="inline-flex items-center bg-blue-600 hover:bg-blue-700 text-white px-6 py-3 rounded-md transition duration-300"
            >
              <Calendar className="h-5 w-5 mr-2" />
              Book Appointment
            </a>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white pt-16 pb-8">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-4 gap-8 mb-12">
            <div>
              <div className="flex items-center mb-4">
                <div className="text-blue-400 mr-2">
                  <svg width="32" height="32" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M20 5L33.6603 12.5V27.5L20 35L6.33975 27.5V12.5L20 5Z" fill="currentColor" fillOpacity="0.2" />
                    <path d="M20 0L37.3205 10V30L20 40L2.67949 30V10L20 0Z" stroke="currentColor" strokeWidth="2" />
                    <path d="M8 20L20 26L32 20" stroke="currentColor" strokeWidth="2" />
                    <path d="M20 13V26" stroke="currentColor" strokeWidth="2" />
                  </svg>
                </div>
                <div>
                  <h2 className="text-xl font-bold">Summit Electric</h2>
                  <p className="text-xs text-blue-400 -mt-1">Licensed Electrical Contractors</p>
                </div>
              </div>
              <p className="text-gray-400 mb-4">
                Providing professional electrical services to Boulder County and beyond since 2005. Licensed, insured, and committed to quality.
              </p>
              <div className="flex space-x-4">
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <Facebook className="h-5 w-5" />
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <Instagram className="h-5 w-5" />
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <Twitter className="h-5 w-5" />
                </a>
                <a href="#" className="text-gray-400 hover:text-white transition-colors">
                  <Linkedin className="h-5 w-5" />
                </a>
              </div>
            </div>

            <div>
              <h3 className="font-bold mb-4 text-lg border-b border-gray-700 pb-2">Our Services</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Residential Services</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Commercial Services</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Emergency Repairs</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Electrical Inspections</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Panel Upgrades</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Lighting Installation</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Rewiring Services</a></li>
              </ul>
            </div>

            <div>
              <h3 className="font-bold mb-4 text-lg border-b border-gray-700 pb-2">Quick Links</h3>
              <ul className="space-y-2">
                <li><a href="#about" className="text-gray-400 hover:text-white transition-colors">About Us</a></li>
                <li><a href="#services" className="text-gray-400 hover:text-white transition-colors">Services</a></li>
                <li><a href="#testimonials" className="text-gray-400 hover:text-white transition-colors">Testimonials</a></li>
                <li><a href="#faq" className="text-gray-400 hover:text-white transition-colors">FAQ</a></li>
                <li><a href="#contact" className="text-gray-400 hover:text-white transition-colors">Contact Us</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Careers</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Blog</a></li>
              </ul>
            </div>

            <div>
              <h3 className="font-bold mb-4 text-lg border-b border-gray-700 pb-2">Contact Info</h3>
              <ul className="space-y-3">
                <li className="flex items-start">
                  <MapPin className="h-5 w-5 text-blue-400 mr-2 mt-0.5 flex-shrink-0" />
                  <span className="text-gray-400">1234 Electric Avenue<br />Boulder, CO 80301</span>
                </li>
                <li className="flex items-center">
                  <Phone className="h-5 w-5 text-blue-400 mr-2 flex-shrink-0" />
                  <span className="text-gray-400">(555) 123-4567</span>
                </li>
                <li className="flex items-center">
                  <Mail className="h-5 w-5 text-blue-400 mr-2 flex-shrink-0" />
                  <span className="text-gray-400">info@summitelectric.com</span>
                </li>
                <li className="flex items-start">
                  <Clock className="h-5 w-5 text-blue-400 mr-2 mt-0.5 flex-shrink-0" />
                  <div>
                    <span className="text-gray-400">Mon-Fri: 7:00 AM - 6:00 PM<br />Sat: 8:00 AM - 2:00 PM<br />24/7 Emergency Service</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="border-t border-gray-800 pt-8 flex flex-col md:flex-row justify-between">
            <p className="text-gray-500 text-sm mb-4 md:mb-0">
              &copy; {new Date().getFullYear()} Summit Electric. All rights reserved. CO License #EL-12345
            </p>
            <div className="flex flex-wrap gap-4">
              <a href="#" className="text-gray-500 hover:text-gray-400 text-sm">Privacy Policy</a>
              <a href="#" className="text-gray-500 hover:text-gray-400 text-sm">Terms of Service</a>
              <a href="#" className="text-gray-500 hover:text-gray-400 text-sm">Cookie Policy</a>
              <a href="#" className="text-gray-500 hover:text-gray-400 text-sm">Sitemap</a>
            </div>
          </div>
        </div>
      </footer>

      {/* Bottom CTA */}
      <div className="fixed bottom-4 right-4 z-20">
        <div className="flex flex-col space-y-2">
          <a 
            href="tel:5551234567" 
            className="bg-green-600 hover:bg-green-700 text-white p-4 rounded-full shadow-lg transition-all duration-300 flex items-center justify-center"
            aria-label="Call now"
          >
            <Phone className="h-6 w-6" />
          </a>
          <a 
            href="#contact" 
            className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-full shadow-lg transition-all duration-300 flex items-center"
          >
            <span className="mr-2">Get a Quote</span>
            <MessageSquare className="h-4 w-4" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default LocalBusinessDemo;