import React, { useEffect, useState, useRef, useCallback } from 'react';
import { MessageCircle, History, ArrowRight, ChevronDown, Check, Clipboard, PanelLeft, PanelRight, Plus, Settings, Share2 } from 'lucide-react'; // Added Check, Clipboard, etc.
import MessageInput from './MessageInput';
import ThreadSidebar from '../Sidebar/ThreadSidebar';
import { API_BASE_URL } from '../../services/api';
import useSettings from '../../hooks/useSettings';

// --- Copied from Header.js: Model Data Structure and Content ---
// Helper function to format token counts
const formatTokens = (tokens) => {
  if (typeof tokens !== 'number') return 'N/A';
  if (tokens >= 1000000) return `${tokens / 1000000}M`;
  if (tokens >= 1000) return `${tokens / 1000}K`;
  return tokens.toString();
};

const modelsData = [
  // 1. Hot / Leading Edge 🔥
  { value: 'claude-3-7-sonnet-20250219', label: 'Claude 3.7 Sonnet', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡', cost: '$$$', tags: ['✨', '</>', '🎨👑', '💬'], description: 'Excellent coding capabilities, excels at UI generation, strong mathematical reasoning and planning. Suitable for nuanced conversations.', notes: 'Higher cost model.', inputContextLength: 200000, outputContextLength: 128000, outputNote: '' },
  { value: 'claude-3-7-sonnet-thinking', label: 'Claude 3.7 Sonnet Thinking', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡', cost: '$$$', tags: ['✨', '</>', '🎨👑', '💬', '📝', '🧠'], description: 'Enhanced version of 3.7 Sonnet. Excels at coding, UI generation, planning, and handling complex tasks and conversations. May output intermediate reasoning tokens before the final answer.', notes: 'Higher cost model. The "thinking" process may slightly increase latency.', inputContextLength: 200000, outputContextLength: 128000, outputNote: '' },
  { value: 'deepseek-chat', label: 'DeepSeek Chat', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡', cost: '$', tags: ['⚙️', '</>', '🎨', '🧮', '💰', '⚠️'], description: 'Strong performance in coding, UI generation, and mathematics at a low cost.', notes: 'Both regional variants may have stricter content filtering and potential biases influenced by training data.', inputContextLength: 64000, outputContextLength: 8192 },
  { value: 'gemini-2.5-pro-exp-03-25', label: 'Gemini 2.5 Pro Latest', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡', cost: '$$', tags: ['✨', '</>', '📝', '💡'], description: 'Excellent coding, planning, and reasoning capabilities. Strong creative generation. Efficiently processes large documents and codebases.', notes: 'Mid-range cost model.', inputContextLength: 1048576, outputContextLength: 65536 }, // 1M Input, 64K Output
  { value: 'chatgpt-4o-latest', label: 'ChatGPT-4o Latest', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡', cost: '$$$', tags: ['👍', '</>', '💬', '🧮', 'Vibe'], description: 'Strong coding and mathematical abilities. Adept at understanding conversational nuances. Suitable for general tasks. Engaging conversational style.', notes: 'Moderate performance regarding speed and latency. Less proficient in UI code generation compared to some peers.', inputContextLength: 128000, outputContextLength: 16384 }, // 128K Input, 16K Output
  { value: 'o3-mini', label: 'o3 Mini', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡⚡', cost: '$$', tags: ['👍', '</>', '🧮', '📝', 'Web', '⚠️'], description: 'Fast response times, particularly for simpler queries. Good performance in math, instruction following, planning, and coding. Includes web access capabilities.', notes: 'May occasionally generate inaccurate information (hallucinate). Less effective for UI code generation.', inputContextLength: 200000, outputContextLength: 100000 }, // 200K Input, 100K Output

  // 2. Speed & Value ⚡💰
  { value: 'claude-3-haiku-20240307', label: 'Claude 3 Haiku 2024-03-07', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚡', '💰', '📊'], description: 'Legacy model. Very high speed and very low cost. Effective for data structuring tasks.', notes: 'Limited capabilities for coding and UI generation.', inputContextLength: 200000, outputContextLength: 4096 }, // 200K Input, 4K Output
  { value: 'claude-3-5-haiku-20241022', label: 'Claude 3.5 Haiku 2024-10-22', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$$', tags: ['</>', '🎨', '🗑️'], description: 'Offers reasonable coding/UI capabilities relative to its release date, with high speed.', notes: 'Performance is superseded by current Haiku/Sonnet versions. Weaker in mathematical reasoning and instruction following.', inputContextLength: 200000, outputContextLength: 8192 }, // 200K Input, 8K Output
  { value: 'command-r7b-12-2024', label: 'Command R7B 12-2024', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['💰', '🤔'], description: 'Very low cost. General capabilities are less advanced compared to leading models. Designed for lightweight RAG', notes: '', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'gemini-2.0-flash-001', label: 'Gemini 2.0 Flash', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚡', '💰', '</>', '📊'], description: 'Very high speed and low cost. Competent coding abilities. Strong performance in data structuring and context-based Q&A.', notes: 'Less effective for UI code generation.', inputContextLength: 1048576, outputContextLength: 8192 }, // 1M Input, 8K Output
  { value: 'gemini-2.0-flash-lite-preview', label: 'Gemini 2.0 Flash Lite Preview', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚡', '💰'], description: 'High speed and low cost. A less capable variant of Gemini 2.0 Flash.', notes: '', inputContextLength: 1048576, outputContextLength: 8192 }, // 1M Input, 8K Output
  { value: 'gemini-2.0-flash-thinking-exp', label: 'Gemini 2.0 Flash Thinking Exp', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚙️', '⚡', '💰', '🧠', '🧪'], description: 'Experimental variant of Flash with potentially enhanced reasoning. May output intermediate reasoning tokens before the final answer, potentially increasing unpredictability with the tradeoff of higher performance.', notes: 'Experimental status implies potential for instability. The "thinking" process may slightly increase perceived latency.', inputContextLength: 1048576, outputContextLength: 8192 }, // 1M Input, 8K Output
  { value: 'gpt-4o-mini', label: 'GPT-4O Mini', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚡', '💰', 'Web', '🧠', '⚠️'], description: 'High speed and low cost. Includes web access capabilities.', notes: 'Prone to generating inaccurate information (hallucinations) more often than models like Gemini 2.0 Flash. Limited performance in mathematics and complex coding tasks.', inputContextLength: 128000, outputContextLength: 16384 }, // 128K Input, 16K Output

  // 3. Expensive Models 💸
  { value: 'claude-3-opus-20240229', label: 'Claude 3 Opus 2024-02-29', category: 'Expensive Models 💸', speed: '⚡', cost: '$$$$', tags: ['✍️', '💬', 'Vibe', '🗑️'], description: 'Legacy state-of-the-art model. Excels in creative writing and engaging conversation.', notes: 'Superseded by newer models in coding, UI generation, and planning tasks.', inputContextLength: 200000, outputContextLength: 4096 }, // 200K Input, 4K Output
  { value: 'gpt-4-turbo', label: 'GPT-4 Turbo', category: 'Expensive Models 💸', speed: '⚡', cost: '$$$$', tags: ['💬', 'Vibe', '🗑️'], description: 'Legacy model. Valued by some users for its conversational abilities & style.', notes: 'High cost. Current coding and UI generation capabilities are considered subpar compared to newer alternatives.', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'o1', label: 'o1', category: 'Expensive Models 💸', speed: '⚡', cost: '$$$$', tags: ['⚙️', '</>', '🧮', '📝', '💸', '🐢', '⚠️'], description: 'Exceptionally strong performance in coding, mathematics, report generation, and general knowledge recall.', notes: 'Very high cost, significantly slower response times, and high latency.', inputContextLength: 200000, outputContextLength: 100000 }, // 200K Input, 100K Output
  { value: 'o1-pro', label: 'o1 Pro', category: 'Expensive Models 💸', speed: '⚡', cost: '$$$$$$', tags: ['🧠', '⚙️', '💸', '🐢'], description: 'High-compute version of o1 with enhanced reasoning capabilities. Uses reinforcement learning for complex reasoning tasks.', notes: 'WARNING: Extremely high cost 10x as expensive as o1. Very slow response times.', inputContextLength: 200000, outputContextLength: 100000 },
  { value: 'gpt-4.5-preview', label: 'GPT-4.5 Preview', category: 'Expensive Models 💸', speed: '⚡', cost: '$$$$$', tags: ['✨', '🧠', '💬', 'Vibe'], description: 'Largest GPT model yet. Excels at creative tasks, agentic planning, and open-ended thinking. Strong world knowledge and understanding of user intent.', notes: 'WARNING: Very high cost more than 2x as expensive as o1. Sep 2023 knowledge cutoff.', inputContextLength: 128000, outputContextLength: 16384 },

  // 4. Specialized & Experimental ⚙️🧪
  { value: 'claude-3-5-sonnet-20241022', label: 'Claude 3.5 Sonnet 2024-10-22', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$$', tags: ['⚙️', '💬', 'Vibe', '⏳', '⚠️'], description: 'Possesses a distinct conversational style. Performance is generally lower than Claude 3.7 Sonnet.', notes: 'Frequently fails to generate longer responses, which can be a significant limitation.', inputContextLength: 200000, outputContextLength: 8192 }, // 200K Input, 8K Output
  { value: 'command-r', label: 'Command R', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡⚡', cost: '$', tags: ['⚙️', 'RAG', 'COBOL', '🤔'], description: 'Less capable general-purpose model. Notable strengths in Retrieval-Augmented Generation (RAG) and support for less common programming languages (e.g., COBOL).', notes: '', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'command-r-plus-08-2024', label: 'Command R Plus 08-2024', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$$', tags: ['⚙️', 'RAG', '🤔'], description: 'A larger, more capable model than Command R, offering enhanced performance with a focus on Retrieval-Augmented Generation (RAG) applications.', notes: 'Mid-to-high range cost and performance level.', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'deepseek-reasoner', label: 'DeepSeek R1 (china)', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$', tags: ['⚙️', '</>', '🧮', '💰', '⚠️'], description: 'Improved coding and mathematical abilities compared to DeepSeek Chat, but less proficient in UI generation.', notes: 'Both regional variants may have stricter content filtering and potential biases influenced by training data (CCP). Standard context window size. Has 32K intermediate thinking tokens.', inputContextLength: 64000, outputContextLength: 8192 }, // 64K Input, 8K Output (+32K thinking)
  { value: 'DeepSeek-R1', label: 'DeepSeek R1 (usa)', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$', tags: ['⚙️', '</>', '🧮', '💰', '⚠️'], description: 'Functionally equivalent to DeepSeek R1 (china) but typically exhibits higher latency due to US-based hosting.', notes: 'Both regional variants may have stricter content filtering and potential biases influenced by training data (CCP). Higher latency compared to the alternative region. Standard context window size. Has 32K intermediate thinking tokens.', inputContextLength: 64000, outputContextLength: 8192 }, // 64K Input, 8K Output (+32K thinking)
  { value: 'Meta-Llama-3.1-405B-Instruct', label: 'Meta Llama 3.1 405B Instruct', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$$', tags: ['⚙️', '</>', '✍️'], description: 'Very large parameter model. Strong capabilities in creative writing and coding. Features a distinct conversational style.', notes: 'Limited effectiveness for UI code generation.', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'reka-core-20240904', label: 'Reka Core', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$', tags: ['⚙️', '🖼️', '🤔'], description: 'Specialized in multimodal tasks, particularly image and video recognition/analysis (e.g., identifying objects). General chat and coding capabilities are limited.', notes: '', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'reka-edge-20240208', label: 'Reka Edge', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚙️', '🖼️', '🤔'], description: 'Specialized in multimodal tasks, particularly image and video recognition/analysis. Optimized for speed, potentially suitable for edge devices.', notes: 'Low-cost option for multimodal analysis tasks.', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'reka-flash-3', label: 'Reka Flash 3', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡⚡', cost: '$', tags: ['⚙️', '🖼️', '🤔'], description: 'Specialized in multimodal tasks, particularly image and video recognition/analysis. Offers a balance of speed and capability.', notes: 'Faster analysis option compared to Reka Core.', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output

  // 5. Legacy / Archive 🗑️
  { value: 'DeepSeek-R1-Zero', label: 'DeepSeek R1 Zero', category: 'Legacy / Archive 🗑️', speed: '⚡⚡', cost: '$$', tags: ['🗑️'], description: 'Deprecated model, superseded by newer versions.', notes: '', inputContextLength: null, outputContextLength: null }, // Context Unknown
  { value: 'gemini-1.5-flash-8b', label: 'Gemini 1.5 Flash 8B', category: 'Legacy / Archive 🗑️', speed: '⚡⚡⚡⚡', cost: '$', tags: ['💰', '🗑️'], description: 'Legacy model. Lower cost but less capable than Gemini 2.0 Flash Lite.', notes: '', inputContextLength: null, outputContextLength: null }, // Context Unknown
  { value: 'gemini-1.5-flash-latest', label: 'Gemini 1.5 Flash Latest', category: 'Legacy / Archive 🗑️', speed: '⚡⚡⚡⚡', cost: '$', tags: ['🗑️'], description: 'Legacy model. Superseded by the more capable Gemini 2.0 Flash.', notes: '', inputContextLength: null, outputContextLength: null }, // Context Unknown
  { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo', category: 'Legacy / Archive 🗑️', speed: '⚡⚡⚡⚡', cost: '$$', tags: ['🗑️'], description: 'Legacy model. Performance is significantly below current standards.', notes: '', inputContextLength: 16385, outputContextLength: 4096 }, // 16K Input, 4K Output (Common default)
  { value: 'gpt-4o-2024-11-20', label: 'GPT-4O 2024-11-20', category: 'Legacy / Archive 🗑️', speed: '⚡⚡⚡', cost: '$$$', tags: ['👍', '🧮', 'Vibe', 'Web', '⚠️', '🗑️'], description: 'Snapshot version of GPT-4O. Fast for simpler queries, good mathematical ability, understands conversational nuances. Includes web access.', notes: 'May generate inaccurate information (hallucinate). Less effective for UI and general coding compared to the latest GPT-4O version.', inputContextLength: 128000, outputContextLength: 16384 }, // 128K Input, 16K Output
  { value: 'grok-beta', label: 'Grok Beta', category: 'Legacy / Archive 🗑️', speed: '⚡⚡', cost: '$$$', tags: ['🤔', '🗑️'], description: 'Legacy beta version of Grok. Limited capabilities compared to contemporary models. Newer versions anticipated.', notes: '', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'o1-mini', label: 'o1 Mini', category: 'Legacy / Archive 🗑️', speed: '⚡⚡⚡', cost: '$$$', tags: ['🗑️'], description: 'Legacy model, the predecessor to o3 Mini.', notes: '', inputContextLength: null, outputContextLength: null } // Context Unknown
];

const modelCategories = [
    'Hot / Leading Edge 🔥',
    'Speed & Value ⚡💰',
    'Specialized & Experimental ⚙️🧪',
    'Expensive Models 💸',
    'Legacy / Archive 🗑️',
];

// --- Copied from Header.js: Helper component for the info popup ---
const ModelInfoPopup = ({ model, position }) => {
  if (!model || !position) return null;
  const hasContextData = typeof model.inputContextLength === 'number' || typeof model.outputContextLength === 'number';
  return (
    <div
      className="fixed bg-[#3a3a3a] text-white p-4 rounded-lg shadow-xl z-[60] w-64 border border-gray-600 text-xs" // Increased z-index
      style={{ top: `${position.top}px`, left: `${position.left}px` }}
    >
      <h4 className="font-bold mb-1 text-sm">{model.label}</h4>
      <p className="text-gray-400 text-[11px] mb-2">{model.category}</p>
      <div className="flex space-x-3 mb-2 text-[11px]">
        <span>Speed: {model.speed}</span>
        <span>Cost: {model.cost}</span>
      </div>
      {hasContextData && (
         <div className="mb-2 text-[11px]">
            <span className="font-semibold">Context:</span>
            {typeof model.inputContextLength === 'number' && ` Input: ${formatTokens(model.inputContextLength)}`}
            {typeof model.outputContextLength === 'number' && ` Output: ${formatTokens(model.outputContextLength)}`}
            {model.outputNote && <span className="text-gray-400"> ({model.outputNote})</span>}
         </div>
      )}
      <p className="mb-2 text-[11px] leading-relaxed">{model.description}</p>
      <div className="mb-2 text-[11px]">
        <span className="font-semibold">Tags:</span> {model.tags.join(' ')}
      </div>
      {model.notes && (
         <p className="text-gray-400 text-[11px]"><span className="font-semibold text-gray-300">Notes:</span> {model.notes}</p>
       )}
    </div>
  );
};

// --- ChatLanding Component ---
const ChatLanding = ({
  onNewChat,
  threads,
  threadOrder,
  onThreadSelect,
  inputMessage,
  setInputMessage,
  onSendMessage,
  currentlySelectedModel,
  experimentalFeatures,
  onToggleExperimental,
  pastes,
  onDeletePaste,
  onFileUpload,
  onAddPaste,
  sidebarPosition = 'left',
  isSidebarOpen,
  onToggleSidebar,
  onLogout,
  userMenuContainerRef,
  handleMouseLeaveSidebar,
  hasMore,
  loadMoreThreads,
  onEditThread,
  onDeleteThread,
  handleThreadChangeWrapper,
  threadsLoading,
  createNewChat,
  setCurrentlySelectedModel,
  setSidebarPosition,
  navigate, // Added navigate here
  isLoadingMore
}) => {
  const [isLoadingMoreState, setIsLoadingMoreState] = useState(false);
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // State for hover popup (copied from Header.js)
  const [hoveredModelValue, setHoveredModelValue] = useState(null);
  const [popupPosition, setPopupPosition] = useState(null);
  const [hoverTimeoutId, setHoverTimeoutId] = useState(null);

  // Get the 5 most recent threads
  const recentThreads = threadOrder
    ?.slice(0, 5)
    .map(id => threads?.get(id))
    .filter(Boolean);

  // Initialize settings hook
  const {
    customInstructions,
    handleSaveSettings,
    fetchSettings,
  } = useSettings({
    onLogout,
    defaultModel: currentlySelectedModel,
  });

  // Fetch settings on mount
  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  // Handle model selection with save
  const handleModelSelect = async (modelValue) => {
    try {
      setCurrentlySelectedModel(modelValue);
      setIsModelDropdownOpen(false);
      setHoveredModelValue(null); // Close popup on select
      setPopupPosition(null);
      if (hoverTimeoutId) clearTimeout(hoverTimeoutId);
      await handleSaveSettings({
        customInstructions: customInstructions || '',
        currentlySelectedModel: modelValue,
        sidebarPosition: sidebarPosition,
      });
    } catch (error) {
      console.error('Failed to save model selection:', error);
    }
  };

  // Handle sidebar position toggle
  const handleSidebarPositionToggle = async (newPosition) => {
    try {
      setSidebarPosition(newPosition);
      await handleSaveSettings({
        customInstructions: customInstructions || '',
        currentlySelectedModel: currentlySelectedModel,
        sidebarPosition: newPosition,
      });
    } catch (error) {
      console.error('Failed to save sidebar position:', error);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsModelDropdownOpen(false);
        setHoveredModelValue(null); // Close popup on outside click
        setPopupPosition(null);
        if (hoverTimeoutId) clearTimeout(hoverTimeoutId);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        if (hoverTimeoutId) clearTimeout(hoverTimeoutId); // Cleanup timeout on unmount
    }
  }, [hoverTimeoutId]); // Added hoverTimeoutId dependency

  // Get current model label using modelsData
  const getCurrentModelLabel = () => {
    const model = modelsData.find(m => m.value === currentlySelectedModel);
    return model ? model.label : 'Select Model';
  };

  // Handle mobile sidebar body class
  useEffect(() => {
    if (isSidebarOpen && window.innerWidth <= 450) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
    return () => {
      document.body.classList.remove('sidebar-open');
    };
  }, [isSidebarOpen]);

  // Update loadMoreThreads to handle isLoadingMore
  const handleLoadMore = async () => {
    setIsLoadingMoreState(true);
    await loadMoreThreads(() => setIsLoadingMoreState(false));
  };

  // Handle starting a new chat with the first message
  const handleNewChatAndMessage = async (msg) => {
    try {
      // Just send the message - the backend will create a new thread if needed
      const response = await onSendMessage(msg);
      return response;
    } catch (error) {
      console.error("Error sending initial message:", error);
      return null;
    }
  };

  // --- Copied from Header.js: Hover handlers for popup ---
  const handleModelMouseEnter = (event, model) => {
     if (hoverTimeoutId) clearTimeout(hoverTimeoutId);
     const targetElement = event.currentTarget;
     const showPopup = () => {
        if (targetElement && document.body.contains(targetElement)) {
            const rect = targetElement.getBoundingClientRect();
            const popupTop = rect.top; // Position below the item
            const viewportWidth = window.innerWidth;
            const popupWidth = 256; // approx width of popup (w-64)
            let popupLeft = rect.left; // Align left edge initially

            // Adjust if popup goes off-screen right
            if (popupLeft + popupWidth > viewportWidth - 8) {
                popupLeft = viewportWidth - popupWidth - 8; // Position it with some padding from right edge
            }
            // Ensure it doesn't go off-screen left
            if (popupLeft < 8) {
               popupLeft = 8;
            }

            // Try positioning to the right if there's space (like original header)
            const spaceRight = viewportWidth - rect.right;
            if (spaceRight >= popupWidth + 8) {
                popupLeft = rect.right + 8; // Default right position
            } else {
                // If not enough space right, position to the left
                popupLeft = rect.left - popupWidth - 8;
                if (popupLeft < 8) { // Keep it on screen if positioning left fails
                   popupLeft = 8;
                }
            }

            setHoveredModelValue(model.value);
            setPopupPosition({ top: rect.bottom + 4, left: popupLeft }); // Position below, adjust left/right
        } else {
            console.warn("Target element for popup disappeared or is not valid.");
            setHoveredModelValue(null);
            setPopupPosition(null);
        }
     };
     const timeoutId = setTimeout(showPopup, 150); // 150ms delay
     setHoverTimeoutId(timeoutId);
  };

  const handleModelMouseLeave = () => {
    if (hoverTimeoutId) clearTimeout(hoverTimeoutId);
     const hideTimeoutId = setTimeout(() => {
        const popupElement = document.getElementById('model-info-popup-id');
        // Check if mouse is NOT over the popup before hiding
        if (!popupElement || !popupElement.matches(':hover')) {
             setHoveredModelValue(null);
             setPopupPosition(null);
        }
     }, 100); // Slight delay to allow moving mouse onto popup
    setHoverTimeoutId(hideTimeoutId);
  };

  const handlePopupMouseEnter = () => {
     if (hoverTimeoutId) clearTimeout(hoverTimeoutId); // Cancel any pending hide timeouts
  };

  const handlePopupMouseLeave = () => {
      const hideTimeoutId = setTimeout(() => { // Hide after leaving popup
          setHoveredModelValue(null);
          setPopupPosition(null);
      }, 100);
      setHoverTimeoutId(hideTimeoutId);
  };
  // --- END Hover ---

  const hoveredModel = modelsData.find(m => m.value === hoveredModelValue);


  const renderSidebar = () => {
      return (
          <ThreadSidebar
              position={sidebarPosition}
              threads={threads}
              threadOrder={threadOrder}
              currentThreadId={0} // No thread selected on landing
              onThreadChange={onThreadSelect}
              onEditThread={onEditThread}
              onDeleteThread={onDeleteThread}
              onNewChat={onNewChat}
              isOpen={isSidebarOpen}
              onMouseLeave={handleMouseLeaveSidebar}
              hasMore={hasMore}
              onLoadMore={handleLoadMore}
              isLoading={threadsLoading}
              isLoadingMore={isLoadingMoreState}
              onToggleSidebar={onToggleSidebar}
              onLogout={onLogout}
              userMenuContainerRef={userMenuContainerRef}
              className={`absolute top-0 bottom-0 ${sidebarPosition === 'left' ? 'left-0' : 'right-0'}`}
          />
      );
  };

  return (
    <> {/* Use Fragment to allow Popup rendering outside main div */}
      <div className="flex flex-col h-screen bg-[#1a1a1a] text-white relative overflow-hidden">
          {isSidebarOpen && (
              <div
                  className="fixed inset-0 bg-black bg-opacity-50 z-10 sm:hidden"
                  onClick={onToggleSidebar}
              />
          )}
        <div className={`
          flex-1 relative flex flex-col
          transition-all duration-300
        `}>
            {/* Render the sidebar here so it's rendered above the content area on mobile */}
            {renderSidebar()}
          <div className={`
              flex-grow flex justify-center overflow-hidden relative
              ${isSidebarOpen && sidebarPosition === 'left' ? 'sm:ml-72' : 'ml-0'}
              ${isSidebarOpen && sidebarPosition === 'right' ? 'sm:mr-72' : 'mr-0'}
              transition-all duration-300 {/* Added transition */}
          `}>
            <div className={`
              flex flex-col flex-grow min-h-0
              w-full
              sm:max-w-[1000px]
              px-2 sm:px-4
            `}>
              {/* Hero Section */}
              <div className="flex flex-col items-center justify-center h-full w-full px-2 sm:px-4">
                <div className="text-center space-y-4 mb-8 w-full">
                  <h1 className="text-3xl sm:text-4xl font-bold text-gray-100">
                  What can I help you accomplish?
                  </h1>
                </div>

                {/* Center - Model Selector (Updated) */}
                <div className="relative mx-4 min-w-[120px] max-w-[280px] flex-shrink mb-8" ref={dropdownRef}>
                    <button
                        onClick={() => setIsModelDropdownOpen(!isModelDropdownOpen)}
                        className="w-full flex items-center justify-between px-4 py-2 bg-[#2a2a2a] rounded-lg hover:bg-[#3a3a3a] transition-colors"
                        aria-haspopup="listbox" aria-expanded={isModelDropdownOpen} aria-label="Select Model" title="Select Model"
                    >
                        <span className="text-sm font-medium truncate pr-2">{getCurrentModelLabel()}</span>
                        <ChevronDown size={16} className={`transform transition-transform duration-200 flex-shrink-0 ${isModelDropdownOpen ? 'rotate-180' : ''}`} />
                    </button>
                    {/* Dropdown List (Copied & adapted from Header.js) */}
                                        {/* Dropdown List (Copied & adapted from Header.js with the fix) */}
                                        {isModelDropdownOpen && (
                        <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-64 py-1 bg-[#2a2a2a] rounded-lg shadow-xl z-50 max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-[#3a3a3a] hover:scrollbar-thumb-gray-300 scrollbar-track-[#1a1a1a] scrollbar-thumb-rounded-full"
                             role="listbox">
                          {modelCategories.map(category => {
                            const modelsInCategory = modelsData.filter(model => model.category === category);
                            if (modelsInCategory.length === 0) return null;
                            return (
                              <div key={category} className="relative"> {/* Added relative positioning */}
                                {/* Category header with full-width background */}
                                <div className="sticky top-0 z-10">
                                  <div className="px-3 py-1.5 text-xs font-semibold text-orange-500 bg-[#2a2a2a]">
                                    {category}
                                  </div>
                                  {/* Optional: Gradient fade effect below the header */}
                                  {/* <div className="h-1 bg-gradient-to-b from-[#2a2a2a] to-transparent"></div> */}
                                </div>

                                {/* Models list with padding to prevent overlap */}
                                <div className="pt-1"> {/* Added padding-top */}
                                  {modelsInCategory.map((model) => (
                                    <button
                                      key={model.value}
                                      onClick={() => handleModelSelect(model.value)}
                                      onMouseEnter={(e) => handleModelMouseEnter(e, model)}
                                      onMouseLeave={handleModelMouseLeave}
                                      className={`w-full text-left px-3 py-1.5 text-sm hover:bg-[#3a3a3a] transition-colors flex justify-between items-center ${
                                        currentlySelectedModel === model.value ? 'bg-orange-600 text-white' : 'text-gray-200'
                                      }`}
                                      role="option" aria-selected={currentlySelectedModel === model.value}
                                      title={`Select ${model.label}`} // Title remains
                                    >
                                      <span className="flex-1 truncate mr-2">{model.label}</span>
                                    </button>
                                  ))}
                                </div>

                                {/* Add space between categories */}
                                <div className="h-2"></div> {/* Added space */}
                              </div>
                            );
                          })}
                        </div>
                    )}
                </div>

                {/* Message Input Section */}
                <div className="w-full max-w-2xl mb-8 sm:mb-12 px-2">
                  <MessageInput
                    inputMessage={inputMessage}
                    setInputMessage={setInputMessage}
                    onSendMessage={handleNewChatAndMessage}
                    isGenerating={false}
                    pastes={pastes}
                    onDeletePaste={onDeletePaste}
                    onFileUpload={onFileUpload}
                    onAddPaste={onAddPaste}
                    currentlySelectedModel={currentlySelectedModel}
                    experimentalFeatures={experimentalFeatures}
                    onToggleExperimental={onToggleExperimental}
                  />
                </div>

                {/* Recent Chats Section */}
                <div className="w-full max-w-2xl px-2">
                  <div className="flex items-center gap-2 mb-4">
                    <History className="text-orange-500" size={20} />
                    <h2 className="text-lg sm:text-xl font-semibold text-gray-100">Recent Conversations</h2>
                  </div>
                  {recentThreads?.length > 0 ? (
                    <div className="space-y-2">
                      {recentThreads.map(thread => (
                        <button
                          key={thread.id}
                          onClick={() => {
                            onThreadSelect(thread.id);
                          }}
                          className="w-full p-3 sm:p-4 bg-[#2a2a2a] rounded-lg hover:bg-[#3a3a3a]
                            transition-colors duration-200 group text-left flex
                            items-center justify-between"
                        >
                          <div className="flex items-center gap-2 sm:gap-3 overflow-hidden"> {/* Added overflow-hidden */}
                            <MessageCircle
                              size={16}
                              className="text-gray-500 group-hover:text-orange-500 flex-shrink-0" // Added flex-shrink-0
                            />
                            <div className="flex-1 overflow-hidden"> {/* Added flex-1 and overflow-hidden */}
                              <h3 className="text-sm sm:text-base text-gray-200 font-medium truncate"> {/* Added truncate */}
                                {thread.name}
                              </h3>
                                {thread.preview && (
                                    <p className="text-xs sm:text-sm text-gray-400 truncate"> {/* Added truncate */}
                                        {thread.preview}
                                    </p>
                                )}
                            </div>
                          </div>
                          <ArrowRight
                            size={16}
                            className="text-gray-500 group-hover:text-orange-500
                              opacity-0 group-hover:opacity-100 transition-opacity ml-2 flex-shrink-0" // Added ml-2 and flex-shrink-0
                          />
                        </button>
                      ))}
                    </div>
                  ) : (
                    <div className="text-center py-6 sm:py-8 text-sm sm:text-base text-gray-400">
                      No conversations loaded yet - start a new chat above!
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Render Popup (Copied from Header.js) */}
      <div
         id="model-info-popup-id" // Added ID
         onMouseEnter={handlePopupMouseEnter}
         onMouseLeave={handlePopupMouseLeave}
         // Style ensures the popup container doesn't interfere with layout but is present for hover checks
         style={{ position: 'fixed', top: 0, left: 0, width: 0, height: 0, zIndex: 55 }}
       >
          <ModelInfoPopup model={hoveredModel} position={popupPosition} />
      </div>
    </>
  );
};

export default ChatLanding;