import React, { useState } from 'react';
import {
  ChevronRight,
  CreditCard,
  Check,
  Truck,
  Shield,
  Info,
  AlertCircle,
  ArrowLeft
} from 'lucide-react';

const EcommerceCheckout = () => {
  const [step, setStep] = useState('information');
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [shippingMethod, setShippingMethod] = useState('standard');
  const [savedAddresses, setSavedAddresses] = useState([]);
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    apartment: '',
    city: '',
    state: '',
    zipCode: '',
    country: 'United States',
    phone: '',
    saveInfo: true,
    cardNumber: '',
    cardName: '',
    expiryDate: '',
    cvv: ''
  });
  
  // Form input change handler
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };
  
  // Cart items
  const cartItems = [
    {
      id: 1,
      name: "Premium Cotton T-Shirt",
      price: 34.99,
      color: "Midnight Blue",
      size: "M",
      quantity: 1,
      image: "https://images.unsplash.com/photo-1521572163474-6864f9cf17ab?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1480&q=80"
    },
    {
      id: 2,
      name: "Slim Fit Chino Pants",
      price: 69.95,
      color: "Khaki",
      size: "32",
      quantity: 1,
      image: "https://images.unsplash.com/photo-1473966968600-fa801b869a1a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80"
    }
  ];
  
  // Calculate order summary
  const subtotal = cartItems.reduce((sum, item) => sum + (item.price * item.quantity), 0);
  const shipping = shippingMethod === 'express' ? 14.99 : 0;
  const tax = subtotal * 0.08; // 8% tax
  const total = subtotal + shipping + tax;

  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Header */}
      <header className="bg-white border-b border-gray-200">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 py-4">
          <div className="flex justify-between items-center">
            <a href="#" className="text-2xl font-bold text-gray-900 flex items-center">
              <svg className="h-6 w-6 mr-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 9H20L19.1654 18.1811C19.0717 19.2112 18.208 20 17.1736 20H6.82643C5.79202 20 4.92829 19.2112 4.83464 18.1811L4 9Z" stroke="#2563EB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M8 9V5C8 3.89543 8.89543 3 10 3H14C15.1046 3 16 3.89543 16 5V9" stroke="#2563EB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <span>NORDIC</span>
            </a>
            <div className="flex items-center text-sm">
              <span className="text-gray-500">Need help?</span>
              <a href="#" className="ml-2 text-blue-600 hover:text-blue-800 font-medium">Contact us</a>
            </div>
          </div>
        </div>
      </header>
      
      {/* Checkout Steps */}
      <div className="max-w-6xl mx-auto px-4 sm:px-6 py-8">
        <div className="flex items-center justify-center mb-8">
          <div className={`flex items-center ${step === 'information' || step === 'shipping' || step === 'payment' ? 'text-blue-600' : 'text-gray-400'}`}>
            <div className={`h-8 w-8 rounded-full flex items-center justify-center ${step === 'information' || step === 'shipping' || step === 'payment' ? 'bg-blue-100' : 'bg-gray-200'}`}>
              {step === 'information' || step === 'shipping' || step === 'payment' ? (
                <Check className="h-5 w-5" />
              ) : (
                <span>1</span>
              )}
            </div>
            <span className="ml-2 font-medium">Information</span>
          </div>
          <ChevronRight className="h-5 w-5 mx-2 text-gray-400" />
          <div className={`flex items-center ${step === 'shipping' || step === 'payment' ? 'text-blue-600' : 'text-gray-400'}`}>
            <div className={`h-8 w-8 rounded-full flex items-center justify-center ${step === 'shipping' || step === 'payment' ? 'bg-blue-100' : 'bg-gray-200'}`}>
              {step === 'shipping' || step === 'payment' ? (
                <Check className="h-5 w-5" />
              ) : (
                <span>2</span>
              )}
            </div>
            <span className="ml-2 font-medium">Shipping</span>
          </div>
          <ChevronRight className="h-5 w-5 mx-2 text-gray-400" />
          <div className={`flex items-center ${step === 'payment' ? 'text-blue-600' : 'text-gray-400'}`}>
            <div className={`h-8 w-8 rounded-full flex items-center justify-center ${step === 'payment' ? 'bg-blue-100' : 'bg-gray-200'}`}>
              {step === 'payment' ? (
                <Check className="h-5 w-5" />
              ) : (
                <span>3</span>
              )}
            </div>
            <span className="ml-2 font-medium">Payment</span>
          </div>
        </div>
        
        <div className="grid md:grid-cols-3 gap-8">
          {/* Checkout Form */}
          <div className="md:col-span-2">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              {/* Information Step */}
              {step === 'information' && (
                <>
                  <h2 className="text-lg font-bold text-gray-900 mb-4">Contact Information</h2>
                  
                  <div className="mb-6">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">Email address</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>
                  
                  <h2 className="text-lg font-bold text-gray-900 mb-4">Shipping Address</h2>
                  
                  {savedAddresses.length > 0 && (
                    <div className="mb-6">
                      <label className="block text-sm font-medium text-gray-700 mb-1">Saved Addresses</label>
                      <select
                        className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="">Select a saved address</option>
                        {savedAddresses.map((address, index) => (
                          <option key={index} value={index}>
                            {address.firstName} {address.lastName}, {address.address}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
                    <div>
                      <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">First name</label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">Last name</label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>
                  
                  <div className="mb-4">
                    <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">Address</label>
                    <input
                      type="text"
                      id="address"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>
                  
                  <div className="mb-4">
                    <label htmlFor="apartment" className="block text-sm font-medium text-gray-700 mb-1">Apartment, suite, etc. (optional)</label>
                    <input
                      type="text"
                      id="apartment"
                      name="apartment"
                      value={formData.apartment}
                      onChange={handleInputChange}
                      className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mb-4">
                    <div className="col-span-2 sm:col-span-1">
                      <label htmlFor="city" className="block text-sm font-medium text-gray-700 mb-1">City</label>
                      <input
                        type="text"
                        id="city"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                        className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                    <div>
                      <label htmlFor="state" className="block text-sm font-medium text-gray-700 mb-1">State</label>
                      <select
                        id="state"
                        name="state"
                        value={formData.state}
                        onChange={handleInputChange}
                        className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        required
                      >
                        <option value="">Select...</option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        {/* Other states would go here */}
                        <option value="WY">Wyoming</option>
                      </select>
                    </div>
                    <div>
                      <label htmlFor="zipCode" className="block text-sm font-medium text-gray-700 mb-1">ZIP code</label>
                      <input
                        type="text"
                        id="zipCode"
                        name="zipCode"
                        value={formData.zipCode}
                        onChange={handleInputChange}
                        className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        required
                      />
                    </div>
                  </div>
                  
                  <div className="mb-6">
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">Phone</label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      required
                    />
                  </div>
                  
                  <div className="flex items-center mb-6">
                    <input
                      id="saveInfo"
                      name="saveInfo"
                      type="checkbox"
                      checked={formData.saveInfo}
                      onChange={handleInputChange}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <label htmlFor="saveInfo" className="ml-2 block text-sm text-gray-700">
                      Save this information for next time
                    </label>
                  </div>
                  
                  <div className="flex items-center justify-between">
                    <a href="#" className="inline-flex items-center text-blue-600 hover:text-blue-800">
                      <ArrowLeft className="h-4 w-4 mr-1" />
                      Return to cart
                    </a>
                    <button
                      onClick={() => setStep('shipping')}
                      className="bg-blue-600 text-white px-6 py-2 rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Continue to shipping
                    </button>
                  </div>
                </>
              )}
              
              {/* Shipping Step */}
              {step === 'shipping' && (
                <>
                  <div className="mb-6 bg-gray-50 p-4 rounded-lg">
                    <div className="flex justify-between">
                      <div>
                        <h3 className="text-sm font-medium text-gray-700">Contact</h3>
                        <p className="text-sm text-gray-500">{formData.email}</p>
                      </div>
                      <button className="text-sm text-blue-600 hover:text-blue-800">
                        Change
                      </button>
                    </div>
                    <div className="border-t border-gray-200 mt-4 pt-4 flex justify-between">
                      <div>
                        <h3 className="text-sm font-medium text-gray-700">Ship to</h3>
                        <p className="text-sm text-gray-500">
                          {formData.address}, {formData.apartment && `${formData.apartment}, `}
                          {formData.city}, {formData.state} {formData.zipCode}, {formData.country}
                        </p>
                      </div>
                      <button className="text-sm text-blue-600 hover:text-blue-800">
                        Change
                      </button>
                    </div>
                  </div>
                  
                  <h2 className="text-lg font-bold text-gray-900 mb-4">Shipping Method</h2>
                  
                  <div className="space-y-3 mb-6">
                    <div
                      className={`border rounded-md p-4 ${shippingMethod === 'standard' ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'}`}
                      onClick={() => setShippingMethod('standard')}
                    >
                      <div className="flex items-center">
                        <input
                          id="shipping-standard"
                          name="shipping-method"
                          type="radio"
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                          checked={shippingMethod === 'standard'}
                          onChange={() => setShippingMethod('standard')}
                        />
                        <label htmlFor="shipping-standard" className="ml-3 flex flex-1 justify-between">
                          <div>
                            <span className="block text-sm font-medium text-gray-900">Standard Shipping</span>
                            <span className="block text-sm text-gray-500">3-5 business days</span>
                          </div>
                          <span className="text-sm font-medium text-gray-900">Free</span>
                        </label>
                      </div>
                    </div>
                    
                    <div
                      className={`border rounded-md p-4 ${shippingMethod === 'express' ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'}`}
                      onClick={() => setShippingMethod('express')}
                    >
                      <div className="flex items-center">
                        <input
                          id="shipping-express"
                          name="shipping-method"
                          type="radio"
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                          checked={shippingMethod === 'express'}
                          onChange={() => setShippingMethod('express')}
                        />
                        <label htmlFor="shipping-express" className="ml-3 flex flex-1 justify-between">
                          <div>
                            <span className="block text-sm font-medium text-gray-900">Express Shipping</span>
                            <span className="block text-sm text-gray-500">1-2 business days</span>
                          </div>
                          <span className="text-sm font-medium text-gray-900">$14.99</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  
                  <div className="flex items-center justify-between">
                    <button
                      onClick={() => setStep('information')}
                      className="inline-flex items-center text-blue-600 hover:text-blue-800"
                    >
                      <ArrowLeft className="h-4 w-4 mr-1" />
                      Return to information
                    </button>
                    <button
                      onClick={() => setStep('payment')}
                      className="bg-blue-600 text-white px-6 py-2 rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Continue to payment
                    </button>
                  </div>
                </>
              )}
              
              {/* Payment Step */}
              {step === 'payment' && (
                <>
                  <div className="mb-6 bg-gray-50 p-4 rounded-lg">
                    <div className="flex justify-between">
                      <div>
                        <h3 className="text-sm font-medium text-gray-700">Contact</h3>
                        <p className="text-sm text-gray-500">{formData.email}</p>
                      </div>
                      <button className="text-sm text-blue-600 hover:text-blue-800">
                        Change
                      </button>
                    </div>
                    <div className="border-t border-gray-200 mt-4 pt-4 flex justify-between">
                      <div>
                        <h3 className="text-sm font-medium text-gray-700">Ship to</h3>
                        <p className="text-sm text-gray-500">
                          {formData.address}, {formData.apartment && `${formData.apartment}, `}
                          {formData.city}, {formData.state} {formData.zipCode}, {formData.country}
                        </p>
                      </div>
                      <button className="text-sm text-blue-600 hover:text-blue-800">
                        Change
                      </button>
                    </div>
                    <div className="border-t border-gray-200 mt-4 pt-4 flex justify-between">
                      <div>
                        <h3 className="text-sm font-medium text-gray-700">Method</h3>
                        <p className="text-sm text-gray-500">
                          {shippingMethod === 'standard' ? 'Standard Shipping · Free' : 'Express Shipping · $14.99'}
                        </p>
                      </div>
                      <button
                        onClick={() => setStep('shipping')}
                        className="text-sm text-blue-600 hover:text-blue-800"
                      >
                        Change
                      </button>
                    </div>
                  </div>
                  
                  <h2 className="text-lg font-bold text-gray-900 mb-4">Payment Method</h2>
                  
                  <div className="space-y-3 mb-6">
                    <div
                      className={`border rounded-md p-4 ${paymentMethod === 'card' ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'}`}
                      onClick={() => setPaymentMethod('card')}
                    >
                      <div className="flex items-center mb-4">
                        <input
                          id="payment-card"
                          name="payment-method"
                          type="radio"
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                          checked={paymentMethod === 'card'}
                          onChange={() => setPaymentMethod('card')}
                        />
                        <label htmlFor="payment-card" className="ml-3 block text-sm font-medium text-gray-900">
                          Credit Card
                        </label>
                        <div className="flex items-center ml-auto space-x-2">
                          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Visa.svg/1200px-Visa.svg.png" alt="Visa" className="h-6" />
                          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Mastercard_2019_logo.svg/1200px-Mastercard_2019_logo.svg.png" alt="Mastercard" className="h-6" />
                          <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/American_Express_logo_%282018%29.svg/1200px-American_Express_logo_%282018%29.svg.png" alt="Amex" className="h-6" />
                        </div>
                      </div>
                      
                      {paymentMethod === 'card' && (
                        <div className="space-y-4">
                          <div>
                            <label htmlFor="cardNumber" className="block text-sm font-medium text-gray-700 mb-1">Card number</label>
                            <input
                              type="text"
                              id="cardNumber"
                              name="cardNumber"
                              value={formData.cardNumber}
                              onChange={handleInputChange}
                              placeholder="1234 5678 9012 3456"
                              className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              required
                            />
                          </div>
                          
                          <div>
                            <label htmlFor="cardName" className="block text-sm font-medium text-gray-700 mb-1">Name on card</label>
                            <input
                              type="text"
                              id="cardName"
                              name="cardName"
                              value={formData.cardName}
                              onChange={handleInputChange}
                              className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              required
                            />
                          </div>
                          
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <label htmlFor="expiryDate" className="block text-sm font-medium text-gray-700 mb-1">Expiry date (MM/YY)</label>
                              <input
                                type="text"
                                id="expiryDate"
                                name="expiryDate"
                                value={formData.expiryDate}
                                onChange={handleInputChange}
                                placeholder="MM/YY"
                                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                required
                              />
                            </div>
                            <div>
                              <label htmlFor="cvv" className="block text-sm font-medium text-gray-700 mb-1">CVV</label>
                              <input
                                type="text"
                                id="cvv"
                                name="cvv"
                                value={formData.cvv}
                                onChange={handleInputChange}
                                placeholder="123"
                                className="block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                required
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    
                    <div
                      className={`border rounded-md p-4 ${paymentMethod === 'paypal' ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'}`}
                      onClick={() => setPaymentMethod('paypal')}
                    >
                      <div className="flex items-center">
                        <input
                          id="payment-paypal"
                          name="payment-method"
                          type="radio"
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                          checked={paymentMethod === 'paypal'}
                          onChange={() => setPaymentMethod('paypal')}
                        />
                        <label htmlFor="payment-paypal" className="ml-3 block text-sm font-medium text-gray-900">
                          PayPal
                        </label>
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/PayPal.svg/1200px-PayPal.svg.png" alt="PayPal" className="h-6 ml-auto" />
                      </div>
                    </div>
                    
                    <div
                      className={`border rounded-md p-4 ${paymentMethod === 'applePay' ? 'border-blue-500 bg-blue-50' : 'border-gray-300 hover:border-gray-400'}`}
                      onClick={() => setPaymentMethod('applePay')}
                    >
                      <div className="flex items-center">
                        <input
                          id="payment-applepay"
                          name="payment-method"
                          type="radio"
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                          checked={paymentMethod === 'applePay'}
                          onChange={() => setPaymentMethod('applePay')}
                        />
                        <label htmlFor="payment-applepay" className="ml-3 block text-sm font-medium text-gray-900">
                          Apple Pay
                        </label>
                        <svg className="h-6 ml-auto" viewBox="0 0 43 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.89112 3.55005C9.38912 2.95205 9.73612 2.13405 9.63312 1.29605C8.91312 1.33405 8.03512 1.77005 7.51512 2.36805C7.05712 2.90205 6.65712 3.75205 6.77412 4.55005C7.59312 4.62205 8.39312 4.14805 8.89112 3.55005Z" fill="black"/>
                          <path d="M11.0801 8.32395C11.0801 6.25195 12.7381 5.27995 12.8201 5.22395C11.9931 4.02395 10.7351 3.87995 10.2731 3.86395C9.04709 3.74395 7.85908 4.60795 7.22508 4.60795C6.59108 4.60795 5.60309 3.87995 4.58309 3.89595C3.24309 3.91195 1.99109 4.66795 1.31709 5.85595C-0.058906 8.24795 0.921094 11.776 2.24109 13.824C2.92709 14.832 3.72309 15.952 4.75509 15.92C5.75909 15.888 6.12309 15.28 7.35309 15.28C8.58309 15.28 8.91509 15.92 9.98309 15.904C11.0801 15.888 11.7661 14.896 12.4361 13.888C13.2321 12.736 13.5641 11.632 13.5801 11.584C13.5641 11.568 11.0801 10.704 11.0801 8.32395Z" fill="black"/>
                          <path d="M19.074 1.85303C21.048 1.85303 22.43 3.26882 22.43 5.29693C22.43 7.32504 21.048 8.74082 19.074 8.74082H16.754V12.674H15.144V1.85303H19.074ZM16.754 7.35779H18.754C20.062 7.35779 20.792 6.62779 20.792 5.29693C20.792 3.96607 20.062 3.23607 18.754 3.23607H16.754V7.35779Z" fill="black"/>
                          <path d="M25.5392 9.76611C25.5392 8.51486 26.4472 7.75203 28.0232 7.63164L29.8792 7.49722V6.89405C29.8792 6.08731 29.3472 5.60107 28.4072 5.60107C27.5472 5.60107 26.9992 6.00339 26.8792 6.63939H25.3912C25.4632 5.30852 26.7312 4.26104 28.4552 4.26104C30.3232 4.26104 31.4512 5.26461 31.4512 6.89405V12.674H29.9312V11.4228H29.8952C29.4392 12.2775 28.5152 12.7478 27.5512 12.7478C26.0272 12.7478 25.5392 11.6043 25.5392 9.76611ZM29.8792 8.85938V8.59053L28.2432 8.71092C27.3112 8.77925 26.8552 9.19964 26.8552 9.85771C26.8552 10.5879 27.3712 11.0423 28.1912 11.0423C29.2072 11.0423 29.8792 10.3603 29.8792 8.85938Z" fill="black"/>
                          <path d="M35.6639 18.029H34.092V12.3974H34.128C34.56 13.0056 35.3439 13.5358 36.4559 13.5358C38.3959 13.5358 39.5599 11.9303 39.5599 8.50407C39.5599 5.04565 38.3719 3.50285 36.4559 3.50285C35.2559 3.50285 34.5119 4.10601 34.056 4.74003H34.02V3.58942H32.5239V18.029H34.092V12.3974ZM36.0479 12.0008C34.8959 12.0008 34.068 11.047 34.068 8.50407C34.068 5.96117 34.896 5.03772 36.0479 5.03772C37.2479 5.03772 37.944 5.96117 37.944 8.50407C37.944 11.047 37.248 12.0008 36.0479 12.0008Z" fill="black"/>
                        </svg>
                      </div>
                    </div>
                  </div>
                  
                  <h2 className="text-lg font-bold text-gray-900 mb-4">Billing Address</h2>
                  
                  <div className="mb-6">
                    <div className="flex items-center mb-4">
                      <input
                        id="same-address"
                        name="billing-address"
                        type="radio"
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                        checked={true}
                      />
                      <label htmlFor="same-address" className="ml-3 block text-sm font-medium text-gray-900">
                        Same as shipping address
                      </label>
                    </div>
                    
                    <div className="flex items-center">
                      <input
                        id="different-address"
                        name="billing-address"
                        type="radio"
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                        disabled
                      />
                      <label htmlFor="different-address" className="ml-3 block text-sm font-medium text-gray-500">
                        Use a different billing address
                      </label>
                    </div>
                  </div>
                  
                  <div className="flex items-center justify-between">
                    <button
                      onClick={() => setStep('shipping')}
                      className="inline-flex items-center text-blue-600 hover:text-blue-800"
                    >
                      <ArrowLeft className="h-4 w-4 mr-1" />
                      Return to shipping
                    </button>
                    <button
                      className="bg-blue-600 text-white px-6 py-2 rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Complete order
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          
          {/* Order Summary */}
          <div className="md:col-span-1">
            <div className="bg-white p-6 rounded-lg shadow-sm">
              <h2 className="text-lg font-bold text-gray-900 mb-4 flex items-center justify-between">
                <span>Order Summary</span>
                <span className="text-sm font-normal text-blue-600 cursor-pointer">Edit cart</span>
              </h2>
              
              <div className="divide-y divide-gray-200">
                {cartItems.map((item) => (
                  <div key={item.id} className="py-4 flex">
                    <div className="h-20 w-20 flex-shrink-0 overflow-hidden rounded-md border border-gray-200 relative">
                      <img
                        src={item.image}
                        alt={item.name}
                        className="h-full w-full object-cover object-center"
                      />
                      <div className="absolute top-0 right-0 -mt-2 -mr-2 h-5 w-5 rounded-full bg-gray-200 text-gray-800 flex items-center justify-center text-xs font-bold">
                        {item.quantity}
                      </div>
                    </div>
                    <div className="ml-4 flex flex-1 flex-col">
                      <div className="flex justify-between text-base font-medium text-gray-900">
                        <h3>{item.name}</h3>
                        <p className="ml-4">${item.price.toFixed(2)}</p>
                      </div>
                      <p className="mt-1 text-sm text-gray-500">
                        {item.color} / Size {item.size}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              
              <div className="border-t border-gray-200 mt-4 pt-4 space-y-2">
                <div className="flex justify-between text-sm">
                  <p className="text-gray-600">Subtotal</p>
                  <p className="text-gray-900 font-medium">${subtotal.toFixed(2)}</p>
                </div>
                <div className="flex justify-between text-sm">
                  <p className="text-gray-600">Shipping</p>
                  <p className="text-gray-900 font-medium">
                    {shipping === 0 ? 'Free' : `$${shipping.toFixed(2)}`}
                  </p>
                </div>
                <div className="flex justify-between text-sm">
                  <p className="text-gray-600">Taxes (estimated)</p>
                  <p className="text-gray-900 font-medium">${tax.toFixed(2)}</p>
                </div>
                
                <div className="flex items-center mt-4">
                  <input
                    type="text"
                    placeholder="Discount code"
                    className="block w-full px-4 py-2 border border-gray-300 rounded-l-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                  <button className="bg-gray-100 text-gray-800 px-4 py-2 border border-l-0 border-gray-300 rounded-r-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    Apply
                  </button>
                </div>
                
                <div className="border-t border-gray-200 mt-4 pt-4 flex justify-between">
                  <p className="text-base font-medium text-gray-900">Total</p>
                  <p className="text-base font-bold text-gray-900">${total.toFixed(2)}</p>
                </div>
              </div>
              
              <div className="mt-6 space-y-4">
                <div className="flex items-center text-gray-600 text-sm">
                  <Truck className="h-4 w-4 mr-2 text-green-500" />
                  <span>Free shipping on orders over $100</span>
                </div>
                <div className="flex items-center text-gray-600 text-sm">
                  <Shield className="h-4 w-4 mr-2 text-green-500" />
                  <span>Secure checkout</span>
                </div>
              </div>
              
              <div className="mt-6 border-t border-gray-200 pt-4">
                <div className="flex items-start bg-blue-50 p-4 rounded-md">
                  <Info className="h-5 w-5 text-blue-600 mr-3 flex-shrink-0 mt-0.5" />
                  <p className="text-sm text-blue-800">
                    Need assistance? Contact our support team at <a href="mailto:support@nordic.com" className="font-medium">support@nordic.com</a> or call us at (800) 123-4567.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EcommerceCheckout;