// Header.js
import React, { useState, useEffect, useRef } from 'react';
import { Settings, MessageCircle, Plus, Share2, Clipboard, Check, ChevronDown, PanelLeft, PanelRight } from 'lucide-react';
import { shareThread } from '../services/api';
import useSettings from '../hooks/useSettings';

// --- Model Data Structure and Content ---

// Helper function to format token counts
const formatTokens = (tokens) => {
  if (typeof tokens !== 'number') return 'N/A';
  if (tokens >= 1000000) return `${tokens / 1000000}M`;
  if (tokens >= 1000) return `${tokens / 1000}K`;
  return tokens.toString();
};

const modelsData = [
  // 1. Hot / Leading Edge 🔥
  { value: 'claude-3-7-sonnet-20250219', label: 'Claude 3.7 Sonnet', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡', cost: '$$$', tags: ['✨', '</>', '🎨👑', '💬'], description: 'Excellent coding capabilities, excels at UI generation, strong mathematical reasoning and planning. Suitable for nuanced conversations.', notes: 'Higher cost model.', inputContextLength: 200000, outputContextLength: 128000, outputNote: '' },
  { value: 'claude-3-7-sonnet-thinking', label: 'Claude 3.7 Sonnet Thinking', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡', cost: '$$$', tags: ['✨', '</>', '🎨👑', '💬', '📝', '🧠'], description: 'Enhanced version of 3.7 Sonnet. Excels at coding, UI generation, planning, and handling complex tasks and conversations. May output intermediate reasoning tokens before the final answer.', notes: 'Higher cost model. The "thinking" process may slightly increase latency.', inputContextLength: 200000, outputContextLength: 128000, outputNote: '' },
  { value: 'deepseek-chat', label: 'DeepSeek Chat', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡', cost: '$', tags: ['⚙️', '</>', '🎨', '🧮', '💰', '⚠️'], description: 'Strong performance in coding, UI generation, and mathematics at a low cost.', notes: 'Both regional variants may have stricter content filtering and potential biases influenced by training data.', inputContextLength: 64000, outputContextLength: 8192 },
  { value: 'gemini-2.5-pro-exp-03-25', label: 'Gemini 2.5 Pro Latest', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡', cost: '$$', tags: ['✨', '</>', '📝', '💡'], description: 'Excellent coding, planning, and reasoning capabilities. Strong creative generation. Efficiently processes large documents and codebases.', notes: 'Mid-range cost model.', inputContextLength: 1048576, outputContextLength: 65536 }, // 1M Input, 64K Output
  { value: 'chatgpt-4o-latest', label: 'ChatGPT-4o Latest', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡', cost: '$$$', tags: ['👍', '</>', '💬', '🧮', 'Vibe'], description: 'Strong coding and mathematical abilities. Adept at understanding conversational nuances. Suitable for general tasks. Engaging conversational style.', notes: 'Moderate performance regarding speed and latency. Less proficient in UI code generation compared to some peers.', inputContextLength: 128000, outputContextLength: 16384 }, // 128K Input, 16K Output
  { value: 'o3-mini', label: 'o3 Mini', category: 'Hot / Leading Edge 🔥', speed: '⚡⚡⚡', cost: '$$', tags: ['👍', '</>', '🧮', '📝', 'Web', '⚠️'], description: 'Fast response times, particularly for simpler queries. Good performance in math, instruction following, planning, and coding. Includes web access capabilities.', notes: 'May occasionally generate inaccurate information (hallucinate). Less effective for UI code generation.', inputContextLength: 200000, outputContextLength: 100000 }, // 200K Input, 100K Output

  // 2. Speed & Value ⚡💰
  { value: 'claude-3-haiku-20240307', label: 'Claude 3 Haiku 2024-03-07', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚡', '💰', '📊'], description: 'Legacy model. Very high speed and very low cost. Effective for data structuring tasks.', notes: 'Limited capabilities for coding and UI generation.', inputContextLength: 200000, outputContextLength: 4096 }, // 200K Input, 4K Output
  { value: 'claude-3-5-haiku-20241022', label: 'Claude 3.5 Haiku 2024-10-22', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$$', tags: ['</>', '🎨', '🗑️'], description: 'Offers reasonable coding/UI capabilities relative to its release date, with high speed.', notes: 'Performance is superseded by current Haiku/Sonnet versions. Weaker in mathematical reasoning and instruction following.', inputContextLength: 200000, outputContextLength: 8192 }, // 200K Input, 8K Output
  { value: 'command-r7b-12-2024', label: 'Command R7B 12-2024', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['💰', '🤔'], description: 'Very low cost. General capabilities are less advanced compared to leading models. Designed for lightweight RAG', notes: '', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'gemini-2.0-flash-001', label: 'Gemini 2.0 Flash', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚡', '💰', '</>', '📊'], description: 'Very high speed and low cost. Competent coding abilities. Strong performance in data structuring and context-based Q&A.', notes: 'Less effective for UI code generation.', inputContextLength: 1048576, outputContextLength: 8192 }, // 1M Input, 8K Output
  { value: 'gemini-2.0-flash-lite-preview', label: 'Gemini 2.0 Flash Lite Preview', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚡', '💰'], description: 'High speed and low cost. A less capable variant of Gemini 2.0 Flash.', notes: '', inputContextLength: 1048576, outputContextLength: 8192 }, // 1M Input, 8K Output
  { value: 'gemini-2.0-flash-thinking-exp', label: 'Gemini 2.0 Flash Thinking Exp', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚙️', '⚡', '💰', '🧠', '🧪'], description: 'Experimental variant of Flash with potentially enhanced reasoning. May output intermediate reasoning tokens before the final answer, potentially increasing unpredictability with the tradeoff of higher performance.', notes: 'Experimental status implies potential for instability. The "thinking" process may slightly increase perceived latency.', inputContextLength: 1048576, outputContextLength: 8192 }, // 1M Input, 8K Output
  { value: 'gpt-4o-mini', label: 'GPT-4O Mini', category: 'Speed & Value ⚡💰', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚡', '💰', 'Web', '🧠', '⚠️'], description: 'High speed and low cost. Includes web access capabilities.', notes: 'Prone to generating inaccurate information (hallucinations) more often than models like Gemini 2.0 Flash. Limited performance in mathematics and complex coding tasks.', inputContextLength: 128000, outputContextLength: 16384 }, // 128K Input, 16K Output

  // 3. Expensive Models 💸
  { value: 'claude-3-opus-20240229', label: 'Claude 3 Opus 2024-02-29', category: 'Expensive Models 💸', speed: '⚡', cost: '$$$$', tags: ['✍️', '💬', 'Vibe', '🗑️'], description: 'Legacy state-of-the-art model. Excels in creative writing and engaging conversation.', notes: 'Superseded by newer models in coding, UI generation, and planning tasks.', inputContextLength: 200000, outputContextLength: 4096 }, // 200K Input, 4K Output
  { value: 'gpt-4-turbo', label: 'GPT-4 Turbo', category: 'Expensive Models 💸', speed: '⚡', cost: '$$$$', tags: ['💬', 'Vibe', '🗑️'], description: 'Legacy model. Valued by some users for its conversational abilities & style.', notes: 'High cost. Current coding and UI generation capabilities are considered subpar compared to newer alternatives.', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'o1', label: 'o1', category: 'Expensive Models 💸', speed: '⚡', cost: '$$$$', tags: ['⚙️', '</>', '🧮', '📝', '💸', '🐢', '⚠️'], description: 'Exceptionally strong performance in coding, mathematics, report generation, and general knowledge recall.', notes: 'Very high cost, significantly slower response times, and high latency.', inputContextLength: 200000, outputContextLength: 100000 }, // 200K Input, 100K Output
  { value: 'o1-pro', label: 'o1 Pro', category: 'Expensive Models 💸', speed: '⚡', cost: '$$$$$$', tags: ['🧠', '⚙️', '💸', '🐢'], description: 'High-compute version of o1 with enhanced reasoning capabilities. Uses reinforcement learning for complex reasoning tasks.', notes: 'WARNING: Extremely high cost 10x as expensive as o1. Very slow response times.', inputContextLength: 200000, outputContextLength: 100000 },
  { value: 'gpt-4.5-preview', label: 'GPT-4.5 Preview', category: 'Expensive Models 💸', speed: '⚡', cost: '$$$$$', tags: ['✨', '🧠', '💬', 'Vibe'], description: 'Largest GPT model yet. Excels at creative tasks, agentic planning, and open-ended thinking. Strong world knowledge and understanding of user intent.', notes: 'WARNING: Very high cost more than 2x as expensive as o1. Sep 2023 knowledge cutoff.', inputContextLength: 128000, outputContextLength: 16384 },

  // 4. Specialized & Experimental ⚙️🧪
  { value: 'claude-3-5-sonnet-20241022', label: 'Claude 3.5 Sonnet 2024-10-22', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$$', tags: ['⚙️', '💬', 'Vibe', '⏳', '⚠️'], description: 'Possesses a distinct conversational style. Performance is generally lower than Claude 3.7 Sonnet.', notes: 'Frequently fails to generate longer responses, which can be a significant limitation.', inputContextLength: 200000, outputContextLength: 8192 }, // 200K Input, 8K Output
  { value: 'command-r', label: 'Command R', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡⚡', cost: '$', tags: ['⚙️', 'RAG', 'COBOL', '🤔'], description: 'Less capable general-purpose model. Notable strengths in Retrieval-Augmented Generation (RAG) and support for less common programming languages (e.g., COBOL).', notes: '', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'command-r-plus-08-2024', label: 'Command R Plus 08-2024', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$$', tags: ['⚙️', 'RAG', '🤔'], description: 'A larger, more capable model than Command R, offering enhanced performance with a focus on Retrieval-Augmented Generation (RAG) applications.', notes: 'Mid-to-high range cost and performance level.', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'deepseek-reasoner', label: 'DeepSeek R1 (china)', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$', tags: ['⚙️', '</>', '🧮', '💰', '⚠️'], description: 'Improved coding and mathematical abilities compared to DeepSeek Chat, but less proficient in UI generation.', notes: 'Both regional variants may have stricter content filtering and potential biases influenced by training data (CCP). Standard context window size. Has 32K intermediate thinking tokens.', inputContextLength: 64000, outputContextLength: 8192 }, // 64K Input, 8K Output (+32K thinking)
  { value: 'DeepSeek-R1', label: 'DeepSeek R1 (usa)', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$', tags: ['⚙️', '</>', '🧮', '💰', '⚠️'], description: 'Functionally equivalent to DeepSeek R1 (china) but typically exhibits higher latency due to US-based hosting.', notes: 'Both regional variants may have stricter content filtering and potential biases influenced by training data (CCP). Higher latency compared to the alternative region. Standard context window size. Has 32K intermediate thinking tokens.', inputContextLength: 64000, outputContextLength: 8192 }, // 64K Input, 8K Output (+32K thinking)
  { value: 'Meta-Llama-3.1-405B-Instruct', label: 'Meta Llama 3.1 405B Instruct', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$$', tags: ['⚙️', '</>', '✍️'], description: 'Very large parameter model. Strong capabilities in creative writing and coding. Features a distinct conversational style.', notes: 'Limited effectiveness for UI code generation.', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'reka-core-20240904', label: 'Reka Core', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡', cost: '$$', tags: ['⚙️', '🖼️', '🤔'], description: 'Specialized in multimodal tasks, particularly image and video recognition/analysis (e.g., identifying objects). General chat and coding capabilities are limited.', notes: '', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'reka-edge-20240208', label: 'Reka Edge', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡⚡⚡', cost: '$', tags: ['⚙️', '🖼️', '🤔'], description: 'Specialized in multimodal tasks, particularly image and video recognition/analysis. Optimized for speed, potentially suitable for edge devices.', notes: 'Low-cost option for multimodal analysis tasks.', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'reka-flash-3', label: 'Reka Flash 3', category: 'Specialized & Experimental ⚙️🧪', speed: '⚡⚡⚡', cost: '$', tags: ['⚙️', '🖼️', '🤔'], description: 'Specialized in multimodal tasks, particularly image and video recognition/analysis. Offers a balance of speed and capability.', notes: 'Faster analysis option compared to Reka Core.', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output

  // 5. Legacy / Archive 🗑️
  { value: 'DeepSeek-R1-Zero', label: 'DeepSeek R1 Zero', category: 'Legacy / Archive 🗑️', speed: '⚡⚡', cost: '$$', tags: ['🗑️'], description: 'Deprecated model, superseded by newer versions.', notes: '', inputContextLength: null, outputContextLength: null }, // Context Unknown
  { value: 'gemini-1.5-flash-8b', label: 'Gemini 1.5 Flash 8B', category: 'Legacy / Archive 🗑️', speed: '⚡⚡⚡⚡', cost: '$', tags: ['💰', '🗑️'], description: 'Legacy model. Lower cost but less capable than Gemini 2.0 Flash Lite.', notes: '', inputContextLength: null, outputContextLength: null }, // Context Unknown
  { value: 'gemini-1.5-flash-latest', label: 'Gemini 1.5 Flash Latest', category: 'Legacy / Archive 🗑️', speed: '⚡⚡⚡⚡', cost: '$', tags: ['🗑️'], description: 'Legacy model. Superseded by the more capable Gemini 2.0 Flash.', notes: '', inputContextLength: null, outputContextLength: null }, // Context Unknown
  { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo', category: 'Legacy / Archive 🗑️', speed: '⚡⚡⚡⚡', cost: '$$', tags: ['🗑️'], description: 'Legacy model. Performance is significantly below current standards.', notes: '', inputContextLength: 16385, outputContextLength: 4096 }, // 16K Input, 4K Output (Common default)
  { value: 'gpt-4o-2024-11-20', label: 'GPT-4O 2024-11-20', category: 'Legacy / Archive 🗑️', speed: '⚡⚡⚡', cost: '$$$', tags: ['👍', '🧮', 'Vibe', 'Web', '⚠️', '🗑️'], description: 'Snapshot version of GPT-4O. Fast for simpler queries, good mathematical ability, understands conversational nuances. Includes web access.', notes: 'May generate inaccurate information (hallucinate). Less effective for UI and general coding compared to the latest GPT-4O version.', inputContextLength: 128000, outputContextLength: 16384 }, // 128K Input, 16K Output
  { value: 'grok-beta', label: 'Grok Beta', category: 'Legacy / Archive 🗑️', speed: '⚡⚡', cost: '$$$', tags: ['🤔', '🗑️'], description: 'Legacy beta version of Grok. Limited capabilities compared to contemporary models. Newer versions anticipated.', notes: '', inputContextLength: 128000, outputContextLength: 4096 }, // 128K Input, 4K Output
  { value: 'o1-mini', label: 'o1 Mini', category: 'Legacy / Archive 🗑️', speed: '⚡⚡⚡', cost: '$$$', tags: ['🗑️'], description: 'Legacy model, the predecessor to o3 Mini.', notes: '', inputContextLength: null, outputContextLength: null } // Context Unknown
];

const modelCategories = [
    'Hot / Leading Edge 🔥',
    'Speed & Value ⚡💰',
    'Specialized & Experimental ⚙️🧪',
    'Expensive Models 💸',
    'Legacy / Archive 🗑️',
];

// --- Helper component for the info popup ---
const ModelInfoPopup = ({ model, position }) => {
  if (!model || !position) return null;

  const hasContextData = typeof model.inputContextLength === 'number' || typeof model.outputContextLength === 'number';

  return (
    <div
      className="fixed bg-[#3a3a3a] text-white p-4 rounded-lg shadow-xl z-[60] w-64 border border-gray-600 text-xs" // Increased z-index
      style={{ top: `${position.top}px`, left: `${position.left}px` }}
    >
      <h4 className="font-bold mb-1 text-sm">{model.label}</h4>
      <p className="text-gray-400 text-[11px] mb-2">{model.category}</p>
      <div className="flex space-x-3 mb-2 text-[11px]">
        <span>Speed: {model.speed}</span>
        <span>Cost: {model.cost}</span>
      </div>
      {hasContextData && (
         <div className="mb-2 text-[11px]">
            <span className="font-semibold">Context:</span>
            {typeof model.inputContextLength === 'number' && ` Input: ${formatTokens(model.inputContextLength)}`}
            {typeof model.outputContextLength === 'number' && ` Output: ${formatTokens(model.outputContextLength)}`}
            {model.outputNote && <span className="text-gray-400"> ({model.outputNote})</span>}
         </div>
      )}
      <p className="mb-2 text-[11px] leading-relaxed">{model.description}</p>
      <div className="mb-2 text-[11px]">
        <span className="font-semibold">Tags:</span> {model.tags.join(' ')}
      </div>
      {model.notes && (
         <p className="text-gray-400 text-[11px]"><span className="font-semibold text-gray-300">Notes:</span> {model.notes}</p>
       )}
    </div>
  );
};


// --- Header Component ---
const Header = ({
  onSettingsToggle,
  onLogout,
  onNewChat,
  onToggleSidebar,
  onDashboardClick,
  currentThreadId,
  currentlySelectedModel,
  setCurrentlySelectedModel,
  sidebarPosition,
  setSidebarPosition,
  isSidebarOpen,
  isLanding,
  threadsLoading,
}) => {
  // State Management
  const [showShareModal, setShowShareModal] = useState(false);
  const [showShareConfirm, setShowShareConfirm] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [isSharing, setIsSharing] = useState(false);
  const [shareError, setShareError] = useState('');
  const [copied, setCopied] = useState(false);
  const [isModelDropdownOpen, setIsModelDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  // State for hover popup
  const [hoveredModelValue, setHoveredModelValue] = useState(null);
  const [popupPosition, setPopupPosition] = useState(null);
  const [hoverTimeoutId, setHoverTimeoutId] = useState(null);
  const {
    customInstructions,
    handleSaveSettings,
    fetchSettings,
  } = useSettings({
    onLogout,
    defaultModel: currentlySelectedModel
  });

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  const handleModelSelect = async (modelValue) => {
    try {
      setCurrentlySelectedModel(modelValue);
      setIsModelDropdownOpen(false);
      setHoveredModelValue(null); // Close popup on select
      setPopupPosition(null);
      if (hoverTimeoutId) clearTimeout(hoverTimeoutId);
      await handleSaveSettings({
        customInstructions: customInstructions || '',
        currentlySelectedModel: modelValue,
        sidebarPosition: sidebarPosition
      });
    } catch (error) {
      console.error('Failed to save model selection:', error);
    }
  };

  const handleSidebarPositionToggle = async (newPosition) => {
    try {
      setSidebarPosition(newPosition);
      await handleSaveSettings({
        customInstructions: customInstructions || '',
        currentlySelectedModel: currentlySelectedModel,
        sidebarPosition: newPosition
      });
    } catch (error) {
      console.error('Failed to save sidebar position:', error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsModelDropdownOpen(false);
        setHoveredModelValue(null);
        setPopupPosition(null);
        if (hoverTimeoutId) clearTimeout(hoverTimeoutId);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        if (hoverTimeoutId) clearTimeout(hoverTimeoutId); // Cleanup timeout on unmount
    }
  }, [hoverTimeoutId]);

  const getCurrentModelLabel = () => {
    const model = modelsData.find(m => m.value === currentlySelectedModel);
    return model ? model.label : 'Select Model';
  };

  // --- Hover handlers for popup ---
  const handleModelMouseEnter = (event, model) => {
     if (hoverTimeoutId) clearTimeout(hoverTimeoutId);
     const targetElement = event.currentTarget;
     const showPopup = () => {
        if (targetElement && document.body.contains(targetElement)) {
            const rect = targetElement.getBoundingClientRect();
            const popupTop = rect.top;
            const viewportWidth = window.innerWidth;
            const popupWidth = 256; // approx width of popup (w-64)
            const spaceRight = viewportWidth - rect.right;
            let popupLeft = rect.right + 8; // Default right

            if (spaceRight < popupWidth + 8) { // If not enough space right
                popupLeft = rect.left - popupWidth - 8; // Position left
                 // Ensure it doesn't go off-screen left either
                 if (popupLeft < 8) {
                    popupLeft = 8;
                 }
            }
            setHoveredModelValue(model.value);
            setPopupPosition({ top: popupTop, left: popupLeft });
        } else {
            console.warn("Target element for popup disappeared or is not valid.");
            setHoveredModelValue(null);
            setPopupPosition(null);
        }
     };
     const timeoutId = setTimeout(showPopup, 150); // 150ms delay
     setHoverTimeoutId(timeoutId);
  };

  const handleModelMouseLeave = () => {
    if (hoverTimeoutId) clearTimeout(hoverTimeoutId);
     const hideTimeoutId = setTimeout(() => {
        const popupElement = document.getElementById('model-info-popup-id');
        // Check if mouse is NOT over the popup before hiding
        if (!popupElement || !popupElement.matches(':hover')) {
             setHoveredModelValue(null);
             setPopupPosition(null);
        }
     }, 100); // Slight delay to allow moving mouse onto popup
    setHoverTimeoutId(hideTimeoutId);
  };

  const handlePopupMouseEnter = () => {
     if (hoverTimeoutId) clearTimeout(hoverTimeoutId); // Cancel any pending hide timeouts
  };

  const handlePopupMouseLeave = () => {
      const hideTimeoutId = setTimeout(() => { // Hide after leaving popup
          setHoveredModelValue(null);
          setPopupPosition(null);
      }, 100);
      setHoverTimeoutId(hideTimeoutId);
  };
  // --- END Hover ---

  const hoveredModel = modelsData.find(m => m.value === hoveredModelValue);

  // --- Share Functionality ---
  const handleShareClick = () => {
    if (!currentThreadId) {
      setShareError('Please select a thread to share');
      setShowShareModal(true);
      return;
    }
    setShowShareConfirm(true);
  };

  const handleConfirmedShare = async () => {
    setShowShareConfirm(false);
    setIsSharing(true);
    setShareError('');
    setShareUrl('');
    setCopied(false);
    try {
      const { shareUrl, publicId } = await shareThread(currentThreadId);
      setShareUrl(shareUrl);
      setShareError('');
    } catch (error) {
      console.error('Failed to share thread:', error);
      setShareError('Failed to share thread. Please try again.');
    } finally {
      setIsSharing(false);
      setShowShareModal(true); // Show modal with result (link or error)
    }
  };

  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy URL:', err);
      setShareError('Failed to copy URL to clipboard');
    }
  };

  // --- Modals ---
  const ShareConfirmModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#2a2a2a] p-6 rounded-lg shadow-xl max-w-sm w-full">
        <h2 className="text-lg font-bold mb-4 text-white">Share Thread</h2>
        <p className="mb-6 text-gray-300">
          Are you sure you want to share this thread? Anyone with the share link will be able to view this conversation.
        </p>
        <div className="flex justify-end space-x-3">
          <button onClick={() => setShowShareConfirm(false)} className="px-4 py-1.5 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors">Cancel</button>
          <button onClick={handleConfirmedShare} className="px-4 py-1.5 bg-orange-500 text-white rounded hover:bg-orange-600 transition-colors">Share</button>
        </div>
      </div>
    </div>
  );

  const ShareModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#2a2a2a] p-6 rounded-lg shadow-xl max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold text-white">Share Thread</h2>
          <button onClick={() => setShowShareModal(false)} className="text-gray-400 hover:text-white transition-colors" aria-label="Close Modal">×</button>
        </div>
        {shareError && !shareUrl ? ( // Show error only if no URL generated
          <div className="mb-4 p-3 bg-red-500 bg-opacity-20 border border-red-500 rounded text-red-400">{shareError}</div>
        ) : isSharing ? (
          <div className="mb-4 text-gray-300 flex items-center"><div className="animate-spin rounded-full h-4 w-4 border-b-2 border-white mr-2"></div>Generating share link...</div>
        ) : shareUrl ? (
          <>
            {shareError && <div className="mb-4 p-3 bg-red-500 bg-opacity-20 border border-red-500 rounded text-red-400">{shareError}</div>} {/* Show copy error */}
            <div className="mb-4">
              <label className="block text-sm text-gray-400 mb-2">Share Link</label>
              <div className="flex items-center bg-[#1a1a1a] rounded border border-gray-700">
                <input type="text" value={shareUrl} readOnly className="flex-1 bg-transparent text-white p-2 outline-none" />
                <button onClick={handleCopyUrl} className={`px-4 py-2 flex items-center space-x-2 transition-colors ${ copied ? 'text-green-500' : 'text-orange-500 hover:text-orange-400' }`} aria-label="Copy URL">
                  {copied ? ( <><Check size={16} /><span className="text-sm">Copied!</span></> ) : ( <><Clipboard size={16} /><span className="text-sm">Copy</span></> )}
                </button>
              </div>
            </div>
            <p className="text-gray-400 text-sm">Anyone with this link can view this thread</p>
          </>
        ) : ( // Initial state before clicking share
           <p className="text-gray-300 mb-4">Generate a public link to share this conversation.</p>
        )}
        <div className="flex justify-end mt-6">
          <button onClick={() => setShowShareModal(false)} className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors">Close</button>
        </div>
      </div>
    </div>
  );

  // --- Render ---
  return (
    <>
      <header className={`flex justify-between items-center p-3 bg-[#1a1a1a] w-full z-30
       ${isLanding ? `${isSidebarOpen ? 'sm:ml-72' : 'ml-0'} ${sidebarPosition === 'right' && isSidebarOpen ? 'sm:mr-72' : 'mr-0' }` : 'ml-0 mr-0'}
        transition-all duration-300`}>
        {/* Left side */}
        <div className="flex items-center">
          <button onClick={onToggleSidebar} className="lg:hidden p-1.5 rounded hover:bg-[#2a2a2a] text-gray-400 hover:text-white transition-colors mr-2" aria-label="Toggle Sidebar">
            {sidebarPosition === 'left' ? <PanelRight size={15} /> : <PanelLeft size={15} />}
          </button>
          {sidebarPosition === 'left' && !isSidebarOpen && (
            <button onClick={onToggleSidebar} className="hidden lg:flex p-1.5 rounded hover:bg-[#2a2a2a] text-gray-400 hover:text-white transition-colors mr-2" aria-label="Open Sidebar" title="Open Sidebar">
              <PanelRight size={15} />
            </button>
          )}
          <div className="hidden lg:flex items-center cursor-pointer ml-2" onClick={() => window.location.href = '/'}>
            <img src="/output.webp" alt="Henosis Logo" className="h-6 w-6 mr-2" />
            <div className="text-lg font-bold">henosis</div>
          </div>
        </div>

        {/* Center - Model Selector */}
        { !isLanding && !threadsLoading && (
            <div className="relative mx-4 min-w-[120px] max-w-[280px] flex-shrink" ref={dropdownRef}>
            <button
                onClick={() => setIsModelDropdownOpen(!isModelDropdownOpen)}
                className="w-full flex items-center justify-between px-4 py-2 bg-[#2a2a2a] rounded-lg hover:bg-[#3a3a3a] transition-colors"
                aria-haspopup="listbox" aria-expanded={isModelDropdownOpen} aria-label="Select Model" title="Select Model"
            >
                <span className="text-sm font-medium truncate pr-2">{getCurrentModelLabel()}</span>
                <ChevronDown size={16} className={`transform transition-transform duration-200 flex-shrink-0 ${isModelDropdownOpen ? 'rotate-180' : ''}`} />
            </button>
            {/* Dropdown List */}
            {isModelDropdownOpen && (
              <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-64 py-1 bg-[#2a2a2a] rounded-lg shadow-xl z-50 max-h-96 overflow-y-auto scrollbar-thin scrollbar-thumb-[#3a3a3a] hover:scrollbar-thumb-gray-300 scrollbar-track-[#1a1a1a] scrollbar-thumb-rounded-full"
                  role="listbox">
                {modelCategories.map(category => {
                  const modelsInCategory = modelsData.filter(model => model.category === category);
                  if (modelsInCategory.length === 0) return null;
                  return (
                    <div key={category} className="relative">
                      {/* Category header with full-width background */}
                      <div className="sticky top-0 z-10">
                        <div className="px-3 py-1.5 text-xs font-semibold text-orange-500 bg-[#2a2a2a]">
                          {category}
                        </div>
                        {/* Gradient fade effect below the header */}
                        <div className="h-1 bg-gradient-to-b from-[#2a2a2a] to-transparent"></div>
                      </div>
                      
                      {/* Models list with padding to prevent overlap */}
                      <div className="pt-1">
                        {modelsInCategory.map((model) => (
                          <button
                            key={model.value}
                            onClick={() => handleModelSelect(model.value)}
                            onMouseEnter={(e) => handleModelMouseEnter(e, model)}
                            onMouseLeave={handleModelMouseLeave}
                            className={`w-full text-left px-3 py-1.5 text-sm hover:bg-[#3a3a3a] transition-colors flex justify-between items-center ${
                              currentlySelectedModel === model.value ? 'bg-orange-600 text-white' : 'text-gray-200'
                            }`}
                            role="option" aria-selected={currentlySelectedModel === model.value}
                          >
                            <span className="flex-1 truncate mr-2">{model.label}</span>
                          </button>
                        ))}
                      </div>
                      
                      {/* Add space between categories */}
                      <div className="h-2"></div>
                    </div>
                  );
                })}
              </div>
          )}
            </div>
        )}

        {/* Right side */}
        <div className="flex items-center space-x-2">
            {!isLanding && (
                <>
                    <button onClick={handleShareClick} className={`p-1.5 rounded transition-colors ${ isSharing ? 'bg-gray-600 cursor-not-allowed text-gray-400' : ' hover:bg-[#2a2a2a] text-gray-400 hover:text-white' }`} aria-label="Share Thread" title="Share Thread" disabled={isSharing}>
                        <Share2 size={15} />
                    </button>
                    <button onClick={onNewChat} className="p-1.5 rounded hover:bg-[#2a2a2a] text-gray-400 hover:text-white transition-colors" aria-label="New Chat" title="New Chat">
                        <MessageCircle size={15} className="relative">
                            <Plus size={8} className="absolute top-[1px] left-[1px]" strokeWidth={3} />
                        </MessageCircle>
                    </button>
                    <button onClick={onSettingsToggle} className="p-1.5 rounded hover:bg-[#2a2a2a] text-gray-400 hover:text-white transition-colors" aria-label="Settings" title="Settings">
                        <Settings size={15} />
                    </button>
                </>
            )}
          {sidebarPosition === 'right' && !isSidebarOpen &&(
              <button onClick={onToggleSidebar} className="hidden lg:flex p-1.5 rounded hover:bg-[#2a2a2a] text-gray-400 hover:text-white transition-colors ml-2" aria-label="Open Sidebar" title="Open Sidebar">
                  <PanelLeft size={15} />
              </button>
          )}
        </div>
      </header>

      {/* Render Popup - Added ID for hover check */}
      <div
         id="model-info-popup-id" // Added ID
         onMouseEnter={handlePopupMouseEnter}
         onMouseLeave={handlePopupMouseLeave}
       >
          <ModelInfoPopup model={hoveredModel} position={popupPosition} />
      </div>

      {/* Modals */}
      {showShareConfirm && <ShareConfirmModal />}
      {showShareModal && <ShareModal />}
    </>
  );
};

export default Header;