import React, { useState } from 'react';
import {
  Grid,
  Link,
  ExternalLink,
  Search,
  Zap,
  Settings,
  HelpCircle,
  Check,
  Lock,
  RefreshCw,
  Edit,
  Code,
  Database,
  Server,
  Smartphone,
  Globe,
  Cloud,
  AlertTriangle,
  Shield,
  Inbox,
  ThumbsUp,
  RefreshCcw,
  Sliders,
  PlusCircle,
  CheckCircle,
  X,
  ChevronRight,
  ArrowRight,
  Info,
} from 'lucide-react';

const IntegrationsPage = () => {
  const [activeTab, setActiveTab] = useState('connected');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  
  // Sample integration data
  const connectedIntegrations = [
    {
      id: 1,
      name: "Salesforce CRM",
      category: "crm",
      description: "Bi-directional lead sync with automatic qualification status updates",
      icon: "/salesforce-logo.png",
      lastSync: "5 minutes ago",
      status: "active",
      connectedOn: "Aug 15, 2023"
    },
    {
      id: 2,
      name: "HubSpot Marketing",
      category: "marketing",
      description: "Sync lead scores and campaign attribution data",
      icon: "/hubspot-logo.png",
      lastSync: "1 hour ago",
      status: "active",
      connectedOn: "Jun 3, 2023"
    },
    {
      id: 3,
      name: "Google Analytics",
      category: "analytics",
      description: "Website visitor tracking and behavior analysis",
      icon: "/ga-logo.png",
      lastSync: "3 hours ago",
      status: "active",
      connectedOn: "May 12, 2023"
    },
    {
      id: 4,
      name: "Marketo",
      category: "marketing",
      description: "Lead nurturing campaign integration",
      icon: "/marketo-logo.png",
      lastSync: "2 days ago",
      status: "inactive",
      connectedOn: "Feb 8, 2023"
    }
  ];
  
  const availableIntegrations = [
    {
      id: 5,
      name: "Microsoft Dynamics",
      category: "crm",
      description: "Enterprise CRM integration with custom field mapping",
      icon: "/dynamics-logo.png",
      popularityScore: 92
    },
    {
      id: 6,
      name: "Mailchimp",
      category: "email",
      description: "Email marketing campaign integration and subscriber sync",
      icon: "/mailchimp-logo.png",
      popularityScore: 88
    },
    {
      id: 7,
      name: "Zapier",
      category: "automation",
      description: "Connect with 3,000+ apps through automated workflows",
      icon: "/zapier-logo.png",
      popularityScore: 95
    },
    {
      id: 8,
      name: "Slack",
      category: "communication",
      description: "Real-time lead qualification notifications and alerts",
      icon: "/slack-logo.png",
      popularityScore: 90
    },
    {
      id: 9,
      name: "Segment",
      category: "analytics",
      description: "Customer data platform for unified tracking and analysis",
      icon: "/segment-logo.png",
      popularityScore: 87
    },
    {
      id: 10,
      name: "Intercom",
      category: "customer_support",
      description: "Customer messaging platform with lead data enrichment",
      icon: "/intercom-logo.png",
      popularityScore: 89
    },
    {
      id: 11,
      name: "Zoom",
      category: "webinar",
      description: "Webinar and meeting integration for lead capture",
      icon: "/zoom-logo.png",
      popularityScore: 86
    },
    {
      id: 12,
      name: "LinkedIn Sales Navigator",
      category: "social",
      description: "B2B social selling tool integration for lead enrichment",
      icon: "/linkedin-logo.png",
      popularityScore: 91
    }
  ];
  
  // Filter integrations based on search and category
  const filterIntegrations = (integrations) => {
    return integrations.filter(integration => {
      const matchesSearch = integration.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          integration.description.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesCategory = selectedCategory === 'all' || integration.category === selectedCategory;
      
      return matchesSearch && matchesCategory;
    });
  };
  
  const filteredConnected = filterIntegrations(connectedIntegrations);
  const filteredAvailable = filterIntegrations(availableIntegrations);
  
  // Categories for filtering
  const categories = [
    { id: 'all', name: 'All Categories' },
    { id: 'crm', name: 'CRM' },
    { id: 'marketing', name: 'Marketing' },
    { id: 'analytics', name: 'Analytics' },
    { id: 'email', name: 'Email' },
    { id: 'automation', name: 'Automation' },
    { id: 'communication', name: 'Communication' },
    { id: 'customer_support', name: 'Customer Support' },
    { id: 'webinar', name: 'Webinar & Events' },
    { id: 'social', name: 'Social Media' }
  ];
  
  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Header */}
      <header className="bg-white border-b border-gray-200 sticky top-0 z-40">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <div className="bg-teal-500 h-8 w-8 rounded-md flex items-center justify-center mr-2">
                  <Link className="h-5 w-5 text-white" />
                </div>
                <span className="font-bold text-xl text-gray-900">Lead<span className="text-teal-500">Qualify</span></span>
              </div>
              <nav className="hidden sm:ml-6 sm:flex sm:space-x-8">
                <button
                  onClick={() => setActiveTab('connected')}
                  className={`border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                    activeTab === 'connected' ? 'border-teal-500 text-teal-600' : ''
                  }`}
                >
                  Connected Integrations
                </button>
                <button
                  onClick={() => setActiveTab('available')}
                  className={`border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                    activeTab === 'available' ? 'border-teal-500 text-teal-600' : ''
                  }`}
                >
                  Available Integrations
                </button>
                <button
                  onClick={() => setActiveTab('api')}
                  className={`border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                    activeTab === 'api' ? 'border-teal-500 text-teal-600' : ''
                  }`}
                >
                  API & Webhooks
                </button>
                <button
                  onClick={() => setActiveTab('data')}
                  className={`border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm ${
                    activeTab === 'data' ? 'border-teal-500 text-teal-600' : ''
                  }`}
                >
                  Data Management
                </button>
              </nav>
            </div>
            <div className="flex items-center">
              <button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                <span className="sr-only">Help</span>
                <HelpCircle className="h-6 w-6" aria-hidden="true" />
              </button>
              <button className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                <Zap className="h-4 w-4 mr-2" />
                Add Integration
              </button>
            </div>
          </div>
        </div>
      </header>

      {/* Main content */}
      <main className="py-6">
        <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
          {/* Search and filter section */}
          <div className="bg-white shadow rounded-lg mb-6">
            <div className="px-4 py-5 sm:p-6">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="md:col-span-2">
                  <label htmlFor="search" className="sr-only">Search integrations</label>
                  <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Search className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                      type="text"
                      name="search"
                      id="search"
                      className="focus:ring-teal-500 focus:border-teal-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                      placeholder="Search integrations..."
                      value={searchTerm}
                      onChange={e => setSearchTerm(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="category" className="sr-only">Category</label>
                  <select
                    id="category"
                    name="category"
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm rounded-md"
                    value={selectedCategory}
                    onChange={e => setSelectedCategory(e.target.value)}
                  >
                    {categories.map(category => (
                      <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          
          {activeTab === 'connected' && (
            <div>
              <div className="bg-white shadow rounded-lg mb-6">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Connected Integrations</h3>
                  <p className="mt-1 text-sm text-gray-500">Manage your active integrations and data connections.</p>
                </div>
                
                {filteredConnected.length > 0 ? (
                  <div className="divide-y divide-gray-200">
                    {filteredConnected.map(integration => (
                      <div key={integration.id} className="px-4 py-6 sm:px-6">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-12 w-12 bg-gray-100 rounded-md flex items-center justify-center">
                              {/* Placeholder for logo */}
                              <span className="text-xs font-medium text-gray-500">{integration.name.substring(0, 2).toUpperCase()}</span>
                            </div>
                            <div className="ml-4">
                              <h4 className="text-lg font-medium text-gray-900">{integration.name}</h4>
                              <div className="flex items-center mt-1">
                                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                  integration.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-yellow-100 text-yellow-800'
                                }`}>
                                  {integration.status === 'active' ? 'Active' : 'Inactive'}
                                </span>
                                <span className="text-xs text-gray-500 ml-2">Connected on {integration.connectedOn}</span>
                              </div>
                            </div>
                          </div>
                          <div className="flex items-center">
                            <span className="text-sm text-gray-500 mr-4">Last synced: {integration.lastSync}</span>
                            <div className="flex space-x-2">
                              <button className="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                                <RefreshCw className="h-4 w-4" />
                              </button>
                              <button className="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                                <Settings className="h-4 w-4" />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="mt-4">
                          <p className="text-sm text-gray-500">{integration.description}</p>
                        </div>
                        <div className="mt-4 flex justify-between">
                          <div className="flex items-center text-sm text-gray-500">
                            <div className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-100 text-gray-800 capitalize">
                              {integration.category.replace('_', ' ')}
                            </div>
                          </div>
                          <button className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-teal-700 bg-teal-100 hover:bg-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                            View Details
                            <ChevronRight className="ml-1 h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="px-4 py-12 sm:px-6 text-center">
                    <p className="text-gray-500">No connected integrations match your search criteria.</p>
                  </div>
                )}
              </div>
              
              {/* Connection health dashboard */}
              <div className="bg-white shadow rounded-lg mb-6">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Integration Health</h3>
                  <p className="mt-1 text-sm text-gray-500">Monitor the status and performance of your data connections.</p>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    <div className="bg-gray-50 rounded-lg p-4">
                      <h4 className="text-sm font-medium text-gray-500 mb-2">API Usage This Month</h4>
                      <div className="flex items-baseline">
                        <span className="text-2xl font-semibold text-gray-900">23,456</span>
                        <span className="ml-2 text-sm text-gray-500">/ 50,000 calls</span>
                      </div>
                      <div className="mt-2 w-full bg-gray-200 rounded-full h-2.5">
                        <div className="bg-teal-500 h-2.5 rounded-full" style={{ width: '47%' }}></div>
                      </div>
                      <p className="mt-2 text-xs text-gray-500">47% of monthly allocation used</p>
                    </div>
                    
                    <div className="bg-gray-50 rounded-lg p-4">
                      <h4 className="text-sm font-medium text-gray-500 mb-2">Last 24 Hour Activity</h4>
                      <div className="flex items-baseline">
                        <span className="text-2xl font-semibold text-gray-900">1,248</span>
                        <span className="ml-2 text-sm text-green-600">+12% from average</span>
                      </div>
                      <div className="mt-2 h-10 flex items-end space-x-1">
                        {[30, 45, 25, 60, 75, 45, 80, 90, 65, 55, 40, 50].map((height, i) => (
                          <div 
                            key={i}
                            className="w-full bg-teal-500 rounded-t"
                            style={{ height: `${height}%` }}
                          ></div>
                        ))}
                      </div>
                      <div className="mt-2 text-xs text-gray-500 flex justify-between">
                        <span>12 AM</span>
                        <span>12 PM</span>
                        <span>11 PM</span>
                      </div>
                    </div>
                    
                    <div className="bg-gray-50 rounded-lg p-4">
                      <h4 className="text-sm font-medium text-gray-500 mb-2">System Status</h4>
                      <div className="space-y-2 mb-2">
                        <div className="flex justify-between items-center">
                          <span className="text-sm text-gray-600">All Systems Operational</span>
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            <Check className="h-2 w-2 mr-1" />
                            Active
                          </span>
                        </div>
                        {connectedIntegrations.filter(i => i.status === 'active').map(integration => (
                          <div key={integration.id} className="flex justify-between items-center">
                            <span className="text-sm text-gray-600">{integration.name}</span>
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              <Check className="h-2 w-2 mr-1" />
                              Connected
                            </span>
                          </div>
                        ))}
                        {connectedIntegrations.filter(i => i.status === 'inactive').map(integration => (
                          <div key={integration.id} className="flex justify-between items-center">
                            <span className="text-sm text-gray-600">{integration.name}</span>
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                              <AlertTriangle className="h-2 w-2 mr-1" />
                              Needs Attention
                            </span>
                          </div>
                        ))}
                      </div>
                      <button className="mt-2 text-sm text-teal-600 hover:text-teal-700 flex items-center">
                        View detailed status
                        <ArrowRight className="ml-1 h-3 w-3" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Recent sync history */}
              <div className="bg-white shadow rounded-lg">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200 flex justify-between items-center">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Recent Sync Activity</h3>
                    <p className="mt-1 text-sm text-gray-500">Data synchronization logs across all integrations.</p>
                  </div>
                  <button className="inline-flex items-center px-3 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                    View All Logs
                  </button>
                </div>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Time
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Integration
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Activity
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Records
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          5 mins ago
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-8 w-8 bg-gray-100 rounded-md flex items-center justify-center">
                              <span className="text-xs font-medium text-gray-500">SF</span>
                            </div>
                            <div className="ml-3">
                              <div className="text-sm font-medium text-gray-900">Salesforce CRM</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Lead data sync (outbound)
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          38 records
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            <Check className="h-3 w-3 mr-1" />
                            Completed
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          32 mins ago
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-8 w-8 bg-gray-100 rounded-md flex items-center justify-center">
                              <span className="text-xs font-medium text-gray-500">HB</span>
                            </div>
                            <div className="ml-3">
                              <div className="text-sm font-medium text-gray-900">HubSpot Marketing</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Campaign attribution update
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          142 records
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            <Check className="h-3 w-3 mr-1" />
                            Completed
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          1 hour ago
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-8 w-8 bg-gray-100 rounded-md flex items-center justify-center">
                              <span className="text-xs font-medium text-gray-500">GA</span>
                            </div>
                            <div className="ml-3">
                              <div className="text-sm font-medium text-gray-900">Google Analytics</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Website visitor data import
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          823 records
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            <Check className="h-3 w-3 mr-1" />
                            Completed
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          3 hours ago
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-8 w-8 bg-gray-100 rounded-md flex items-center justify-center">
                              <span className="text-xs font-medium text-gray-500">SF</span>
                            </div>
                            <div className="ml-3">
                              <div className="text-sm font-medium text-gray-900">Salesforce CRM</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Lead qualification status update
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          65 records
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                            <AlertTriangle className="h-3 w-3 mr-1" />
                            Partial (5 errors)
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          1 day ago
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="flex items-center">
                            <div className="flex-shrink-0 h-8 w-8 bg-gray-100 rounded-md flex items-center justify-center">
                              <span className="text-xs font-medium text-gray-500">MK</span>
                            </div>
                            <div className="ml-3">
                              <div className="text-sm font-medium text-gray-900">Marketo</div>
                            </div>
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Connection validation
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          N/A
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                            <X className="h-3 w-3 mr-1" />
                            Failed
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
          
          {activeTab === 'available' && (
            <div>
              {/* Featured integrations */}
              <div className="bg-white shadow rounded-lg mb-6">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Popular Integrations</h3>
                  <p className="mt-1 text-sm text-gray-500">Top integrations used by LeadQualify customers.</p>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
                    {filteredAvailable.slice(0, 4).map(integration => (
                      <div key={integration.id} className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex flex-col items-center hover:border-teal-500 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-teal-500">
                        <div className="flex-shrink-0 h-16 w-16 bg-gray-100 rounded-md flex items-center justify-center">
                          <span className="text-base font-medium text-gray-500">{integration.name.substring(0, 2).toUpperCase()}</span>
                        </div>
                        <div className="mt-4 text-center">
                          <h3 className="text-base font-medium text-gray-900">{integration.name}</h3>
                          <p className="mt-1 text-sm text-gray-500 line-clamp-2">{integration.description}</p>
                        </div>
                        <button className="mt-4 inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-teal-700 bg-teal-100 hover:bg-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                          Connect
                          <ArrowRight className="ml-1 h-4 w-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              
              {/* All available integrations */}
              <div className="bg-white shadow rounded-lg overflow-hidden">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">All Available Integrations</h3>
                  <p className="mt-1 text-sm text-gray-500">Connect your favorite tools and services with LeadQualify.</p>
                </div>
                
                {filteredAvailable.length > 0 ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 divide-x divide-y divide-gray-200">
                    {filteredAvailable.map(integration => (
                      <div key={integration.id} className="p-6 hover:bg-gray-50">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-12 w-12 bg-gray-100 rounded-md flex items-center justify-center">
                            <span className="text-base font-medium text-gray-500">{integration.name.substring(0, 2).toUpperCase()}</span>
                          </div>
                          <div className="ml-4">
                            <h4 className="text-base font-medium text-gray-900">{integration.name}</h4>
                            <div className="flex items-center mt-1">
                              <div className="inline-flex items-center px-2.5 py-0.5 rounded-md text-xs font-medium bg-gray-100 text-gray-800 capitalize">
                                {integration.category.replace('_', ' ')}
                              </div>
                              
                              {integration.popularityScore >= 90 && (
                                <span className="inline-flex items-center ml-2 px-2.5 py-0.5 rounded-full text-xs font-medium bg-teal-100 text-teal-800">
                                  <ThumbsUp className="h-3 w-3 mr-1" />
                                  Popular
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <p className="mt-3 text-sm text-gray-500">{integration.description}</p>
                        <div className="mt-4 flex justify-end">
                          <button className="inline-flex items-center px-3 py-1 border border-transparent text-sm leading-4 font-medium rounded-md text-teal-700 bg-teal-100 hover:bg-teal-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                            Connect
                            <ArrowRight className="ml-1 h-4 w-4" />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="px-4 py-12 sm:px-6 text-center">
                    <p className="text-gray-500">No integrations match your search criteria.</p>
                  </div>
                )}
              </div>
            </div>
          )}
          
          {activeTab === 'api' && (
            <div>
              {/* API keys management */}
              <div className="bg-white shadow rounded-lg mb-6">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">API Keys</h3>
                  <p className="mt-1 text-sm text-gray-500">Manage API keys for programmatic access to your data.</p>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <div className="bg-teal-50 border border-teal-200 rounded-md p-4 mb-6">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <Info className="h-5 w-5 text-teal-400" aria-hidden="true" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-teal-800">API Access Information</h3>
                        <div className="mt-2 text-sm text-teal-700">
                          <p>
                            Your API keys grant full access to your LeadQualify account. Keep them secure and never share them in public repositories or client-side code.
                          </p>
                        </div>
                        <div className="mt-3">
                          <a href="#" className="text-sm font-medium text-teal-600 hover:text-teal-500 flex items-center">
                            View API documentation
                            <ArrowRight className="ml-1 h-4 w-4" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  <div className="space-y-4">
                    <div className="border rounded-md p-4">
                      <div className="flex justify-between items-start">
                        <div>
                          <h4 className="text-base font-medium text-gray-900">Production API Key</h4>
                          <div className="flex items-center mt-1">
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              Active
                            </span>
                            <span className="text-sm text-gray-500 ml-2">Created on Aug 1, 2023</span>
                          </div>
                          <div className="mt-2 flex items-center">
                            <div className="bg-gray-100 px-3 py-1.5 rounded-md flex items-center">
                              <Lock className="h-4 w-4 text-gray-500 mr-2" />
                              <span className="font-mono text-sm text-gray-500">••••••••••••••••••••••••</span>
                            </div>
                            <button className="ml-2 text-sm text-gray-500 hover:text-gray-700">
                              Copy
                            </button>
                            <button className="ml-2 text-sm text-gray-500 hover:text-gray-700">
                              Reveal
                            </button>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <button className="inline-flex items-center px-3 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                            <RefreshCcw className="h-4 w-4 mr-1" />
                            Rotate Key
                          </button>
                        </div>
                      </div>
                    </div>
                    
                    <div className="border rounded-md p-4">
                      <div className="flex justify-between items-start">
                        <div>
                          <h4 className="text-base font-medium text-gray-900">Development API Key</h4>
                          <div className="flex items-center mt-1">
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                              Testing Only
                            </span>
                            <span className="text-sm text-gray-500 ml-2">Created on Aug 15, 2023</span>
                          </div>
                          <div className="mt-2 flex items-center">
                            <div className="bg-gray-100 px-3 py-1.5 rounded-md flex items-center">
                              <Lock className="h-4 w-4 text-gray-500 mr-2" />
                              <span className="font-mono text-sm text-gray-500">••••••••••••••••••••••••</span>
                            </div>
                            <button className="ml-2 text-sm text-gray-500 hover:text-gray-700">
                              Copy
                            </button>
                            <button className="ml-2 text-sm text-gray-500 hover:text-gray-700">
                              Reveal
                            </button>
                          </div>
                        </div>
                        <div className="flex items-center">
                          <button className="inline-flex items-center px-3 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                            <RefreshCcw className="h-4 w-4 mr-1" />
                            Rotate Key
                          </button>
                        </div>
                      </div>
                    </div>
                    
                    <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                      <PlusCircle className="h-4 w-4 mr-2" />
                      Generate New API Key
                    </button>
                  </div>
                </div>
              </div>
              
              {/* Webhook configuration */}
              <div className="bg-white shadow rounded-lg mb-6">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200 flex justify-between">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Webhooks</h3>
                    <p className="mt-1 text-sm text-gray-500">Configure webhooks to receive real-time data updates.</p>
                  </div>
                  <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                    <PlusCircle className="h-4 w-4 mr-2" />
                    Add Webhook
                  </button>
                </div>
                <div className="divide-y divide-gray-200">
                  <div className="px-4 py-6 sm:px-6">
                    <div className="flex justify-between items-center">
                      <div>
                        <h4 className="text-base font-medium text-gray-900">Lead Qualification Webhook</h4>
                        <div className="flex items-center mt-1">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Active
                          </span>
                        </div>
                        <div className="mt-2">
                          <a href="https://example.com/webhooks/qualified-leads" className="text-sm text-gray-500 hover:text-gray-700 font-mono">
                            https://example.com/webhooks/qualified-leads
                          </a>
                        </div>
                      </div>
                      <div className="flex space-x-2">
                        <button className="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                          <Edit className="h-4 w-4" />
                        </button>
                        <button className="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                          <Code className="h-4 w-4" />
                        </button>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500">Triggers on: Lead qualification status change</span>
                        <span className="text-gray-500">Last triggered: 38 minutes ago</span>
                      </div>
                    </div>
                  </div>
                  
                  <div className="px-4 py-6 sm:px-6">
                    <div className="flex justify-between items-center">
                      <div>
                        <h4 className="text-base font-medium text-gray-900">New Lead Webhook</h4>
                        <div className="flex items-center mt-1">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Active
                          </span>
                        </div>
                        <div className="mt-2">
                          <a href="https://api.yourcrm.com/incoming/leads" className="text-sm text-gray-500 hover:text-gray-700 font-mono">
                            https://api.yourcrm.com/incoming/leads
                          </a>
                        </div>
                      </div>
                      <div className="flex space-x-2">
                        <button className="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                          <Edit className="h-4 w-4" />
                        </button>
                        <button className="inline-flex items-center p-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                          <Code className="h-4 w-4" />
                        </button>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="flex justify-between text-sm">
                        <span className="text-gray-500">Triggers on: New lead creation</span>
                        <span className="text-gray-500">Last triggered: 2 hours ago</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* API sandbox */}
              <div className="bg-white shadow rounded-lg">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">API Sandbox</h3>
                  <p className="mt-1 text-sm text-gray-500">Test API calls and explore endpoints in a safe environment.</p>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <div className="bg-gray-50 p-6 rounded-md">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                      <div>
                        <label htmlFor="request" className="block text-sm font-medium text-gray-700 mb-2">Request</label>
                        <div className="flex mb-2">
                          <select className="max-w-xs inline-flex items-center px-3 py-2 border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm rounded-l-md">
                            <option>GET</option>
                            <option>POST</option>
                            <option>PUT</option>
                            <option>DELETE</option>
                          </select>
                          <input
                            type="text"
                            id="endpoint"
                            className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-teal-500 focus:border-teal-500 sm:text-sm border-gray-300"
                            placeholder="e.g. /api/v1/leads"
                          />
                        </div>
                        <div className="border rounded-md">
                          <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-md">
                            <div className="flex justify-between">
                              <span className="text-xs font-medium text-gray-700">Headers</span>
                              <button className="text-xs text-teal-600 hover:text-teal-500">Add header</button>
                            </div>
                          </div>
                          <div className="p-3 h-28 bg-white rounded-b-md">
                            <div className="text-sm text-gray-700 font-mono">
                              <div>Content-Type: application/json</div>
                              <div>Authorization: Bearer api_key_...</div>
                            </div>
                          </div>
                        </div>
                        
                        <div className="mt-4">
                          <label htmlFor="request_body" className="block text-sm font-medium text-gray-700 mb-2">Request Body</label>
                          <div className="border rounded-md">
                            <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-md">
                              <span className="text-xs font-medium text-gray-700">JSON</span>
                            </div>
                            <div className="p-3 h-40 bg-white rounded-b-md">
                              <pre className="text-sm text-gray-700 font-mono">
{`{
  "query": {
    "score_gt": 80,
    "source": "website"
  },
  "limit": 10
}`}
                              </pre>
                            </div>
                          </div>
                        </div>
                        
                        <div className="mt-4">
                          <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                            Send Request
                          </button>
                        </div>
                      </div>
                      
                      <div>
                        <div className="flex justify-between items-center mb-2">
                          <label htmlFor="response" className="block text-sm font-medium text-gray-700">Response</label>
                          <span className="text-xs font-medium text-green-700 bg-green-100 px-2 py-1 rounded-full">200 OK</span>
                        </div>
                        <div className="border rounded-md">
                          <div className="px-3 py-2 bg-gray-100 border-b border-gray-200 rounded-t-md">
                            <div className="flex justify-between">
                              <span className="text-xs font-medium text-gray-700">Response Body</span>
                              <span className="text-xs text-gray-500">523 ms</span>
                            </div>
                          </div>
                          <div className="p-3 h-96 overflow-auto bg-white rounded-b-md">
                            <pre className="text-sm text-gray-700 font-mono">
{`{
  "status": "success",
  "data": {
    "leads": [
      {
        "id": "lead_123abc",
        "email": "john.doe@example.com",
        "first_name": "John",
        "last_name": "Doe",
        "company": "Acme Corp",
        "score": 92,
        "status": "qualified",
        "source": "website",
        "created_at": "2023-09-12T14:32:09Z",
        "last_activity": "2023-09-14T09:12:45Z"
      },
      {
        "id": "lead_456def",
        "email": "jane.smith@company.org",
        "first_name": "Jane",
        "last_name": "Smith",
        "company": "Company Inc",
        "score": 88,
        "status": "qualified",
        "source": "website",
        "created_at": "2023-09-11T08:45:22Z",
        "last_activity": "2023-09-13T16:08:12Z"
      }
      // Additional results truncated
    ],
    "pagination": {
      "total": 37,
      "page": 1,
      "limit": 10,
      "has_more": true
    }
  }
}`}
                            </pre>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          
          {activeTab === 'data' && (
            <div>
              {/* Data management options */}
              <div className="bg-white shadow rounded-lg mb-6">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Data Management</h3>
                  <p className="mt-1 text-sm text-gray-500">Control how your data is stored, accessed, and synchronized.</p>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    <div className="rounded-lg border border-gray-300 bg-white p-6 shadow-sm">
                      <div className="flex items-center">
                        <div className="h-12 w-12 rounded-md bg-teal-100 flex items-center justify-center">
                          <Database className="h-6 w-6 text-teal-600" />
                        </div>
                        <h3 className="ml-3 text-lg font-medium text-gray-900">Data Import</h3>
                      </div>
                      <div className="mt-4">
                        <p className="text-sm text-gray-500">Import lead data from CSV, Excel, or directly from your CRM system.</p>
                      </div>
                      <div className="mt-6">
                        <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                          Import Data
                        </button>
                      </div>
                    </div>
                    
                    <div className="rounded-lg border border-gray-300 bg-white p-6 shadow-sm">
                      <div className="flex items-center">
                        <div className="h-12 w-12 rounded-md bg-teal-100 flex items-center justify-center">
                          <Server className="h-6 w-6 text-teal-600" />
                        </div>
                        <h3 className="ml-3 text-lg font-medium text-gray-900">Data Export</h3>
                      </div>
                      <div className="mt-4">
                        <p className="text-sm text-gray-500">Export your lead data and analytics in various formats.</p>
                      </div>
                      <div className="mt-6">
                        <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                          Export Data
                        </button>
                      </div>
                    </div>
                    
                    <div className="rounded-lg border border-gray-300 bg-white p-6 shadow-sm">
                      <div className="flex items-center">
                        <div className="h-12 w-12 rounded-md bg-teal-100 flex items-center justify-center">
                          <RefreshCcw className="h-6 w-6 text-teal-600" />
                        </div>
                        <h3 className="ml-3 text-lg font-medium text-gray-900">Sync Settings</h3>
                      </div>
                      <div className="mt-4">
                        <p className="text-sm text-gray-500">Configure synchronization frequency and conflict resolution rules.</p>
                      </div>
                      <div className="mt-6">
                        <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                          Manage Sync
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Data field mapping */}
              <div className="bg-white shadow rounded-lg mb-6">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200 flex justify-between">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Field Mapping</h3>
                    <p className="mt-1 text-sm text-gray-500">Define how data fields map between LeadQualify and your connected systems.</p>
                  </div>
                  <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
                    <PlusCircle className="h-4 w-4 mr-2" />
                    Add Custom Field
                  </button>
                </div>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          LeadQualify Field
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Type
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Salesforce Field
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          HubSpot Field
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Status
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Actions</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          email
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Email
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Email
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          email
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Active
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-teal-600 hover:text-teal-900">Edit</button>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          first_name
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Text
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          FirstName
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          firstname
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Active
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-teal-600 hover:text-teal-900">Edit</button>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          last_name
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Text
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          LastName
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          lastname
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Active
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-teal-600 hover:text-teal-900">Edit</button>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          company
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Text
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Company
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          company
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Active
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-teal-600 hover:text-teal-900">Edit</button>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          lead_score
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Number
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Lead_Score__c
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          hs_lead_score
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Active
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-teal-600 hover:text-teal-900">Edit</button>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          qualification_status
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Picklist
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          LeadQual_Status__c
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          qualification_status
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                            <AlertTriangle className="h-3 w-3 mr-1" />
                            Mapping Issue
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-teal-600 hover:text-teal-900">Edit</button>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          industry
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Picklist
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          Industry
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          industry
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            Active
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                          <button className="text-teal-600 hover:text-teal-900">Edit</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              
              {/* Data privacy section */}
              <div className="bg-white shadow rounded-lg">
                <div className="px-4 py-5 sm:px-6 border-b border-gray-200">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">Data Privacy & Compliance</h3>
                  <p className="mt-1 text-sm text-gray-500">Manage data privacy settings and compliance requirements.</p>
                </div>
                <div className="px-4 py-5 sm:p-6">
                  <div className="space-y-6">
                    <div className="bg-gray-50 p-4 rounded-md">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <Shield className="h-5 w-5 text-teal-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-gray-900">Data Retention Policy</h3>
                          <div className="mt-2 text-sm text-gray-700">
                            <p>Currently set to retain lead data for <span className="font-medium">24 months</span> after last activity.</p>
                          </div>
                          <div className="mt-3">
                            <button className="text-sm font-medium text-teal-600 hover:text-teal-500">
                              Adjust Retention Period
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="bg-gray-50 p-4 rounded-md">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <Inbox className="h-5 w-5 text-teal-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-gray-900">Data Subject Access Requests</h3>
                          <div className="mt-2 text-sm text-gray-700">
                            <p>Tools to respond to data access, deletion, and portability requests.</p>
                          </div>
                          <div className="mt-3">
                            <button className="text-sm font-medium text-teal-600 hover:text-teal-500">
                              Manage DSAR Requests
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="bg-gray-50 p-4 rounded-md">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <Globe className="h-5 w-5 text-teal-400" aria-hidden="true" />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-gray-900">Compliance Settings</h3>
                          <div className="mt-2 text-sm text-gray-700">
                            <div className="space-y-2">
                              <div className="flex items-center">
                                <input
                                  id="gdpr-compliance"
                                  name="gdpr-compliance"
                                  type="checkbox"
                                  className="h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded"
                                  defaultChecked
                                />
                                <label htmlFor="gdpr-compliance" className="ml-3 text-sm text-gray-700">
                                  GDPR Compliance Mode
                                </label>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id="ccpa-compliance"
                                  name="ccpa-compliance"
                                  type="checkbox"
                                  className="h-4 w-4 text-teal-600 focus:ring-teal-500 border-gray-300 rounded"
                                  defaultChecked
                                />
                                <label htmlFor="ccpa-compliance" className="ml-3 text-sm text-gray-700">
                                  CCPA Compliance Mode
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3">
                            <button className="text-sm font-medium text-teal-600 hover:text-teal-500">
                              Configure Compliance Settings
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          
        </div>
      </main>
    </div>
  );
};

export default IntegrationsPage;