// MessageInput.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Paperclip, Send, Square, Globe, Mic } from 'lucide-react';
import PastesPreview from '../Common/PastesPreview';
import { modelsWithImage, modelsWithAudio } from '../../hooks/usePastes';
import { sanitizeFileName } from '../../utils/utils';
import ExperimentalTooltip from '../Common/ExperimentalTooltip';
import useSettings from '../../hooks/useSettings';
import useAudioRecording from '../../hooks/useAudioRecording';

const getImageFromClipboard = async (clipboardItems) => {
  for (const item of clipboardItems) {
    if (item.type.startsWith('image/')) {
      const blob = await item.getAsFile();
      return blob;
    }
  }
  return null;
};

const RecordingIndicator = () => (
  <div className="flex items-center space-x-2 text-sm text-red-500 ml-2">
    <div className="w-2 h-2 rounded-full bg-red-500 animate-pulse" />
    <span>Recording...</span>
  </div>
);

const MessageInput = ({
  onSendMessage,
  onStopGeneration,
  isGenerating,
  pastes,
  onDeletePaste,
  onFileUpload,
  inputMessage,
  setInputMessage,
  onAddPaste,
  currentlySelectedModel,
  experimentalFeatures,
  onToggleExperimental,
  isDisabled,
  disabledMessage,
}) => {
  const textareaRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const { isModelWithExperimental } = useSettings({ onLogout: () => {}, defaultModel: currentlySelectedModel });
  const isExperimentalSupported = isModelWithExperimental(currentlySelectedModel);
  const {
    isRecording,
    audioBlob,
    recordingTime,
    startRecording,
    stopRecording,
    resetRecording,
    getAudioData,
    error: recordingError,
  } = useAudioRecording((error) => {
    console.error('Recording error:', error);
    alert(`Audio recording failed: ${error.message}`);
  });

  const isAudioSupported = modelsWithAudio.some(model =>
    model.value === currentlySelectedModel
  );

  const adjustTextareaHeight = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, []);

  useEffect(() => {
    adjustTextareaHeight();
  }, [pastes, adjustTextareaHeight, inputMessage]);

  const handleImageFile = useCallback((file) => {
    const modelSupportsImages = modelsWithImage.some(model =>
      model.value === currentlySelectedModel ||
      currentlySelectedModel.includes('claude-3')
    );
    if (!modelSupportsImages) {
      alert('The current model does not support image uploads. Please switch to a model with image support.');
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileName = file.name || 'pasted-image.png';
      const sanitizedFileName = sanitizeFileName(fileName);
      const base64Data = e.target.result.split(',')[1];
      const imageContent = `[!image]{${sanitizedFileName}}|${base64Data}|[/!image]`;
      onAddPaste({
        type: 'image',
        fileName: sanitizedFileName,
        content: imageContent,
        mediaType: file.type,
        isImage: true
      });
    };
    reader.readAsDataURL(file);
  }, [currentlySelectedModel, onAddPaste]);

  const handlePaste = useCallback(async (e) => {
    const hasImages = Array.from(e.clipboardData.items).some(item =>
      item.type.startsWith('image/')
    );
    if (hasImages) {
      e.preventDefault();
      if (e.clipboardData.files.length > 0) {
        const file = e.clipboardData.files[0];
        if (file.type.startsWith('image/')) {
          handleImageFile(file);
        }
        return;
      }
      const image = await getImageFromClipboard(e.clipboardData.items);
      if (image) {
        handleImageFile(image);
      }
    }
  }, [handleImageFile]);

  const handleInputChange = useCallback(
    (e) => {
      const value = e.target.value;
      if (value.length > 2000) {
        const newPasteIndex = pastes.length + 1;
        const newPaste = {
          content: `<paste-${newPasteIndex}>${value}</paste-${newPasteIndex}>`,
          fileName: `Paste ${newPasteIndex}`,
        };
        onAddPaste(newPaste);
        setInputMessage('');
      } else {
        setInputMessage(value);
      }
      adjustTextareaHeight();
    },
    [pastes.length, adjustTextareaHeight, onAddPaste, setInputMessage]
  );

  const handleSendMessage = useCallback(() => {
    const message = inputMessage.trim();
    if (message || pastes.length > 0) {
      onSendMessage(message);
      setInputMessage('');
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
      }
    }
  }, [inputMessage, pastes.length, onSendMessage, setInputMessage]);

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
      }
    },
    [handleSendMessage]
  );

  const handleFileUploadWrapper = useCallback((event) => {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    const files = Array.from(event.target.files);
    const imageFiles = files.filter(file => file.type.startsWith('image/'));
    const modelSupportsImages = modelsWithImage.some(model =>
      model.value === currentlySelectedModel ||
      currentlySelectedModel.includes('claude-3')
    );
    if (imageFiles.length > 0 && !modelSupportsImages) {
      alert('The current model does not support image uploads. Please switch to a model with image support.');
      event.target.value = null;
      return;
    }
    files.forEach(file => {
      if (file.type.startsWith('image/')) {
        handleImageFile(file);
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target.result;
          const sanitizedFileName = sanitizeFileName(file.name);
          const fileTag = `<${sanitizedFileName}>${content}</${sanitizedFileName}>\n`;
          onAddPaste({
             type: 'text',
            fileName: sanitizedFileName,
            content: fileTag
          });
        };
        reader.readAsText(file);
      }
    });
    event.target.value = null;
  }, [currentlySelectedModel, onAddPaste, handleImageFile]);

  const handleAudioRecording = useCallback(async () => {
    try {
      if (isRecording) {
        await stopRecording();
        const audioData = await getAudioData();
        if (audioData) {
          const fileName = `recording-${Date.now()}.webm`;
          const audioContent = `[!audio]{${fileName}}|${audioData.base64}|[/!audio]`;
          onAddPaste({
            type: 'audio',
            fileName,
            content: audioContent,
            duration: recordingTime,
            isAudio: true
          });
        }
        resetRecording();
      } else {
        await startRecording();
      }
    } catch (error) {
      console.error('Audio recording error:', error);
      alert('Failed to handle audio recording: ' + error.message);
    }
  }, [isRecording, stopRecording, startRecording, getAudioData, onAddPaste, recordingTime, resetRecording]);

  return (
    // Add focus-within:border-[#505050] and transition-colors for smooth effect
    <div className={`p-4 md:p-2 bg-[#2a2a2a] rounded-lg border border-[#383838] focus-within:border-[#505050] transition-colors ${isDisabled ? 'opacity-50' : ''}`}>
      {isDisabled && (
        <div className="text-sm text-orange-400 mb-2 text-center">
          {disabledMessage}
        </div>
      )}
      {pastes.length > 0 && (
        <PastesPreview pastes={pastes} onDeletePaste={onDeletePaste} />
      )}
      <div className="flex items-start">
        <textarea
          ref={textareaRef}
          value={inputMessage}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          placeholder="Type a message..."
          className="flex-grow bg-transparent p-2 outline-none resize-none min-h-[40px] max-h-[200px] overflow-y-auto"
          rows="1"
          disabled={isDisabled}
        />
        <div className="flex-shrink-0 flex items-center sticky top-0 p-2">
          {/* Internet Access Toggle */}
          <div className="relative">
            <button
              onClick={onToggleExperimental}
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              className={`p-2 transition-colors ${
                isDisabled ? 'opacity-50 cursor-not-allowed' :
                !isExperimentalSupported
                  ? 'text-gray-600'
                  : experimentalFeatures
                    ? 'text-purple-400 hover:text-purple-300'
                    : 'text-gray-400 hover:text-gray-300'
              }`}
              disabled={isDisabled}
            >
              <Globe
                 size={22}
                className="transition-transform"
              />
            </button>
            {showTooltip && (
              <ExperimentalTooltip
                 isSupported={isExperimentalSupported}
                currentModel={currentlySelectedModel}
              />
            )}
          </div>
          {/* Microphone Button */}
          {isAudioSupported && (
            <button
              onClick={handleAudioRecording}
              className={`p-2 ${
                isRecording
                  ? 'text-red-500 hover:text-red-400'
                  : 'text-gray-400 hover:text-gray-300'
              } transition-colors ${
                isDisabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isDisabled}
            >
              <Mic size={20} />
            </button>
          )}
          {/* File Upload */}
          <button
            onClick={() => document.querySelector('input[type="file"]').click()}
            className={`p-2 text-gray-400 hover:text-gray-300 transition-colors ${
              isDisabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            disabled={isDisabled}
          >
            <Paperclip size={20} />
          </button>
          {/* Hidden File Input */}
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={handleFileUploadWrapper}
            multiple
            disabled={isDisabled}
          />
          {/* Recording Indicator */}
          {isAudioSupported && isRecording && (
            <RecordingIndicator />
          )}
          {/* Send/Stop Button */}
          {isGenerating ? (
            <button
              onClick={onStopGeneration}
              className={`bg-red-500 text-white p-1.5 rounded hover:bg-red-600 transition-colors ml-2 ${
                isDisabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isDisabled}
            >
              <Square size={20} />
            </button>
          ) : (
            <button
              onClick={handleSendMessage}
              className={`bg-orange-500 text-white p-1.5 rounded hover:bg-orange-600 transition-colors ml-2 ${
                isDisabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isDisabled}
            >
              <Send size={20} />
            </button>
          )}
        </div>
      </div>
      {/* Audio Preview */}
      {audioBlob && !isRecording && (
        <div className="mt-2">
          <audio controls src={URL.createObjectURL(audioBlob)} className="w-full" />
        </div>
      )}
    </div>
  );
};

export default MessageInput;